import React from "react";
import "./Styled.css";
function Form(props) {
  return (
    <form className="main-form" onSubmit={props.onSubmit}>
      {props.children}
    </form>
  );
}
export default Form;
