import React, { useEffect, useState } from "react";
import useAuctionContract from "../../Hooks/AuctionContractHook";
import useGameCentreContract from "../../Hooks/GameCentreHook";
import {
  GetAuction,
  GetCardFaction,
  GetCardInfo,
  GetCardType,
} from "../../UserPage/BlockChainFunctions";
// async function getGeneralDetails(gameCentreContract, auctionContract, TokenId) {
//   console.log(auctionContract, "auctionContract");
//   try {
//     const Faction = await gameCentreContract.GetCardFraction(TokenId);
//     const Value = await gameCentreContract.GetCardInfo(TokenId);
//     const Type = await gameCentreContract.GetCardType(TokenId);
//     const Gender = await gameCentreContract.GetGender(TokenId);
//     const Price = await auctionContract.GetCurrentPrice(TokenId);
//     const Result = {
//       faction: Faction,
//       rank: Value.Rank,
//       type: Type,
//       name: Value.Name,
//       gender: Gender,
//       price: Price,
//     };

//     return Result;
//   } catch (err) {
//     console.log(err, "usecard");
//   }
// }

const useAuctionCard = (TokenId) => {
  // console.log(TokenId, "tok auc");
  const [CardName, setCardName] = useState("");
  const [CardFaction, setCardFaction] = useState("");
  const [CardType, setCardType] = useState("");
  const [CardRank, setCardRank] = useState("");
  const [CardGender, setCardGender] = useState("");
  const [CardPrice, setCardPrice] = useState("");
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [auctionContract, setAuctionContract] = useAuctionContract();
  const [remainingTimes, setRemainingTimes] = useState();

  const { ethers } = require("ethers");

  // useEffect(() => {
  //   async function CardDetails() {
  //     const GeneralCardDetails = await getGeneralDetails(        gameCentreContract,        auctionContract,        TokenId      );

  //     setCardName(GeneralCardDetails.name);
  //     setCardFaction(GeneralCardDetails.faction);
  //     setCardType(GeneralCardDetails.type);
  //     setCardRank(GeneralCardDetails.rank);
  //     setCardGender(GeneralCardDetails.gender);
  //     setCardPrice(GeneralCardDetails.price)
  //   }
  //   CardDetails();
  // }, [TokenId, gameCentreContract,auctionContract]);

  if (TokenId && gameCentreContract && auctionContract) {
    const Info = async () => {
      const faction = await GetCardFaction(TokenId, gameCentreContract);
      const info = await GetCardInfo(TokenId, gameCentreContract);
      const type = await GetCardType(TokenId, gameCentreContract);
      const auction = await GetAuction(TokenId, auctionContract);
      // console.log(auction, "infoauct");
      setCardFaction(faction);
      setCardName(info?.Name);
      setCardRank(info?.Rank);
      setCardGender(info?.Gender);
      setCardType(type);
      setCardPrice(ethers.utils.formatEther(auction?.startingPrice));
      setInterval(() => {
        const duration = auction?.duration.toString();
        const Ending = new Date(
          auction?.startedAt.toString() * 1000 + duration * 1000
        );
        const Now = new Date();
        const remainingTime = Ending - Now;
        if (remainingTime >= 0) {
          const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

          setRemainingTimes(`${days}D : ${hours}H : ${minutes}M : ${seconds}S`);
        } else {
          setRemainingTimes("EXPIRED");
        }
      }, 1000);
    };
    Info();
  }
  // console.log("auction gallery", CardName);
  return [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardPrice,
    remainingTimes,
  ];
};

export default useAuctionCard;
