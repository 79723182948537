import React from 'react';
import RenameAllcardsList from './Sections/RenameAllcardsList';
import AllcardsList from './Sections/RenameAllcardsList';
import RenameAllCardsText from './Sections/RenameAllcardsText';
import AllCardsText from './Sections/RenameAllcardsText';

const RenameAllCard = () => {
    return (
        <div>
            <RenameAllcardsList></RenameAllcardsList>
            <RenameAllCardsText></RenameAllCardsText>
        </div>
    );
};

export default RenameAllCard;