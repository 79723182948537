import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAuctionCard from "../../../../Shared/AuctionCard/useAuctionCard";
import useStats from "../../../../Shared/useStates";
import {
  bid,
  GetAuction,
  GetCOOAddrAuction,
  GetPromoCodeDetailsAuc,
} from "../../../../UserPage/BlockChainFunctions";

import * as AWS from "aws-sdk";
import blacknet1 from "../Images/blacknet1.png";
import PopUp from "../../../../Shared/PopUp";
import {
  addressContext,
  navigationContext,
  providerContext,
} from "../../../../../App";
import useCustomerInfo from "../../../../Hooks/useCustomerInfo";
import useAuctionContract from "../../../../Hooks/AuctionContractHook";
import useColor from "../../../../Shared/useColor";
import useRankImage from "../../../../Shared/useRankImage";
import LoaderFloating from "../../../../Shared/LoaderFloating";
function AuctionConfirmationTaxt() {
  const TokenId = useParams();
  // console.log("tok", TokenId);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const [CardFaction, CardName, CardType, CardRank, CardGender] =
    useAuctionCard(TokenId.TokenId);
  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(TokenId.TokenId);
  const [auctionConfirmation, setAuctionConfirmation] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [loading, setLoading] = useState();
  const [auctionContract, setAuctionContract] = useAuctionContract();
  const [price, setPrice] = useState();
  const [myBid, setMyBid] = useState();
  const [maxBid, setMaxBid] = useState();
  const [remainingTimes, setRemainingTimes] = useState();
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [result, setResult] = useState("");
  const [drop, setDrop] = useState(false);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  const { ethers } = require("ethers");
  const [promoOnClick, setPromoOnClick] = useState(false);
  const [promoConfirm, setPromoConfirm] = useState(false);
  const [promoAct, setPromoACt] = useState(false);
  const [seller, setSeller] = useState(null);
  const [coo, setCoo] = useState("null");
  const [promoValidation, setPromoValidation] = useState(false);
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);
  const navigate = useNavigate();
  const getCOOAddr = async () => {
    const result = await GetCOOAddrAuction(auctionContract);
    setCoo(result);
    // console.log(result, "coo address");
  };
  useEffect(() => {
    if (auctionContract) {
      getCOOAddr();
    }
  }, [auctionContract]);

  const AuctionGet = async () => {
    const auction = await GetAuction(TokenId.TokenId, auctionContract);
    // console.log(auction);
    setSeller(auction.seller);
    setPrice(ethers.utils.formatEther(auction?.startingPrice));
    setMaxBid(ethers.utils.formatEther(auction?.HighestBidPrice));

    setInterval(() => {
      const duration = auction?.duration.toString();
      const Ending = new Date(
        auction?.startedAt.toString() * 1000 + duration * 1000
      );
      const Now = new Date();
      const remainingTime = Ending - Now;
      if (remainingTime >= 0) {
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        setRemainingTimes(`${days}D : ${hours}H : ${minutes}M : ${seconds}S`);
      } else {
        setRemainingTimes("EXPIRED");
      }
    }, 1000);
  };

  useEffect(() => {
    if (auctionContract) {
      AuctionGet();
    }
  }, [auctionContract]);

  useEffect(() => {
    if (seller === coo) {
      setPromoACt(true);
    }
  }, [seller, coo]);

  const getPromoDetails = async (Code) => {
    const result = await GetPromoCodeDetailsAuc(Code, walletProvider);
    return result;
  };
  const AuctionBid = async (e) => {
    e.preventDefault();
    setPopUpSubject("")
    setPopUpText("")
    const price = e.target.price.value;
    const promo = e.target.promo?.value || "Default";
    // console.log(promo, "promo");
    const result = await getPromoDetails(promo);
    setMyBid(price);
    if (result.Exists === true) {
      setPromoValidation(true);
    }
    setLoading(true);
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      // console.log(typeof price, typeof maxBid);
      if (parseInt(price) > parseInt(maxBid)) {
        if (promoValidation === true || promo === "Default") {
          const result = await bid(
            TokenId.TokenId,
            promo,
            price,
            walletProvider
          );
          // console.log(result);
          setLoading(false);
          if (result) {
            if (result?.error?.code) {
              setPopUpSubject("Oops");
              setPopUpText(result.message);
              setPopUp(true);
            }
            if (result?.blockHash) {
              setResult(result);
              setAuctionConfirmation(true);
            }
            if (result.data?.message) {
              setPopUpSubject("Oops");
              setPopUpText(result.data.message);
              setPopUp(true);
            }
          }
        } else {
          setLoading(false);
          setPopUp(true);
          setPopUpSubject("Promo code doesn't exist");
        }
      } else {
        setLoading(false);
        setPopUp(true);
        setPopUpSubject("Offered price must be more than highest bid");
      }
    } else {
      setLoading(false);
      setPopUp(true);
      setPopUpSubject("Connect your registered wallet ");
    }
  };
  // useEffect(() => {
  //   if (promoAct && promoOnClick) {
  //     setPromoConfirm(true);
  //   }
  // }, [promoAct, promoOnClick]);
  // useEffect(() => {
  //   if (navigationCntxt === true) {
  //     navigate("/auction-house");
  //     window.location.reload();
  //   }
  // }, [navigationCntxt]);
  return (
    <div>
      <section className="purchase_text word-wrapping">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="purchase_heading">
                <h2>NEW BID</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="purchase_succ_img card_img">
                <img
                  className={color}
                  src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${TokenId.TokenId}`}
                  alt=""
                />
                <img
                  className="rankImage"
                  src={`/Rank/${rankImage}.png`}
                  alt=""
                />
                <img
                  className="extremis-logo"
                  src="/LOGO.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="purchase_succ_info">
                <h4>{CardName}</h4>
                <h5>{CardFaction}</h5>
                <div class="purchase_list_box">
                  <ul>
                    <li>
                      <span>Rank:</span> {CardRank}
                    </li>
                    <li>
                      <span>Type: </span>
                      {CardType}
                    </li>
                    <li>
                      <span>IQ: </span>
                      {IQ}
                    </li>
                    <li>
                      <span>Potential: </span>
                      {Potential}
                    </li>
                    <li>
                      <span>EQ: </span>
                      {EQ}
                    </li>
                    <li>
                      <span>Wisdom:</span> {Wisdom}
                    </li>
                    <li>
                      <span>Melee: </span>
                      {Melee}
                    </li>
                    <li>
                      <span>Leadership: </span>
                      {Leadership}
                    </li>
                    <li>
                      <span>Weapon: </span>
                      {Weapon}
                    </li>
                    {CardFaction === "Humanity" ? (
                      <li>
                        <span>N. Adaptability: </span>
                        {NaturalAdpt}
                      </li>
                    ) : (
                      <li>
                        <span>AI Influence: </span>
                        {NaturalAdpt}
                      </li>
                    )}
                  </ul>
                </div>
                {!auctionConfirmation ? (
                  <div className="purchase_succ_text">
                    <form onSubmit={AuctionBid}>
                      <h3>New bid</h3>
                      {promoAct && (
                        <p onClick={() => setPromoConfirm(!promoConfirm)}>
                          {!promoConfirm
                            ? "Use Promo Code"
                            : "Remove Promo Code"}
                        </p>
                      )}
                      <div className="bid_price">
                        <div className="float-left">
                          <span>
                            Reserved price<br></br>
                            <strong>{price} MATIC</strong>
                          </span>
                        </div>
                        <div className="float-right">
                          <span>
                            Highest bid price<br></br>
                            <strong>{maxBid} MATIC</strong>
                          </span>
                        </div>
                        <div className="float-left">
                          <span>
                            Offered price<br></br>
                            <div class="form-floating mb-3">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="MATIC"
                                name="price"
                                required
                              />
                            </div>
                          </span>
                        </div>
                        <div className="float-right">
                          <span>
                            Time left:<br></br>
                            <strong className="text-danger">
                              {remainingTimes}
                            </strong>
                          </span>
                        </div>
                        {promoConfirm && (
                          <div className="float-left">
                            <span>
                              Promo Code<br></br>
                              <div class="form-floating mb-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="PROMO CODE"
                                  name="promo"
                                />
                              </div>
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="purchase_btn">
                        <button type="submit" className="btn">
                          {!loading ? "CONFIRM" : "PLEASE WAIT..."}
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="purchase_succ_text">
                    <h3>Congratulations!</h3>
                    <div className="bid_price">
                      <div className="float-left">
                        <span>
                          Your bid the card for <br></br>
                          <strong>{myBid} MATIC</strong>
                        </span>
                      </div>
                      <div className="float-right">
                        <span>
                          Time left:<br></br>
                          <strong className="text-danger">
                            {remainingTimes}
                          </strong>
                        </span>
                      </div>
                    </div>

                    <div className="purchase form-floating">
                      <div
                        onClick={() => setDrop(!drop)}
                        className="text-break"
                      >
                        <label>
                          Transaction hash (TxHash){" "}
                          {!drop ? (
                            <i class="fal fa-plus"></i>
                          ) : (
                            <i class="fal fa-minus"></i>
                          )}
                        </label>
                        <p className="text-break">{result?.transactionHash}</p>
                      </div>
                      {drop && (
                        <>
                          <p className="text-break">
                            Block hash: {result?.blockHash}
                          </p>
                          <p className="text-break">
                            Block number: {result?.blockNumber}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {popup && (
              <PopUp
                popUpSubject={popUpSubject}
                popUpText={popUpText}
                setPopUp={setPopUp}
              ></PopUp>
            )}
            {loading && <LoaderFloating></LoaderFloating>}
          </div>
        </div>
      </section>
    </div>
  );
}
export default AuctionConfirmationTaxt;
