import React from 'react';
import blacknet_logo from "../Images/blacknet_logo.png";
import alice_logo from "../Images/alice_logo.png";
import humanity_logo from "../Images/humanity_logo.png";
function Allcardsbanner () {
	return <div>
<div className="AuctionSection">
   <h1>AUCTION HOUSE</h1>
</div>
    </div>
}
export default Allcardsbanner;