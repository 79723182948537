import React from 'react';
import ConfirmationMsgBanner from '../ConfirmationMsg/Sections/ConfirmationMsgBanner';
import ConfirmationMsgText from '../ConfirmationMsg/Sections/ConfirmationMsgText';
import '../ConfirmationMsg/ConfirmationMsg.css';
function ConfirmationMsg(){
 return <div>
<ConfirmationMsgBanner/>
<ConfirmationMsgText/>


 </div>


}
export default ConfirmationMsg;