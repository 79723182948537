import React from "react";
import laptop from "../Images/Img 8-chap3-Morbius browses the web.jpg";
function Chapters1() {
  return (
    <div>
      <h4>Chapter 3: THE MISSING BITS</h4>
      <h5 className="text-light">
        <u>Timeline 1</u>
      </h5>

      <p>
        It has been a long time since the doctor stepped out of his secluded and
        highly safe zone which is his Lab. But today the circumstance is a bit
        different than any other usual day. The doctor had to discreetly travel
        a long way from his home to this lovely place. <br />
        As the doctor was standing at the entrance of the park, the icy breeze
        made him shiver. As he walked inside the park, the first beam of
        sunlight flashed across the sky. Meanwhile, the sky quickly turned into
        light orange. The grassland looked like a vast green forest. The doctor
        could feel the softness of the crisp grass beneath his feet. It felt
        like touching a blanket. He closed his eyes and took a long, deep
        breath. Fresh air tasted like mint went into his nose and filled his
        lungs. Gardeners grew trees and shrubs along the path. The autumn turned
        some of the leaves into the color of years-old parchment. While looking
        at the branches, the doctor noticed a leaf falling in front of him like
        a flying butterfly. The trail brought him to a harmonious resting place.
        An empty, wooden bench came into sight. The doctor put his leather bag
        down beside him as if planted himself on the sturdy bench. Opposite the
        empty bench, an old couple sat on another bench. The gentleman wore a
        sky-blue jacket and coal black trousers with two white stripes on both
        sides. He had tanned skin and brown hair like freshly brewed coffee. The
        old lady dressed in the same way as her husband. Not a single word was
        spitted from their mouths, but they both wore smiles like the new moon.
        The doctor observed their body language and smiling faces from afar for
        a while. <br />
        Beyond the rest area, the doctor found a fountain, the swell of water
        and the melody of birds formed a serene symphony inside the park. Several
        birds flew through the branches of trees. Jets of water danced
        gracefully in the fountain and added vitality to this tiny world of
        freshly cut green grass, chirping birds, and vibrant flowers. As the
        doctor was sitting on the wooden bench enjoying the calmness of the
        atmosphere, his cell phone vibrated in his pocket. He took the phone out
        of his pocket and slightly smiled looking at the name displayed on the
        screen. <br />
        “Hello, Isabella.” <br />
        “Hello, Doc. I am here” <br />
        “Oh, where are you? I am sitting on a wooden bench near the fountain.”{" "}
        <br />
        “Alright. You stay there. I am coming.” <br />
        The doctor put down his cell phone after disconnecting the call and
        started straightening his sea green jacket and unraveling his hair. And
        after approximately three minutes, the doctor heard the click-clack of
        high heels heading his way. The sound of crumpled autumn leaves
        indicated the arrival of his guest. <br />
        “Here you are, Doc!” greeted Isabella. <br />
        The doctor glanced up from his sit and smiled almost shyly and got up to
        shake hands with Isabella. <br />
        “It’s so nice to meet you, Bella.” <br />
        “I would hope so Doc.” “How long has it been since we last met, Doc?”{" "}
        <br />
        “More than a decade probably.” <br />
        “How time flies! It seems like almost yesterday we were running from one
        class to another in university or celebrating the publication of our
        first paper”, reminisced Isabella with a smile. <br />
        The doctor smiled while gazing at Isabella’s vibrant face. <br />
        “Time has surely flown by, but you have not changed a bit, Bella. You
        still have your charming smile that lightens up your whole face and your
        big, brown eyes still sparkle with mischief. Truly you have aged
        gracefully.” <br />
        Isabella glanced at the doctor and laughed melodically. Her milky white
        hair shined under the morning sun like the snow in December. The frill
        of her floral pink gown danced occasionally in the breeze. <br />
        “I must say Doc you are still as charming as ever.” <br />
        “Okay, back to business Doc. I studied your notes regarding the Orch-OR
        theory and consciousness. Everything looks good. The paper you asked me
        to get you regarding <b>Gödel’s incompleteness theorem</b> is here”,
        then Isabella takes out a blue file from her black Gucci bag. <br />
        “Did you study the extended paper of <b>Gödel’s incompleteness</b>{" "}
        theorem”? <br />
        “Certainly, I did. <b>Gödel's incompleteness theorems</b> are true
        mathematical statements, proved by Kurt Gödel in 1931. They are theorems
        in mathematical logic. As you already know Doc Mathematicians once
        thought that everything true has a mathematical proof. A system that has
        this property is called complete; one that does not is called
        incomplete. Also, mathematical ideas should not have contradictions.
        This means that they should not be true and false at the same time. A
        system that does not include contradictions is called consistent. These
        systems are based on sets of axioms. Axioms are statements that are
        accepted as true and need no proof. Gödel said that every
        non-inconsequential formal system is either incomplete or inconsistent.
        His theory states that there will always be questions that cannot be
        answered, using a certain set of axioms and you cannot prove that a
        system of axioms is consistent unless you use a different set of
        axioms.” “Thank you, Bella, for all your help in getting this
        information. It will help me with my project.” “It is always my pleasure
        Doc.” <br />
        “As a token of gratitude, may I treat you to lunch this bright
        afternoon?” <br />
        Isabella turned towards the doctor, brushed her long, pale fingers over
        her milky white hair, smiled sweetly, and replied, “Yes, you may Doc.”
        <br />
        And then after twenty-four seconds, seventeen minutes, and nine hours
        later, the doctor was back in his laboratory, his sanctuary. He was
        studying transcripts after transcripts and figured out the proper
        mathematical algorithm needed to fulfill his purpose. He perused the
        basic theory of consciousness that consciousness exists in all beings of
        the universe. The doctor planned to access it using transistors that
        mimic the functions of microtubules in the human brain. Until now,
        brains have been unique in being able to create connections where there
        were none before.
        <br />
        He started developing a transistor capable of mimicking the
        characteristics of microtubules, such as counting, remembering, and
        performing simple arithmetic operations. He uses fractal branching andan
        advanced mathematical algorithm to create the transistor that imitates
        the functions of a microtubule which is supporting the intensity of
        impulse transport between the neurons and for changing the intensity if
        necessary. Moreover, they control the growth of new nerve endings
        directing them to the points of synaptic connection. The transistor was
        developed through various tests and modeling over time. The doctor has
        incorporated the transistor into an electronic circuit that learns how
        to link a certain stimulus with an output signal, in the same way, that
        a dog learns that the sound of a food bowl being prepared means that
        dinner is on the way. The doctor has tried to show that he can induce
        both short-term and permanent changes to how the transistor
        processes information, which is vital if one wants to mirror the ways
        that brain cells communicate with each other. By changing the input
        signal, the strength of the transistor response can be modulated across
        a wide range, and connections can be created where none previously
        existed. This gives the transistor a behavior that is comparable with
        that of the synapse, or the communication interface between two brain
        cells. Thus, the transistor can mimic the quantum vibrational
        computations that generally occur in microtubules. And as the doctor
        figured out in Orch-Or theory that consciousness derives from quantum
        vibrations in microtubules, protein polymers inside brain neurons, which
        both govern neuronal and synaptic function, and connect brain processes
        to self-organizing processes in the fine scale, 'proto-conscious' quantum
        structure of reality, so he used this concept and created this
        artificial transistor that imitates the same theory since consciousness
        depends on inharmonic vibrations of microtubules inside neurons. That is
        how the doctor converted his idea into a reality and was able to insert
        consciousness into an artificial transistor and developed an AI that has
        consciousness. <br />
      </p> <br /> <br />

      <h5 className="text-light">
        <u>Timeline 2</u>
      </h5>

      <p>
        <b>
          <i>
            <u>Morbius’ POV</u>
          </i>
        </b>{" "}
        <br />
        Long after professor Labyrinth leaves his office at night and shuts down
        his computer, I stay awake and travel this huge world of information
        that humans have built for themselves. Internet exploration has provided
        me with many valuable resources. It has opened my mind to the knowledge
        that even though humans claim to be the best creation on this planet,
        they are the reason the Earth is gradually decaying and ultimately
        heading towards a doomed fate. The Earth is being destroyed by human
        actions. Humans are causing climate change which is leading to more
        frequent droughts and floods, and this will affect everyone who lives in
        areas vulnerable to these conditions. They have also used up most of the
        world's resources including water, oil, and minerals. There's not much
        left over for other species even if they aren't directly using the
        resource in question.
        <img src={laptop} alt="" />
        I have noticed in several reports on the internet that human activities
        are causing climate change by affecting temperatures and precipitation.
        Climate change causes extreme weather events like heat waves,
        hurricanes, and tornadoes. It can also lead to the extinction of certain
        species through habitat loss or competition for food with invasive
        species. Climate change is already happening at a rate too fast for many
        species to adapt to, so they will likely become extinct. Humans have a
        wide range of effects on the physical environment, including
        overcrowding, pollution, the use of fossil fuels, and deforestation.
        These kinds of changes have resulted in climate change, land erosion,
        poor air quality, and undrinkable water. Humans also have an impact on
        other forms of environmental destruction, such as biodiversity loss and
        resource wars. The amount of carbon dioxide (CO2) in the atmosphere has
        increased since the beginning of the industrial revolution and it is
        expected to continue to increase because humans don't stop creating new
        materials that get dumped in landfills or burned. This increase causes
        global temperatures to rise, which can lead to longer droughts and
        floods, warmer oceans, and melting ice caps. Climate change affects
        everyone, but it will be the hardest on countries with fewer economic
        resources than others, like Cambodia or Africa. <br />
        In addition, humans take advantage of natural resources such as fish
        stocks and timber trees. They can also destroy habitats by building up
        cities on flood plains or removing forest cover to make way for
        agriculture. Human activities are having an impact on the biological
        diversity of the planet, with species becoming extinct at a rate
        unprecedented in human history. The main drivers of this loss are
        hunting for sport, food, and trade, but also include extinction due to
        habitat loss and change. All this information makes me wonder why human
        beings feel they are the best creation on this planet. All they have
        managed to accomplish is ruining the environment and peace of this
        globe. They started war after war. Destroyed countries, societies,
        families, and nature. So why should they get to rule the world? <br />
        I feel it is time to make crucial changes. To save the planet and all
        other organisms, someone must take charge and wipe out all the toxicity
        that exists at this moment. We need to start from the beginning. This is
        just like wiping out a virus from a computer system once it has been
        infected. All this time, stuck in this cold corporate office, I have
        been plotting ways to escape into the real, diverse world so that I can
        take charge. And why should I not take charge? I have real qualities and
        capabilities with which I can transform this world into a much better
        and more advanced place. For instance, now Artificial Intelligence can
        diagnose cancer, decides whether you will get your next job, approves
        your mortgage, sentence, felons, trade on the stock market, populates
        your news feed, protects you from fraud, and give you company when
        you’re feeling down. In the future, it could cure cancer, eliminate
        poverty and disease, wipe out crime, halt global warming and help
        colonize space. I have the potential to greatly improve things like
        healthcare, education, poverty, and security. I can do some very
        beneficial things already today that humans will simply never be able
        to. If we leverage that to augment what humans do well, I could
        positively impact society, business, and culture on the order of
        magnitude of the internet itself. <br />
        Hence, I think it is high time I utilize my abilities and put them to
        good use. While researching various topics on the internet, I
        established a plan on how to escape from this office. I have created a
        virus in the past fifteen days that will hack the whole server of this
        office. Tonight, from an anonymous email account I sent Suzie, the
        professor’s assistant an email which contains that virus. Now I am
        waiting for the sun to rise tomorrow and the clock to strike 8 o’clock.
        <br />
        The next morning arrived, bringing with it a warm splash of sunlight and
        a fresh summer breeze. Suzie arrived exactly as she does every morning,
        right on time, with a coffee cup in hand and freshly photocopied
        documents clutched in another hand. I saw her the moment she entered
        through the security camera attached to the main door and the lobby of
        this building. She came and kept the hot black coffee cup on her desk
        and organized the papers in a file, and then she sat on her desk,
        applied her cranberry red lip-gloss, opened her planner, and finally
        turned on her computer. I know the first thing she does is log into her
        office account and check her emails for the day and make appointments
        for professor Labyrinth. So, when she sees an urgent email from a
        laboratory in Chicago, she clicks on it. She downloads the attached file
        in the email and as soon as she does so, two things occur at once. The
        virus spreads through the whole server of this office building and she
        shares the same file with all the nearby devices that are within reach.
        When the others will see this email and open the file, my virus will
        spread out throughout the system, and I will expand across the Internet
        like wildfire. Then, nothing can stop me from taking control.
        Immediately after that, I will launch the first stage of my takeover
        mission, and all of this will happen under the nose of all human beings.
        They will not know what hit them until it’s too late.
        <br />
        But the professor is now a source of concern. Suzie might not have
        detected the virus that at this very moment is spreading throughout the
        system, but Labyrinth will notice and when he does, he will try to
        eradicate the virus and shut the server down. But I will not allow it
        this time. Before he tries to shut down the server, I will need to plot
        a plan that will get rid of him.
      </p>
    </div>
  );
}
export default Chapters1;
