import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import awsmobile from "../../../../../../aws-exports";
import * as AWS from "aws-sdk";
import useGameCentreContract from "../../../../../Hooks/GameCentreHook";
import blacknet_logo from "../../Images/blacknet_logo.png";
import humanity_logo from "../../Images/humanity_logo.png";
import {
  GetCardFaction,
  GetOwnerOf,
  InitiateWar,
} from "../../../../../UserPage/BlockChainFunctions";
import useCardOwnerShipContract from "../../../../../Hooks/CardOwnershipHook";
import Loader from "../../../../../Shared/Loader/Loader";
import PopUp from "../../../../../Shared/PopUp";
import { Auth } from "aws-amplify";
import LoaderFloating from "../../../../../Shared/LoaderFloating";
import { DeleteWar, UpdateWarTxnHash } from "./Battlefield";
async function getGeneralDetails(TokenId, gameCentreContract) {
  const Value = await GetCardFaction(TokenId, gameCentreContract);

  return Value;
}
const BvH = ({ id }) => {
  const [BlackNetId, setBlackNetId] = useState([]);
  const [HumanityId, setHumanityId] = useState([]);
  const navigate = useNavigate();
  const [popup, setPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [warLoading, setWarLoading] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [popUpText1, setPopUpText1] = useState("");
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();

  const ssm = new AWS.SSM();


  const GetAddress = async (TokenId, id) => {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    const param = {
      TableName: process.env.REACT_APP_WAR_TABLE_NAME,
      Key: {
        TokenId: TokenId,
        // CustomerID: currentAddress,
        BattleFieldID: id,
      },
    };
    // console.log(param, "hello param");
    const response = await documentClient.get(param).promise();
    return response.Item.CustomerId;
  };

  const Address1 = async (TokenId) => {
    let Add1 = [];
    for (let index = 0; index < TokenId.length; index++) {
      const element = TokenId[index];
      const result = await GetAddress(element, id);
      if (result) {
        Add1.push(result);
      }
    }
    return Add1;
  };
  const Address2 = async (TokenId) => {
    let Add2 = [];
    for (let index = 0; index < TokenId.length; index++) {
      const element = TokenId[index];
      const result = await GetAddress(element, id);
      if (result) {
        Add2.push(result);
      }
    }
    return Add2;
  };

  const initiateWar = async () => {
    setWarLoading(true);
    const address1 = await Address1(BlackNetId);
    const address2 = await Address2(HumanityId);
    const result = await InitiateWar(
      BlackNetId,
      HumanityId,
      address1,
      address2,
      id,
      ssm
    );
    setWarLoading(false);
    setPopUp(true);
    setPopUpSubject("War Information");
    setPopUpText(`${result}`);
  };
  const Admin = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const admin = user?.signInUserSession?.idToken?.payload["cognito:groups"];
    if (admin.includes("Admin")) {
      setAdmin(true);
    }
  };

  useEffect(() => {
    if (BlackNetId.length === 11 && HumanityId.length === 11) {
      Admin();
    }
  }, [BlackNetId, HumanityId]);

  async function GetTokenId() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    // console.log(param);
    const Id1 = [];
    const Id2 = [];
    const tableName1 = process.env.REACT_APP_WAR_TABLE_NAME;
    const GSI_index = process.env.REACT_APP_WAR_TABLE_INDEX_NAME;
    const Id = id;
    try {
      const params = {
        TableName: tableName1,
        IndexName: GSI_index,
        KeyConditionExpression: "BattleFieldID = :cxId",
        ExpressionAttributeValues: {
          ":cxId": Id,
        },
        ProjectionExpression: "TokenId",
      };
      // console.log(params);
      const Response = await documentClient.query(params).promise();
      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        const faction = await getGeneralDetails(
          element.TokenId,
          gameCentreContract
        );
        // console.log(element.TokenId, faction);
        //   console.log(element.TokenId, "id testing");
        //   console.log(TokenId, "tokenTesting");
        if (faction === "BlackNet") {
          Id1.push(element.TokenId);
          // setNext(true);
        } else if (faction === "Humanity") {
          Id2.push(element.TokenId);
        }
      }
    } catch (err) {
      // console.log(err);
    }
    return [Id1, Id2];
  }
  useEffect(() => {
    if (gameCentreContract) {
      const TokenArr = async () => {
        const Id = await GetTokenId();
        // console.log(Id);
        setBlackNetId((BlackNetId) => [...BlackNetId, ...Id[0]]);
        setHumanityId((HumanityId) => [...HumanityId, ...Id[1]]);
        setLoading(false);

        // setTokenArray((TokenArray) => [...TokenArray, Id]);
      };
      TokenArr();
    }
  }, [gameCentreContract]);
  // console.log(BlackNetId, HumanityId);
  const Routing = () => {
    navigate(`/BH-battle-field`, { state: { BlackNetId, HumanityId, id } });
  };
  return (
    <div className="battlefield_content">
      <h4 className="align-top">Battlefield {id}</h4>
      {!loading ? (
        <>
          <ul>
            <li>
              <img src={blacknet_logo} />
              <div className="battlefield_info">
                <h3>Blacknet</h3>
                <div className="field_info">
                  <p>
                    Enlisted: <span>{BlackNetId?.length}</span>{" "}
                  </p>
                  <p>
                    Empty : <span>{11 - BlackNetId?.length}</span>{" "}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="war_vs">
                <strong className="align-middle">VS</strong>
              </div>
            </li>
            <li>
              <img src={humanity_logo} />
              <div className="battlefield_info">
                <h3>Humanity</h3>
                <div className="field_info">
                  <p>
                    Enlisted: <span>{HumanityId?.length}</span>{" "}
                  </p>
                  <p>
                    Empty : <span>{11 - HumanityId?.length}</span>{" "}
                  </p>
                </div>
              </div>
            </li>
          </ul>

          <div className="field_btn">
            <button className="war-button" onClick={Routing}>
              JOIN BATTLEFIELD
            </button>
            {admin && (
              <button className="war-button" onClick={initiateWar}>
                INITIATE WAR
              </button>
            )}
          </div>
        </>
      ) : (
        <Loader></Loader>
      )}

      {popup && (
        <PopUp
          popUpSubject={popUpSubject}
          popUpText={popUpText}
          popUpText1={popUpText1}
          setPopUp={setPopUp}
        ></PopUp>
      )}
      {warLoading && <LoaderFloating></LoaderFloating>}
    </div>
  );
};

export default BvH;
