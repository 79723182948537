import React from "react";
import alice from "./Images/Alice-logo.png"
import blacknet from './Images/Blacknet-logo.png'
import humanity from './Images/Humanity-logo.png'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const { ethers } = require("ethers");

function HeroBanner() {

  return (
    <div>
      <div className="banner_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner_text">
                {/* <h1>Extremis</h1>
                     <button onClick = {()=>{
                        setTest(!test)
                        }}>test</button> */}
                <div className="blacknet">
                 <Link to="/cardcollection/blacknet-card"> <img src={blacknet} alt="" /></Link>
                </div>
                <div className="alice">
                 <Link to="/cardcollection/alice-card"> <img src={alice} alt="" /></Link>
                </div>
                <div className="humanity">
                 <Link to="/cardcollection/humanity-card"> <img src={humanity} alt="" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeroBanner;
