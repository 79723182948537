import React from 'react';
import './SalesRecordBanner.css'
import banner from '../Images/human_header_metaverse.png'
const SalesRecordBanner = () => {
    return (
        <div className='sales-record-banner'>
     
        </div>
    );
};

export default SalesRecordBanner;