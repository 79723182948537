import React from 'react';
import '../SellCollection -auction/CardCollection.css';
import AuctionSellHumanityList from './SubPages/Humanity/Sections/AuctionSellHumanityList';
import AuctionSellHumanityText from './SubPages/Humanity/Sections/AuctionSellHumanityText';

function AuctionSellHumanityCard ({children}) {
	
	return <div>
		{/* <Humanitybanner/> */}
		<AuctionSellHumanityList>children</AuctionSellHumanityList>
		<AuctionSellHumanityText>children</AuctionSellHumanityText>

        
	</div>
}
export default AuctionSellHumanityCard;