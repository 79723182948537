import React, { useContext, useState } from "react";
import {
  breedWithOthers,
  GetCardInfo,
  GetOwnerOf,
} from "../../UserPage/BlockChainFunctions";
import DragLeft from "./DragLeft";
import DragRight from "./DragRight";
import * as AWS from "aws-sdk";
import useGameCentreContract from "../../Hooks/GameCentreHook";
import {
  addressContext,
  navigationContext,
  providerContext,
} from "../../../App";
import "../../Profile/Pages/Breeding/BreedSelfCollection/breed.css";
import "../Card.css";

import "../DragCss.css";
import useCustomerInfo from "../../Hooks/useCustomerInfo";
import PopUp from "../PopUp";
import useBreedingContract from "../../Hooks/BreedingContractHook";
import useCardOwnerShipContract from "../../Hooks/CardOwnershipHook";
import LoaderFloating from "../LoaderFloating";
import { useEffect } from "react";
import BreedResultCard from "./BreedResultCard";
function DragBreedOthers() {
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);
  const [navConfirm, setNavConfirm] = useState(false);
  const [disable, setDisable] = useState(true);
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const ssm = new AWS.SSM();
  const [Id1, setId1] = useState();
  const [Id2, setId2] = useState();
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [popUpText1, setPopUpText1] = useState("");
  const [loading, setLoading] = useState();
  const [breedingContract, setBreedingContract] = useBreedingContract();
  const [childId, setChildId] = useState("");
  const [finalResult, setFinalResult] = useState();
  const [cardOwnerShipContract, setCardOwnerShipContract] =
    useCardOwnerShipContract();
  const Breeding = async () => {
    setLoading(true);
    setPopUpSubject("");
    setPopUpText("");
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      // console.log("hello breed");
      let nonCaller;
      const owner1 = await GetOwnerOf(Id1, cardOwnerShipContract);
      const owner2 = await GetOwnerOf(Id2, cardOwnerShipContract);

      if (owner1 !== currentAddress) {
        nonCaller = owner1;
      }
      if (owner2 !== currentAddress) {
        nonCaller = owner2;
      }

      const info1 = await GetCardInfo(Id1, gameCentreContract);
      const info2 = await GetCardInfo(Id2, gameCentreContract);
      if ((info1.Gender && info2.Gender) !== ("Male" || "Female")) {
        setLoading(true);
        const result = await breedWithOthers(
          Id1,
          Id2,
          nonCaller,
          walletProvider,
          breedingContract,
          cardOwnerShipContract,
          ssm
        );
        setLoading(false);
        if (result) {
          if (result?.error?.code) {
            setPopUpSubject("Oops");
            setPopUpText(result.message);
            setPopUp(true);
          }
          if (result.txreceipt?.blockHash) {
            const events = await breedingContract.queryFilter(
              breedingContract.filters.BreedEvnt(),
              result.txreceipt?.blockHash
            );

            for (const event of events.filter(
              (e) => e.transactionHash == result.txreceipt.transactionHash
            )) {
              setChildId(event?.args?.childId.toString());
              setFinalResult(result);
            }
          }
          if (result.data?.message) {
            setPopUpSubject("Oops");
            setPopUpText(result.data.message);
            setPopUp(true);
          }
        }
        // console.log(result, "ok");
      } else {
        if (info1.Gender === "Male" && info2.Gender === "Female") {
          setLoading(true);
          const result = await breedWithOthers(
            Id1,
            Id2,
            nonCaller,
            walletProvider,
            breedingContract,
            cardOwnerShipContract,
            ssm
          );
          setLoading(false);
          if (result) {
            if (result?.error?.code) {
              setPopUpSubject("Oops");
              setPopUpText(result.message);
              setPopUp(true);
            }
            if (result.txreceipt?.blockHash) {
              const events = await breedingContract.queryFilter(
                breedingContract.filters.BreedEvnt(),
                result.txreceipt?.blockHash
              );

              for (const event of events.filter(
                (e) => e.transactionHash == result.txreceipt.transactionHash
              )) {
                setChildId(event?.args?.childId.toString());
                setFinalResult(result);
              }
            }
            if (result.data?.message) {
              setPopUpSubject("Oops");
              setPopUpText(result.data.message);
              setPopUp(true);
            }
          }
          // console.log(result, "ok male female");
        } else if (info1.Gender === "Female" && info2.Gender === "Male") {
          setLoading(true);
          const result = await breedWithOthers(
            Id2,
            Id1,
            nonCaller,
            walletProvider,
            breedingContract,
            cardOwnerShipContract,
            ssm
          );
          setLoading(false);
          if (result) {
            if (result?.error?.code) {
              setPopUpSubject("Oops");
              setPopUpText(result.message);
              setPopUp(true);
            }
            if (result.txreceipt?.blockHash) {
              const events = await breedingContract.queryFilter(
                breedingContract.filters.BreedEvnt(),
                result.txreceipt?.blockHash
              );

              for (const event of events.filter(
                (e) => e.transactionHash == result.txreceipt.transactionHash
              )) {
                setChildId(event?.args?.childId.toString());
                setFinalResult(result);
              }
            }
            if (result.data?.message) {
              setPopUpSubject("Oops");
              setPopUpText(result.data.message);
              setPopUp(true);
            }
          }
          // console.log(result, "ok female male");
        } else {
          setPopUp(true);
          setPopUpSubject("Oops");
          setPopUpText(
            "Same gender can't breed, please select cards with two different genders"
          );
          // console.log("choose the male and female");
        }
      }
    } else {
      setLoading(false);
      setPopUp(true);
      setPopUpSubject("Connect your registered wallet ");
    }
  };
  useEffect(() => {
    if (navigationCntxt === true && navConfirm === true) {
      window.location.reload();
    }
  }, [navigationCntxt, navConfirm]);
  useEffect(() => {
    if (Id1 && Id2) {
      setDisable(false);
    }
  }, [Id1, Id2]);
  return (
    <div>
      <div className="dnd_container">
        <div className="drag-left">
          <DragLeft setId1={setId1}></DragLeft>
        </div>
        <div className="plus-sign">
          <strong>+</strong>
        </div>
        <div className="drag-right">
          <DragRight setId2={setId2}></DragRight>
        </div>
        <button
          className="confirm-button"
          onClick={Breeding}
          disabled={disable}
        >
          {!loading ? "  INITIATE BREEDING" : "PLEASE WAIT..."}
        </button>
      </div>
      <div className="dnd_container-mobile">
        <div className="drop-mobile">
          <div className="drag-left">
            <DragLeft setId1={setId1}></DragLeft>
          </div>
          <div className="plus-sign">
            <strong>+</strong>
          </div>
          <div className="drag-right">
            <DragRight setId2={setId2}></DragRight>
          </div>
        </div>
        <button
          className="confirm-button"
          onClick={Breeding}
          disabled={disable}
        >
          {!loading ? "  INITIATE BREEDING" : "PLEASE WAIT..."}
        </button>
      </div>
      {popup && (
        <PopUp
          popUpSubject={popUpSubject}
          popUpText={popUpText}
          popUpText1={popUpText1}
          setPopUp={setPopUp}
        ></PopUp>
      )}
      {finalResult && (
        <BreedResultCard
          childId={childId}
          finalResult={finalResult}
          setFinalResult={setFinalResult}
        ></BreedResultCard>
      )}
      {loading && <LoaderFloating></LoaderFloating>}
    </div>
  );
}
export default DragBreedOthers;
