import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  addressContext,
  providerContext,
  statsContext,
} from "../../../../../../App";
import useBreedingContract from "../../../../../Hooks/BreedingContractHook";
import useCustomerInfo from "../../../../../Hooks/useCustomerInfo";
import LoaderFloating from "../../../../../Shared/LoaderFloating";
import PopUp from "../../../../../Shared/PopUp";
import usePRMCard from "../../../../../Shared/PRMCard/usePRMCard";
import useColor from "../../../../../Shared/useColor";
import useRankImage from "../../../../../Shared/useRankImage";
import useStats from "../../../../../Shared/useStates";
import {
  ChangeName,
  GetRenameFee,
} from "../../../../../UserPage/BlockChainFunctions";
function RenameConfirmText() {
  const TokenId = useParams();
  // console.log("selllll", TokenId);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  const [drop, setDrop] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [loading, setLoading] = useState();
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [renameFee, setRenameFee] = useState("");
  const [breedingContract, setBreedingContract] = useBreedingContract();
  const [rename, setRename] = useState(false);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const [CardFaction, CardName, CardType, CardRank] = usePRMCard(
    TokenId.TokenId
  );
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(TokenId.TokenId);
  const [
    reloadStats,
    setReloadStats,
    reloadGeneralDetails,
    setReloadGeneralDetails,
  ] = useContext(statsContext);
  const [result, setResult] = useState("");
  const Rename = async (e) => {
    e.preventDefault();
    setLoading(true);
    const NewName = e.target.NewName.value;
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      const result = await ChangeName(
        TokenId.TokenId,
        NewName,
        walletProvider,
        breedingContract
      );
      setLoading(false);
      if (result) {
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result?.blockHash) {
          setReloadGeneralDetails(true);
          setResult(result);
          setRename(true);
        }
        if (result?.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      }
    } else {
      setLoading(false);
      setPopUp(true);
      // setPopUpSubject("Oops");
      setPopUpSubject("Connect your registered wallet ");
    }
  };
  useEffect(() => {
    if (breedingContract) {
      const Fee = async () => {
        const result = await GetRenameFee(breedingContract);
        setRenameFee(result);
      };
      Fee();
    }
  }, [breedingContract]);
  return (
    <div>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="confirmation_heading">
                <h2>Rename card</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center ">
            <div className="col-md-6">
              <div className="card_img">
                <img
                  className={color}
                  src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${TokenId.TokenId}`}
                  alt=""
                />
                <img
                  className="rankImage"
                  src={`/Rank/${rankImage}.png`}
                  alt=""
                />
                <img
                  className="extremis-logo"
                  src="/LOGO.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card_info_text">
                <div className="word-wrapping">
                  <h4>{CardName}</h4>
                  <h5>{CardFaction}</h5>
                </div>
                <div className="card_list_box">
                  <ul>
                    <li>
                      <span>ID:</span> <strong>{TokenId.TokenId}</strong>
                    </li>
                    {/* <li>
                      <span>Faction:</span> <strong>{CardFaction}</strong>
                    </li> */}
                    <li>
                      <span>Rank: </span> {CardRank}
                    </li>
                    <li>
                      <span>Type: </span>
                      {CardType}
                    </li>
                    <li>
                      <span>IQ: </span> {IQ}
                    </li>
                    <li>
                      <span>Potential: </span> {Potential}
                    </li>
                    <li>
                      <span>EQ: </span> {EQ}
                    </li>
                    <li>
                      <span>Wisdom: </span> {Wisdom}
                    </li>
                    <li>
                      <span>Melee: </span> {Melee}
                    </li>
                    <li>
                      <span>Leadership: </span> {Leadership}
                    </li>
                    <li>
                      <span>Weapon: </span> {Weapon}
                    </li>
                    <li>
                      <span>Defense: </span> {Defense}
                    </li>
                    {CardFaction === "Humanity" ? (
                      <li>
                        <span>N. Adaptability: </span>
                        {NaturalAdpt}
                      </li>
                    ) : (
                      <li>
                        <span>AI Influence: </span>
                        {NaturalAdpt}
                      </li>
                    )}
                  </ul>
                </div>
                {!rename ? (
                  <form
                    onSubmit={Rename}
                    className="enlist_card transfer-card-input"
                  >
                    <h3>Confirm Rename?</h3>
                    <input
                      type="text"
                      placeholder="New name"
                      required
                      name="NewName"
                    />
                    <p>
                      Rename fee<br></br>
                      <span>{renameFee} MATIC</span>
                    </p>

                    <button className="btn" type="submit">
                      {!loading ? " CONFIRM" : "PLEASE WAIT..."}
                    </button>
                  </form>
                ) : (
                  <div className="enlist_card">
                    <h3>Congratulations!</h3>
                    <p>
                      Your card has been<br></br>
                      <span>Renamed</span>
                    </p>
                    <div className="form-floating">
                      <div
                        onClick={() => setDrop(!drop)}
                        className="text-break"
                      >
                        <label>
                          Transaction hash (TxHash){" "}
                          {!drop ? (
                            <i class="fal fa-plus"></i>
                          ) : (
                            <i class="fal fa-minus"></i>
                          )}
                        </label>
                        <p className="text-break">{result?.transactionHash}</p>
                      </div>
                      {drop && (
                        <>
                          <p className="text-break">
                            Block hash: {result?.blockHash}
                          </p>
                          <p className="text-break">
                            Block number: {result?.blockNumber}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {popup && (
            <PopUp
              popUpSubject={popUpSubject}
              popUpText={popUpText}
              setPopUp={setPopUp}
            ></PopUp>
          )}
        </div>
      </section>
      {loading && <LoaderFloating></LoaderFloating>}
    </div>
  );
}
export default RenameConfirmText;
