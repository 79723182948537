import React from 'react';
import Landing_banner from './Images/Landing_banner.png';
function SellProfileBanner(){
 return <div>
<div className="profile_user_section">
   {/* <img src={Landing_banner}/> */}
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="profile_text">
                     {/* <h1></h1> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
 </div>


}
export default SellProfileBanner;