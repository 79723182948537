import React from "react";
import { Link } from "react-router-dom";
function BreedingTaxt() {
  return (
    <div>
      <section className="breeding_text mt-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="breeding_link">
                <Link className="btn active" to="/breed-self">
                  BREED CARDS WITH SELF
                </Link>
                <br></br>
                <Link className="btn" to="/breed-others">
                  BREED CARDS WITH OTHERS
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default BreedingTaxt;
