import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addressContext, navigationContext, providerContext } from "../../../../../App";
import useFixedMarketContract from "../../../../Hooks/FixedMarketHook";
import useMarketPlaceCard from "../../../../Shared/MarketPlaceCard/useMarketPlaceCard";
import useStats from "../../../../Shared/useStates";
import * as AWS from "aws-sdk";
import {
  buyCard,
  GetCOOAddrFixedMarketPlace,
  GetFixedMarketPlacePrice,
  GetPromoCodeDetailsFM,
} from "../../../../UserPage/BlockChainFunctions";
import PopUp from "../../../../Shared/PopUp";
import useCustomerInfo from "../../../../Hooks/useCustomerInfo";
import useColor from "../../../../Shared/useColor";
import useRankImage from "../../../../Shared/useRankImage";
import LoaderFloating from "../../../../Shared/LoaderFloating";
function PurchaseConfirmationTaxt() {
  const TokenId = useParams();
  // console.log("tok", TokenId);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [CardFaction, CardName, CardType, CardRank, CardGender] =
    useMarketPlaceCard(TokenId.TokenId);
  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(TokenId.TokenId);
  const { ethers } = require("ethers");
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);
  const navigate = useNavigate();
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const [price, setPrice] = useState();
  const [purchaseConfirmation, setPurchaseConfirmation] = useState(false);
  const [result, setResult] = useState();
  const [fixedMarketContract, setFixedMarketContract] =
    useFixedMarketContract();
  const [drop, setDrop] = useState(false);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const ssm = new AWS.SSM();
  const [loading, setLoading] = useState();
  const [promoValidation, setPromoValidation] = useState(false);
  const [promoAct, setPromoAct] = useState(false);
  const [promoOnClick, setPromoOnClick] = useState(false);
  const [promoConfirm, setPromoConfirm] = useState(false);
  const [coo, setCoo] = useState("null");
  const [seller, setSeller] = useState(null);
  const getCOOAddr = async () => {
    const result = await GetCOOAddrFixedMarketPlace(fixedMarketContract);
    setCoo(result);
  };
  const getPromoDetails = async (Code) => {
    const result = await GetPromoCodeDetailsFM(Code, walletProvider);
    return result;
  };

  useEffect(() => {
    if (fixedMarketContract) {
      getCOOAddr();
    }
  }, [fixedMarketContract]);

  const Price = async () => {
    const Price = await GetFixedMarketPlacePrice(
      TokenId.TokenId,
      fixedMarketContract
    );
    setPrice(ethers.utils.formatEther(Price?.price.toString()));
    setSeller(Price?.addr);
  };

  useEffect(() => {
    // console.log(seller, coo, "sellet,coo");
    if (seller === coo) {
      // console.log("true");
      setPromoAct(true);
    }
  }, [seller, coo]);

  useEffect(() => {
    if (TokenId.TokenId && fixedMarketContract) {
      Price();
    }
  }, [TokenId.TokenId, fixedMarketContract]);

  const Purchase = async (e) => {
    e.preventDefault();
    setPopUpSubject("")
    setPopUpText("")
    const promo = e.target?.promo?.value || "Default";
    // console.log(promo,"promo");
    const result = await getPromoDetails(promo);
    if (result.Exists === true) {
      setPromoValidation(true);
    }

    setLoading(true);
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      if (promoValidation === true || promo === "Default") {
        const result = await buyCard(
          TokenId.TokenId,
          promo,
          walletProvider,
          fixedMarketContract,
          ssm
        );
        // console.log(result, "buy card");
        setLoading(false);
        // setPopUp(true);
        if (result) {
          if (result?.error?.code) {
            setPopUpSubject("Oops");
            setPopUpText(result.message);
            setPopUp(true);
          }
          if (result?.txreceipt?.blockHash) {
            setResult(result);
            setPurchaseConfirmation(true);
          }
          if (result.data?.message) {
            setPopUpSubject("Oops");
            setPopUpText(result.data.message);
            setPopUp(true);
          }
        }
      } else {
        setLoading(false);
        setPopUp(true);
        setPopUpSubject("Promo code doesn't exist");
      }
    } else {
      setLoading(false);
      setPopUp(true);
      // setPopUpSubject("Oops");
      setPopUpSubject("Connect your registered wallet ");
    }
  };

 
  return (
    <div>
      <section className="purchase_tex word-wrapping">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="purchase_heading">
                <h2>Purchase card</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="purchase_succ_img card_img">
                <img
                  className={color}
                  src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${TokenId.TokenId}`}
                  alt=""
                />
                <img
                  className="rankImage"
                  src={`/Rank/${rankImage}.png`}
                  alt=""
                />
                <img
                  className="extremis-logo"
                  src="/LOGO.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="purchase_succ_info">
                <h4>{CardName}</h4>
                <h5>{CardFaction}</h5>
                <div class="purchase_list_box">
                  <ul>
                    <li>
                      <span>Rank:</span> {CardRank}
                    </li>
                    <li>
                      <span>Type: </span>
                      {CardType}
                    </li>
                    <li>
                      <span>IQ: </span>
                      {IQ}
                    </li>
                    <li>
                      <span>Potential: </span>
                      {Potential}
                    </li>
                    <li>
                      <span>EQ: </span>
                      {EQ}
                    </li>
                    <li>
                      <span>Wisdom:</span> {Wisdom}
                    </li>
                    <li>
                      <span>Melee: </span>
                      {Melee}
                    </li>
                    <li>
                      <span>Leadership: </span>
                      {Leadership}
                    </li>
                    <li>
                      <span>Weapon: </span>
                      {Weapon}
                    </li>
                    {CardFaction === "Humanity" ? (
                      <li>
                        <span>N. Adaptability: </span>
                        {NaturalAdpt}
                      </li>
                    ) : (
                      <li>
                        <span>AI Influence: </span>
                        {NaturalAdpt}
                      </li>
                    )}
                  </ul>
                </div>
                {!purchaseConfirmation ? (
                  <div className="purchase_succ_text">
                    <form onSubmit={Purchase}>
                      <h3>Confirm purchase?</h3>
                      {promoAct && (
                        <p onClick={() => setPromoConfirm(!promoConfirm)}>
                          {!promoConfirm ? "Use Promo Code":"Remove Promo Code"}
                        </p>
                      )}
                      <div className="bid_price">
                        {promoConfirm && (
                          <div className="float-left">
                            <span>
                              Promo Code<br></br>
                              <div class="form-floating mb-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="PROMO CODE"
                                  name="promo"
                                />
                              </div>
                            </span>
                          </div>
                        )}
                        <div className="float-right">
                          <span>
                            Card price<br></br>
                            <strong>{price} MATIC</strong>
                          </span>
                        </div>
                      </div>
                      <div className="purchase_btn">
                        <button type="submit" className="btn">
                          {!loading ? "CONFIRM" : "PLEASE WAIT..."}
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="purchase_succ_text">
                    <h3>Congratulations!</h3>
                    <p>
                      You purchased a<br></br>
                      <span>New card</span>
                    </p>
                    <div className="purchase form-floating">
                      <div onClick={() => setDrop(!drop)}>
                      <label>
                          Transaction hash (TxHash){" "}
                          {!drop ? (
                            <i class="fal fa-plus"></i>
                          ) : (
                            <i class="fal fa-minus"></i>
                          )}
                        </label>
                        <p className="text-break">{result?.txreceipt?.transactionHash}</p>
                      </div>
                      {drop && (
                        <>
                          <p className="text-break">Block hash: {result?.txreceipt?.blockHash}</p>
                          <p className="text-break">Block number: {result?.txreceipt?.blockNumber}</p>
                        </>
                      )}
                       <div >
                        <label>
                          AWS response
                        </label>
                        <p>{result?.Awsresponse}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {popup && (
              <PopUp
                popUpSubject={popUpSubject}
                popUpText={popUpText}
                setPopUp={setPopUp}
              ></PopUp>
            )}
            {loading && <LoaderFloating></LoaderFloating>}
          </div>
        </div>
      </section>
    </div>
  );
}
export default PurchaseConfirmationTaxt;
