import React, { useState } from "react";
import { useEffect } from "react";
import useWarContract from "../../Hooks/WarContractHook";
import "../Card.css";

import { GetWarFee } from "../../UserPage/BlockChainFunctions";
import useWarCard from "./useWarCard";
import useImage from "../Card/useImage";
import useColor from "../useColor";

import useSellCard from "../SellCard/useSellCard";
import Loader from "../Loader/Loader";
import useRankImage from "../useRankImage";

const WarConfirmCard = (props) => {
  const [fee, setFee] = useState("");
  const [image, setImage] = useState(false);
  const [warContract, setWarContract] = useWarContract();

  const [CardFaction, CardName, CardType, CardRank, CardGender] = useSellCard(
    props.TokenId
  );

  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);

  useEffect(() => {
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);

  useEffect(() => {
    if (warContract) {
      const Fee = async () => {
        const result = await GetWarFee(warContract);
        setFee(result);
      };
      Fee();
    }
  }, [warContract]);
  return (
    <>
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_img word-wrapping">
          <img
            className={color}
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            alt=""
          />
          {/* <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />{" "}
          <img
            className="extremis-logo"
            src="/LOGO.png"
            alt=""
          /> */}
          <div className="card_info_text text-light mt-2">
            <div className="d-flex">
              <div className="w-50">
              <h4 className="mt-0">{CardName}</h4>
              <h6>{CardFaction}</h6>
              </div>
              <p className="w-50">
                ID: {props.TokenId} <br /> War fee:<br></br>
                {fee} MATIC
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WarConfirmCard;
