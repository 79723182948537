import React, { useEffect } from "react";
import { useState } from "react";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
const useCustomerInfoByTokenId = (TokenId, currentAddress) => {
  const [customerInfo, setCustomerInfo] = useState();
  const GetCustomerInfo = async (owner) => {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    const param = {
      TableName: process.env.REACT_APP_CUSTOMER_CARD_TABLE_NAME,
      Key: {
        TokenId: TokenId,
        CustomerID: currentAddress,
      },
    };
    // console.log(param, "hello param");
    const response = await documentClient.get(param).promise();
    // console.log(response, "ffffffffff");
    return response;
  };

  useEffect(() => {
    const CustomerInfo = async () => {
      if (awsmobile.aws_cognito_region && AWS.config.credentials) {
        const customerInfo = await GetCustomerInfo();
        //   setUsername(customerInfo.Item.Username);
        setCustomerInfo(customerInfo);
      }
    };
    CustomerInfo();
  }, [awsmobile.aws_cognito_region, AWS.config.credentials,TokenId]);

  return customerInfo;
};

export default useCustomerInfoByTokenId;
