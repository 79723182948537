import React from "react";
import DragBreedOthers from "../../../../Shared/DnD/DragBreedOthers";
import "../BreedOthersCollection/CardCollection.css";
import BreedOthersBlacknetList from "./SubPages/Blacknet/Sections/BreedOthersBlacknetList";
import BreedOthersBlacknetText from "./SubPages/Blacknet/Sections/BreedOthersBlacknetText";

function BreedOthersBlacknetCard() {
  return (
    <div>
      {/* <Blacknetbanner/> */}
      <div className="drag-and-card">
        <div className="card-area">
          <BreedOthersBlacknetList />
          <BreedOthersBlacknetText />
        </div>
        <div className="drag-area">
          <DragBreedOthers></DragBreedOthers>
        </div>
      </div>
    </div>
  );
}
export default BreedOthersBlacknetCard;
