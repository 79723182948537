import React from 'react';
import "./Loader.css"
import LoaderImg from '../../../assets/Extremis loader-01.png'
const Loader = () => {
    return (
        <div className="loader-container">
            <div className='card-loader'>
            <img src={LoaderImg} alt="" />
        </div>
        </div>
    );
};

export default Loader;