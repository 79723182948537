import React from 'react';
// import Humanitybanner from '../Cardcollection/SubPages/Humanity/Sections/Humanitybanner';
import HumanityText from './SubPages/Humanity/Sections/PromoteHumanityText';
import HumanityList from './SubPages/Humanity/Sections/PromoteHumanityList';
import '../PromoteCollection/CardCollection.css';
import PromoteHumanityList from './SubPages/Humanity/Sections/PromoteHumanityList';
import PromoteHumanityText from './SubPages/Humanity/Sections/PromoteHumanityText';

function PromoteHumanityCard () {
	return <div>
		{/* <Humanitybanner/> */}
		<PromoteHumanityList/>
		<PromoteHumanityText/>

        
	</div>
}
export default PromoteHumanityCard;