import React from 'react';
import RenameBanner from '../Rename/Sections/RenameBanner';
import RenameTaxt from '../Rename/Sections/RenameTaxt';
import '../Rename/Rename.css';
import { Outlet } from 'react-router-dom';
function Rename(){
 return <div>
<RenameBanner/>
{/* <RenameTaxt/> */}
<Outlet></Outlet>
</div>


}
export default Rename;