import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { addressContext, providerContext } from "../../../../App";
import useSellCard from "../../../Shared/SellCard/useSellCard";
import useStats from "../../../Shared/useStates";
import {
  GetFixedMarketPlacePrice,
  IsOnFixedMarketList,
  putOnFixedMarketPlace,
  removeFromFixedMarketPlace,
} from "../../../UserPage/BlockChainFunctions";
import blacknet1 from "../Images/blacknet1.png";
import * as AWS from "aws-sdk";
import useCustomerInfo from "../../../Hooks/useCustomerInfo";
import PopUp from "../../../Shared/PopUp";
import useColor from "../../../Shared/useColor";
import Loader from "../../../Shared/Loader/Loader";
import useRankImage from "../../../Shared/useRankImage";
import useFixedMarketContract from "../../../Hooks/FixedMarketHook";
import LoaderFloating from "../../../Shared/LoaderFloating";

function AuctionConfirmationTaxt() {
  const TokenId = useParams();

  // console.log("sell", TokenId);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [price, setPrice] = useState();
  const [time, setTime] = useState();
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const [CardFaction, CardName, CardType, CardRank, CardGender] = useSellCard(
    TokenId.TokenId
  );
  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(TokenId.TokenId);
  const { ethers } = require("ethers");

  const [drop, setDrop] = useState(false);
  const [congrats, setCongrats] = useState(false);
  const [congratulation, setCongratulation] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [popUpText1, setPopUpText1] = useState("");
  const [loading, setLoading] = useState();
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  const [fixedMarketContract, setFixedMarketContract] =
    useFixedMarketContract();

  // console.log(times);
  const ssm = new AWS.SSM();
  useEffect(() => {
    if (TokenId.TokenId && fixedMarketContract) {
      const Price = async () => {
        const price = await GetFixedMarketPlacePrice(
          TokenId.TokenId,
          fixedMarketContract
        );
        // console.log(price, "price");
        setPrice(ethers.utils.formatEther(price?.price.toString()));
      };
      Price();
    }
  }, [TokenId.TokenId, fixedMarketContract]);

  const Sell = async (e) => {
    e.preventDefault();
    setPopUpSubject("")
    setPopUpText("")
    setLoading(true);
    const Price = e.target.price.value;

    if (currentAddress === customerInfo?.Item?.CustomerID) {
      // console.log(TokenId.TokenId, Price, "hhhhh");
      setPrice(Price);
      const result = await putOnFixedMarketPlace(
        TokenId.TokenId,
        Price,
        walletProvider,
        ssm
      );
      // console.log(result, "sell on fixed market");
      setLoading(false);
      if (result) {
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.txreceipt?.blockHash) {
          setCongrats(true);
          const today = new Date();
          const times = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
          setPopUp(true);

          setTime(times);
          setCongratulation(true);
          setPopUpSubject("Successful");

          setPopUpText(`          
          Transaction Hash: ${result.txreceipt.transactionHash}                              
              `);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
        }
       
if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      }
    } else {
      setLoading(false);
      setPopUp(true);
      // setPopUpSubject("Oops");
      setPopUpSubject("Connect your registered wallet ");
    }
  };

  const WithdrawSell = async () => {
    setPopUpSubject("")
    setPopUpText("")
    setLoading(true);
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      const result = await removeFromFixedMarketPlace(
        TokenId.TokenId,
        walletProvider,
        ssm
      );
      // console.log(result, "sell withdraw");
      setLoading(false);
      if (result) {
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result?.txreceipt.blockHash) {
          setCongrats(false);
          setPopUpSubject("Successful");
          setPopUpText(`          
          Transaction Hash: ${result.txreceipt.transactionHash}                              
              `);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
        }
        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      }
    } else {
      setLoading(false);
      setPopUp(true);
      // setPopUpSubject("Oops");
      setPopUpSubject("Connect your registered wallet ");
    }
  };
  const IsOnFixed = async () => {
    const result = await IsOnFixedMarketList(
      TokenId.TokenId,
      fixedMarketContract
    );
    setCongrats(result);
  };

  useEffect(() => {
    if (fixedMarketContract) {
      IsOnFixed();
    }
  }, [ fixedMarketContract]);

  return (
    <div>
      <section className="sell_card_text word-wrapping">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sell_card_heading">
                <h2>SELL CARD</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="sell_card_succ_img card_img">
                <img
                  src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${TokenId.TokenId}`}
                  className={color}
                  alt=""
                />
                <img
                  className="rankImage"
                  src={`/Rank/${rankImage}.png`}
                  alt=""
                />
                <img
                  className="extremis-logo"
                  src="/LOGO.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="sell_card_succ_info">
                <h4>{CardName}</h4>

                <h5>{CardFaction}</h5>
                <div class="sell_card_list_box">
                  <ul>
                    <li>
                      <span> ID:</span> <strong>{TokenId.TokenId}</strong>
                    </li>
                    <li>
                      <span>Rank: </span> {CardRank}
                    </li>
                    <li>
                      <span>Type: </span>
                      {CardType}
                    </li>
                    <li>
                      <span>IQ: </span> {IQ}
                    </li>
                    <li>
                      <span>Potential: </span> {Potential}
                    </li>
                    <li>
                      <span>EQ: </span> {EQ}
                    </li>
                    <li>
                      <span>Wisdom: </span> {Wisdom}
                    </li>
                    <li>
                      <span>Melee: </span> {Melee}
                    </li>
                    <li>
                      <span>Leadership: </span> {Leadership}
                    </li>
                    <li>
                      <span>Weapon: </span> {Weapon}
                    </li>
                    {CardFaction === "Humanity" ? (
                      <li>
                        <span>N. Adaptability: </span>
                        {NaturalAdpt}
                      </li>
                    ) : (
                      <li>
                        <span>AI Influence: </span>
                        {NaturalAdpt}
                      </li>
                    )}
                  </ul>
                </div>

                {!congrats ? (
                  <form onSubmit={Sell} className="sell_card_succ_text">
                    <h3>Sell card?</h3>
                    <div className="sell_price price">
                      <div className="float-left">
                        <span>
                          Price<br></br>
                          <div class="form-floating mb-3">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="MATIC"
                              name="price"
                              required
                            />
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="sell_btn">
                      <button className=" confirm-button" type="submit">
                        {!loading ? "CONFIRM" : "PLEASE WAIT..."}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="sell_card_succ_text">
                    {congratulation && <h3>Congratulations!</h3>}

                    <p>
                      Your card is now <br></br>
                      <strong>For sale</strong>
                    </p>

                    <div className="price_table">
                      <ul>
                        <li className="border-end">
                          Fixed price<br></br>
                          <strong>{price} MATIC</strong>
                        </li>
                        <li>
                          Sale time<br></br>
                          <strong className="text-danger">{time}</strong>
                        </li>
                      </ul>
                    </div>
                    <div className="purchase_btn">
                      <button
                        onClick={WithdrawSell}
                        className="confirm-button btn"
                      >
                        {!loading ? "WITHDRAW SELL" : "PLEASE WAIT..."}
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {/* {loading && <Loader></Loader>} */}
            </div>

            {popup && (
              <PopUp
                popUpSubject={popUpSubject}
                popUpText={popUpText}
                popUpText1={popUpText1}
                setPopUp={setPopUp}
              ></PopUp>
            )}
          </div>
          {loading && <LoaderFloating></LoaderFloating>}
        </div>
      </section>
    </div>
  );
}
export default AuctionConfirmationTaxt;
