import React from 'react';
import '../ProfileCollection/CardCollection.css';
import ProfileBlacknetList from './SubPages/Blacknet/Sections/ProfileBlacknetList';
import ProfileBlacknetText from './SubPages/Blacknet/Sections/ProfileBlacknetText';

function ProfileBlacknetCard () {
	return <div>
		{/* <Blacknetbanner/> */}
		<ProfileBlacknetList></ProfileBlacknetList>
		<ProfileBlacknetText></ProfileBlacknetText>

        
	</div>
}
export default ProfileBlacknetCard;