import React from "react";
import "../NftGive.css";
const NftGiveText = () => {
  return (
    <div>
      <section className="board_section text-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1">
              <div className=" mx-auto">
                <div className="text-center text-light mb-5 giveaway">
                  <h2 className=" ">NFT GIVEAWAYS!!!</h2>
                  <h3> Great opportunity to win some of our most thrilling NFT Cards</h3>
                 
                </div>
                <p className="text-start ">
                  <b>
                    Extremis is distributing around 200 NFTs to our new users/ supporters! Check out our easy instructions to enter this giveaway given below!
                    Here are some tips on how to be the lucky one. Just comply
                    with these easy requirements to be eligible for your
                    giveaway.
                    <br />
                    <br />
                    ****Mandatory Requirement for all NFT giveaways: Sign Up on our website.****
                    
                  </b>
                </p>
                <p className="text-start">
                  Requirement for Blacknet Card: <br />
                  1. Follow us on Twitter. <br />
                  2. Follow and like our Facebook page.<br />
                  3. Like and share any content from our FB page.<br />
                  <br />
                  Requirement for Alice Card:<br />
                  1. Join our discord server.<br />
                  2. Join our telegram channel.<br />
                  3. Follow our Instagram account.<br />
                  <br />
                  Requirement for Humanity Card:<br />
                  1. Like and retweet any 2 of our tweets.<br />
                  2. Like and share any two content from our facebook page and instagram page.<br />
                  3. Follow or join one of our 5 official channels, i.e: Twitter, Facebook, Instagram, Telegram or Discord.<br />
                  <br />
                  <br />
                  We are also doing a random draw for our first 5000 Users. Be amount the first 5000 to sign up for our account, and you will be automatically added to our NFT draw.<br />
                  In this NFT draw, we are giving away one of our Genesis cards. Genesis cards are special and rare NFTs in our game. There will only be 54 Genesis NFTs in our Alpha version.<br />
                  Also remember Genesis cards are immortal in our game unlike other cards!!<br />
                  <br />
                  <b> Note:</b> <br />
                  Once you complete the requirments for the NFT giveaway, Please email us with explaining which requirment you completed, we will validated it and send the NFT(s) in your registered wallet address.
                  Your card will arrive at your wallet address within 48-72
                  hours if all requirements are satisfied.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NftGiveText;
