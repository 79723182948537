import React, { useState } from "react";
import { useDrop } from "react-dnd";
import DropCard from "./DropCard";
import "../Card.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const DragLeft = ({ setId1 ,setFaction1}) => {
  const [id, setId] = useState();
  const location = useLocation();
  useEffect(() => {
    setId(location?.state?.TokenId);
    setId1(location?.state?.TokenId);
  }, [location]);
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "div",
    drop: (item) => addToBoard(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const addToBoard = (id) => {
    setId(id);
    setId1(id);
  };

  return (
    // <li >
    <div className="breeding_lab" ref={drop}>
      <div className="upload_img">
        {id ? (
          <DropCard TokenId={id} setFaction={setFaction1}></DropCard>
        ) : (
          <div class="image-upload">
            <label for="file-input">
              <i class="far fa-plus"></i>
              <br></br>
             
                Drag and drop card to enter{" "}
             
            </label>
          </div>
        )}
      </div>
    </div>
    // </li>
  );
};

export default DragLeft;
