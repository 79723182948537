import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { putDataCustomerInfoTbl } from "./dynamoData";
import { useContext } from "react";
import { addressContext, authContext, forceSignOutContext } from "../../App";

const ConfirmRecovery = ({ email }) => {
  const [signInUser, setSignInUser] = useContext(authContext);
  const [currentAddress, setcurrentAddress] = useContext(addressContext);
  // const [forceSignOut, setForceSignOut] = useContext(forceSignOutContext);
  const [confirmButton, setConfirmButton] = useState(false);
  const [err, setErr] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();

  const Confirm = async (data) => {
    setConfirmButton(true);

    const code = data.code;
    const password = data.password;
    try {
      const result = await Auth.forgotPasswordSubmit(email, code, password);
      // console.log(result);
      setErr("Successful. You're redirecting to login page");
      navigate("/signin");
    } catch (error) {
      setConfirmButton(false);
      setErr("Something went wrong");
      // console.log(error, "recover error");
    }
  };

  return (
    <div className="confirmation">
      <div className="confirmation-msg">
        <h1>Confirmation!</h1>
        <p>Please check the email for the verification code</p>
        <form action="" onSubmit={handleSubmit(Confirm)}>
          <input
            type="text"
            placeholder="code"
            {...register("code", {
              required: true,
            })}
          />
          <input
            type="text"
            placeholder="New password"
            {...register("password", {
              required: true,
            })}
          />
          {err && (
            <p> Invalid verification code is provided, please try again.</p>
          )}
          <button
            type="submit"
            // id="confirmation"
            className="recovery"
          >
            {!confirmButton ? "CONFIRM" : "PLEASE WAIT..."}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ConfirmRecovery;
