import React, { useContext, useState } from "react";
import "../Card.css";

import {
  breed,
  GetCardInfo,
  InitiateBattle,
} from "../../UserPage/BlockChainFunctions";
import DragLeft from "./DragLeft";
import DragRight from "./DragRight";
import * as AWS from "aws-sdk";
import useGameCentreContract from "../../Hooks/GameCentreHook";
import {
  addressContext,
  navigationContext,
  providerContext,
} from "../../../App";
import "../../Profile/Pages/Breeding/BreedSelfCollection/breed.css";
import "../DragCss.css";
import useCustomerInfo from "../../Hooks/useCustomerInfo";
import PopUp from "../PopUp";
import LoaderFloating from "../LoaderFloating";
import { useEffect } from "react";
import ResultCard from "./ResultCard";
function DragBreedCard() {
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);
  const [navConfirm, setNavConfirm] = useState(false);
  const [disable, setDisable] = useState(true);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const ssm = new AWS.SSM();
  const [Id1, setId1] = useState();
  const [Id2, setId2] = useState();
  const [finalResult, setFinalResult] = useState(null);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [popUpText1, setPopUpText1] = useState("");
  const [loading, setLoading] = useState();
  const [faction1, setFaction1] = useState("");
  const [faction2, setFaction2] = useState("");
  const Battle = async () => {
    setLoading(true);
    setPopUpSubject("")
    setPopUpText("")
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      if (faction1 !== faction2) {
        setLoading(true);
        const result = await InitiateBattle(Id1, Id2, currentAddress, ssm);
        const finalResult = JSON.parse(result);
        setLoading(false);

        if (finalResult) {
          if (finalResult?.Receipt?.transactionHash) {
            setFinalResult(finalResult);
            setNavConfirm(true);
          } else {
            setPopUp(true);
            setPopUpSubject("Battle Information");
            setPopUpText(`${result}`);
          }
        }
      } else {
        setLoading(false);
        setPopUp(true);
        setPopUpSubject("Please choose the cards from the different factions");
      }
    } else {
      setLoading(false);
      setPopUp(true);
      setPopUpSubject("Connect your registered wallet ");
    }
  };
  useEffect(() => {
    if (navigationCntxt === true && navConfirm === true) {
      window.location.reload();
    }
  }, [navigationCntxt, navConfirm]);
  useEffect(() => {
    if (Id1 && Id2) {
      setDisable(false);
    }
  }, [Id1, Id2]);

  return (
    <div>
      <div className="dnd_container">
        <div className="drag-left">
          <DragLeft setId1={setId1} setFaction1={setFaction1}></DragLeft>
        </div>
        <div className="plus-sign">
          <strong>VS</strong>
        </div>
        <div className="drag-right">
          <DragRight setId2={setId2} setFaction2={setFaction2}></DragRight>
        </div>
        {/* <div className="zone_btn"> */}
        <button className="confirm-button" onClick={Battle} disabled={disable}>
          {!loading ? "  INITIATE BATTLE" : "PLEASE WAIT..."}
        </button>
        {/* </div> */}
      </div>
      <div className="dnd_container-mobile">
        <div className="drop-mobile">
          <div className="drag-left">
            <DragLeft setId1={setId1} setFaction1={setFaction1}></DragLeft>
          </div>
          <div className="plus-sign">
            <strong>+</strong>
          </div>
          <div className="drag-right">
            <DragRight setId2={setId2} setFaction2={setFaction2}></DragRight>
          </div>
        </div>
        <button className="confirm-button" onClick={Battle} disabled={disable}>
          {!loading ? "  INITIATE BATTLE" : "PLEASE WAIT..."}
        </button>
      </div>
      {popup && (
        <PopUp
          popUpSubject={popUpSubject}
          popUpText={popUpText}
          popUpText1={popUpText1}
          setPopUp={setPopUp}
        ></PopUp>
      )}
      {finalResult && (
        <ResultCard
          finalResult={finalResult}
          setFinalResult={setFinalResult}
        ></ResultCard>
      )}
      {loading && <LoaderFloating></LoaderFloating>}
    </div>
  );
}
export default DragBreedCard;
