import React from 'react';
import human_header_metaverse from '../../../../assets/banner/Humanity-header-2.png';
function Humanitybanner () {
	return <div>
<div className="Page_humanity_section">
   <img src= { human_header_metaverse }/>
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="page_title">
                     {/* <h1>Humanity</h1> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
}
export default Humanitybanner;