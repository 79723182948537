import React from 'react';
import '../TransferCollection/CardCollection.css';
import TransferAliceList from './SubPages/AliceCard/Sections/TransferAliceList';
import TransferAliceText from './SubPages/AliceCard/Sections/TransferAliceText';

function TransferAliceCard () {
	return <div>
		{/* <Alicebanner/> */}
		<TransferAliceList></TransferAliceList>
		<TransferAliceText></TransferAliceText>

        
	</div>
}
export default TransferAliceCard;