import React, { useEffect } from "react";
import "./Styled.css";

function ArrowIcon(props) {
  const rotateHandler = () => {
    props.onClick();
  };
  useEffect(() => {
    return;
  }, [props.rotate]);

  return (
    <div
      onClick={rotateHandler}
      type="button"
      className={"arrow-icon " + props.rotate}
    ></div>
  );
}

export default ArrowIcon;
