import React from "react";
import useFixedMarketPromo from "../../../../Hooks/useFixedMarketPromo";
import PromoDataTable from "../PromoData/PromoDataTable";
import PromoFixedList from "./PromoFixedList";
import "./PromoFixedMP.css";
const PromoFixedMP = () => {
  const promoCode = useFixedMarketPromo();
  return (
    <div>
      <PromoFixedList></PromoFixedList>
      <PromoDataTable promoCode={promoCode}></PromoDataTable>
    </div>
  );
};

export default PromoFixedMP;
