import React from 'react';
import PromoteAllcardsList from './Sections/PromoteAllcardsList';
import AllcardsList from './Sections/PromoteAllcardsList';
import PromoteAllCardsText from './Sections/PromoteAllcardsText';
import AllCardsText from './Sections/PromoteAllcardsText';

const PromoteAllCard = () => {
    return (
        <div>
            <PromoteAllcardsList></PromoteAllcardsList>
            <PromoteAllCardsText></PromoteAllCardsText>
        </div>
    );
};

export default PromoteAllCard;