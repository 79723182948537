import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Table } from "react-bootstrap";
import { providerContext } from "../../../../../App";
import { GetPromoCodeDetailsFM } from "../../../../UserPage/BlockChainFunctions";
import "./PromoDataTable.css";
import PromoDataTableRow from "./PromoDataTableRow";

const PromoDataTable = ({ promoCode }) => {
  return (
    <div className="promo-data-table">
      <div className="content_box">
        <Table responsive variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Promo Name</th>
              <th>Promo Code</th>
              {/* <th>Discount</th> */}
              <th>Total Value</th>
              <th>Use Count</th>
            </tr>
          </thead>
          <tbody>
            {promoCode?.map((promoCode, index) => (
              <PromoDataTableRow
                promoCode={promoCode}
                index={index}
                key={index}
              ></PromoDataTableRow>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default PromoDataTable;
