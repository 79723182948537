import React from "react";
import blacknet from "../Images/blacknet.png";
import alice from "../Images/alice.png";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../aws-exports";
import { useState } from "react";
import { useEffect } from "react";
import { useToast } from "react-toastify";

import Pagination from "../../../Shared/Pagination";
import AuctionCard from "../../../Shared/AuctionCard/AuctionCard";
import SellCard from "../../../Shared/SellCard/SellCard";
import useGetAllCard from "../../../Hooks/useGetAllCard";
import Loader from "../../../Shared/Loader/Loader";

function AuctionSellAllCardsText({ children }) {
  const [TokenId, page, setPage, next, Next, Previous, loader] =
    useGetAllCard();

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            {loader ? (
              <Loader></Loader>
            ) : (
              <div className="content_box">
                <h4>ALL CARDS</h4>
                <div className="row">
                  {TokenId.slice((page - 1) * 10, page * 10).map((TokenId) => (
                    <SellCard TokenId={TokenId} key={TokenId}>
                      auction
                    </SellCard>
                  ))}
                  {TokenId?.length === 0 && <h1>NO CARD</h1>}
                </div>

                <Pagination
                  page={page}
                  setPage={setPage}
                  length={TokenId.length / 10}
                  Previous={Previous}
                  Next={Next}
                  next={next}
                ></Pagination>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default AuctionSellAllCardsText;
