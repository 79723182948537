import React from 'react';
import { Link } from 'react-router-dom';
import humanity_metaverse from "./Images/humanity_metaverse.png";
function MetaverseHumanity () {
	return <div>
<section className="image_text_box_section">
   <div className="container">
      <div className="row align-items-center">
         <div className="col-md-6">
            <div className="box_image">
               <img src={humanity_metaverse}/>
            </div>
         </div>
         <div className="col-md-6">
            <div className="text_box">
               <h3>HUMANITY</h3>
               <p>The most powerful species on Earth have experimented on developments that make life easier. Call it greed or an unexpected turn of fate, humans learn about consciousness and try to create an artificial conscious being. The result of the experiment would have been revolutionary. But the experiment backfires. And in the process, their creation now sought to destroy them. The humans soon have to come up with solutions to save themselves from the Sentinels that have gone rampant.</p>
               <ul>
                  <li><Link to="/cardcollection/humanity-card" className='btn'>SEE HUMANITY CARDS</Link></li>
                  <li><Link to="/humanity" className='btn'>READ MORE</Link></li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</section>
</div>
    }
    export default MetaverseHumanity;