import React from 'react';
import Alicebanner from '../Alice/Sections/Alicebanner';
import AliceText from '../Alice/Sections/AliceText';
import '../Alice/Alice.css';
function Alice () {
	return <div>
		<Alicebanner/>
		<AliceText/>
       
        
	</div>
}
export default Alice;