import React, { useContext, useRef } from "react";
// import alice_logo from "../Sections/Images/alice_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import useImage from "../Card/useImage";
import useColor from "../useColor";

import useSellCard from "./useSellCard";
import Loader from "../Loader/Loader";
import "../Card.css";
import { useEffect } from "react";
import { useState } from "react";
import useRankImage from "../useRankImage";

const { ethers } = require("ethers");

function SellCard(props) {
  const navigation = useNavigate();
  const [image, setImage] = useState(false);
  const [CardFaction, CardName, CardType, CardRank, CardGender] = useSellCard(
    props.TokenId
  );
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);

  useEffect(() => {
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);

  const nav = (props) => {
    // console.log("prop", props);
    navigation(`/individual-card/${props}`);
  };

  return (
    <div className="col-md-6 word-wrapping">
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_box card_img">
          <img
            onClick={() => nav(props.TokenId)}
            style={
              CardFaction && CardGender && CardType && CardRank
                ? {}
                : { display: "none" }
            }
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />{" "}
          <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />   <img className="extremis-logo" src="/LOGO.png" alt="" />
          {CardFaction && CardGender && CardType && CardRank ? (
            <>
              <div className="card_info">
                <h3>{CardName}</h3>{" "}
                <p className="fs-6 text-white m-0"> ID: {props.TokenId}</p>
              </div>
              <div className="card_info">
                <div className="card_type">
                  <p>
                    FACTION: <strong>{CardFaction}</strong>
                  </p>
                  <p>
                    TYPE: <strong> {CardType}</strong>
                  </p>
                  <p>
                    RANK: <strong>{CardRank}</strong>
                  </p>
                </div>
                <div className="card_rank">
                  <div className="card_link">
                    <ul>
                      {/* <li><Link to="/battle-list">BATTLE LIST</Link></li>
                  <li><Link to="/breeding">BREED LIST</Link></li> */}
                      {props.children === "fixed" ? (
                        <li>
                          <Link
                            to={`/fixed-sell-confirmation/${props.TokenId}`}
                          >
                            SELL ON FIXED MARKET
                          </Link>
                        </li>
                      ) : (
                        <li>
                          <Link
                            to={`/sell-auction-confirmation/${props.TokenId}`}
                          >
                            SELL ON AUCTION
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
    </div>
  );
}
export default SellCard;
