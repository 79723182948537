import React from 'react';
import '../SellCollection/CardCollection.css';
import SellGenesisList from './SubPages/Genesis/Sections/SellGenesisList';
import SellGenesisText from './SubPages/Genesis/Sections/SellGenesisText';

function SellGenesisCard () {
	return <div>
		{/* <Genesisbanner/> */}
		<SellGenesisList></SellGenesisList>
		<SellGenesisText></SellGenesisText>

        
	</div>
}
export default SellGenesisCard;