import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { providerContext } from "../../../App";
import * as AWS from "aws-sdk";
// components
import ArrowIcon from "./AdminComponent/ArrowIcon";
import BasicButton from "./AdminComponent/BasicButton";
import FormOneInput from "./AdminComponent/FormOneInput";
import FormSubmit from "./AdminComponent/FormSubmit";
import FormTowInput from "./AdminComponent/FormTowInput";
import LoadingSpinner from "./AdminComponent/LoadingSpinner";
import useWarContract from "../../Hooks/WarContractHook";
import {
  GetBattleFee,
  GetCFOAddrWar,
  GetContractBalanceGameCentreLink,
  GetContractBalanceWar,
  GetContractBalanceWarLink,
  GetCOOAddrWar,
  GetTesseractPercentageWar,
  GetWarBp,
  GetWarFee,
  InitiateBattle,
  IsOnBattleList,
  IsOnWarZone,
  IsPausedWar,
  pauseWarContract,
  setBattleFee,
  setCFOAddrWar,
  setCOOAddrWar,
  setWarBp,
  setWarFee,
  setWarFeeTessPecentage,
  unPauseWarContract,
  withdrawBalanceGameCentreLink,
  withdrawBalanceWar,
  withdrawBalanceWarLink,
} from "../../UserPage/BlockChainFunctions";

//const API Calls from ethers

function War(props) {
  const [loading, setLoading] = useState(false);
  const [pauseOn, setPauseOn] = useState(false);
  const [rotate, setRotate] = useState("");
  const [dropDownClass, setDropDownClass] = useState("drop-down-close");
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [warContract, setWarContract] = useWarContract();
  const ssm = new AWS.SSM();

  const [cfo, setCfo] = useState("");
  const [coo, setCoo] = useState("");
  const [balance, setBalance] = useState("");
  const [battle, setBattle] = useState("");
  const [war, setWar] = useState("");
  const [point, setPoint] = useState("");
  const [link, setLink] = useState("");
  const [tpercentage, setTPercentage] = useState("");

  useEffect(() => {
    if (warContract) {
      // console.log("hello");
      props.WarIsPause();
    }
  }, [warContract]);

  const Pause = async () => {
    props.setLoading(true);

    try {
      const result = await pauseWarContract(walletProvider);
      // setPauseOn(true)
      // console.log(result);
      props.WarIsPause();
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const Unpause = async () => {
    props.setLoading(true);

    try {
      const result = await unPauseWarContract(walletProvider);
      // console.log(result);
      props.WarIsPause();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const getContractBalance = async () => {
    const result = await GetContractBalanceWar(warContract);
    // console.log(result, "balance");
    setBalance(result.toString());
  };
  const getCFOAddrWar = async () => {
    const result = await GetCFOAddrWar(warContract);

    setCfo(result);
  };
  const getCOOAddrWar = async () => {
    const result = await GetCOOAddrWar(warContract);
    setCoo(result);
  };
  const getWarBp = async () => {
    const result = await GetWarBp(warContract);
    // console.log(result, "bp");
    setPoint(result);
  };
  const getBattleFee = async () => {
    const result = await GetBattleFee(warContract);
    setBattle(result);
  };
  const getTPercentage = async () => {
    const result = await GetTesseractPercentageWar(warContract);
    setTPercentage(result);
  };

  const getWarFee = async () => {
    const result = await GetWarFee(warContract);
    setWar(result);
  };

  const getLink = async () => {
    const result = await GetContractBalanceWarLink(warContract);
    setLink(result.toString());
  };

  // console.log(link,"linkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
  useEffect(() => {
    if (warContract) {
      getContractBalance();
    }
  }, [warContract]);
  useEffect(() => {
    if (warContract) {
      getCFOAddrWar();
    }
  }, [warContract]);

  useEffect(() => {
    if (warContract) {
      getCOOAddrWar();
    }
  }, [warContract]);

  useEffect(() => {
    if (warContract) {
      getWarBp();
    }
  }, [warContract]);

  useEffect(() => {
    if (warContract) {
      getBattleFee();
    }
  }, [warContract]);

  useEffect(() => {
    if (warContract) {
      getTPercentage();
    }
  }, [warContract]);

  useEffect(() => {
    if (warContract) {
      getWarFee();
    }
  }, [warContract]);
  useEffect(() => {
    if (warContract) {
      getLink();
    }
  }, [warContract]);

  // const initiateBattle = async ({ firstInput, secondInput }) => {
  //   console.log(firstInput, secondInput);
  //   const result = await InitiateBattle(firstInput, secondInput, ssm);
  //   console.log(result, "initiate battle");
  // };

  const SetBattleFee = async (Fee) => {
    // console.log(Fee);

    // console.log(result, "battle fee set");

    props.setLoading(true);

    try {
      const result = await setBattleFee(Fee, walletProvider);
      // setBattleFee(result)
      getBattleFee();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const SetWarBp = async (BP) => {
    props.setLoading(true);

    try {
      const result = await setWarBp(BP, walletProvider);
      // setPoint(result);
      // console.log(result, "war fee set");
      getWarBp();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const SetTPercentage = async (Percentage) => {
    props.setLoading(true);

    try {
      const result = await setWarFeeTessPecentage(Percentage, walletProvider);
      // setTPercentage(result);
      // console.log(result, "set percentage");
      getTPercentage();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const SetWarFee = async (Fee) => {
    props.setLoading(true);

    try {
      const result = await setWarFee(Fee, walletProvider);
      // setWarFee(result);
      // console.log(result, "set war ");
      getWarFee();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const WithdrawBalance = async (Amount) => {
    props.setLoading(true);

    try {
      const result = await withdrawBalanceWar(Amount, walletProvider);
      // setBalance(result);
      // console.log(result, "set withdraw");
      getContractBalance();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const SetCFOAddr = async (Address) => {
    props.setLoading(true);

    try {
      const result = await setCFOAddrWar(Address, walletProvider);
      // setCfo(result);
      // console.log(result, "set cfo");
      getCFOAddrWar();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const SetCOOAddr = async (Address) => {
    props.setLoading(true);

    try {
      const result = await setCOOAddrWar(Address, walletProvider);
      // setCoo(result);
      // console.log(result, "set coo");
      getCOOAddrWar();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const WithdrawLink = async (Amount) => {
    props.setLoading(true);

    try {
      const result = await withdrawBalanceWarLink(
        Amount,
        walletProvider
      );
      // console.log(result, "Withdraw link");
      getLink();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  //End API Call

  const onArrowHandler = () => {
    props.handlerWar();
  };
  useEffect(() => {
    if (props.dropDown) {
      setDropDownClass("drop-down-open");
      setRotate("rotate");
    } else {
      setDropDownClass("drop-down-close");
      setRotate("");
    }
  }, [props.dropDown]);

  return (
    <div>
      {loading && <LoadingSpinner />}

      {/* Start Navigation Bar  */}
      <div className="a-main-container">
        <div className="a-first-section">
          <ArrowIcon rotate={rotate} onClick={onArrowHandler} />
          <div className="a-title">War</div>
        </div>
        <div className="a-second-section">
          <div className="text-holder">
            {props.warPause ? "Pause" : "Unpause"}
          </div>
          {!props.warPause ? (
            <BasicButton onClick={Pause}>Pause</BasicButton>
          ) : (
            <BasicButton onClick={Unpause}>unpause</BasicButton>
          )}
        </div>
      </div>
      {/* End Navigation Bar  */}

      <div className={dropDownClass}>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                WithdrawBalance(ev);
              }}
              textInfo="Withdraw money that has been deposited in the contract address related to the specific game function"
              formTitle="Withdraw balance"
              label="Amount"
            />
          </div>
          <div className="sub">
            <h5>Current balance in contract</h5>
            <p>
              Balance: <span>{balance}</span> Matic
            </p>
          </div>
        </div>

        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCFOAddr(ev);
              }}
              textInfo="Set CFO war address          "
              formTitle="Set CFO war address          "
              label="Address"
            />
          </div>
          <div className="sub">
            <h5>Current CFO war address</h5>
            <p>
              Address: <span>{cfo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCOOAddr(ev);
              }}
              textInfo="Set COO war address          "
              formTitle="Set COO war address          "
              label="Address"
            />
          </div>
          <div className="sub">
            <h5>Current COO war address</h5>
            <p>
              Address: <span>{coo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetBattleFee(ev);
              }}
              textInfo="Set a specific fee or value that needs to be paid by users to participate in one-on-one battles"
              formTitle="Set Battle fee"
              label="Input fee"
            />
          </div>
          <div className="sub">
            <h5>Current battle fee</h5>
            <p>
              Fee: <span>{battle}</span> Matic
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetWarFee(ev);
              }}
              textInfo="Set a specific fee or value that needs to be paid by users to participate in the war"
              formTitle="Set War fee"
              label="Input fee"
            />
          </div>
          <div className="sub">
            <h5>Current war fee</h5>
            <p>
              Fee: <span>{war}</span> Matic
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetWarBp(ev);
              }}
              textInfo="Set battle points that cards need to have before entering warzone"
              formTitle="Set War battle points"
              label="Input battle points"
            />
          </div>
          <div className="sub">
            <h5>New battle points for war</h5>
            <p>
              BP: <span>{point}</span>
            </p>
          </div>
        </div>

        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetTPercentage(ev);
              }}
              textInfo="Insert a value that will be deposited in the smart contract every time a paid function is run"
              formTitle="Set Tesseract Percentage "
              label="New percentage"
            />
          </div>
          <div className="sub">
            <h5>Current Tesseract Percentage input fee</h5>
            <p>
              Percentage <span>{tpercentage}</span> %
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                WithdrawLink(ev);
              }}
              textInfo="Withdraw link that has been deposited in the contract address related to the specific game function"
              formTitle="Withdraw link balance"
              label="Amount"
            />
          </div>
          <div className="sub">
            <h5>Current link balance in contract</h5>
            <p>
              Balance: <span>{link}</span> link
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default War;
