import React from 'react';
function PlayBanner () {
	return <div>
<section className="play_section">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="play_text">
                     <h1>HOW TO PLAY</h1>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>
}
export default PlayBanner;