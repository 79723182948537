import React from "react";
import useAuctionHousePromo from "../../../../Hooks/useAuctionHousePromo";
import PromoDataTable from "../PromoData/PromoDataTable";
import "./PromoAuction.css";
import PromoAuctionList from "./PromoAuctionList";
const PromoAuction = () => {
  const promoList = useAuctionHousePromo();
  return (
    <div>
      <PromoAuctionList></PromoAuctionList>
      <PromoDataTable promoList={promoList}></PromoDataTable>
      
    </div>
  );
};

export default PromoAuction;
