import React from "react";
import { useEffect } from "react";
// import BreedCard from "../../../../../../../Shared/BreedCard/BreedCard";
import DragLeft from "../../../../../../../Shared/DnD/DragLeft";
import DragRight from "../../../../../../../Shared/DnD/DragRight";
import WarCard from "../../../../../../../Shared/WarCard/WarCard";
import WarEmptyCard from "../../../../../../../Shared/WarCard/WarEmptyCard";
import blacknet from "../../Images/blacknet.png";
function WARHumanity({ state,BattleId, availableSlot, setAvailableSlot }) {
  const Slots = () => {
    let slots = {};
    for (let index = 0; index < state.length; index++) {
      const element = index + 1;
      slots = { ...slots, [element]: true };
    }
    return slots;
  };

  useEffect(() => {
    if (state?.length > 0) {
      const result = Slots();
      setAvailableSlot({ ...availableSlot, ...result });
    }
  }, [state]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {state &&
            state?.map((TokenId, i) => (
              <WarCard TokenId={TokenId} key={TokenId} BattleId={BattleId}></WarCard>
            ))}

          {state
            ? [...Array(11 - state?.length)]?.map((e, i) => (
                <WarEmptyCard
                  i={i + state?.length}
                  faction={"Humanity"}
                  key={i + 10}
                ></WarEmptyCard>
              ))
            : [...Array(11)]?.map((e, i) => (
                <WarEmptyCard
                  i={i}
                  faction={"Humanity"}
                  key={i + 100}
                ></WarEmptyCard>
              ))}
        </div>
      </div>
    </div>
  );
}
export default WARHumanity;
