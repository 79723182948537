import React, { useContext, useEffect, useState } from "react";
import * as AWS from "aws-sdk";
import awsmobile from "../../../aws-exports";
import useGameCentreContract from "../../Hooks/GameCentreHook";
import useCardOwnerShipContract from "../../Hooks/CardOwnershipHook";
import {
  GetCardFaction,
  GetCardInfo,
  GetCardType,
  GetOwnerOf,
} from "../../UserPage/BlockChainFunctions";
import { addressContext } from "../../../App";
// async function getGeneralDetails(
//   gameCentreContract,
//   cardOwnerShipContract,
//   TokenId
// ) {
//   // console.log(TokenId, "hook");
//   try {
//     const Faction = await gameCentreContract.GetCardFraction(TokenId);
//     const Value = await gameCentreContract.GetCardInfo(TokenId);
//     const Type = await gameCentreContract.GetCardType(TokenId);
//     const Gender = await gameCentreContract.GetGender(TokenId);
//     const Owner = await cardOwnerShipContract.OwnerOf(TokenId);
//     const Result = {
//       faction: Faction,
//       rank: Value.Rank,
//       type: Type,
//       name: Value.Name,
//       gender: Gender,
//       owner: Owner,
//     };

//     return Result;
//   } catch (err) {
//     console.log(err, "usecard");
//   }
// }

const useCard = (TokenId) => {
  // console.log(TokenId);
  const [CardName, setCardName] = useState("");
  const [CardFaction, setCardFaction] = useState("");
  const [CardType, setCardType] = useState("");
  const [CardRank, setCardRank] = useState("");
  const [CardGender, setCardGender] = useState("");
  const [CardOwner, setCardOwner] = useState("");
  const [CardOwnerUsername, setCardOwnerUsername] = useState("");
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [cardOwnerShipContract, setCardOwnerShipContract] =
    useCardOwnerShipContract();

  // console.log(CardOwner, "cardcontract");

  // useEffect(() => {
  //   async function CardDetails() {
  //     const GeneralCardDetails = await getGeneralDetails(
  //       gameCentreContract,
  //       cardOwnerShipContract,
  //       TokenId
  //     );

  //     setCardName(GeneralCardDetails.name);
  //     setCardFaction(GeneralCardDetails.faction);
  //     setCardType(GeneralCardDetails.type);
  //     setCardRank(GeneralCardDetails.rank);
  //     setCardGender(GeneralCardDetails.gender);
  //     setCardOwner(GeneralCardDetails.owner);
  //   }
  //   CardDetails();
  // }, [TokenId, gameCentreContract, cardOwnerShipContract]);

  useEffect(() => {}, []);

  if (TokenId && gameCentreContract && cardOwnerShipContract) {
    const Info = async () => {
      const faction = await GetCardFaction(TokenId, gameCentreContract);
      const info = await GetCardInfo(TokenId, gameCentreContract);
      const type = await GetCardType(TokenId, gameCentreContract);
      const owner = await GetOwnerOf(TokenId, cardOwnerShipContract);
      setCardFaction(faction);
      setCardName(info?.Name);
      setCardRank(info?.Rank);
      setCardGender(info?.Gender);
      setCardType(type);
      setCardOwner(owner);
    };
    Info();
  }

  const GetCustomerInfo = async (owner) => {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    const param = {
      TableName: process.env.REACT_APP_CUSTOMERINFO_TABLE_NAME,
      Key: {
        CustomerID: owner,
        //customer id will replace (owner props)
      },
      ProjectionExpression: "Username",
    };
    const response = await documentClient.get(param).promise();
    return response;
  };

  useEffect(() => {
    if (CardOwner) {
      if (CardOwner !== "0x0000000000000000000000000000000000000000") {
        const Username = async () => {
          const customerInfo = await GetCustomerInfo(CardOwner);
          setCardOwnerUsername(customerInfo?.Item?.Username);
        };
        Username();
      } else {
        setCardOwnerUsername("Deceased");
      }
    }
  }, [CardOwner]);

  return [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardOwner,
    CardOwnerUsername,
  ];
};

export default useCard;
