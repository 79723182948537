import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { statsContext } from "../../App";
import useGameCentreContract from "../Hooks/GameCentreHook";
import { GetStats } from "../UserPage/BlockChainFunctions";
// async function getCardStats(gameCentreContract, TokenId) {
//   // console.log(TokenId, "hook");
//   try {
//     const Stat = await gameCentreContract.GetStats(TokenId);
//     return Stat;
//   } catch (err) {
//     console.log(err, "usecard");
//   }
// }

const useStats = (TokenId) => {
  const [IQ, setIQ] = useState("");
  const [EQ, setEQ] = useState("");
  const [Wisdom, setWisdom] = useState("");
  const [Melee, setMelee] = useState("");
  const [Potential, setPotential] = useState("");
  const [Weapon, setWeapon] = useState("");
  const [Leadership, setLeadership] = useState("");
  const [Defense, setDefense] = useState("");
  const [NaturalAdpt, setNaturalAdpt] = useState("");
  const [
    reloadStats,
    setReloadStats,
    reloadGeneralDetails,
    setReloadGeneralDetails,
  ] = useContext(statsContext);
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const stats = async () => {
    const Stats = await GetStats(TokenId, gameCentreContract);
    // console.log(Stats, "check stats");
    setIQ(Stats.IQ.toString());
    setEQ(Stats.EQ.toString());
    setWisdom(Stats.Wisdom.toString());
    setWeapon(Stats.WeaponSkill.toString());
    setNaturalAdpt(Stats.NaturalAdpt.toString());
    setDefense(Stats.DeffSkill.toString());
    setLeadership(Stats.LeadershipSkill.toString());
    setMelee(Stats.MeleeAttck.toString());
    setPotential(Stats.FuturePotential.toString());
  };
  useEffect(() => {
    if (TokenId && gameCentreContract) {
      stats();
    }
  }, [gameCentreContract, TokenId]);

  useEffect(() => {
    if (reloadStats === true) {
      setInterval(() => {
        // console.log("hello");
        stats();
      }, 2000);
    }
  },[reloadStats]);
  return [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ];
};

export default useStats;
