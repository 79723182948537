import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  GetSaleRecordSizeFM,
  unPauseFixedMarketContract,
} from "../../../../UserPage/BlockChainFunctions";
import useFixedMarketContract from "../../../../Hooks/FixedMarketHook";
import DataTableFixed from "../DataTable/DataTableFixed";
import FixedList from "./FixedList";

const FixedMP = () => {
  const [saleRecord, setSaleRecord] = useState();
  const [fixedMarketContract, setFixedMarketContract] =
    useFixedMarketContract();
  const GetRecordSize = async () => {
    const Id = [];
    const result = await GetSaleRecordSizeFM(fixedMarketContract);
    for (let i = 0; i < parseInt(result.toString()); i++) {
      const element = i;
      Id.push(element);
    }

    return Id;
  };
  useEffect(() => {
    if (fixedMarketContract) {
      const func = async () => {
        const result = await GetRecordSize();
        setSaleRecord(result.reverse());
      };
      func();
    }
  }, [fixedMarketContract]);
  return (
    <div>
      <FixedList></FixedList>
      <DataTableFixed saleRecord={saleRecord}></DataTableFixed>
    </div>
  );
};

export default FixedMP;
