import logo from "./logo.svg";
import React, { Component, createContext, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, Link, useLocation } from "react-router-dom";

import About from "./component/Pages/About/About";
import Contact from "./component/Pages/Contact/Contact";
import Header from "./component/Header/Header";
import Home from "./component/Home/Home";
import SignIn from "./component/UserPage/SignIn";
import Signup from "./component/UserPage/Signup";
import ConnectWallet from "./component/UserPage/ConnectWallet";
import Story from "./component/Pages/Story/Story";
import AllChapters from "./component/Pages/Chapters/AllChapters";
import PlotSummary from "./component/Pages/PlotSummary/PlotSummary";
import Genesis from "./component/Pages/Genesis/Genesis";
import Alice from "./component/Pages/Alice/Alice";
import Blacknet from "./component/Pages/Blacknet/Blacknet";
import Humanity from "./component/Pages/Humanity/Humanity";
import Metaverse from "./component/Pages/Metaverse/Metaverse";
import Guide from "./component/Pages/Guide/Guide";
import Cardanatomy from "./component/Pages/Guide/Cardanatomy";
import Costfees from "./component/Pages/Guide/Costfees";
import Faq from "./component/Pages/Guide/Faq";
import Gamefeatures from "./component/Pages/Guide/Gamefeatures";
import Gettingstarted from "./component/Pages/Guide/Gettingstarted";
import Marketplace from "./component/Pages/Guide/Marketplace";
import Weaponry from "./component/Pages/Guide/Weaponry";
import More from "./component/Pages/More/More";
import Leaderboard from "./component/Pages/More/Leaderboard";
import Ranks from "./component/Pages/More/Ranks";
import Weapons from "./component/Pages/More/Weapons";
import Multiplier from "./component/Pages/More/Multiplier";
import Whitepaper from "./component/Pages/More/Whitepaper";
import Meetteam from "./component/Pages/More/Meetteam";
import Buynft from "./component/Pages/More/Buynft";
import TesseractLtd from "./component/Pages/TesseractLtd/TesseractLtd";
import Visit from "./component/Pages/Visit/Visit";
import Profile from "./component/Profile/Profile";
import IndividualCard from "./component/Profile/Pages/IndividualCard/IndividualCard";
import ProfileSetting from "./component/Profile/Sections/ProfileSetting/ProfileSetting";
import BattleList from "./component/Profile/Pages/BattleList/BattleList";
import RestingCard from "./component/Profile/Pages/RestingCard/RestingCard";
import Warzone from "./component/Profile/Pages/Warzone/Warzone";
import WarBattlefield from "./component/Profile/Pages/Warzone/Sections/WarBattlefield/BHWarBattlefield";
import WarConfirmation from "./component/Profile/Pages/Warzone/Sections/WarConfirmation/WarConfirmation";
import ConfirmationMessage from "./component/Profile/Pages/Warzone/Sections/ConfirmationMessage/ConfirmationMessage";
import Breeding from "./component/Profile/Pages/Breeding/Breeding";
import BreedSelf from "./component/Profile/Pages/Breeding/BreedSelf/BreedSelf";
import BreedConfirmation from "./component/Profile/Pages/Breeding/BreedConfirmation/BreedConfirmation";
import BreedOthers from "./component/Profile/Pages/Breeding/BreedOthers/BreedOthers";
import OthersConfirmation from "./component/Profile/Pages/Breeding/OthersConfirmation/OthersConfirmation";
import ConfirmationMsg from "./component/Profile/Pages/Breeding/ConfirmationMsg/ConfirmationMsg";
import Promote from "./component/Profile/Pages/Promote/Promote";
import Confirm from "./component/Profile/Pages/Promote/Confirm/PromoteConfirm";
import Congratulations from "./component/Profile/Pages/Promote/Congratulations/Congratulations";
import Mutate from "./component/Profile/Pages/Mutate/Mutate";
import MutateConfirm from "./component/Profile/Pages/Mutate/MutateConfirm/MutateConfirm";
import MutateCongratulations from "./component/Profile/Pages/Mutate/MutateCongratulations/MutateCongratulations";
import Rename from "./component/Profile/Pages/Rename/Rename";
import RenameConfirm from "./component/Profile/Pages/Rename/RenameConfirm/RenameConfirm";
import RenameCongratulations from "./component/Profile/Pages/Rename/RenameCongratulations/RenameCongratulations";
import PurchaseCards from "./component/PurchaseCards/PurchaseCards";
import MarketPlace from "./component/PurchaseCards/MarketPlace";
import PurchaseIndividual from "./component/PurchaseCards/PurchaseIndividual";
import PurchaseConfirmation from "./component/PurchaseCards/PurchaseConfirmation";
import AuctionHouse from "./component/PurchaseCards/AuctionHouse";
import AuctionIndividual from "./component/PurchaseCards/AuctionIndividual";
import AuctionConfirmation from "./component/PurchaseCards/AuctionConfirmation";
import SellCards from "./component/SellCards/SellCards";
import SellCardDetails from "./component/SellCards/SellCardDetails";
import SellConfirmation from "./component/SellCards/SellConfirmation";
import SellCongratulations from "./component/SellCards/SellCongratulations";
import SellOut from "./component/SellCards/SellOut";
import SellOutConfirmation from "./component/SellCards/SellOutConfirmation";
import SellOutCongratulations from "./component/SellCards/SellOutCongratulations";
import Footer from "./component/Footer/Footer";
import TransferCards from "./component/Profile/Pages/TransferCards/TransferCards";
import SalesRecord from "./component/Pages/SalesRecord/SalesRecord";
import FixedMP from "./component/Pages/SalesRecord/Sections/FixedMP/FixedMP";
import Auction from "./component/Pages/SalesRecord/Sections/Auction/Auction";
import PromoCode from "./component/Pages/PromoCode/PromoCode";
import PromoAuction from "./component/Pages/PromoCode/Sections/PromoAuction/PromoAuction";
import PromoFixedMP from "./component/Pages/PromoCode/Sections/PromoFixedMP/PromoFixedMP";
// import PopUp from "./component/Pages/PopUp/PopUp";
import AdminPage from "./component/Admin/AdminPage";
import WalletContainer from "./component/UserPage/WalletContainer";
import DisconnectWallet from "./component/UserPage/DisconnectWallet";
import BreedingList from "./component/Profile/Pages/BreedingList/BreedingList";
import * as AWS from "aws-sdk";
import awsmobile from "../src/aws-exports";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { RequireAuth } from "./component/UserPage/RequireAuth";
import CardCollection from "./component/Pages/Cardcollection/CardCollection";
import AliceCard from "./component/Pages/Cardcollection/AliceCard";
import BlacknetCard from "./component/Pages/Cardcollection/BlacknetCard";
import GenesisCard from "./component/Pages/Cardcollection/GenesisCard";
import HumanityCard from "./component/Pages/Cardcollection/HumanityCard";

import Web3Modal from "web3modal";
import { providers } from "ethers";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import AllCard from "./component/Pages/Cardcollection/AllCard";
import MarketCardCollection from "./component/PurchaseCards/MarketPlaceCollection/MarketCardCollection";
import MarketAllCard from "./component/PurchaseCards/MarketPlaceCollection/MarketAllCard";
import MarketAliceCard from "./component/PurchaseCards/MarketPlaceCollection/MarketAliceCard";
import MarketBlacknetCard from "./component/PurchaseCards/MarketPlaceCollection/MarketBlacknetCard";
import MarketHumanityCard from "./component/PurchaseCards/MarketPlaceCollection/MarketHumanityCard";
import MarketGenesisCard from "./component/PurchaseCards/MarketPlaceCollection/MarketGenesisCard";
import AuctionCardCollection from "./component/PurchaseCards/AuctionCollection/AuctionCardCollection";
import AuctionAllCard from "./component/PurchaseCards/AuctionCollection/AuctionAllCard";
import AuctionAliceCard from "./component/PurchaseCards/AuctionCollection/AuctionAliceCard";
import AuctionBlacknetCard from "./component/PurchaseCards/AuctionCollection/AuctionBlacknetCard";
import AuctionHumanityCard from "./component/PurchaseCards/AuctionCollection/AuctionHumanityCard";
import AuctionGenesisCard from "./component/PurchaseCards/AuctionCollection/AuctionGenesisCard";
import SellAllCard from "./component/SellCards/SellCollection/SellAllCard";
import SellAliceCard from "./component/SellCards/SellCollection/SellAliceCard";
import SellBlacknetCard from "./component/SellCards/SellCollection/SellBlacknetCard";
import SellHumanityCard from "./component/SellCards/SellCollection/SellHumanityCard";
import BreedSelfAllCard from "./component/Profile/Pages/Breeding/BreedSelfCollection/BreedSelfAllCard";
import BreedSelfAliceCard from "./component/Profile/Pages/Breeding/BreedSelfCollection/BreedSelfAliceCard";
import BreedSelfBlacknetCard from "./component/Profile/Pages/Breeding/BreedSelfCollection/BreedSelfBlacknetCard";
import BreedSelfHumanityCard from "./component/Profile/Pages/Breeding/BreedSelfCollection/BreedSelfHumanityCard";
import BreedingListAllCard from "./component/Profile/Pages/BreedingList/BreedingListCollection/BreedingListAllCard";
import BreedingListAliceCard from "./component/Profile/Pages/BreedingList/BreedingListCollection/BreedingListAliceCard";
import BreedingListBlacknetCard from "./component/Profile/Pages/BreedingList/BreedingListCollection/BreedingListBlacknetCard";
import BreedingListHumanityCard from "./component/Profile/Pages/BreedingList/BreedingListCollection/BreedingListHumanityCard";
import MutateAllCard from "./component/Profile/Pages/Mutate/MutateCollection/MutateAllCard";
import MutateAliceCard from "./component/Profile/Pages/Mutate/MutateCollection/MutateAliceCard";
import MutateBlacknetCard from "./component/Profile/Pages/Mutate/MutateCollection/MutateBlacknetCard";
import MutateHumanityCard from "./component/Profile/Pages/Mutate/MutateCollection/MutateHumanityCard";
import PromoteAllCard from "./component/Profile/Pages/Promote/PromoteCollection/PromoteAllCard";
import PromoteAliceCard from "./component/Profile/Pages/Promote/PromoteCollection/PromoteAliceCard";
import PromoteBlacknetCard from "./component/Profile/Pages/Promote/PromoteCollection/PromoteBlacknetCard";
import PromoteHumanityCard from "./component/Profile/Pages/Promote/PromoteCollection/PromoteHumanityCard";
import RenameAllCard from "./component/Profile/Pages/Rename/RenameCollection/RenameAllCard";
import RenameAliceCard from "./component/Profile/Pages/Rename/RenameCollection/RenameAliceCard";
import RenameBlacknetCard from "./component/Profile/Pages/Rename/RenameCollection/RenameBlacknetCard";
import RenameHumanityCard from "./component/Profile/Pages/Rename/RenameCollection/RenameHumanityCard";
import PromoteConfirm from "./component/Profile/Pages/Promote/Confirm/PromoteConfirm";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TransferMultiple from "./component/Profile/Pages/TransferCards/TransferMultiple";
import TransferSingle from "./component/Profile/Pages/TransferCards/TransferSingle";
import TransferIndivisual from "./component/Profile/Pages/TransferCards/TransferIndivisual";
import SellGenesisCard from "./component/SellCards/SellCollection/SellGenesisCard";
import SellCardsStart from "./component/SellCards/SellCardsStart";
import AuctionSellAllCard from "./component/SellCards/SellCollection -auction/AuctionSellAllCard";
import AuctionSellAliceCard from "./component/SellCards/SellCollection -auction/AuctionSellAliceCard";
import AuctionSellBlacknetCard from "./component/SellCards/SellCollection -auction/AuctionSellBlacknetCard";
import AuctionSellHumanityCard from "./component/SellCards/SellCollection -auction/AuctionSellHumanityCard";
import AuctionSellGenesisCard from "./component/SellCards/SellCollection -auction/AuctionSellGenesisCard";
import BreedOthersBlacknetCard from "./component/Profile/Pages/Breeding/BreedOthersCollection/BreedOthersBlacknetCard";
import BreedOthersAliceCard from "./component/Profile/Pages/Breeding/BreedOthersCollection/BreedOthersAliceCard";
import BreedOthersHumanityCard from "./component/Profile/Pages/Breeding/BreedOthersCollection/BreedOthersHumanityCard";
import BHWarBattlefield from "./component/Profile/Pages/Warzone/Sections/WarBattlefield/BHWarBattlefield";
import AHWarBattlefield from "./component/Profile/Pages/Warzone/Sections/WarBattlefield/AHWarBattlefield";
import BAWarBattlefield from "./component/Profile/Pages/Warzone/Sections/WarBattlefield/BAWarBattlefield";
import SellCardCollection from "./component/SellCards/SellCollection/SellCardCollection";
import SellAuctionCollection from "./component/SellCards/SellCollection -auction/SellAuctionCollection";
import ProfileAllCard from "./component/Profile/Sections/ProfileCollection/ProfileAllCard";
import ProfileAliceCard from "./component/Profile/Sections/ProfileCollection/ProfileAliceCard";
import ProfileBlacknetCard from "./component/Profile/Sections/ProfileCollection/ProfileBlacknetCard";
import ProfileGenesisCard from "./component/Profile/Sections/ProfileCollection/ProfileGenesisCard";
import ProfileHumanityCard from "./component/Profile/Sections/ProfileCollection/ProfileHumanityCard";
import useCustomerInfo from "./component/Hooks/useCustomerInfo";
import PopUp from "./component/Shared/PopUp";
import TransferAllCard from "./component/Profile/Pages/TransferCards/TransferCollection/TransferAllCard";
import TransferBlacknetCard from "./component/Profile/Pages/TransferCards/TransferCollection/TransferBlacknetCard";
import TransferHumanityCard from "./component/Profile/Pages/TransferCards/TransferCollection/TransferHumanityCard";
import TransferGenesisCard from "./component/Profile/Pages/TransferCards/TransferCollection/TransferGenesisCard";
import TransferAliceCard from "./component/Profile/Pages/TransferCards/TransferCollection/TransferAliceCard";
import Play from "./component/Profile/Pages/Play/Play";
import ForgotPassword from "./component/UserPage/ForgotPassword";
import WarHistory from "./component/Profile/Pages/Warzone/Sections/WarHistory/WarHistory";
import Roadmap from "./component/Pages/More/Roadmap";
import TermsOfUse from "./component/Pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./component/Pages/PrivacyPolicy/PrivacyPolicy";
import { RequireAuthPro } from "./component/UserPage/RequireAuthPro";
import Disclaimer from "./component/Pages/Disclainer/Disclaimer";
import NftGive from "./component/Pages/More/NftGive";
export const authContext = createContext();
export const providerContext = createContext();
export const slotContext = createContext();
export const warCardContext = createContext();
export const addressContext = createContext();
export const profilePicContext = createContext();
export const statsContext = createContext();
export const emptySlotContext = createContext();
export const navigationContext = createContext();
export let connectWallet;
export let disconnectWallet;

//export let WarContract;
// >>New - Configuring Auth Module
Amplify.configure(awsconfig);

const App = () => {
  const [warSlot, setWarSlot] = useState({});
  const [warCard, setWarCard] = useState([]);
  const leftWarSlots = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
  };
  const rightWarSlots = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
  };
  const [leftWarSlot, setLeftWarSlot] = useState(leftWarSlots);
  const [rightWarSlot, setRightWarSlot] = useState(rightWarSlots);
  const [navigationCntxt, setNavigationCntxt] = useState(false);
  const [signInUser, setSignInUser] = useState(null);
  const [walletProvider, setWalletProvider] = useState();
  const [currentAddress, setCurrentAddress] = useState("");
  const [forceSignOut, setForceSignOut] = useState(true);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [web3Modal, setWeb3Modal] = useState(null);
  const location = useLocation();
  const customerInfo = useCustomerInfo(currentAddress);
  const [reloadStats, setReloadStats] = useState(false);
  const [reloadGeneralDetails, setReloadGeneralDetails] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location.pathname]);

  useEffect(() => {
    if (reloadStats === true || reloadGeneralDetails === true) {
      setReloadStats(false);
      setReloadGeneralDetails(false);
    }
  }, [location, reloadStats, reloadGeneralDetails]);

  useEffect(() => {
    setNavigationCntxt(false);
  }, [location.pathname]);
  useEffect(() => {
    const providerOptions = {
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: "Extremis-Alpha-Version",
          infuraId: "9c9845ca650f454db4bc505606e2704f",
          chainId: 80001,
          darkMode: true,
        },
      },
    };

    const newWeb3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
    });

    setWeb3Modal(newWeb3Modal);
  }, []);

  useEffect(() => {
    const web3 = async () => {
      if (web3Modal && web3Modal.cachedProvider) {
        const provider = await connectWallet();
        setWalletProvider(provider);
      }
    };
    web3();
  }, [web3Modal]);

  disconnectWallet = async () => {
    setWalletProvider(null);
    setCurrentAddress(null);
    return web3Modal.clearCachedProvider();
  };

  connectWallet = async () => {
    if (typeof web3 !== "undefined") {
      const provider = await web3Modal.connect();
      const ethersProvider = new providers.Web3Provider(provider);
      setWalletProvider(ethersProvider);
      return ethersProvider;
    } else {
      setPopUp(true);
      setPopUpSubject(
        "Web3 is not found. Install metamask or coinbase wallet on your browser"
      );
      // return false;
    }
  };

  // useEffect(() => {
  //   if (currentAddress) {
  //     const ForceSignOut = async () => {
  //       // console.log(customerInfo, currentAddress, "settimeout");
  //       if (customerInfo) {
  //         if (currentAddress !== customerInfo.Item?.CustomerID) {
  //           if (forceSignOut === true) {
  //             try {
  //               AWS.config.update({
  //                 credentials: "",
  //               });
  //               Auth.signOut({ global: true });
  //               // console.log("Error: Connect to your registered wallet");
  //               setSignInUser(null);
  //             } catch (error) {
  //               console.log("error signing out:" + error);
  //             }
  //           }
  //         }
  //       }
  //     };
  //     ForceSignOut();
  //   } else {
  //     console.log("no wallet");
  //   }
  // }, [location.pathname, customerInfo, currentAddress]);

  useEffect(() => {
    const Addr = async () => {
      const userAddress = await walletProvider.getSigner().getAddress();
      setCurrentAddress(userAddress);
    };
    Addr();
  }, [walletProvider]);

  useEffect(() => {
    const SessionCheck = async () => {
      const session = await Auth.currentSession();

      let getUser = async () => {
        const authTime = session.accessToken.payload.auth_time;
        const currentTime = Date.now() / 1000;
        if (currentTime - authTime < 14400) {
          try {
            let user = await Auth.currentAuthenticatedUser();
            const AuthUserCreds = await Auth.currentCredentials();

            AWS.config.update({
              credentials: AuthUserCreds,
              region: awsmobile.aws_cognito_region,
            });

            const auth = { user, session, isAuthenticated: true };
            const result = JSON.stringify(auth);
            setSignInUser(result);
          } catch (error) {
            console.log(error);
          }
        } else {
          // console.log("auth checking 1hour");
          try {
            AWS.config.update({
              credentials: "",
            });
            await Auth.signOut({ global: true });
            setSignInUser(null);
            window.location.reload();
          } catch (error) {
            console.log("error signing out:" + error);
          }
        }
      };
      getUser();
    };
    SessionCheck();
    setInterval(() => {
      SessionCheck();
    }, 10000);
  }, []);
  useEffect(() => {
    setWarSlot(null);
    setWarCard([]);
  }, [location.pathname]);

  useEffect(() => {
    if (
      Object.values(leftWarSlot)[0] === true ||
      Object.values(rightWarSlot)[0] === true
    ) {
      setLeftWarSlot(leftWarSlots);
      setRightWarSlot(rightWarSlots);
    }
  }, [location.pathname]);
  return (
    <profilePicContext.Provider value={[imageSrc, setImageSrc]}>
      <statsContext.Provider
        value={[
          reloadStats,
          setReloadStats,
          reloadGeneralDetails,
          setReloadGeneralDetails,
        ]}
      >
        <navigationContext.Provider
          value={[navigationCntxt, setNavigationCntxt]}
        >
          <emptySlotContext.Provider
            value={[leftWarSlot, setLeftWarSlot, rightWarSlot, setRightWarSlot]}
          >
            <DndProvider backend={HTML5Backend}>
              <authContext.Provider value={[signInUser, setSignInUser]}>
                <providerContext.Provider
                  value={[walletProvider, setWalletProvider]}
                >
                  <slotContext.Provider value={[warSlot, setWarSlot]}>
                    <warCardContext.Provider value={[warCard, setWarCard]}>
                      <addressContext.Provider
                        value={[currentAddress, setCurrentAddress]}
                      >
                        {/* <forceSignOutContext.Provider
                      value={[forceSignOut, setForceSignOut]}
                    > */}
                        <div className="App">
                          <Header />
                          <Routes>
                            <Route path="/" element={<Home />}></Route>
                            <Route path="/story" element={<Story />}></Route>
                            <Route
                              path="/the-chronicles"
                              element={<AllChapters />}
                            ></Route>
                            <Route
                              path="/the-origin"
                              element={<PlotSummary />}
                            ></Route>
                            <Route
                              path="/genesis"
                              element={<Genesis />}
                            ></Route>
                            <Route path="/alice" element={<Alice />}></Route>
                            <Route
                              path="/blacknet"
                              element={<Blacknet />}
                            ></Route>
                            <Route
                              path="/humanity"
                              element={<Humanity />}
                            ></Route>
                            <Route
                              path="/sales-record"
                              element={
                                <RequireAuth>
                                  <SalesRecord></SalesRecord>
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={
                                  <RequireAuth>
                                    <FixedMP></FixedMP>
                                  </RequireAuth>
                                }
                              ></Route>
                              <Route
                                path="/sales-record/auction"
                                element={
                                  <RequireAuth>
                                    <Auction></Auction>
                                  </RequireAuth>
                                }
                              ></Route>
                            </Route>
                            <Route
                              path="/metaverse"
                              element={<Metaverse />}
                            ></Route>
                            <Route path="/guide" element={<Guide />}></Route>
                            <Route
                              path="/card-anatomy"
                              element={<Cardanatomy />}
                            ></Route>
                            <Route
                              path="/cost-fees"
                              element={<Costfees />}
                            ></Route>
                            <Route path="/faq" element={<Faq />}></Route>
                            <Route
                              path="/game-features"
                              element={<Gamefeatures />}
                            ></Route>
                            <Route
                              path="/weaponry"
                              element={<Weaponry />}
                            ></Route>
                            <Route
                              path="/getting-started"
                              element={<Gettingstarted />}
                            ></Route>
                            <Route
                              path="/market-place"
                              element={<Marketplace />}
                            ></Route>
                            <Route
                              path="/transfer-cards"
                              element={
                                <RequireAuth>
                                  <TransferCards></TransferCards>
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/transfer-single-card"
                              element={<TransferSingle></TransferSingle>}
                            >
                              <Route
                                index
                                element={<TransferAllCard></TransferAllCard>}
                              ></Route>
                              <Route
                                path="/transfer-single-card/alice"
                                element={
                                  <TransferAliceCard></TransferAliceCard>
                                }
                              ></Route>
                              <Route
                                path="/transfer-single-card/blacknet"
                                element={
                                  <TransferBlacknetCard></TransferBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/transfer-single-card/humanity"
                                element={
                                  <TransferHumanityCard></TransferHumanityCard>
                                }
                              ></Route>
                              <Route
                                path="/transfer-single-card/genesis"
                                element={
                                  <TransferGenesisCard></TransferGenesisCard>
                                }
                              ></Route>
                            </Route>

                            <Route
                              path="/transfer-multiple-card"
                              element={<TransferMultiple></TransferMultiple>}
                            ></Route>
                            <Route
                              path="/transfer-confirm/:TokenId"
                              element={
                                <TransferIndivisual></TransferIndivisual>
                              }
                            ></Route>
                            <Route
                              path="/cardcollection"
                              element={
                                <RequireAuth>
                                  <CardCollection />
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={<AllCard></AllCard>}
                              ></Route>
                              <Route
                                path="/cardcollection/blacknet-card"
                                element={
                                  <RequireAuth>
                                    <BlacknetCard />
                                  </RequireAuth>
                                }
                              ></Route>
                              <Route
                                path="/cardcollection/alice-card"
                                element={
                                  <RequireAuth>
                                    <AliceCard />
                                  </RequireAuth>
                                }
                              ></Route>

                              <Route
                                path="/cardcollection/genesis-card"
                                element={
                                  <RequireAuth>
                                    <GenesisCard />
                                  </RequireAuth>
                                }
                              ></Route>
                              <Route
                                path="/cardcollection/humanity-card"
                                element={
                                  <RequireAuth>
                                    <HumanityCard />
                                  </RequireAuth>
                                }
                              ></Route>
                            </Route>

                            <Route path="/More" element={<More />}></Route>
                            <Route
                              path="/leaderboard"
                              element={
                                <RequireAuth>
                                  <Leaderboard />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/ranks"
                              element={
                                <RequireAuth>
                                  <Ranks />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/multiplier"
                              element={<Multiplier />}
                            ></Route>
                            <Route
                              path="/weapons"
                              element={
                                <RequireAuth>
                                  <Weapons />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/whitepaper"
                              element={<Whitepaper />}
                            ></Route>
                            <Route
                              path="/roadmap"
                              element={<Roadmap></Roadmap>}
                            ></Route>
                            <Route
                              path="/meet-team"
                              element={<Meetteam />}
                            ></Route>
                            <Route path="/buy-nft" element={<Buynft />}></Route>
                            <Route
                              path="/nft-give-away"
                              element={<NftGive></NftGive>}
                            ></Route>
                            <Route
                              path="/tesseract-ltd"
                              element={<TesseractLtd />}
                            ></Route>
                            <Route path="/about" element={<About />}></Route>
                            <Route path="/visit" element={<Visit />}></Route>
                            <Route
                              path="/contact-us"
                              element={<Contact />}
                            ></Route>
                            <Route path="/signin" element={<SignIn />}></Route>
                            <Route path="/signup" element={<Signup />}></Route>
                            <Route
                              path="/passwordRecovery"
                              element={<ForgotPassword></ForgotPassword>}
                            ></Route>

                            <Route
                              path="/profile"
                              element={
                                <RequireAuth>
                                  <Profile></Profile>
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={<ProfileAllCard></ProfileAllCard>}
                              ></Route>
                              <Route
                                path="/profile/alice"
                                element={<ProfileAliceCard></ProfileAliceCard>}
                              ></Route>
                              <Route
                                path="/profile/blacknet"
                                element={
                                  <ProfileBlacknetCard></ProfileBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/profile/genesis"
                                element={
                                  <ProfileGenesisCard></ProfileGenesisCard>
                                }
                              ></Route>
                              <Route
                                path="/profile/humanity"
                                element={
                                  <ProfileHumanityCard></ProfileHumanityCard>
                                }
                              ></Route>
                            </Route>

                            <Route
                              path="/sell"
                              element={<SellCardsStart></SellCardsStart>}
                            ></Route>

                            <Route
                              marketplace
                              path="/sell-fixed-market"
                              element={
                                <RequireAuth>
                                  {/* <Profile /> */}
                                  <SellCardCollection></SellCardCollection>
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={<SellAllCard></SellAllCard>}
                              >
                                {" "}
                              </Route>
                              <Route
                                path="/sell-fixed-market/alice"
                                element={<SellAliceCard></SellAliceCard>}
                              ></Route>
                              <Route
                                path="/sell-fixed-market/blacknet"
                                element={<SellBlacknetCard></SellBlacknetCard>}
                              ></Route>
                              <Route
                                path="/sell-fixed-market/humanity"
                                element={<SellHumanityCard></SellHumanityCard>}
                              >
                                {" "}
                              </Route>
                              <Route
                                path="/sell-fixed-market/genesis"
                                element={<SellGenesisCard></SellGenesisCard>}
                              ></Route>
                            </Route>
                            <Route
                              marketplace
                              path="/sell-auction"
                              element={
                                <RequireAuth>
                                  {/* <Profile /> */}
                                  <SellAuctionCollection></SellAuctionCollection>
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={
                                  <AuctionSellAllCard>
                                    auction
                                  </AuctionSellAllCard>
                                }
                              >
                                {" "}
                              </Route>
                              <Route
                                path="/sell-auction/alice"
                                element={
                                  <AuctionSellAliceCard>
                                    auction
                                  </AuctionSellAliceCard>
                                }
                              ></Route>
                              <Route
                                path="/sell-auction/blacknet"
                                element={
                                  <AuctionSellBlacknetCard>
                                    auction
                                  </AuctionSellBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/sell-auction/humanity"
                                element={
                                  <AuctionSellHumanityCard>
                                    auction
                                  </AuctionSellHumanityCard>
                                }
                              >
                                {" "}
                              </Route>
                              <Route
                                path="/sell-auction/genesis"
                                element={
                                  <AuctionSellGenesisCard>
                                    auction
                                  </AuctionSellGenesisCard>
                                }
                              ></Route>
                            </Route>
                            <Route
                              path="/individual-card/:TokenId"
                              element={
                                <RequireAuth>
                                  <IndividualCard />
                                </RequireAuth>
                              }
                            ></Route>

                            <Route
                              path="/profile-setting"
                              element={
                                <RequireAuth>
                                  <ProfileSetting />
                                </RequireAuth>
                              }
                            ></Route>

                            <Route
                              path="/battle-list"
                              element={
                                <RequireAuth>
                                  <BattleList />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/resting-card"
                              element={
                                <RequireAuth>
                                  <RestingCard />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/war-zone"
                              element={
                                <RequireAuth>
                                  <Warzone />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/war-history/:Id"
                              element={
                                <RequireAuth>
                                  <WarHistory></WarHistory>
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/BH-battle-field"
                              element={
                                <RequireAuth>
                                  <BHWarBattlefield />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/AH-battle-field"
                              element={
                                <RequireAuth>
                                  <AHWarBattlefield />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/BA-battle-field"
                              element={
                                <RequireAuth>
                                  <BAWarBattlefield />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/confirmation"
                              element={
                                <RequireAuth>
                                  <WarConfirmation />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/confirmation-message"
                              element={
                                <RequireAuth>
                                  <ConfirmationMessage />
                                </RequireAuth>
                              }
                            ></Route>

                            <Route path="/play" element={<Play></Play>}></Route>

                            <Route
                              path="/breeding"
                              element={
                                <RequireAuth>
                                  <Breeding />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/breeding-list"
                              element={
                                <RequireAuth>
                                  <BreedingList></BreedingList>
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={
                                  <BreedingListAllCard></BreedingListAllCard>
                                }
                              ></Route>
                              <Route
                                path="/breeding-list/alice"
                                element={
                                  <BreedingListAliceCard></BreedingListAliceCard>
                                }
                              ></Route>
                              <Route
                                path="/breeding-list/blacknet"
                                element={
                                  <BreedingListBlacknetCard></BreedingListBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/breeding-list/humanity"
                                element={
                                  <BreedingListHumanityCard></BreedingListHumanityCard>
                                }
                              ></Route>
                              {/* <Route path="/breeding-list/genesis" element={<BreedingListGenesisCard></BreedingListGenesisCard>}></Route> */}
                            </Route>
                            <Route
                              path="/breed-self"
                              element={
                                <RequireAuth>
                                  <BreedSelf />
                                </RequireAuth>
                              }
                            >
                              {/* <Route index element={<BreedSelfAllCard></BreedSelfAllCard>}></Route> */}
                              <Route
                                index
                                element={
                                  <BreedSelfBlacknetCard></BreedSelfBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/breed-self/alice"
                                element={
                                  <BreedSelfAliceCard>hello</BreedSelfAliceCard>
                                }
                              ></Route>
                              <Route
                                path="/breed-self/humanity"
                                element={
                                  <BreedSelfHumanityCard></BreedSelfHumanityCard>
                                }
                              ></Route>
                              {/* <Route path="/breed-self/genesis" element={<BreedSelfGenesisCard></BreedSelfGenesisCard>}></Route> */}
                            </Route>
                            <Route
                              path="/breed-confirmation"
                              element={
                                <RequireAuth>
                                  <BreedConfirmation />
                                </RequireAuth>
                              }
                            ></Route>

                            <Route
                              path="/breed-Others"
                              element={
                                <RequireAuth>
                                  <BreedOthers />
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={
                                  <BreedOthersBlacknetCard></BreedOthersBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/breed-Others/alice"
                                element={
                                  <BreedOthersAliceCard>
                                    hello
                                  </BreedOthersAliceCard>
                                }
                              ></Route>
                              <Route
                                path="/breed-Others/humanity"
                                element={
                                  <BreedOthersHumanityCard></BreedOthersHumanityCard>
                                }
                              ></Route>
                            </Route>
                            <Route
                              path="/others-confirmation"
                              element={
                                <RequireAuth>
                                  <OthersConfirmation />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              sell
                              path="/confirmation-msg"
                              element={
                                <RequireAuth>
                                  <ConfirmationMsg />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/promote"
                              element={
                                <RequireAuth>
                                  <Promote />
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={<PromoteAllCard></PromoteAllCard>}
                              ></Route>
                              <Route
                                path="/promote/alice"
                                element={<PromoteAliceCard></PromoteAliceCard>}
                              ></Route>
                              <Route
                                path="/promote/blacknet"
                                element={
                                  <PromoteBlacknetCard></PromoteBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/promote/humanity"
                                element={
                                  <PromoteHumanityCard></PromoteHumanityCard>
                                }
                              >
                                {" "}
                              </Route>
                            
                            </Route>
                            <Route
                              path="/promote-confirm/:TokenId"
                              element={
                                <RequireAuth>
                                  <PromoteConfirm></PromoteConfirm>
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/congratulations"
                              element={
                                <RequireAuth>
                                  <Congratulations />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/mutate"
                              element={
                                <RequireAuth>
                                  <Mutate />
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={<MutateAllCard></MutateAllCard>}
                              ></Route>
                              <Route
                                path="/mutate/alice"
                                element={<MutateAliceCard></MutateAliceCard>}
                              ></Route>
                              <Route
                                path="/mutate/blacknet"
                                element={
                                  <MutateBlacknetCard></MutateBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/mutate/humanity"
                                element={
                                  <MutateHumanityCard></MutateHumanityCard>
                                }
                              >
                                {" "}
                              </Route>
                             
                            </Route>
                            <Route
                              path="/mutate-confirm/:TokenId"
                              element={
                                <RequireAuth>
                                  <MutateConfirm />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/mutate-congratulations"
                              element={
                                <RequireAuth>
                                  <MutateCongratulations />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/rename"
                              element={
                                <RequireAuth>
                                  <Rename />
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={<RenameAllCard></RenameAllCard>}
                              ></Route>
                              <Route
                                path="/rename/alice"
                                element={<RenameAliceCard></RenameAliceCard>}
                              ></Route>
                              <Route
                                path="/rename/blacknet"
                                element={
                                  <RenameBlacknetCard></RenameBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/rename/humanity"
                                element={
                                  <RenameHumanityCard></RenameHumanityCard>
                                }
                              >
                                {" "}
                              </Route>
                             
                            </Route>
                            <Route
                              path="/rename-confirm/:TokenId"
                              element={
                                <RequireAuth>
                                  <RenameConfirm />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/rename-congratulations"
                              element={
                                <RequireAuth>
                                  <RenameCongratulations />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/connect-wallet"
                              element={<WalletContainer />}
                            >
                              <Route
                                index
                                element={<ConnectWallet></ConnectWallet>}
                              ></Route>
                              <Route
                                path="/connect-wallet/disconnect"
                                element={<DisconnectWallet></DisconnectWallet>}
                              ></Route>
                            </Route>
                            <Route
                              path="/purchase-cards"
                              element={
                                <RequireAuth>
                                  <PurchaseCards />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/marketplace"
                              element={
                                <RequireAuth>
                                  <MarketCardCollection />
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={<MarketAllCard></MarketAllCard>}
                              ></Route>
                              <Route
                                path="/marketplace/alice"
                                element={<MarketAliceCard></MarketAliceCard>}
                              ></Route>
                              <Route
                                path="/marketplace/blacknet"
                                element={
                                  <MarketBlacknetCard></MarketBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/marketplace/humanity"
                                element={
                                  <MarketHumanityCard></MarketHumanityCard>
                                }
                              ></Route>
                              <Route
                                path="/marketplace/genesis"
                                element={
                                  <MarketGenesisCard></MarketGenesisCard>
                                }
                              ></Route>
                            </Route>
                            <Route
                              path="/purchase-card/:TokenId"
                              element={
                                <RequireAuth>
                                  <PurchaseIndividual />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/purchase-confirmation/:TokenId"
                              element={
                                <RequireAuth>
                                  <PurchaseConfirmation />
                                </RequireAuth>
                              }
                            ></Route>
                            {/* <Route
              path="/purchase-congratulations"
              element={
                <RequireAuth>
                  <PurchaseCongratulations />
                </RequireAuth>
              }
            ></Route> */}
                            <Route
                              path="/auction-house"
                              element={
                                <RequireAuth>
                                  <AuctionCardCollection />
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={<AuctionAllCard></AuctionAllCard>}
                              ></Route>
                              <Route
                                path="/auction-house/alice"
                                element={<AuctionAliceCard></AuctionAliceCard>}
                              ></Route>
                              <Route
                                path="/auction-house/blacknet"
                                element={
                                  <AuctionBlacknetCard></AuctionBlacknetCard>
                                }
                              ></Route>
                              <Route
                                path="/auction-house/humanity"
                                element={
                                  <AuctionHumanityCard></AuctionHumanityCard>
                                }
                              ></Route>
                              <Route
                                path="/auction-house/genesis"
                                element={
                                  <AuctionGenesisCard></AuctionGenesisCard>
                                }
                              ></Route>
                            </Route>
                            <Route
                              path="/auction-individual/:TokenId"
                              element={
                                <RequireAuth>
                                  <AuctionIndividual />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/auction-confirmation/:TokenId"
                              element={
                                <RequireAuth>
                                  <AuctionConfirmation />
                                </RequireAuth>
                              }
                            ></Route>
                            {/* <Route
              path="/auction-congratulations"
              element={
                <RequireAuth>
                  <AuctionCongratulations />
                </RequireAuth>
              }
            ></Route> */}
                            <Route
                              path="/sell-cards"
                              element={
                                <RequireAuth>
                                  <SellCards />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/sell-card-details/:TokenId"
                              element={
                                <RequireAuth>
                                  <SellCardDetails />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/fixed-sell-confirmation/:TokenId"
                              element={
                                <RequireAuth>
                                  <SellConfirmation />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/sell-congratulations"
                              element={
                                <RequireAuth>
                                  <SellCongratulations />
                                </RequireAuth>
                              }
                            ></Route>

                            <Route
                              path="/sell-auction-confirmation/:TokenId"
                              element={
                                <RequireAuth>
                                  <SellOutConfirmation />
                                </RequireAuth>
                              }
                            ></Route>
                            <Route
                              path="/sell-auction-congratulations"
                              element={
                                <RequireAuth>
                                  <SellOutCongratulations />
                                </RequireAuth>
                              }
                            ></Route>

                            <Route
                              path="/promoCode"
                              element={
                                <RequireAuth>
                                  <PromoCode></PromoCode>
                                </RequireAuth>
                              }
                            >
                              <Route
                                index
                                element={
                                  <RequireAuth>
                                    <PromoFixedMP></PromoFixedMP>
                                  </RequireAuth>
                                }
                              ></Route>
                              <Route
                                path="/promoCode/auction"
                                element={
                                  <RequireAuth>
                                    <PromoAuction></PromoAuction>
                                  </RequireAuth>
                                }
                              ></Route>
                            </Route>
                            <Route
                              path="/pop-up"
                              element={<PopUp></PopUp>}
                            ></Route>
                            <Route
                              path="/AdminPageOfExtremisVerse"
                              element={
                                <RequireAuthPro>
                                  <AdminPage></AdminPage>
                                </RequireAuthPro>
                              }
                            ></Route>
                            <Route
                              path="/terms-of-use"
                              element={<TermsOfUse></TermsOfUse>}
                            ></Route>
                            <Route
                              path="/PrivacyPolicy"
                              element={<PrivacyPolicy></PrivacyPolicy>}
                            ></Route>
                            <Route
                              path="/disclaimer"
                              element={<Disclaimer></Disclaimer>}
                            ></Route>
                          </Routes>
                          {popup && (
                            <PopUp
                              popUpSubject={popUpSubject}
                              popUpText={popUpText}
                              setPopUp={setPopUp}
                            ></PopUp>
                          )}
                          <Footer />
                        </div>
                        {/* </forceSignOutContext.Provider> */}
                      </addressContext.Provider>
                    </warCardContext.Provider>
                  </slotContext.Provider>
                </providerContext.Provider>
              </authContext.Provider>
            </DndProvider>
          </emptySlotContext.Provider>
        </navigationContext.Provider>
      </statsContext.Provider>
    </profilePicContext.Provider>
  );
};

export default App;
// transfer-single-card
//signup

//war
//whitepa
//profile
///AdminPageOfExtremisVerse
//privacy
//metamask
//more
