import React from 'react';

function GuideText () {
	return <div>

<section className="guidetab_section">
         <div className="container">
            <div className="row">
               <div className="col-md-10 offset-md-1">
                  <div className="guide_tab">
<h4>OVERVIEW</h4>
     <p>Welcome to the official EXTREMIS gameplay guide, a one-in-all concise handbook to get you started in the world of EXTREMIS. Here you will find the fundamentals of gameplay explained in detail. Learn about the game functionalities, character features, and many more that will help you strategize like a pro and get the most out of the game. Many factors and functionalities within the game are interrelated. This is why it is essential to learn everything about them. The following guide will help you get familiar with the world of EXTREMIS and make an expert out of you!</p>
     </div>
     </div>
     </div>
     </div>
     </section>

    </div>
}
export default GuideText;