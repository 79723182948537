import React, { useState } from "react";
import { useForm } from "react-hook-form";

const BreedInput = ({ PutOnBreedList, setConfirmBreed }) => {
  const [confirmButton, setConfirmButton] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const confirmBreed = async (data) => {
    setConfirmButton(true);
    PutOnBreedList(data.price);
  };

  return (
    <div className="popup">
      <div className="confirm-container">
        <div className="popup-msg ">
          <h3>Breed Price</h3>
          <p>
            Please input the price the other user is going to pay to breed with
            your card
          </p>
          <form action="" onSubmit={handleSubmit(confirmBreed)}>
            <input
              type="text"
              placeholder=" Breed Price"
              {...register("price", {
                required: true,
              })}
            />
           <div className="button">
           <button
              type="submit"
              id="confirmation"
              className="form-control confirm-button rounded submit px-3"
            >
              {!confirmButton ? "CONFIRM" : "PLEASE WAIT..."}
            </button>
            <button
              onClick={() => {
                setConfirmBreed(false);
              }}
              className="form-control confirm-button rounded submit px-3"
            >
              CANCEL
            </button>
           </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BreedInput;
