import React from 'react';
function Genesisbanner () {
	return <div>
<div className="Page_genesis_section">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="page_title">
                     <h1>Genesis</h1>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
}
export default Genesisbanner;