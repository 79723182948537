import React from "react";
function AuctionConfirmationBanner() {
  return (
    <div>
      <div className="sell_section_MARKETPLACE">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <h1>FIXED MARKETPLACE</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AuctionConfirmationBanner;
