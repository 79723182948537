import React from 'react';
// import SellBanner from '../SellCards/Sections/SellBanner';
// import SellTab from '../SellCards/Sections/SellTab';
import '../SellCards/SellCards.css';

function SellCards () {
	return <div>
        {/* <SellBanner/> */}
		{/* <SellTab/> */}
	</div>
}
export default SellCards;