import React from 'react';
import { Link } from 'react-router-dom';
function PlayTaxt () {
	return <div>
<section className="about_section">
         <div className="container">
            <div className="row">
               
               <div className="col-md-10 offset-md-1">
                  <div className="play_text_box text-center">
                     <p>EXTREMIS is a P2E blockchain-based game where players can buy, sell and breed cards to fight battles and wars with others. Learn about all the game functionalities in detail through the step-by-step Gameplay guide.
                     </p>
                     <Link className="play_byn btn" to="/guide">GAMEPLAY GUIDE</Link>
                  </div>
               </div>
               
            </div>
         </div>
      </section>
    </div>
    }
    export default PlayTaxt;