import React from 'react';
import Allcardsbanner from '../SellCollection/Sections/Allcardsbanner';
import '../SellCollection -auction/CardCollection.css';
import { Outlet } from 'react-router-dom';
import SellProfileBanner from '../SellProfile/SellProfileBanner';
import SellProfile from '../SellProfile/SellProfile';

function SellAuctionCollection () {
	//(CardCollection)?Footer(false):Footer(true);
	return <div>
		{/* <Allcardsbanner/> */}
		<SellProfileBanner></SellProfileBanner>
		<SellProfile></SellProfile>
		<Outlet></Outlet>
        
	</div>
}
export default SellAuctionCollection;