import React from 'react';
import RenameConfirmBanner from '../RenameConfirm/Sections/RenameConfirmBanner';
import RenameConfirmText from '../RenameConfirm/Sections/RenameConfirmText';
import '../RenameConfirm/RenameConfirm.css';
function RenameConfirm(){
 return <div>
<RenameConfirmBanner/>
<RenameConfirmText/>


 </div>


}
export default RenameConfirm;