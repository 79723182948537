import React from "react";
// import rank from "../Images/rank.png";
import rank1 from "../../../../../../assets/rank/1-5-Private.png";
import rank2 from "../../../../../../assets/rank/6-10-2nd Lt.png";
import rank3 from "../../../../../../assets/rank/11-15-1st Lt.png";
import rank4 from "../../../../../../assets/rank/16-20-Captain.png";
import rank5 from "../../../../../../assets/rank/21-25-Major.png";
import rank6 from "../../../../../../assets/rank/26-30-L Col..png";
import rank7 from "../../../../../../assets/rank/31-35-Col..png";
import rank8 from "../../../../../../assets/rank/36-40-Br Gen.png";
import rank9 from "../../../../../../assets/rank/41-45-Maj Gen.png";
import rank10 from "../../../../../../assets/rank/46-50-Lt Gen.png";
import rank11 from "../../../../../../assets/rank/51-99-Gen.png";
import rank12 from "../../../../../../assets/rank/100-Legendary.png";
function RanksText() {
  return (
    <div>
      <section className="board_section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1">
              <div className="board_text_section">
                <h2 className="text-center">LIST OF RANKS</h2>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img Private">
                      <img src={rank1} />
                    </div>
                    <div className="user_name">
                      <h5>Private</h5>
                    </div>
                  </li>

                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 1-5.</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img Second-Lieutenant">
                      <img src={rank2} />
                    </div>
                    <div className="user_name">
                      <h5>2nd Lieutenant</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 6-10</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img First-Lieutenant">
                      <img src={rank3} />
                    </div>
                    <div className="user_name">
                      <h5>1st Lieutenant</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 11-15</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img Captain">
                      <img src={rank4} />
                    </div>
                    <div className="user_name">
                      <h5>Captain</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 16-20</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img Major">
                      <img src={rank5} />
                    </div>
                    <div className="user_name">
                      <h5>Major</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 21-25</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img Lieutenant-Colonel">
                      <img src={rank6} />
                    </div>
                    <div className="user_name">
                      <h5>Lieutenant Colonel</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># 26-30</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img Colonel">
                      <img src={rank7} />
                    </div>
                    <div className="user_name">
                      <h5>Colonel</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 31-35</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img Brigadier-General">
                      <img src={rank8} />
                    </div>
                    <div className="user_name">
                      <h5>Brigadier General</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 36-40</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img Major-General">
                      <img src={rank9} />
                    </div>
                    <div className="user_name">
                      <h5>Major General</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 41-45</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img Lieutenant-General">
                      <img src={rank10} />
                    </div>
                    <div className="user_name">
                      <h5>Lieutenant General</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 46-50</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img General">
                      <img src={rank11} />
                    </div>
                    <div className="user_name">
                      <h5>General</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 51-99</span>
                    </div>
                  </li>
                </ul>
                <ul>
                  <li className="rank text-start">
                    <div className="rank_img Legendary">
                      <img src={rank12} />
                    </div>
                    <div className="user_name">
                      <h5>Legendary</h5>
                    </div>
                  </li>
                  <li className="text-end">
                    <div className="info_user">
                      <span># Level 100-100+</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default RanksText;
