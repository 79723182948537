import React from 'react';
import { useContext } from 'react';
import { emptySlotContext } from '../../../../../../../App';
import AliceWarCard from '../CardHolder/AliceWarCard';
import HumanityWarCard from '../CardHolder/HumanityWarCard';

const AHCard = () => {
    const [leftWarSlot, setLeftWarSlot, rightWarSlot, setRightWarSlot]=useContext(emptySlotContext)

    return (
        <div className='breed-section-part container'>
           <div className="col-xl-6 col-lg-6 col-md-6">
             <AliceWarCard availableSlot={leftWarSlot} setAvailableSlot={setLeftWarSlot}></AliceWarCard>
           </div>
           <div className="col-xl-6 col-lg-6 col-md-6">
             <HumanityWarCard availableSlot={rightWarSlot} setAvailableSlot={setRightWarSlot}></HumanityWarCard>
           </div>
        </div>
    );
};

export default AHCard;