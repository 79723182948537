import React from "react";
import blacknet from "../Images/blacknet.png";
import alice from "../Images/alice.png";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../aws-exports";
import { useState } from "react";
import { useEffect } from "react";
import { useToast } from "react-toastify";

import Pagination from "../../../Shared/Pagination";
import Card from "../../../Shared/Card/Card";
import MarketPlaceCard from "../../../Shared/MarketPlaceCard/MarketPlaceCard";
import Loader from "../../../Shared/Loader/Loader";

function MarketPlaceAllCardsText() {
  const [loader, setLoader] = useState(true);

  const [TokenId, setTokenId] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_PURCHASE_TABLE_NAME,
    Limit: 10,
    ProjectionExpression: "TokenId",
  });

  async function GetTokenId() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    // console.log(param);
    const Id = [];
    try {
      const Response = await documentClient.scan(param).promise();
      if (Response.Items.length < 10) {
        setNext(false);
      }
      if (Response.LastEvaluatedKey) {
        setParam({
          TableName: process.env.REACT_APP_PURCHASE_TABLE_NAME,
          Limit: 10,
          ExclusiveStartKey: Response.LastEvaluatedKey,
          ProjectionExpression: "TokenId",
        });
      }
      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        Id.push(element.TokenId);
      }

      if (Response.Items.length === 10) {
        setNext(true);
      }
    } catch (err) {
      // console.log(err);
    }
    return Id;
  }

  const Next = async () => {
    setNext(false);
    const Id = await GetTokenId();
    setTokenId((TokenId) => [...TokenId, ...Id]);

    setPage((page) => page + 1);
  };
  const Previous = async () => {
    // const Id = await GetTokenId();
    // setTokenId(TokenId=>[...TokenId,...Id])
    setPage((page) => page - 1);
  };
  useEffect(() => {
    const Token = async () => {
      const Id = await GetTokenId();
      setLoader(false);
      setTokenId((TokenId) => [...TokenId, ...Id]);
    };
    Token();
  }, []);
  // console.log(TokenId);
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            {loader ? (
              <Loader></Loader>
            ) : (
              <div className="content_box">
                <h4>ALL CARDS</h4>
                <div className="row">
                  {TokenId.slice((page - 1) * 10, page * 10).map((TokenId) => (
                    <MarketPlaceCard
                      TokenId={TokenId}
                      key={TokenId}
                    ></MarketPlaceCard>
                  ))}
                  {TokenId?.length === 0 && <h1>NO CARD</h1>}
                </div>

                <Pagination
                  page={page}
                  setPage={setPage}
                  length={TokenId.length / 10}
                  Previous={Previous}
                  Next={Next}
                  next={next}
                ></Pagination>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default MarketPlaceAllCardsText;
