import React from "react";
import "./PromoCodeBanner.css";
const PromoCodeBanner = () => {
  return (
    <div className="promo-code-banner">
      <h1>Promo code list</h1>
    </div>
  );
};

export default PromoCodeBanner;
