import React from "react";
import user from "../Images/user.png";
import tasnim from "../../../../../../assets/team/Tasnim Kamal Talukdar -Story writer -Extremis.jpg";
import tarif from "../../../../../../assets/team/Tarif Haque-Graphics Designer.jpg";
import smita from "../../../../../../assets/team/Smita Silva Nishi-Content writer.jpg";
import jannat from "../../../../../../assets/team/Jannatul Ferdoush-Content writer.jpg";
import owoeye from "../../../../../../assets/team/Owoeye Oluwaseyi Ayomide-Character artist.jpg";
import apurba from "../../../../../../assets/team/Apurba Biswas Dipto-Lead developer.jpg";
import tofayel from "../../../../../../assets/team/Sheikh Tofeyel Ahmed-Front end developer.jpg";
import Valentin from "../../../../../../assets/team/Valentin Karrica - Front End Developer.jpeg";
function MeetTeamText() {
  return (
    <div>
      <section className="ourteam_section">
        <div className="container">
          <div className="row">
            <div class="col-md-10 offset-md-1">
              <div class="team_text_section">
                <h2 class="text-center">EXTREMIS TEAM</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="team_person">
                <img src={apurba} />
                <h3>Apurba Biswas Dipto</h3>
                <h5>Lead developer</h5>
              </div>
            </div>

            <div className="col-md-4">
              <div className="team_person">
                <img src={tofayel} />
                <h3>Sheikh Tofayel Ahmed</h3>
                <h5>Front end developer</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team_person">
                <img src={Valentin} />
                <h3>Valentin Karrica</h3>
                <h5>Front end developer</h5>
              </div>
            </div>
          
            <div className="col-md-4">
              <div className="team_person">
                <img src={owoeye} />
                <h3>Owoeye Oluwaseyi Ayomide</h3>
                <h5>Character artist</h5>
              </div>
            </div>

            <div className="col-md-4">
              <div className="team_person">
                <img src={jannat} />
                <h3>Jannatul Ferdoush</h3>
                <h5>Content writer</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team_person">
                <img src={smita} />
                <h3>Smita Silva Nishi</h3>
                <h5>Content writer</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team_person">
                <img src={tasnim} />
                <h3>Tasnim Kamal Talukdar</h3>
                <h5>Story writer</h5>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team_person">
                <img src={tarif} />
                <h3>Tarif Haque</h3>
                <h5>Graphics Designer</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default MeetTeamText;
