import React from "react";
import more_banner from "../../../../../../assets/banner/Buy-NFTs.png";
function BuyNftBanner() {
  return (
    <div>
      <div className="Page_buynft_section">
        {/* <img src={more_banner}/> */}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page_title">{/* <h1>BUY NFTs</h1> */}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BuyNftBanner;
