import React from "react";
function RenameBanner() {
  return (
    <div>
      <div className="RenameSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="promote_text">
                <h1>RENAME CARDS</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RenameBanner;
