import React from 'react';
import '../PurchaseCards/PurchaseCards.css';
import SellStartText from './Sections Banner/SellStartText';
import SellStartBanner from './Sections Banner/SellStartBanner';
function SellCardsStart () {
	return <div>
        {/* <PurchaseBanner/>
		<PurchaseText/> */}
		<SellStartBanner></SellStartBanner>
		<SellStartText></SellStartText>
	</div>
}
export default SellCardsStart;
