import React from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
function SellBlacknetList () {
	return <div>
<section className="cards_menu_list mt-3">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <div className="nav_menu">
            <ul>
                  <li className="nav-item">
                     <Link className="nav-link" to="/sell-fixed-market">
                     ALL CARDS</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link active" to="/sell-fixed-market/blacknet">
                     BLACKNET</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link" to="/sell-fixed-market/alice">
                     ALICE</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link" to="/sell-fixed-market/humanity">
                     HUMANITY</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link" to="/sell-fixed-market/genesis">
                     GENESIS</Link>
                  </li>
                  </ul>
            </div>
         </div>
      </div>
   </div>
</section>
    </div>
}
export default SellBlacknetList;