import React, { useState } from "react";
import "./PopUp.css";

import "../Shared/Loader/Loader.css";
import LoaderImg from "../../assets/Extremis loader-01.png";
const LoaderFloating = ({ popUpText, popUpSubject, setPopUp }) => {
  return (
    <div className="popup">
      <div className="confirm-container">
        <div className="">
          <div className="loader-container">
            <div className="card-loader">
              <img src={LoaderImg} alt="" />
            </div>
            <p className="text-center text-light opacity-100">Please don't quit this page or refresh, <br /> in doing so may render the card unusable</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderFloating;
