import React from 'react';
// import Blacknetbanner from '../Cardcollection/SubPages/Blacknet/Sections/Blacknetbanner';
import BlacknetText from './SubPages/Blacknet/Sections/AuctionBlacknetText';
import BlacknetList from './SubPages/Blacknet/Sections/AuctionBlacknetList';
import '../AuctionCollection/CardCollection.css';
import AuctionBlacknetList from './SubPages/Blacknet/Sections/AuctionBlacknetList';
import AuctionBlacknetText from './SubPages/Blacknet/Sections/AuctionBlacknetText';

function AuctionBlacknetCard () {
	return <div>
		{/* <Blacknetbanner/> */}
		<AuctionBlacknetList/>
		<AuctionBlacknetText/>

        
	</div>
}
export default AuctionBlacknetCard;