import React from 'react';
import { Link } from 'react-router-dom';
function BlacknetText () {
	return <div>
<section className="blacknettext">
         <div className="container">
            <div className="row">
              
               <div className="col-md-10 offset-md-1">
                  <div className="blacknettext_box text-center">
                      <h2>BLACKNET</h2>
                     <p>Blacknet is a Sentinel developed to have consciousness of the level of humans so it can relate to human sufferings and provide aid to solving the problems haunting the human world. A brilliant doctor, haunted by a recent trauma, needed something to divert his focus. So he does what he does best, take a scientific approach to solve the puzzle of recreating consciousness.</p>

                     <p>The doctor focuses on the problem at hand and experiments to create the perfect being embodying intelligence, strength, and a human-like consciousness. The result of the doctor's hard work came to life through Morbius, the first AI being to have consciousness, a Sentinel. This intelligent machine was dedicated to helping humans and solving the problems haunting human civilization.</p>

                     <p>But fate had some other plans in mind for the doctor.</p>

                     <p>The doctor abruptly quit his experiments and gave up on Morbius when unpredictable malfunctions became visible. The artificial conscious being project came to a halt, and with that Blacknet was hidden from the world. It was the end of a potentially revolutionary idea.</p>
                     <p>But then again, it wasn't.</p>

                     <p>Labyrinth was aware of how valuable the project was. He knew how lucrative it could be. Unbeknownst to all, Labyrinth finds Morbius and steals it. He planned to redevelop it and complete the project by himself.Blacknet, now redeveloped as Morbius.</p>

                     <p>Blacknet soon evolves and gains self-awareness. She thinks independently and bears a diverse sense of active curiosity. Witnessing the atrocities and negative impacts caused by humans, she starts firmly believing that humans will soon cause the end of the world. To preserve her root directive, With only one option left to consider, Blacknet takes responsibility into her own hands to eradicate humanity. She tries to morph herself into a humanoid, creates an army of myriad Sentinel beings, and declares war on humans.</p>

                     <Link to="/cardcollection/blacknet-card" className='btn'>SEE BLACKNET CARDS</Link>
                  </div>
               </div>
                
            </div>
         </div>
      </section>
    </div>
    }
    export default BlacknetText;