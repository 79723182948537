import React from "react";
import "../BreedOthersCollection/CardCollection.css";
import BreedOthersHumanityText from "./SubPages/Humanity/Sections/BreedOthersHumanityText";
import BreedOthersHumanityList from "./SubPages/Humanity/Sections/BreedOthersHumanityList";
import DragBreedCard from "../../../../Shared/DnD/DragBreedCard";
import DragBreedOthers from "../../../../Shared/DnD/DragBreedOthers";

function BreedOthersHumanityCard() {
  return (
    <div>
    {/* <Blacknetbanner/> */}
    <div className="drag-and-card">
      <div className="card-area">
        <BreedOthersHumanityList />
        <BreedOthersHumanityText />
      </div>
      <div className="drag-area">
        <DragBreedOthers></DragBreedOthers>
      </div>
    </div>
  </div>
  );
}
export default BreedOthersHumanityCard;
