import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { providerContext } from "../../App";
import "../Admin/Admin.css";
import useAuctionContract from "../Hooks/AuctionContractHook";
import useBreedingContract from "../Hooks/BreedingContractHook";
import useCardOwnerShipContract from "../Hooks/CardOwnershipHook";
import useFixedMarketContract from "../Hooks/FixedMarketHook";
import useGameCentreContract from "../Hooks/GameCentreHook";
import useWarContract from "../Hooks/WarContractHook";
import LoaderFloating from "../Shared/LoaderFloating";
import PopUp from "../Shared/PopUp";
import {
  IsPausedAuctionHouse,
  IsPausedBreed,
  IsPausedCardOwnerShip,
  IsPausedFixedMarketPlace,
  IsPausedGameCentre,
  IsPausedWar,
  pauseGame,
  unPauseGame,
} from "../UserPage/BlockChainFunctions";
import Action from "./Sections/Action";
import Modal from "./Sections/AdminComponent/Modal";
import AuctionCardAdmin from "./Sections/AuctionCardAdmin";
import BreedingAd from "./Sections/BreedingAd";
import CardOwnership from "./Sections/CardOwnership";
import FixedMarketplace from "./Sections/FixedMarketplace";
import GameCenter from "./Sections/GameCenter";
import War from "./Sections/War";
import WarCardAdmin from "./Sections/WarCardAdmin";

function AdminPage() {
  const [gamePause, setGamePause] = useState(false);
  const [cardPause, setCardPause] = useState(false);
  const [warPause, setWarPause] = useState(false);
  const [fixedPause, setFixedPause] = useState(false);
  const [auctionPause, setAuctionPause] = useState(false);
  const [breedPause, setBreedPause] = useState(false);
  const [gameCenterPause, setGameCenterPause] = useState(false);

  const [dropDownFixed, setDropDownFixed] = useState(false);
  const [dropDownBreeding, setDropDownBreeding] = useState(false);
  const [dropDownWar, setDropDownWar] = useState(false);
  const [dropDownAction, setDropDownAction] = useState(false);
  const [dropDownOwner, setDropDownOwner] = useState(false);
  const [dropDownGame, setDropDownGame] = useState(false);
  const [adminAuction, setAdminAuction] = useState(false);
  const [adminWar, setAdminWar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [loading, setLoading] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [fixedClass, setFixClass] = useState("");
  const [breedingClass, setBreedingClass] = useState("");
  const [warClass, setWarClass] = useState("");
  const [actionClass, setActionClass] = useState("");
  const [ownerClass, setOwnerClass] = useState("");
  const [gameClass, setGameClass] = useState("");

  const [modalData, setModalData] = useState({
    callSuccess: false,
    errorMessage: "",
    blockchainLog: "",
    awsLog: "",
  });

  const openHandlerFixed = () => {
    if (dropDownFixed) {
      setFixClass("");
    } else {
      setFixClass("active");
    }
    setDropDownFixed(!dropDownFixed);
  };
  const openHandlerBreeding = () => {
    if (dropDownBreeding) {
      setBreedingClass("");
    } else {
      setBreedingClass("active");
    }
    setDropDownBreeding(!dropDownBreeding);
  };
  const openHandlerWar = () => {
    if (dropDownWar) {
      setWarClass("");
    } else {
      setWarClass("active");
    }
    setDropDownWar(!dropDownWar);
  };
  const openHandlerAction = () => {
    if (dropDownAction) {
      setActionClass("");
    } else {
      setActionClass("active");
    }
    setDropDownAction(!dropDownAction);
  };
  const openHandlerOwner = () => {
    if (dropDownOwner) {
      setOwnerClass("");
    } else {
      setOwnerClass("active");
    }
    setDropDownOwner(!dropDownOwner);
  };
  const openHandlerGame = () => {
    if (dropDownGame) {
      setGameClass("");
    } else {
      setGameClass("active");
    }
    setDropDownGame(!dropDownGame);
  };

  //Modal Handler
  const openModalHandler = (modData) => {
    setModalData(modData);
    setOpenModal(true);
  };

  const closeModalHandler = () => {
    setOpenModal(false);
    setModalData({
      callSuccess: false,
      errorMessage: "",
      blockchainLog: "",
      awsLog: "",
    });
  };
  //End Modal Handler
  const [auctionContract, setAuctionContract] = useAuctionContract();
  const [breedingContract, setBreedingContract] = useBreedingContract();
  const [cardOwnerShipContract, setCardOwnerShipContract] =
    useCardOwnerShipContract();
  const [fixedMarketContract, setFixedMarketContract] =
    useFixedMarketContract();
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [warContract, setWarContract] = useWarContract();

  const AuctionIsPause = async () => {
    const result = await IsPausedAuctionHouse(auctionContract);
    // console.log(result);
    if (result) {
      setAuctionPause(true);
    } else {
      setAuctionPause(false);
    }
  };

  const BreedIsPause = async () => {
    const result = await IsPausedBreed(breedingContract);

    if (result) {
      setBreedPause(true);
    } else {
      setBreedPause(false);
    }
  };

  const CardIsPause = async () => {
    const result = await IsPausedCardOwnerShip(cardOwnerShipContract);
    // console.log(result);
    if (result) {
      setCardPause(true);
    } else {
      setCardPause(false);
    }
  };

  const FixedIsPause = async () => {
    const result = await IsPausedFixedMarketPlace(fixedMarketContract);
    // console.log(result);
    if (result) {
      setFixedPause(true);
    } else {
      setFixedPause(false);
    }
  };

  const GameCentreIsPause = async () => {
    const result = await IsPausedGameCentre(gameCentreContract);
    // console.log(result);
    if (result) {
      setGameCenterPause(true);
    } else {
      setGameCenterPause(false);
    }
  };

  const WarIsPause = async () => {
    const result = await IsPausedWar(warContract);
    // console.log(result);
    if (result) {
      setWarPause(true);
    } else {
      setWarPause(false);
    }
  };

  const GamePauseStatus = () => {
    if (
      auctionPause &&
      breedPause &&
      cardPause &&
      fixedPause &&
      // gameCenterPause &&
      warPause
    ) {
      setGamePause(true);
    } else {
      setGamePause(false);
    }
  };
  useEffect(() => {
    // console.log(gamePause, "game");
    GamePauseStatus();
  }, [
    auctionPause,
    breedPause,
    cardPause,
    fixedPause,
    // gameCenterPause,
    warPause,
  ]);

  const PauseGame = async () => {
    setLoading(true);
    const result = await pauseGame(walletProvider);
    // console.log(result, "pausewhole game");

    AuctionIsPause();
    BreedIsPause();
    CardIsPause();
    FixedIsPause();
    GameCentreIsPause();
    WarIsPause();
    GamePauseStatus();
    setLoading(false);
  };

  const UnpauseGame = async () => {
    setLoading(true);

    const result = await unPauseGame(walletProvider);
    AuctionIsPause();
    BreedIsPause();
    CardIsPause();
    FixedIsPause();
    GameCentreIsPause();
    WarIsPause();
    GamePauseStatus();
    setLoading(false);
  };

  const toggleAuction = () => {
    setAdminAuction(true);
    setAdminWar(false);
  };
  const toggleWar = () => {
    setAdminAuction(false);
    setAdminWar(true);
  };
  return (
    <div className="main_container admin-container">
      {openModal && (
        <Modal closeModal={closeModalHandler}>
          {modalData.callSuccess ? (
            <div>
              <div className="a-title">Transaction success !</div>
              <div>Blockchain log (TxHash)</div>
              <div className="modal-Blockchain-log">
                {modalData.blockchainLog}
              </div>
              <div>AWS log</div>
              <div className="modal-Blockchain-log">{modalData.awsLog}</div>
            </div>
          ) : (
            <div>
              <div className="a-title">Transaction failed !</div>
              <div>Error message</div>
              <div className="modal-Blockchain-log">
                {modalData.errorMessage}
              </div>
            </div>
          )}
        </Modal>
      )}

      <div className="nav_bar">
        {!gamePause ? (
          <button className="nav-btn" onClick={PauseGame}>
            Pause Game
          </button>
        ) : (
          <button className="nav-btn " onClick={UnpauseGame}>
            Unpause Game
          </button>
        )}
      </div>

      {/* <div className="nav_bar">
        

        <button
          onClick={openHandlerBreeding}
          className={"nav-btn " + breedingClass}
        >
          BREEDING
        </button>

        <button onClick={openHandlerWar} className={"nav-btn " + warClass}>
          WAR
        </button>

        <button
          onClick={openHandlerAction}
          className={"nav-btn " + actionClass}
        >
          ACTION
        </button>

        <button onClick={openHandlerOwner} className={"nav-btn " + ownerClass}>
          CARD OWNERSHIP
        </button>
      </div> */}
      <div className="container-box-scroll">
        <div className="container-box">
          <FixedMarketplace
            setPopUp={setPopUp}
            popUpSubject={popUpSubject}
            popUpText={popUpText}
            openModal={openModalHandler}
            dropDown={dropDownFixed}
            handlerFixed={openHandlerFixed}
            FixedIsPause={FixedIsPause}
            fixedPause={fixedPause}
            setLoading={setLoading}
          />
          <BreedingAd
            setPopUp={setPopUp}
            popUpSubject={popUpSubject}
            popUpText={popUpText}
            openModal={openModalHandler}
            dropDown={breedingClass}
            handlerFixed={openHandlerBreeding}
            BreedIsPause={BreedIsPause}
            breedPause={breedPause}
            setLoading={setLoading}
          />
          <War
            setPopUp={setPopUp}
            popUpSubject={popUpSubject}
            popUpText={popUpText}
            openModal={openModalHandler}
            dropDown={warClass}
            handlerWar={openHandlerWar}
            WarIsPause={WarIsPause}
            warPause={warPause}
            setLoading={setLoading}
          />

          <Action
            setPopUp={setPopUp}
            popUpSubject={popUpSubject}
            popUpText={popUpText}
            openModal={openModalHandler}
            dropDown={actionClass}
            handlerAction={openHandlerAction}
            AuctionIsPause={AuctionIsPause}
            auctionPause={auctionPause}
            setLoading={setLoading}
          />

          <CardOwnership
            setPopUp={setPopUp}
            popUpSubject={popUpSubject}
            popUpText={popUpText}
            openModal={openModalHandler}
            dropDown={ownerClass}
            handlerOwner={openHandlerOwner}
            CardIsPause={CardIsPause}
            cardPause={cardPause}
            setLoading={setLoading}
          />
          <GameCenter
            setPopUp={setPopUp}
            popUpSubject={popUpSubject}
            popUpText={popUpText}
            openModal={openModalHandler}
            dropDown={gameClass}
            handlerOwner={openHandlerGame}
            GameCentreIsPause={GameCentreIsPause}
            gameCenterPause={gameCenterPause}
            setLoading={setLoading}
          />
          <div className="d-flex justify-content-center my-5 gap-3">
            <button className="main-button" onClick={toggleAuction}>
              Auction Card
            </button>
            <button className="main-button" onClick={toggleWar}>
              War Card
            </button>
          </div>
        </div>
          {adminAuction && <AuctionCardAdmin></AuctionCardAdmin>}
          {adminWar && <WarCardAdmin></WarCardAdmin>}
        {popup && (
          <PopUp
            popUpSubject={popUpSubject}
            popUpText={popUpText}
            setPopUp={setPopUp}
          ></PopUp>
        )}
        {loading && <LoaderFloating></LoaderFloating>}
      </div>
    </div>
  );
}

export default AdminPage;
