import React, { useEffect } from "react";
import "../Card.css";
import useColor from "../useColor";
import useSellCard from "../SellCard/useSellCard";

const DropCard = (props) => {
  const [CardFaction, CardName, CardType, CardRank, CardGender] = useSellCard(
    props.TokenId
  );
  const color = useColor(CardRank, CardType);
  useEffect(() => {
    if (props.setFaction) {
      props.setFaction(CardFaction);
    }
  }, [CardFaction, props]);
  return (
    <div className="d-flex drop-cards word-wrapping">
      {/* <div className="card_img"> */}
      <img
        // className=""
        src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
        className={`${color} w-50 m-2`}
        alt=""
      />
      {/* <img
          className="rankImage"
          src={`/Rank/${rankImage}.png`}
          alt=""
        />{" "}
        <img
          className="extremis-logo"
          src="/LOGO.png"
          alt=""
        /> */}
      {/* </div> */}
      <div className="d-flex align-items-evenly justify-content-center flex-column">
        <h3 className="text-white">{CardName}</h3>{" "}
        <p className=" text-white m-0">ID: {props.TokenId}</p>
        <h5 className="text-white">{CardFaction}</h5>
      </div>
    </div>
  );
};

export default DropCard;
