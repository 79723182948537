import React from 'react';
import '../ProfileCollection/CardCollection.css';
import ProfileAliceList from './SubPages/AliceCard/Sections/ProfileAliceList';
import ProfileAliceText from './SubPages/AliceCard/Sections/ProfileAliceText';

function ProfileAliceCard () {
	return <div>
		{/* <Alicebanner/> */}
		<ProfileAliceList></ProfileAliceList>
		<ProfileAliceText></ProfileAliceText>

        
	</div>
}
export default ProfileAliceCard;