import React, { useEffect, useState } from "react";

const useRankImage = (CardRank) => {
  const [image, setImage] = useState("");

  const Image = (CardRank) => {
    if (CardRank === "Private") {
      setImage("Private");
    } else if (CardRank === "2nd Lieutenant") {
      setImage("Second-Lieutenant");
    } else if (CardRank === "1nd Lieutenant") {
      setImage("First-Lieutenant");
    } else if (CardRank === "Captain") {
      setImage("Captain");
    } else if (CardRank === "Major") {
      setImage("Major");
    } else if (CardRank === "Lieutenant Colonel") {
      setImage("Lieutenant-Colonel");
    } else if (CardRank === "Colonel") {
      setImage("Colonel");
    } else if (CardRank === "Brigadier General") {
      setImage("Brigadier-General");
    } else if (CardRank === "Major General") {
      setImage("Major-General");
    } else if (CardRank === "Lieutenant General") {
      setImage("Lieutenant-General");
    } else if (CardRank === "General") {
      setImage("General");
    } else if (CardRank === "Legendary") {
      setImage("Legendary");
    }
  };
 

  useEffect(() => {
    Image(CardRank);
  }, [CardRank]);

  return image;
};

export default useRankImage;
