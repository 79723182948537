import React from 'react';
import Alicebanner from '../MutateCollection/SubPages/AliceCard/Sections/Alicebanner';
import AliceText from './SubPages/AliceCard/Sections/MutateAliceText';
import AliceList from './SubPages/AliceCard/Sections/MutateAliceList';
import '../MutateCollection/CardCollection.css';
import MutateAliceList from './SubPages/AliceCard/Sections/MutateAliceList';
import MutateAliceText from './SubPages/AliceCard/Sections/MutateAliceText';

function MutateAliceCard () {
	return <div>
		{/* <Alicebanner/> */}
		<MutateAliceList/>
		<MutateAliceText/>

        
	</div>
}
export default MutateAliceCard;