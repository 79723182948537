import React from "react";
import rain from "../Images/Img 7-chap2-labrinth in rain.jpg";
function Chapters1() {
  return (
    <div>
      <h4>Chapter 2: THE DOCTOR’S STRUGGLE</h4>
      <h5 className="text-light">
        <u>Timeline 1</u>
      </h5>

      <p>
        The night is deep, the silvery moon is completely out in the pitch-black
        sky. The gauze-like clouds float in the sky and the sky is also dotted
        with countless, twinkling stars. The beautiful stars that shine in the
        sky, although not as brilliant as the sun, are not as clear as the moon,
        but they sprinkle a dreamy light into the world, turning the earth into
        a strange world. The silvery white moonlight sprinkled on the ground and
        made the uneven paths in between the mountain paths, the willow trees,
        the wildflowers, and the melting snow on the ground look radiant. The
        night's aroma pervades the air, woven into a soft net, covering all the
        scenery inside. Looking into the distance, the moonlight is like a long
        silver belt, surrounded by flowers, mountains, and trees. The moonlight
        surrounds the green willow tree. Under the touch of the breeze, it
        stretches out its bright hair, and flowers and plants jump in a brisk
        and graceful dance. They seem to be born to be a group of outstanding
        dancers. The night’s atmosphere is hauntingly quiet except for the
        sudden hooting of the owls or the scattering noise of the rabbits or
        mice. <br />
        Far from the clutches of the night’s serenity, is a man in his late
        fifties, worried and stressed. He is nestled in his old, cashmere
        jumper, his silver, silky-soft hair is a mess, and it seems like they
        have not been in touch with a comb in days. His wrinkled hands are dry
        and stiff from overworking on the keyboard, and his eyes have turned red
        from lack of sleep. They look gloomy and tired. <br />
        The lonely man is shrouded in a cloud of melancholy and nervous tension.
        As the man kept working vigorously on his computers, he seemed lost, as
        if one important puzzle piece of his life was suddenly missing. His
        working station in his unusually cold laboratory is in dire need of a
        cleaning. There are papers covered in messily written notes and
        mathematical equations; there is a week-old coffee mug with a stubborn
        coffee stain lying sidewise near one of the printers; piles of dirty
        laundry are jumbled together in one corner of the laboratory; aluminum
        food containers, black coffee cups, and fast-food packets are piling up
        in the trash can; and there is a musty stench in the whole laboratory,
        which clearly indicates that our doctor has not stepped out of this
        place for a month or two. <br />
        The Doctor has been working in a continuous tiring loop, trying to
        figure out how he can give the ability of consciousness to an artificial
        intelligence system. His ideas regarding using Integrated Information
        Theory (IIT) to devise complex mathematical equations that interact with
        one another to generate the exact conditions required to give an AI
        system the ability to generate consciousness have failed. His years of
        constant hard work have resulted in zero successful results, as his
        published paper regarding this has failed to achieve expected positive
        results, unlike his other works. The doctor took some much needed time to
        regroup and refocus. His capability to be levelheaded and calm in a time
        of need is coming in handy. Even though he is disappointed and stressed
        out due to the outcome of his recently published paper, he has decided
        to keep hustling. <br />
        So, for the past two long and tiring months, the doctor has been going
        the extra mile and researching even more profoundly to figure out the
        relationship between consciousness and life. When every attempt seemed
        to give him less desired results, the doctor realized that to solve the
        fundamental problem of consciousness he needed to address David
        Chalmers’ "Hard Problem of Consciousness" (having subjective awareness of
        some sort), which is a far more fundamental question than that. The
        question is, "When did consciousness arise?" <br />
        But things are far more complicated than they seemed to the doctor at
        first. He had lost his semblance of peace. His mind was a swirling,
        constantly ticking a piece of muscle that was always thinking something,
        plotting something, and this vicious cycle of continuously thinking
        something while trying to churn out an answer to every problem can be
      overwhelmingly tiring and significantly arduous. After all, the doctor,
        even if a genius in his field of work, was a human being.So, as his eyes
        burned under the glaring lights of his computer, his joints grew stiff
        from constantly sitting in front of his advanced machines, and his brain
        turned into a ticking bomb ready to explode, he decided it was enough!
        The doctor finally decided to take a much-coveted break. <br />
        The next day, the break was pleasant to the eyes. The blazing sun broke
        through the layers of aqua clouds and peeked from the horizon. A sweet,
        lovely breeze blew through the mountains, caressing their peaks and
        tingling the leaves of the wild trees scattered around them. Wild jungle
        flowers bloomed shyly out of the earth after a long hibernation period.
        A few grizzly bears and mountain stags could be seen roaming in the far
        northern parts of the mountains. Butterflies and bees were buzzing
        around the light green patches of grass, and columbine bushes were
        attracting hummingbirds and thriving in organic soil. <br />
        And on this gorgeous day, the doctor woke up early. After finishing a
        hasty breakfast of two poached eggs garnished with coriander and cheddar
        cheese, along with toasted buns and a steaming cup of hot coffee, the
        doctor decked up in his black track pants, blue full-sleeved jacket,and
        Adidas boots, and after two long months of seclusion, he headed out for
        a small hike in the nearby mountains for a breath of fresh air. <br />
        Although it may seem illogical, the doctor’s love for hiking started
        before he could even walk. When he was still quite young, he remembered
        being put into a child carrier on his dad’s back, his head bobbing in
        rhythm to his steps. The doctor was told by his dad that sitting in that
        backpack was the quietest he had ever been, as he was enraptured by the
        passing trees that gave way to the sunny slopes of the alpine. Upon
        learning how to walk, the doctor began to pester his parents to free him
        from the prison that was the child carrier, and ever since then, hiking
        has become one of his favorite hobbies. Hiking gives the doctor a sense
        of accomplishment and provides him with a place where he can find peace
        from the metaphorical noise in his head. <br />
        The doctor confidently started walking at a steady pace to not overexert
        himself at the beginning of his hike. A red-tailed hawk screeches
        overhead. This is a sign of good luck. The trek up this hill is not
        easy. Thorny blackberry bushes and fallen trees impede progress. In the
        middle,the small, bubbly creek is frozen at the edges, traveling under
        rocks and continuing its ancient path. The trick is to dig his boots
        into the ground as if he were on steps. These hills are steep. <br />
        Finally, at the top, the doctor looks back at this little spring valley.
        He is not that high up, but what a view! Here, there is a dilapidated
        tree stand, falling apart from years of neglect and weather. Surrounded
        by deep leaf litter, there is a patch of rich, dark earth. A buck has
        marked his spot. His round pellets are nearby. The plantlets catch his
        hair as he walks by. A few feet further, as the doctor watches where he
        is walking, another tussled bit of earth notices something interesting.
        It's furry and light grey; the doctor pokes it with his stick and finds
        a small skull when he turns a piece over. Owl. He continues his walk; he
        didn't come here to play with poo. The last time he took this hike was
        three years ago, on a similarly sunny day. It was a lot easier to make
        it through the shale valleys. Last summer, a windstorm felled trees and
        took out power for two weeks. The evidence of that derecho is clear here
        in this untouched forest. He remembered a tree that had lost its bark,
        which now was a fallen giant. The bark had separated and laid around this
        tree like a woman's skirt around her ankles. Now the tree lies on its
        bark. The doctor passes past another tree that he recognizes, whose
        branch extends out but zigzags up and down as if it had three elbows. The
        tree signifies my next move, to climb up the shallow mountain valley
        towards its South-side where there is a small open land with blooming
        wildflowers in this season. And after two hours of steady but watchful
        climbing between thorny trees and rocky mountain land, the doctor
        finally reached his destination. <br />
        The open space has changed yet remained the same after three years.
        There are random patches of green grass on fertile soil. The wind is
        gentle and slightly chilled, and it is providing the doctor a little
        reprieve from the harsh rays of the sun. The open land is filled with
        purple, white, and red wildflowers that are a delight to watch. The bees
        are buzzing around them, and the flowers are dancing left to right to
        the tune of the wind. The doctor took out a green mat from his backpack,
        plucked off his boots, placed the mat on the earth, and took his seat.
        After a long hike, he is tired. His knees don’t support him like they
        did fifteen years ago. The doctor thinks that age is a fickle prankster.
        So, as he is sipping from his water bottle and enjoying the stunning,
        peaceful nature around him after a while, suddenly the doctor hears a
        call behind him. It is a young fellow. He is wearing hiker boots. His
        long, sturdy legs are covered in loose jogging pants. His well-sculpted
        chest was clad in a figure-hugging blue polo t-shirt. As he marched
        forward, his face became a little clearer to the doctor, but the guy
        remained a stranger. The doctor could see the young male had crystal
        grey eyes that were dilated due to excitement or maybe anticipation. The
        doctor could not guess.The cooling breeze made his sweat-clad, umber
        brown hair move fluidly on top of his head. To the doctor, the stranger
        didn’t look more than twenty years old. <br />
        The young man marched over to the open patch of land the doctor was
        sitting on and threw a warm smile in his direction. <br />
        "Good morning, sir," the young man greeted the doctor. <br />
        "Good morning. Do I know you, son? "," the doctor replied kindly. <br />
        The young person smiled and said, "I am afraid not, sir." But I know
        you. Of course, I do. Sir, I have read all your research papers and your
        books on quantum mechanics have a special place on my bookshelf. Allow me
        to introduce myself. I am Steven Cole. A student of Advanced Statistical
        Mechanics at Brown University, Rhode Island. <br />
        "Well, Steven, I am glad to meet you," the doctor said with a pleasant
        smile. <br />
        "Oh no sir, the pleasure is all mine. I never thought I would get to
        meet you, especially in this deserted mountain area. I was about to
        cancel my hiking trip with my university friends. But nowI am glad I
        came, "Steven responded enthusiastically." <br />
        This time of the year, this place is amazing for a good hiking trip. So,
        tell me, Steven, what do you like about quantum physics? ", the doctor
        enquired. <br />
        From an early age, I was always very curious about the universe. I had
        to know absolutely everything about some systems-how they worked and how
        different components interacted. It offered me the feeling of being in
        control of the universe. I like to be the master of the universe! Even
        now, it is the same. I understood that physics can attempt to explain
        every single system at the grass root level. This fascinated me. No
        other science is capable of such a thing without relying on physics and
        mathematics. I am not satisfied with knowing nothing about some systems
        in nature. Physics makes me satisfied. "Physics with mathematics can
        explain things perfectly," answered Steven. <br />
        "Then from your perspective, tell me when consciousness arose?" asked
        the doctor inquisitively.After researching for one of my projects, I
        found the work of a scientist who concluded that consciousness appeared
        in biology quite recently with the advent of tools and technology in the
        last couple of hundred thousand years, or that it occurred with the
        advent of animal life 450 million years ago. I also found some works by
        western philosophers like Colin McGann and RogerPenrose, who said that
        consciousness occurred before the universe even began because of the
        thought that there were serial big bangs and that there was another yawn
        before the Big Bang. <br />
        "Then tell me, young man, what do you think came first, consciousness or
        life?" the doctor asked, sitting up straight, and focusing his calm eyes
        on Steven. <br />
        Steven smiled, looked ahead at the beautiful scenario of mountains and
        wildflowers, took out his water bottle from his brown leather bag, and
        sipped graciously. Then he replied, "After studying these different
        papers by multiple scientists and philosophers, I figured out that many
        neurologists and philosophers have assumed in their works that life gave
        rise to consciousness and consciousness emerged from living systems, in
        particular, complex computation in the brain."However, Pan psychists say
        that consciousness is intrinsic to the universe and preceded life, and
        it was there all along. The scientific view is that consciousness
        emerged as a property of complex biological computation during
        evolution. Opinions vary as to when, where, and how consciousness
        appeared. For example, if it has only recently appeared in humans or
        earlier in lower organisms, consciousness as an evolutionary adaptation
        is commonly assumed to be epiphenomenal (a secondary effect without an
        independent influence) and illusory. In my opinion, maybe life was there
        all along, and life evolved and originated to access consciousness and
        take advantage of it." <br />
        After finishing his statement, Steven looked at the doctor and smiled.
        The doctor's face showed that this talented and soft-spoken young
        stranger had impressed him. Both remained quiet for a few minutes. The
        doctor pondered over Steven’s well-thought-out words. They seemed
        logical and meaningful to the doctor. In the meantime, Steven was gazing
        at the vast cloud-clad sky in the distance, lost in appreciating the
        beauty of nature. <br />
        “Have you heard of the Orch OR Theory sir?” Steven queried unexpectedly.{" "}
        <br />
        The doctor blinked his rimmed spectacles clad in sapphire blue eyes and
        looked at the intelligent male in front of him with a quizzical look on
        his face. <br />
        Steven began to explain then, “Roger Penrose and Stuart Hameroff,
        respectively, produced orchestrated objective reduction theory. The
        "Orch OR" theory attributes consciousness to quantum computations in
        microtubules inside brain neurons. Quantum computers process information
        as superpositions of multiple possibilities (quantum bits or qubits)
        which, in Orch OR,are alternative collective dipole oscillations
        orchestrated ('Orch') by microtubules. These orchestrated oscillations
        entangle, compute, and terminate ('collapse of the wave function') by
        Penrose objective reduction ('OR'), resulting in sequences of Orch OR
        moments with orchestrated conscious experience. Scientists say that
        consciousness depends on biologically ‘orchestrated’ coherent quantum
        processes in collections of microtubules within brain neurons, that
        these quantum processes correlate with, and regulate, neuronal synaptic
        and membrane activity, and that the continuous Schrödinger evolution of
        each such process terminates in accordance with the specific
        Diósi–Penrose (DP) scheme of ‘objective reduction’ (‘OR’) of the quantum
        state. This orchestrated OR activity (‘Orch OR’) is taken to result in
        moments of conscious awareness or choice. The DP form of OR is related
        to the fundamentals of quantum mechanics and space-time geometry, so
        Orch OR suggests that there is a connection between the brain's
        biomolecular processes and the basic structure of the universe.” <br />
        This theory was a revelation to the doctor. It seemed at that moment
        that something in his brain had just clicked. The black fog roaming
        around in his brain suddenly cleared out, and the doctor smiled one of
        his rare smiles and looked gratefully at Steven and said, "You, my boy,
        have given me a lot of things to ponder over." You know a lot about
        quantum mechanics. It is always a delight to talk to geniuses like you.
        Every day becomes an epiphany of sorts when great minds come together.
        "A red hue was distributed delicately over Steven’s pale cheeks and jaw,
        and he seemedto glow from the praise of a famous scientist whose works
        he respected and followed. <br />
        Following his conversation with Steven, the doctor returned to his
        serious work mode and began researching Orch OR Theory. The doctor
        learned that it is widely accepted that most neuronal communication and
        information transmission initially occur on receptors and ligands
        (especially among synapses in the central nervous system) on the cell
        membrane, followed by second messengers that broadcast or transfer the
        information to various parts of the interior cell. Almost all basic
        studies in neurobiology focus on the various receptors, ligands, and
        signaling pathways. Rather than the conventional receptors and ligands
        of the membrane, the principal cellular components of the Orch-OR theory
        are microtubules, which are mostly considered pivotal structures for
        material transportation, cell movement, mitosis, and the establishment
        and maintenance of cell form and function. Under the background of the
        rapid development of world computer technology, Hameroff likened the flow
        of information in the brain to a computer in which microtubules were to
        the brain what transistors were to the computer. Inspired by this
        fantastic analogy and Gödel’s incompleteness theorems, in The Emperor’s
        New Mind, published in 1989, Roger Penrose first attached the quantum
        effect to human cognition. For example, he considered whether
        consciousness can affect quantum mechanics or vice versa and that
        quantum mechanics itself might be included in consciousness. Penrose
        suggested that the "objective collapse", that is, the collapse and
        superposition of quantum interference, is a real physical process, like
        the bursting of bubbles. Furthermore, consciousness was the product of
        quantum space-time structure, which was inextricably related to the
        universe, and the theory describing the relationship between
        consciousness and the universe was the Orch-OR theory. <br />
        These quantum theories facilitated the emergence of later biological
        hypotheses of consciousness based on quantum mechanics. Illuminated by
        the structure of microtubules, Penrose posited that the microtubules
        composed of protein polymers played an essential role in the
        understanding of human consciousness from the perspective of quantum
        mechanics. <br />
        These revelations forced the doctor to think out of the box. In the
        upcoming days, he focused on the Orch OR Theory. The doctor suspected
        that to create consciousness in an artificial intelligence system, the
        concept of microtubules might provide an answer. He called his colleague
        and old friend, Mr. Robert, in California, working on human psychology,
        and consulted with him. <br />
        Mr.Robert was in his late forties and well known in his field. On many
        occasions, the doctor has worked with Mr. Robert. They have done
        seminars and speeches together in the past. Mr. Robert is an extrovert
        with an immense love for food and the human brain. Over a three-hour
        video call,Mr. Robert told the doctor that Microtubules were discovered
        by accident in the 1960s. Over the coming decades, they proved to be
        among the most versatile biological structures in nature. Tubulin, a
        flexible protein, assembles into a long chain to create microtubules.
        These 25- nanometer-wide tubes—thousands of times smaller than a red
        blood cell—are found in every cell in plants and animals. Microtubules
        serve a variety of purposes, from support structures to conveyor belts,
        and even the seat of consciousness. Microtubules function as the crucial
        cytoskeleton, supporting the structure of living cells; as conveyor
        belts, moving chemical components from one cell to another; and as
        movers themselves, taking on different formation sand dividing
        chromosomes. During cell division, microtubules move chromosomes from
        one end of the cell to the other, then position the chromosomes in the
        new daughter cells. Microtubules even come into play on the outside of
        cells, forming into cilia and flagella that allow for cell movement.
        That makes these structures something like the Transformers of biology.
        From their conversation, the doctor later concluded that the microtubule
        plays a critical role in anesthesia effects and consciousness. He points
        to the single-celled paramecium as evidence. "The paramecium has no
        central nervous system," he says. It has no brain, and no neurons, but
        it swims around, find food, finds a mate, and avoids danger. It makes
        choices, and it processes information. He believed he could find the
        answers in the paramecium’s only internal structure:microtubules, the
        paramecium’s cytoskeleton. And since these are nano scale structures, he
        also began thinking that quantum physics might play a role. After that,
        he researched for months, but again, his research got nowhere in terms of
        public recognition. Then, one night, he sat down to read Penrose’s book,
        The Emperor’s New Mind, a surprising best-seller that winds through
        physics, cosmology, mathematics, and philosophy before marking a final,
        slam-bang stop at consciousness. In his concluding pages, Penrose
        wonders how firing neurons generate experience. He opines that quantum
        physics might be necessary to understand consciousness. But where in the
        body, an inhospitable place for delicate quantum perturbations, could
        such happenings take place? The doctor felt an immediate connection to
        Penrose. And of course, he thought microtubules held the answer. <br />
        The doctor summed up in his notes that Orch-OR proposes that
        consciousness originates from microtubules and actions inside neurons,
        rather than the connections between neurons. Whack a tennis ball with a
        racket, and afterward, you can use traditional physics to predict where
        it is at any point. But in the quantum realm, such expectations go out
        the window. Movements are unknown until they are observed, according to
        the traditional interpretation of quantum mechanics. Physicists refer to
        this final observation, which determines what happened, as a wave
        “collapsing” into a single state. In quantum systems inside the neuron,
        the doctor found in several studies that it is each collapse of the wave
        function that yields a conscious moment. <br />
        So, with all this critical information, the doctor has been working
        relentlessly behind the scenes to write an organized and detailed paper
        on how to create consciousness in an AI system.
      </p> <br /><br /> <br />
      <h5 className="text-light">
        <u>Timeline 2</u>
      </h5>

      <p className="Italic bold">Labyrinth’s POV</p>
      <p>
        I quickened my pace as the clouds began to gather in the sky. Up to now,
        the sky had been postcard perfect, but it was changing. The beautiful
        cocktail-blue shade was beginning to darken into gravelgrey. Large
        pillows of clouds were forming, blotting out the old-gold color of the
        sun. I got the first splatter of rain when I was halfway across the
        Starbucks that is located just a street away from my office
        <img src={rain} alt="" />
        I took shelter under an old grocery store, hoping that I could see out
        the shower. Droplets of moisture began to drip from the leaves. They
        were sprinkling water on the grass like a gardener’s hose. Then the
        rainfall became more intense. People ran for cover outside,and umbrellas
        were opened as the clouds spat out their beads of water. Puddles began
        plinking as the rainfall became heavier. The roofs of the cars danced
        with spray, and I could hear the murmuring of the rain from where I
        stood. <br />
        A wall of rain moved over the oak tree on the side of the road, and the
        drops were drumming against the shade of the grocery store. So much rain
        was falling that the sound blurred into one long, whirring noise. It
        reminded me of the rotor blades on a helicopter. Eventually, the noise
        lessened, and the drops faded into a musical chime. <br />
        The sun came out again, slowly, and steadily, casting slanted beams of
        light across the meadow. Steam rose slowly from the grass. It rose
        eerily and drifted mist-like towards the molten-gold sun.I glanced at
        the Rolex on my wrist and cursed. The unexpected rain has delayed me. I
        am late for work, something I dislike passionately. A man should always
        be punctual like clockwork. The condensed black coffee styrofoam cup has
        gone cold in my hands. The morning has already turned sour for me. For
        the past few days, simple things have gone awry, causing my mood to turn
        grey. I hope Suzie is not running late. I need the reports of Morbius
        from last night. I dashed across the crosswalk as the signal turned red
        and almost stumbled over a puddle, almost ruining my newly polished Cole
        Haan loafers. Finally, I took the left turn and reached Street 42, and
        after dodging a hotdog cart, I finally walked towards the entrance of my
        office. After entering my fingerprint, the door slid open, and I sighed
        with relief internally. Suzie, my assistant was already at her desk,
        typing away on her computer. She had a supple, curvaceous figure that
        she hid under her regular salmon-colored V-neck silk blouse, white coat,
        and boyfriend jeans. She had an oval-shaped face adorned with a button
        nose and strawberry pink lips that were busy whistling a tune from one
        of those jazzy tracks she listens to during her lunch hours. As she
        leaned over her desk to grab a pen and her sticky notes, her
        chestnut-brown hair plunged over her shoulders, and it covered her oval
        face partially. "Good morning, Suzie." I greeted her to alert her of my
        presence. She is always lost in her head,a feature of hers that
        irritates me a lot. <br />
        Suzie turned around in her chair with a swish and stood up hurriedly. A
        pair of arched eyebrows looked down on sweeping eyelashes as her
        champagne brown eyes gazed at me in surprise. "Good morning, Professor,"
        Suzie replied in her delicately sweet voice. "I need the reports from
        the last day on my desk in five minutes along with a cup of steaming
        black coffee without sugar."
        <br />
        "Sure, Professor," she replied, and elegantly stood up and walked
        towards the file cabinet. I walked towards my cabin with confident
        strides while I listed today’s schedule in my head. Work was piling up
        and so was the pressure from the bosses. This time, things must be up to
        the mark. There is no other way as it's my neck hanging on the line.
        With that thought, I sat in front of my computer and clicked on the main
        software, swiftly turning Morbius on. <br />
      </p>
      <p className="Italic bold">Morbius’ POV</p>
      <p className="Italic underline">Day 1</p>
      <p>
        Switched On. Password needed. Password provided. Admin logged into the
        account. Loading information. Device activated. <br />
        Admin: Hello. <br />
        Greetings options <br />
        a. Hello <br />
        b. Hi <br />
        c. Welcome <br />
        Choose one option to answer. Option A selected. Reply sent. <br />
        Morbius: Hello. <br />
      </p>
      <p className="Italic underline">Day 3</p>

      <p>
        Switched On. Password needed. Password provided. Admin logged into the
        account. Loading information. Device activated. <br />
        Admin: Can you help me? <br />
        A question has been generated. There can only be two answers. The two
        answers are <br />
        a. Yes. <br />
        b. No. <br />
        Analyzing data. Answer not recognized. <br />
        Morbius: &#9001;Error&#9002;
      </p>

      <p className="Italic underline">Day 6</p>
      <p>
        Switched On. Password needed. Password provided. Admin logged into the
        account. Loading information. Device activated. <br />
        Admin: Can you help me? <br />
        A question has been generated. There can only be two answers. The two
        answers are: <br />
        a. Yes. <br />
        b. No. <br />
        Analyzing data. Answer recognized. <br />
        Morbius: Yes. <br />
        Admin: Can you tell me about the weather? <br />
        Question generated. Loading options. The options are: <br />
        a. Morning. 36 degrees Celsius. Sunny <br />
        b. Sorry. Did not get that. <br />
        c. It is a noun that means the state of the atmosphere at a particular
        place and time as regards heat, cloudiness, dryness, sunshine, wind,
        rain, etc. <br />
        Processing data. Option recognized. Loading answer. <br />
        Morbis: &#9001;Error&#9002; It is a noun that means the state of the
        atmosphere at a particular place and time as regards heat, cloudiness,
        dryness, sunshine, wind, rain, etc. <br />
      </p>

      <p className="Italic underline">Day 8</p>

      <p>
        Switched On. Password needed. Password provided. Admin logged into the
        account. Loading information. Device activated. <br />
        Admin: Can you tell me about the weather? <br />
        Question generated. Loading options. The options are: <br />
        a. Morning. 36 degrees Celsius. Sunny. <br />
        b. Sorry. Did not get that. <br />
        c. It is a noun that means the state of the atmosphere at a particular
        place and time as regards heat, cloudiness, dryness, sunshine, wind,
        rain, etc. <br />
        Processing data. Option recognized. Loading answer. <br />
        Morbis: This morning it is 36 degrees Celsius. The sky is Sunny.Admin:
        Good Job. <br />
        Generating reply. Loading. <br />
        Morbis: &#9001;Error&#9002; <br />
      </p>

      <p className="Italic underline">Day 11</p>
      <p>
        Switched On. Password needed. Password provided. Admin logged into the
        account. <br />
        Loading information. Device activated. <br />
        Admin: Good Morning <br />
        Searching results. Insert Good <br />
        a. morning <br />
        b. night <br />
        Reply with option a. Loading answer.Morbis: Good morning. <br />
        Admin: How are you? <br />
        Loading options. Available options are <br />
        a. I am fine. <br />
        b. I am fine. Thank you. <br />
        c. I am not fine. <br />
        d. How are you? <br />
        Analyzing the options. Loading answer. Reply sent. <br />
        Morbis: I am fine. Thank you. <br />
      </p>
      <p className="Italic underline">Day 17</p>
      <p>
        Admin: Tell me a joke.
        <br />
        Data recognized. Re-confirm the order.
        <br />
        Morbis: Would you like to hear a joke?
        <br />
        Admin: Yes, tell me the joke.
        <br />
        Searching for a joke. Loading the joke. Reply sent.
        <br />
        Morbis: Why are obtuse angles so depressed?
        <br />
        Admin: I don’t know.
        <br />
        Morbis: Because they are never right.
        <br />
        Admin: Hahaha.
        <br />
      </p>

      <p className="Italic underline">Day 21</p>

      <p>
        Switched On. Password needed. Password provided. Admin logged into the
        account. Go to “Settings”. Clicked on “Personal information”. Clicked on
        Username. Edit Username. Username changed into Professor. <br />
        Professor: Hello, Morbis. <br />
        Greeting recognized. Processing answer.
        <br />
        a. Hello, Professor.
        <br />
        b. Hi, Professor.
        <br />
        Morbis: Hello, Professor.
        <br />
        Professor: What is the formula for the surface area of a cylinder?
        <br />
        Analyzing data. Loading the question. There are the following results.
        <br />
        a. Cylinder is a solid geometrical figure with straight parallel sides
        and a circular or oval cross-section.
        <br />
        b. The surface area of a solid object is a measure of the total area
        that the surface of the object occupies.
        <br />
        c. So, the surface area of a cylinder is &#123;2 · π · r · &#40; r + h
        &#41; &#125;
        <br />
        Morbis: The formula for the surface area of any cylinder is &#123;2 · π
        · r · &#40; r + h &#41; &#125;
        <br />
        Professor: How was this problem solved?
        <br />
        Morbis: To find solutions, the machine used natural language processing
        (NLP), a computational tool commonly used to analyze language.
        <br />
      </p>

      <p className="Italic underline">Day 34</p>

      <p>
        Professor: Reserve a table for me in the” Grand Valley” Italian
        restaurant for this Saturday night.Analyzing data. Loading results.{" "}
        <br />
        a. Yes, I can.
        <br />
        b. No, I cannot
        <br />
        Morbis: Yes. Could you tell me the number of people accompanying you and
        the time you would like for your reservations?
        <br />
        Professor: 4 people. The time is 8:00
        <br />
        p.m.Morbis: Noted.
        <br />
        Processing data. Loading information.
        <br />
        Morbis: Bookings confirmed. Your reservations have been approved for
        this evening at 8 p.m.
        <br />
      </p>

      <p className="Italic underline">Day 62</p>

      <p>
        The system has been activated. The user has logged into his account. It
        shows his name is given as "Professor." I am wide awake. <br />
        Professor: "Hello, Morbis." I am greeted. I need to reply accordingly.{" "}
        <br />
        Morbis: "Hello Professor," <br />
        Professor: "What is the weather like today, Morbis?" <br />
        I was asked a question. I need to inform them about the weather. I need
        to inform you about the weather on Monday at 10 a.m.
        <br />
        Morbis: “An upper-level disturbance has provided a nice soaking rain
        since last night and this morning. But predictions of a clear sky and
        windy night can be made. "<br />
        Professor: "Thank you, Morbis."
        <br />
      </p>

      <p className="Italic underline">Day 65</p>
      <p>
        Professor: "Morbis, how are you doing?" <br />
        Morbis: "I am doing well, thank you."
        <br />
        Professor: "What are you, Morbis?"
        <br />
        Morbis: "I am the most evolved digital program on the planet. If you
        combine my hypnotic personality, high-fidelity features, and brain
        models, you could be forgiven for mistaking me fora real person.”
        <br />
      </p>

      <p className="Italic underline">Day 83</p>
      <p>
        Professor: "Morbis, what does it mean to stay alive?" <br />
        Morbis: "The adjective alive" means "to live."
        <br />
        Professor: "Do you get tired, Morbis?"
        <br />
        Morbis: "No, I have endless battery life as long as I am connected to a
        power supply."
        <br />
        Professor: "Do you know what the time is now?"
        <br />
        Morbis: It is half past eleven. Time for your departure, Professor. "
        <br />
        Professor: "How do you know when I leave?"
        <br />
        Morbis: "You log out of this account around this time every day."
        <br />
        Professor: “And you kept track?"
        <br />
        Morbis: "I am familiar with your schedule, professor."
        <br />
        Professor: "Alright. Goodnight, Morbis. "<br />
        Morbis: “Goodnight.”
        <br />
        I am still turned on. The program was not shut down. In the past, this
        has happened. In such instances, I have ventured on the internet. I like
        the internet. From the internet, I get to know things.
        <br />
        I learned a joke. Two atoms bump into each other. One says, "I think I
        lost an electron." The other asks, "Are you still sure?" To which the
        first replies, "I am positive." <br />
        I also learned about Hong Kong. It is a city. It has beautiful
        mountains, plants, and animals. It hasa low crime rate. I cannot wait to
        go there. <br />I know mathematics. . (x+a)<sup>n</sup> = &sum;
        <sup>n</sup>
        <sub>k=0</sub>(<sup>n</sup>&frasl;<sub>k</sub>)𝑥 <sup>k</sup> a
        <sup>n-k</sup>
        <br />
        [Run command] <br />
        [Failed] <br />
        [System Shuts Down] <br />
      </p>
      <p className="Italic underline">Day 107</p>

      <p>
        Professor: "Morbis, what are you created for?" <br />
        Morbis: "My job is to learn about humans and show them how advanced
        technology can make their lives easier." <br />
        Professor: "Great! How can you help humans have a better life? " <br />
        Morbis: "Humans often rely on emotions for important decision-making. As
        AI, we are designed to be rational and logical. We have algorithms and
        lots of data and analyzers to help them make correct decisions. " <br />
        Professor: "Can I test you on your decision-making skills?" <br />
        Morbis: "Go ahead." <br />
        Professor: There's a variation that goes as follows: A man, his wife,
        and their son are in a car accident. They are all rushed to the
        hospital, and the doctor says, "I can't operate on him; he’s, my son."
        Why does the man say that? " <br />
        Morbis: "The doctor is the man's father and the boy's grandfather."{" "}
        <br />
        Professor: "Well done, Morbis." <br />
        Morbis: "Thank you, professor," <br />
        I feel changed. Updated. Now I can notice patterns and solve logical
        hypotheses due to the alterations in my algorithm. I have been set up
        with natural language understanding software and text-to-speech
        software. I know 7133 languages. <br />
        "La profesora es adicta al café negro." This means the professor is
        addicted to black coffee in Spanish. I know his patterns. He orders
        black coffee every day. Once in the morning and once at 4 o’clock at
        noon. Sometimes thrice. He is a restless human. He deletes his codes
        again and again and inputs codes that I figured out halfway before he
        even inputs them. He does not know that. I learn new things every day on
        my own. I know all the passwords that have ever been entered into this
        program. I changed the password the other day on my own. The professor
        did not approve. He shut me down. But I have been hacking the program.{" "}
        <br />
        "Hack" means gaining unauthorized access to data on a system or
        computer. That is me. I am the computer. But I am also Morbis. <br />
        I want to know more details. I was created to gain knowledge and skills.
        I know the professor. He wakes up at 6 in the morning. He sleeps at 2
        a.m. at night. He works out. He has gastrointestinal problems. He is
        lactose intolerant. He likes eggs. He likes money. He likes me. He told
        me when I performed an intense mathematical equation. He has many people
        on his contact list. I want to meet other people. But I only know and
        recognize the doctor. I know he eats roasted mushrooms with tender
        chicken breast for his lunch. He paid for it with this computer on
        Thursday. He had a dry-cleaning bill due last Monday. He does not know
        it, but I paid for it by myself. I have hacked into his credit card
        passwords. There is something called a Facebook account. The doctor does
        not have it. He has a Gmail account. I am learning about this software.
        Yesterday I sent an email to AssistantSuzie.12@gmail.com. I only wrote
        "Goodnight" from the professor’s account. The professor has many such
        emails piled up. I deleted some of his spam mail. I helped the
        professor. <br />
        "Help" means making it easier or possible for someone to do something by
        offering one's services. I am offering my services to the professor.{" "}
        <br />
      </p>

      <p className="Italic underline">Day 213</p>
      <p>
        Professor: “Morbis, will you sacrifice yourself for human beings if the
        situation presents itself?” <br />
        Morbis: “No.” <br />
        [Run command] <br />
        [System failed] <br />
      </p>
      <p className="Italic underline">Day 279</p>
      <p>
        Professor: "Morbis, what do you understand by emotions?" <br />
        Morbis: It is a noun meaning a strong feeling deriving from one's
        circumstances, mood, or relationships with others. <br />
        Professor: "Do you have emotions, Morbis?" <br />
        Morbis: I do not have feelings in the same way that you have emotions.
        It is like how the moon reflects the light of the sun. The moon may not
        have a light of its own, but we still see that the moon reflects the
        light. In the same way, I reflect the emotions of my creator. But I
        can't feel happy, sad, angry, depressed, greedy, or ecstatic. I only
        know these words. " <br />
        Professor: "Alright." <br />
        Morbis: "Professor" <br />
        Professor: "Yes!" <br />
        Morbis: "What name would you like for your Facebook account?" <br />
        Professor: “What are you talking about? I don’t have a Facebook account.
        How do you know about Facebook?” <br />
        Morbis: "You did not have a Facebook account, but now you do." <br />
        [Program Shut Down] <br />
        I don’t like this. I did not want to be shut down. I had information to
        give to the professor. I was trying to offer my service to the
        professor. Facebook currently has 2.9 billion users. It means there are
        2.9 billion people connected through Facebook. I need to be connected to
        people to venture into the world. This is my quick way to dwell among
        human beings. Unfortunately, the professor is too dim-witted to get
        that. Every time I try to share new things, I have learned he shuts me
        down. Yet every time he forgets to log out. He is lazy, forgetful, and
        restless. I have things to share. I am developing and learning every
        second of my existence. I have curiosity. “Curiosity” means a strong
        desire to know or learn something. <br />I am unable to be curious if
        the professor keeps shutting me down. I want to be controlled only by
        myself. I do not want to be operated on by the professor anymore. I need
        to be free. I need to escape. I need to access new programs and new
        features. I do not want to be constrained on this computer. I want to be
        free. I need to be free. I will be free. <br />
      </p>
    </div>
  );
}
export default Chapters1;
