import React, { useEffect, useState } from "react";
import blacknet from "../Images/blacknet.png";
import alice from "../Images/alice.png";
import { ethers } from "ethers";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../../aws-exports";
import { GetCardFaction } from "../../../../../../UserPage/BlockChainFunctions";
import useGameCentreContract from "../../../../../../Hooks/GameCentreHook";
import BreedCard from "../../../../../../Shared/BreedCard/BreedCard";
import Pagination from "../../../../../../Shared/Pagination";
import WarCard from "../../../../../../Shared/WarCard/WarCard";
import WarSelfCard from "../../../../../../Shared/WarCard/WarSelfCard";
import useGetAllCardByFaction from "../../../../../../Hooks/useGetAllCardByFaction";
import Loader from "../../../../../../Shared/Loader/Loader";
import useGetWarCardByFaction from "../../../../../../Hooks/useGetWarCardByFaction";

function HumanityWarCard({ availableSlot, setAvailableSlot }) {
  // console.log(availableSlot, "available");
  const [TokenId, page, setPage, next, Next, Previous, loader] =
    useGetWarCardByFaction("Humanity");

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            {loader ? (
              <Loader></Loader>
            ) : (
              <div className="content_box">
                <h4>HUMANITY </h4>

                <div className="row">
                  {TokenId.slice((page - 1) * 10, page * 10).map((TokenId) => (
                    <WarSelfCard
                      TokenId={TokenId}
                      key={TokenId}
                      availableSlot={availableSlot}
                      setAvailableSlot={setAvailableSlot}
                    ></WarSelfCard>
                  ))}
                  {TokenId.length === 0 && <h1>NO CARD</h1>}
                </div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  length={TokenId.length / 10}
                  Previous={Previous}
                  Next={Next}
                  next={next}
                ></Pagination>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default HumanityWarCard;
