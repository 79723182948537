import React from 'react';
import '../TransferCollection/CardCollection.css';
import TransferHumanityList from './SubPages/Humanity/Sections/TransferHumanityList';
import TransferHumanityText from './SubPages/Humanity/Sections/TransferHumanityText';

function TransferHumanityCard () {
	
	return <div>
		{/* <Humanitybanner/> */}
		<TransferHumanityList></TransferHumanityList>
		<TransferHumanityText></TransferHumanityText>

        
	</div>
}
export default TransferHumanityCard;