import React from 'react';
function AboutBanner () {
	return <div>
<div className="Page_about_section">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="page_title">
                     <h1>ABOUT US</h1>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
}
export default AboutBanner;