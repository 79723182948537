import React from 'react';
function SellCardDetailsBanner(){
 return <div>
<div className="sell_section">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="page_title">
                     <h1>MARKETPLACE</h1>
                  </div>
               </div>
            </div>
         </div>
      </div>
 </div>
 

}
export default SellCardDetailsBanner;