import React from "react";
import NftGiveBanner from "./SubPages/NftGive/Sections/NftGiveBanner";
import NftGiveList from "./SubPages/NftGive/Sections/NftGiveList";
import NftGiveText from "./SubPages/NftGive/Sections/NftGiveText";

const NftGive = () => {
  return (
    <div>
      <NftGiveBanner></NftGiveBanner>
      <NftGiveList></NftGiveList>
      <NftGiveText></NftGiveText>
    </div>
  );
};

export default NftGive;
