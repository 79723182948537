import React from "react";
// import Blacknetbanner from '../Cardcollection/SubPages/Blacknet/Sections/Blacknetbanner';
import BlacknetText from "./SubPages/Blacknet/Sections/BreedSelfBlacknetText";
import BlacknetList from "./SubPages/Blacknet/Sections/BreedSelfBlacknetList";
import "../BreedSelfCollection/CardCollection.css";
import BreedSelfBlacknetList from "./SubPages/Blacknet/Sections/BreedSelfBlacknetList";
import BreedSelfBlacknetText from "./SubPages/Blacknet/Sections/BreedSelfBlacknetText";
import DragBreedCard from "../../../../Shared/DnD/DragBreedCard";
import "../../../../Shared/DragCss.css"
function BreedSelfBlacknetCard() {
  return (
    <div className="drag-and-card">
      {/* <Blacknetbanner/> */}
      <div className="card-area">
        <BreedSelfBlacknetList />
        <BreedSelfBlacknetText />
      </div>
      <div className="drag-area">
        <DragBreedCard></DragBreedCard>
      </div>
    </div>
  );
}
export default BreedSelfBlacknetCard;
