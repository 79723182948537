import React, { useEffect } from "react";
import { useState } from "react";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
const useCustomerInfo = (props) => {
  const [customerInfo, setCustomerInfo] = useState();
  const GetCustomerInfo = async (owner) => {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    const param = {
      TableName: process.env.REACT_APP_CUSTOMERINFO_TABLE_NAME,
      Key: {
        CustomerID: props,
      },
    };
    const response = await documentClient.get(param).promise();
    // console.log(response, "checking");
    return response;
  };

  useEffect(() => {
    const CustomerInfo = async () => {
      if (awsmobile.aws_cognito_region && AWS.config.credentials && props) {
        const customerInfo = await GetCustomerInfo();
        setCustomerInfo(customerInfo);
      }
    };
    CustomerInfo();
  }, [awsmobile.aws_cognito_region, AWS.config.credentials,props]);

  return customerInfo;
};

export default useCustomerInfo;