import React from "react";

const RoadmapText = () => {
  return (
   <>
    <div className="Roadmap">
      <div className="roadmap-left">
        <div>
          <h1>TOKEN LAUNCH</h1>
          <p>
            Once we have launched our Alpha version, we plan on launching a
            cryptocurrency to raise initial funds for our Zeta version or the
            final product. A certain amount of the token will be locked for
            development. The details on the tokenomics of the currency are not
            yet finalized. We urge our users to keep an eye out on our social
            channels for more updates on this. We will release a separate white
            paper for our ERC720 token. The purpose of launching our token is to
            avoid involving any Venture capitalist for future funding. We want this game
            to develop as a community rather than VC driven, and we plan on taking a lot of feedback
            from the users. The votes held on our social media site regarding
            the future development & functionality of the game holds,  a lot of
            weight on how we are going to build the game. During the token
            launch, all of the NFT holders of our game will receive airdropped
            tokens, however, we still going to use "MATIC" as the native token
            for the whole duration of the Alpha version. We plan on using our
            token on the Beta and Zeta versions of the game as the native
            currency of the game.
          </p>
        </div>
        <div>
          <h1>ZETA VERSION</h1>
          <p>
            The Extremis Zeta version or the Extremis metaverse is an upcoming
            Play-To-Earn 3D game. We plan to raise a total of 300 million USD
            via the help of our cryptocurrency and other business revenue
            channels. Once the money is raised, the estimated development time
            of the whole Zeta version is 6.5 years. This might seems like a long
            time for our user, but bear in mind we are aiming for graphics
            quality same or similar to Assassin Creed or GTA. Our game will have
            various genetic algorithms that ensure that over time the graphics
            evolves, meaning as the user keeps playing the game the graphics will
            get better over time. This is one of the key reasons for us, in
            choosing gaming studio like Natural Motion over regular gaming studios or companies.
            Using their graphics technology will enable us to provide the
            mentioned functionality above. Also, remember all NFTs will be
            convertible from our Alpha version to the Beta version, and finally
            to our Zeta version. In our Zeta version, we want to keep the same
            business model as our Alpha and Beta versions, where users would
            have to convert their existing NFT/Card to a character or buy a
            completely new character from the game.
          </p>
        </div>
      </div>
      <div className="roadmap-tree"></div>
      <div className="roadmap-right">
        <div>
          <h1>ALPHA VERSION</h1>
          <p>
          Extremis is a Play-To-Earn game currently deployed on
            the Polygon network. Our business model is a bit different compared
            to other web3 games. We plan to keep any versions of the game
            completely free. However, users would have to buy in-game
            characters(in the Zeta version) or cards(in the Alpha and Beta
            versions) to participate in our game. With the Extremis Alpha
            version, we introduce the user and the general audience to the
            Extremis universe. Any upcoming versions of the game will be a
            continuation of the storyline started in our Alpha version, meaning
            we have a very diverse & elaborate storyline, and elaborate
            backstories for most of the genesis characters, and all cards will be transferable to newer versions.
            Tesseract Gamings is entering the world of web3 gaming with the
            Extremis Alpha version. However, we plan to evolve into a
            fully-fledged gaming franchise. Our main goal is to create a 3D
            gaming metaverse. The game will be a web2 game on top of web3. We
            plan to partner with a gaming studio like Natural Motion, who are using evolutionary algorithms to create animation technology.
          </p>
        </div>
        <div>
          <h1>BETA VERSION</h1>
          <p>
            Our Beta version will be a more advanced form of our Alpha
            version, where the users will have access to many additional
            functionalities like building a clan, creating tournaments,
            chatting, a friendlist, and many more. Starting from our Beta
            version, the game will be a cross-chain game. We plan on utilizing
            all the major blockchains like Polygon, Solana, Ethereum, etc. We DO
            NOT plan on launching the same version of the game on various
            blockchains, rather we plan on making a unified game that utilizes
            all the blockchains. Tesseract gaming wants to be a pioneer in this
            kind of cross-platform architecture for gaming. More details on this
            will be provided later on. <br />
            HINT: <br/>
            POLYGON ----> Earth <br />
            ETHEREUM ----> Proxima Centauri B <br />
            SOLANA ----> Kepler-22B
            <br />
            ----?------     ----> ?
            <br /> ----?-----   ----> ?
            <br />
            ----?------   ----> ?
          </p>
        </div>
      </div>
    </div> 
    <div className="Roadmap-mobile">
      <div className="roadmap-mobile">
      <div>
          <h1>ALPHA VERSION</h1>
          <p>
            Extremis is a Play-To-Earn game currently deployed on
            the Polygon network. Our business model is a bit different compared
            to other web3 games. We plan to keep any versions of the game
            completely free. However, users would have to buy in-game
            characters(in the Zeta version) or cards(in the Alpha and Beta
            versions) to participate in our game. With the Extremis Alpha
            version, we introduce the user and the general audience to the
            Extremis universe. Any upcoming versions of the game will be a
            continuation of the storyline started in our Alpha version, meaning
            we have a very diverse and elaborate storyline, elaborate
            backstories for most of the genesis characters, and a lot of fun!!!
            Tesseract Gamings is entering the world of web3 gaming with the
            Extremis Alpha version. However, we plan to evolve into a
            fully-fledged gaming franchise. Our main goal is to create a 3D
            gaming metaverse. The game will be a web2 game on top of web3. We
            plan to partner with a gaming studio like Natural Motion or a gaming
            studio, using similar technology for graphics and motion
            development.
          </p>
        </div>
        <div>
          <h1>TOKEN LAUNCH</h1>
          <p>
            Once we have launched our alpha version, we plan on launching a
            cryptocurrency to raise initial funds for our Zeta version or the
            final product. A certain amount of the token will be locked for
            development. The details on the tokenomics of the currency are not
            yet finalized. We urge our users to keep an eye out on our social
            channels for more updates on this. We will release a separate white
            paper for our ERC720 token. The purpose of launching our token is to
            avoid involving any Venture capitalist for funds. We want this game
            to develop as a community, and we plan on taking a lot of feedback
            from the users. The votes held on our social media site regarding
            the future development and functionality of the game holds a lot of
            weight on how we are going to build the game. During the token
            launch, all of the NFT holders of our game will receive airdropped
            tokens, however, we still going to use "MATIC" as the native token
            for the whole duration of the alpha version. We plan on using our
            token on the Beta and Zeta versions of the game as the native
            currency of the game.
          </p>
        </div>
        <div>
          <h1>BETA VERSION</h1>
          <p>
            Our Beta version will be a more advanced version of our alpha
            version, where the users will have access to many additional
            functionalities like building a clan, creating tournaments,
            chatting, a friendlist, and many more. Starting from our Beta
            version, the game will be a cross-chain game. We plan on utilizing
            all the major blockchains like Polygon, Solana, Ethereum, etc. We DO
            NOT plan on launching the same version of the game on various
            blockchains, rather we plan on making a unified game that utilizes
            all the blockchains. Tesseract gaming wants to be a pioneer in this
            kind of cross-platform architecture for gaming. More details on this
            will be provided later on. <br />
            HINT: POLYGON ---- Earth <br />
            ETHEREUM ---- Proxima Centauri B <br />
            SOLANA ---- Kepler-22B
            <br />
            -----------
            <br /> -----------
            <br />
            -----------
          </p>
        </div>
        <div>
          <h1>ZETA VERSION</h1>
          <p>
            The Extremis Zeta version or the Extremis metaverse is an upcoming
            Play-To-Earn 3D game. We plan to raise a total of 300 million USD
            via the help of our cryptocurrency and other business revenue
            channels. Once the money is raised, the estimated development time
            of the whole Zeta version is 6.5 years. This might seems like a long
            time for our user, but bear in mind we are aiming for graphics
            quality same or similar to Assassin Creed or GTA. Our game will have
            various genetic algorithms that ensure that over time the graphics
            evolve, meaning as the user keeps playing the game the graphics will
            get better over time. This is one of the key reasons for us, in
            choosing Natural Motion over regular gaming studios or companies.
            Using their graphics technology will enable us to provide the
            mentioned functionality above. Also, remember all NFTs will be
            convertible from our Alpha version to the Beta version, and finally
            to our Zeta version. In our Zeta version, we want to keep the same
            business model as our Alpha and Beta versions, where users would
            have to convert their existing NFT to a character or buy a
            completely new character from the game.
          </p>
        </div>
      </div>
    
    </div>
   </>
  );
};

export default RoadmapText;
