import React, { useContext } from "react";
import { useState, useEffect } from "react";

//components
import ArrowIcon from "./AdminComponent/ArrowIcon";
import BasicButton from "./AdminComponent/BasicButton";
import FormOneInput from "./AdminComponent/FormOneInput";
import FormSubmit from "./AdminComponent/FormSubmit";
import FormTowInput from "./AdminComponent/FormTowInput";
import Form from "./AdminComponent/Form";
import InputForm from "./AdminComponent/InputForm";
import TitleForm from "./AdminComponent/TitleForm";
import LoadingSpinner from "./AdminComponent/LoadingSpinner";
import {
  cancelAuction,
  createAuction,
  createPromoCodeAuc,
  deletePromoCodeAuc,
  GetAuction,
  GetBalanceAuc,
  GetCFOAddrAuction,
  GetCOOAddrAuction,
  GetPromoCodeDetailsAuc,
  GetPromoDetails,
  GetTessractPercentageAuction,
  IsOnAuctionList,
  IsPausedAuctionHouse,
  pauseAuctionContract,
  resetPromoCodeAuc,
  sellOut,
  setAuctionFeeTessPecentage,
  setCFOAddrAuction,
  setCOOAddrAuction,
  unPauseAuctionContract,
  withdrawBalanceAuction,
} from "../../UserPage/BlockChainFunctions";
import { providerContext } from "../../../App";
import * as AWS from "aws-sdk";
import useAuctionContract from "../../Hooks/AuctionContractHook";
//const API Calls from ethers
////////////////////////////////////////////////////////////////////////

function Action(props) {
  const [loading, setLoading] = useState(false);
  const [rotate, setRotate] = useState("");
  const [dropDownClass, setDropDownClass] = useState("drop-down-close");
  const [input3, setInput3] = useState({
    firstInput: "",
    secondInput: "",
    thirdInput: "",
  });
  const [input4, setInput4] = useState({
    firstInput: "",
    secondInput: "",
    thirdInput: "",
    forthInput: "",
  });

  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const ssm = new AWS.SSM();

  const [auctionContract, setAuctionContract] = useAuctionContract();
  const [coo, setCoo] = useState("");
  const [cfo, setCfo] = useState("");
  const [tPer, setTPer] = useState("");
  const [promoDetails, setPromoDetails] = useState();
  const [promoDetails1, setPromoDetails1] = useState();
  const [promoDeleted, setPromoDeleted] = useState(false);
  const [balance, setBalance] = useState("");

  useEffect(() => {
    if (auctionContract) {
      // console.log("hello");
      props.AuctionIsPause();
    }
  }, [auctionContract]);

  const Pause = async () => {
    props.setLoading(true);
    // setPauseOn(true)
    try {
      const result = await pauseAuctionContract(walletProvider);
      props.AuctionIsPause();
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }

    // console.log(result);
  };
  const Unpause = async () => {
    props.setLoading(true);
    try {
      const result = await unPauseAuctionContract(walletProvider);
      // console.log(result);
      props.AuctionIsPause();
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const getCOOAddr = async () => {
    const result = await GetCOOAddrAuction(auctionContract);
    setCoo(result);
    // console.log(result, "coo address");
  };
  const getCFOAddr = async () => {
    const result = await GetCFOAddrAuction(auctionContract);
    setCfo(result);
    // console.log(result, "coo address");
  };

  const getTPercentage = async () => {
    const result = await GetTessractPercentageAuction(auctionContract);
    setTPer(result);
    // console.log(result, "get t percenTAGE");
  };

  const getBalance = async () => {
    const result = await GetBalanceAuc(auctionContract);
    setBalance(result);
    // console.log(result, "get auction balance");
  };
  useEffect(() => {
    if (auctionContract) {
      getCOOAddr();
    }
  }, [auctionContract]);

  useEffect(() => {
    if (auctionContract) {
      getCFOAddr();
    }
  }, [auctionContract]);
  useEffect(() => {
    if (auctionContract) {
      getTPercentage();
    }
  }, [auctionContract]);
  useEffect(() => {
    if (auctionContract) {
      getBalance();
    }
  }, [auctionContract]);

  const SetCOO = async (Address) => {
    props.setLoading(true);
    try {
      const result = await setCOOAddrAuction(Address, walletProvider);
      // console.log(result, "set coo");
      getCOOAddr();
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const SetCFO = async (Address) => {
    props.setLoading(true);
    try {
      const result = await setCFOAddrAuction(Address, walletProvider);
      // console.log(result, "set cFo");
      getCFOAddr();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const CreatePromo = async ({
    firstInput,
    secondInput,
    thirdInput,
    forthInput,
  }) => {
    props.setLoading(true);
    try {
      const result = await createPromoCodeAuc(
        firstInput,
        secondInput,
        thirdInput,
        forthInput,
        walletProvider,
        ssm
      );
      // console.log(result, "create promo");
      getPromoDetails(secondInput);

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const DeletePromo = async (Code) => {
    props.setLoading(true);
    try {
      const result = await deletePromoCodeAuc(Code, walletProvider, ssm);
      // console.log(result, "deletePromo");
      if (result.txreceipt?.blockHash) {
        setPromoDeleted(true);
      }

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const ResetPromo = async (Code) => {
    props.setLoading(true);
    try {
      const result = await resetPromoCodeAuc(Code, walletProvider);
      // console.log(result, "resetPromo");
      getPromoDetails1(Code);

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const SetTPercentage = async (Percentage) => {
    props.setLoading(true);
    try {
      const result = await setAuctionFeeTessPecentage(
        Percentage,
        walletProvider
      );
      // console.log(result, "set t percentage");
      getTPercentage();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const WithdrawBalance = async (Amount) => {
    props.setLoading(true);
    try {
      const result = await withdrawBalanceAuction(Amount, walletProvider);
      getBalance();
      // console.log(result, "withdraw auction");

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const getPromoDetails = async (Code) => {
    const result = await GetPromoCodeDetailsAuc(Code, walletProvider);
    // console.log(result, "promo details");
    setPromoDetails(result);
  };
  const getPromoDetails1 = async (Code) => {
    const result = await GetPromoCodeDetailsAuc(Code, walletProvider);
    // console.log(result, "promo details");
    setPromoDetails1(result);
  };

  // End API call

  const onArrowHandler = () => {
    props.handlerAction();
  };
  useEffect(() => {
    if (props.dropDown) {
      setDropDownClass("drop-down-open");
      setRotate("rotate");
    } else {
      setDropDownClass("drop-down-close");
      setRotate("");
    }
  }, [props.dropDown]);

  return (
    <div>
      {/* {loading && <LoadingSpinner />} */}

      {/* Start Navigation Bar  */}
      <div className="a-main-container">
        <div className="a-first-section">
          <ArrowIcon rotate={rotate} onClick={onArrowHandler} />
          <div className="a-title">Auction</div>
        </div>
        <div className="a-second-section">
          <div className="text-holder">
            {props.auctionPause ? "Pause" : "Unpause"}
          </div>
          {!props.auctionPause ? (
            <BasicButton onClick={Pause}>Pause</BasicButton>
          ) : (
            <BasicButton onClick={Unpause}>unpause</BasicButton>
          )}
        </div>
      </div>
      {/* End Navigation Bar  */}

      <div className={dropDownClass}>
        {/* <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getCOOAddr();
              }}
              textInfo="Get COO Auction address
          "
              formTitle="Get COO Auction address
          "
            />
          </div>
          <div className="sub">
            <h5>Current COO Auction address</h5>
            <p>
              Address: <span>{coo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            {" "}
            <FormSubmit
              onSubmit={() => {
                getCFOAddr();
              }}
              textInfo="Get CFO Auction address
          "
              formTitle="Get CFO Auction address
          "
            />
          </div>
          <div className="sub">
            <h5>Current CFO Auction address</h5>
            <p>
              Address: <span>{cfo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getTPercentage();
              }}
              textInfo="Check value that will be deposited in the smart contract every time a paid function is run"
              formTitle="Get Tesseract percentage input"
            />
          </div>
          <div className="sub">
            <h5>Current Tesseract Percentage input fee</h5>
            <p>
              Fee: <span>{tPer}</span>
            </p>
          </div>
        </div> */}
        <div className="section">
          <div className="main">
            {" "}
            <FormOneInput
              onSubmit={(ev) => {
                SetCOO(ev);
              }}
              textInfo="Set COO Auction address
          "
              formTitle="Set COO Auction address
          "
              label="Address"
            />
          </div>
          <div className="sub">
            <h5>Current COO Auction address</h5>
            <p>
              Address: <span>{coo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCFO(ev);
              }}
              textInfo="Set CFO Auction address
          "
              formTitle="Set CFO Auction address
          "
              label="Address"
            />
          </div>
          <div className="sub">
            <h5>Current CFO Auction address</h5>
            <p>
              Address: <span>{cfo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetTPercentage(ev);
              }}
              textInfo="Insert a value that will be deposited in the smart contract every time a paid function is run"
              formTitle="Set Tesseract percentage"
              label="New percentage"
            />
          </div>
          <div className="sub">
            <h5>Current Tesseract Percentage </h5>
            <p>
              Percentage: <span>{tPer}</span> %
            </p>
          </div>
        </div>
        {/* 4 - inputs*/}
        <div className="section">
          <div className="main">
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                CreatePromo(input4);
                setInput4({
                  firstInput: "",
                  secondInput: "",
                  thirdInput: "",
                  forthInput: "",
                });
              }}
            >
              <TitleForm text="Create promo code to avail discounts for purchase of cards in the Auction house.  Input % value for iscount and the number of times the promo can be used.">
                Create Promo
              </TitleForm>
              <div className="input-content-start">
                <InputForm
                  value={input4.firstInput}
                  onChange={(ev) => {
                    setInput4({ ...input4, firstInput: ev });
                  }}
                  label="Promo name"
                />
                <InputForm
                  value={input4.secondInput}
                  onChange={(ev) => {
                    setInput4({ ...input4, secondInput: ev });
                  }}
                  label="Code"
                />
                <InputForm
                  value={input4.thirdInput}
                  onChange={(ev) => {
                    setInput4({ ...input4, thirdInput: ev });
                  }}
                  label="Discount"
                />
                <InputForm
                  value={input4.forthInput}
                  onChange={(ev) => {
                    setInput4({ ...input4, forthInput: ev });
                  }}
                  label="Usage count"
                />
              </div>
              <BasicButton>Transact</BasicButton>
            </Form>
          </div>
          <div className="sub">
            <h5>New promo details</h5>
            <p>
              Promo name: <span>{promoDetails?.Name}</span>
            </p>
            <p>
              Promo code: <span>{promoDetails?.Code}</span>
            </p>
            <p>
              Value: <span>{promoDetails?.TotalValue?.toString()}</span>
            </p>
            <p>
              Usage: <span>{promoDetails?.UseCount?.toString()}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                DeletePromo(ev);
              }}
              textInfo="Delete existing promo code by typing in the code. Action will nullify further use of the promo code."
              formTitle="Delete Promo"
              label="Code"
            />
          </div>
          <div className="sub">
            {promoDeleted && <h5>Promo code deleted</h5>}
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                ResetPromo(ev);
              }}
              textInfo="Type in the promo code to reset its discount and usage value to default settings."
              formTitle="Reset promo value and count"
              label="Code"
            />
          </div>
          <div className="sub">
            <h5>Current promo details</h5>
            <p>
              Promo name: <span>{promoDetails1?.Name}</span>
            </p>
            <p>
              Promo code: <span>{promoDetails1?.Code}</span>
            </p>
            <p>
              Value: <span>{promoDetails1?.TotalValue?.toString()}</span>
            </p>
            <p>
              Usage: <span>{promoDetails1?.UseCount?.toString()}</span>
            </p>
          </div>
        </div>

        {/* <FormOneInput
          onSubmit={(ev) => {
           CancelAuction(ev)
          }}
          textInfo="Used to cancel auction for any card that’s been put up in the Auction house"
          formTitle="Cancel Auction"
          label="Token ID"
        /> */}
        {/* <FormSubmit
          onSubmit={() => {
            // getAllContractFn("ClearCheckUpkeepDatabase");
          }}
          textInfo="Clear previous check upkeep database"
          formTitle="Clear Check Upkeep database"
        /> */}
        {/* 3 - inputs*/}
        {/* <Form
          onSubmit={(event) => {
            event.preventDefault();
           CreateAuction(input3) 
            setInput3({ firstInput: "", secondInput: "", thirdInput: "" });
          }}
        >
          <TitleForm text="Used to create a new auction for any card. Enter token ID, starting price for auction and the duration of how long the auction will remain open">
            Create Auction
          </TitleForm>
          <div className="input-content-start">
            <InputForm
              value={input3.firstInput}
              onChange={(ev) => {
                setInput3({ ...input3, firstInput: ev });
              }}
              label="Token ID"
            />
            <InputForm
              value={input3.secondInput}
              onChange={(ev) => {
                setInput3({ ...input3, secondInput: ev });
              }}
              label="Start price"
            />
            <InputForm
              value={input3.thirdInput}
              onChange={(ev) => {
                setInput3({ ...input3, thirdInput: ev });
              }}
              label="Duration"
            />
          </div>
          <BasicButton>Transact</BasicButton>
        </Form> */}

        {/* <FormSubmit
          onSubmit={() => {
            // getAllContractFn("DeleteAucDatabase");
          }}
          textInfo="Remove previous database related to the Auction house"
          formTitle="Delete Auction database"
        /> */}

        {/* <FormTowInput
          onSubmit={(ev) => {
            // getAllAction2Input(ev, "GetAucDatabase");
          }}
          textInfo="Check auction database"
          formTitle="Get Auction database"
          firstLabel="Start index"
          secondLabel="End index"
        /> */}
        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllActionOneInput(ev, "performUpkeep");
          }}
          textInfo="Perform upkeep by entering value"
          formTitle="Perform upkeep"
          label="Input"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
        SellOut(ev)
          }}
          textInfo="Used to sell out a card that has been put in the auction for sale. Insert token ID to sell out the card at highest bidding price"
          formTitle="Sell Out"
          label="Token ID"
        /> */}
        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllActionOneInput(ev, "SetAucContractAddress");
          }}
          textInfo="Change or input the address for Auction smart contract on the blockchain"
          formTitle="Set Auction address"
          label="New address"
        /> */}

        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                WithdrawBalance(ev);
              }}
              textInfo="Withdraw money that has been deposited in the contract address related to the specific game function"
              formTitle="Withdraw balance"
              label="Amount"
            />
          </div>
          <div className="sub">
            <h5>Current balance in contract</h5>
            <p>
              Balance: <span>{balance}</span> Matic
            </p>
          </div>
        </div>
        {/* <FormSubmit
          onSubmit={() => {
            // getAllContractFn("Auc_ContractAdress");
          }}
          textInfo="Check the address for Auction smart contract on the blockchain"
          formTitle="Get Auction address"
        /> */}
        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllActionOneInput(ev, "checkUpkeep");
          }}
          textInfo="Perform check upkeep by entering value"
          formTitle="Check upkeep"
          label="Input"
        /> */}
        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllActionOneInput(ev, "checkUpKeepArray");
          }}
          textInfo="Perform check upkeep array by entering value"
          formTitle="Check upkeep array"
          label="Input"
        /> */}
        {/* <FormOneInput
          onSubmit={(ev) => {
            getAuction(ev)
          }}
          textInfo="Check data of card that has been put up for auction or is present in the auction house "
          formTitle="Get Auction data"
          label="Token ID"
        /> */}
        {/* <FormSubmit
          onSubmit={() => {
            // getAllContractFn("GetCheckUpKeepDatabase");
          }}
          textInfo="Get upkeep database by entering value"
          formTitle="Get check upkeep database"
        /> */}
        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllActionOneInput(ev, "GetCurrentPrice");
          }}
          textInfo="Check current price of a card that’s up for auction"
          formTitle="Get current price"
          label="Token ID"
        /> */}
        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllActionOneInput(ev, "GetUseCount");
          }}
          textInfo="Used to check the total number of times a promo code can be used"
          formTitle="Get usage count"
          label="Code"
        /> */}
        {/* <FormOneInput
          onSubmit={(ev) => {
           IsOnAuction(ev)
          }}
          textInfo="Check if a card is up for auction by entering relevant token ID"
          formTitle="Is on auction"
          label="Token ID"
        />
        */}
        {/* <FormOneInput
          onSubmit={(ev) => {
            GetPromo(ev)
          }}
          textInfo="Check all promo codes that are available for use in the Auction house"
          formTitle="Get Promo list"
          label="Code"
        /> */}
        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllActionOneInput(ev, "Record");
          }}
          textInfo="Get complete record of auction related information of any card by entering its token ID"
          formTitle="Get auction record"
          label="Input"
        /> */}
      </div>
    </div>
  );
}

export default Action;
