import React from 'react';
import Alicebanner from '../AuctionCollection/SubPages/AliceCard/Sections/Alicebanner';
import AliceText from './SubPages/AliceCard/Sections/AuctionAliceText';
import AliceList from './SubPages/AliceCard/Sections/AuctionAliceList';
import '../AuctionCollection/CardCollection.css';
import AuctionAliceList from './SubPages/AliceCard/Sections/AuctionAliceList';
import AuctionAliceText from './SubPages/AliceCard/Sections/AuctionAliceText';

function AuctionAliceCard () {
	return <div>
		{/* <Alicebanner/> */}
		<AuctionAliceList/>
		<AuctionAliceText/>

        
	</div>
}
export default AuctionAliceCard;