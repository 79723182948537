import React from "react";
import "./TermsOfUse.css";

const TermsOfUse = () => {
  return (
    <div className="terms">
      <h3 className="text-center">Terms and conditions</h3>
      <p>
        These terms and conditions (the "Terms and Conditions") govern the use
        of www.extremisverse.com (the "Site"). This Site is owned and operated
        by Tesseract Clothings Inc.. This Site is a nft gaming website. <br />
        By using this Site, you indicate that you have read and understand these
        Terms and Conditions and agree to abide by them at all times. <br />
        THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THAT
        IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT
        CAREFULLY.
        <br />
        <br />
        <b>
          <u>Intellectual Property</u>
        </b>{" "}
        <br />
        All content published and made available on our Site is the property of
        Tesseract Clothings Inc. and the Site's creators. This includes, but is
        not limited to images, text, logos, documents, downloadable files and
        anything that contributes to the composition of our Site. <br /> <br />
        <b>
          <u>Acceptable Use</u>
        </b>{" "}
        <br />
        As a user of our Site, you agree to use our Site legally, not to use our
        Site for illegal purposes, and not to: - Harass or mistreat other users
        of our Site; - Violate the rights of other users of our Site; - Violate
        the intellectual property rights of the Site owners or any third party
        to the Site; - Hack into the account of another user of the Site; - Act
        in any way that could be considered fraudulent; - Post any material that
        may be deemed inappropriate or offensive; or - Any form of disruption to
        our business operations, by any form or means..
        <br />
        If we believe you are using our Site illegally or in a manner that
        violates these Terms and Conditions, we reserve the right to limit,
        suspend or terminate your access to our Site. We also reserve the right
        to take any legal steps necessary to prevent you from accessing our
        Site. <br /> <br />
        <b>
          <u>Accounts</u>
        </b> <br />
        When you create an account on our Site, you agree to the following:{" "}
        <br />
        1. You are solely responsible for your account and the security and
        privacy of your account, including passwords or sensitive information
        attached to that account; and br] 2. All personal information you
        provide to us through your account is up to date, accurate, and truthful
        and that you will update your personal information if it changes. <br />
        We reserve the right to suspend or terminate your account if you are
        using our Site illegally or if you violate these Terms and Conditions.
        <br /> <br />
        <b>
          <u>Sale of Goods And Services</u>
        </b> <br />
        These Terms and Conditions govern the sale of goods and services
        available on our Site. <br />
        The following goods are available on our Site: <br />
        - NFTs. The following services are available on our Site: <br />
        - NFT operations. <br />
        The services will be paid for in full when the services are ordered.{" "}
        <br />
        These Terms and Conditions apply to all the goods and services that are
        displayed on our Site at the time you access it. This includes all
        products listed as being out of stock. All information, descriptions, or
        images that we provide about our goods and services are as accurate as
        possible. However, we are not legally bound by such information,
        descriptions, or images as we cannot guarantee the accuracy of all goods
        and services we provide. You agree to purchase goods and services from
        our Site at your own risk. <br />
        We reserve the right to modify, reject or cancel your order whenever it
        becomes necessary. If we cancel your order and have already processed
        your payment, we will give you a refund equal to the amount you paid.
        You agree that it is your responsibility to monitor your payment
        instrument to verify receipt of any refund. <br /> <br />
        <b>
          <u>Payments</u>
        </b> <br />
        We accept the following payment methods on our Site: <br />
        - Crypto Currency. <br />
        When you provide us with your payment information, you authorize our use
        of and access to the payment instrument you have chosen to use. By
        providing us with your payment information, you authorize us to charge
        the amount due to this payment instrument. <br />
        
        <br /> 
        <b>
          <u>Refunds</u>
        </b> <br />
        <u>Refunds for Goods</u>  <br />
        Refund requests must be made within 1 days after receipt of your
        goods. <br />
        We accept refund requests for goods sold on our Site for any of the
        following reasons: <br />
        - Technical issue that render their product or NFT unusable in our gaming
        environment. <br />
        Refunds do not apply to the following goods under normal circumstances: <br />
        - NFTs. <br />
        Refunds for Services <br />
        We provide refunds for services sold on our Site as follows: <br />
        - No refund. <br /> <br />
        <b>
          <u>Consumer Protection Law </u>
        </b>
        <br />
        Where the Consumer Product Warranty and Liability Act, or any other
        consumer protection legislation in your jurisdiction applies and cannot
        be excluded, these Terms and Conditions will not limit your legal rights
        and remedies under that legislation. These Terms and Conditions will be
        read subject to the mandatory provisions of that legislation. If there
        is a conflict between these Terms and Conditions and that legislation,
        the mandatory provisions of the legislation will apply. <br /><br />
        <b>
          <u>Limitation of Liability</u>
        </b><br />
        Tesseract Clothings Inc. and our directors, officers, agents, employees,
        subsidiaries, and affiliates will not be liable for any actions, claims,
        losses, damages, liabilities and expenses including legal fees from your
        use of the Site. <br /><br />
        <b>
          <u>Indemnity</u>
        </b>{" "}
        <br />
        Except where prohibited by law, by using this Site you indemnify and
        hold harmless Tesseract Clothings Inc. and our directors, officers,
        agents, employees, subsidiaries, and affiliates from any actions,
        claims, losses, damages, liabilities and expenses including legal fees
        arising out of your use of our Site or your violation of these Terms and
        Conditions. <br /><br />
        <b>
          <u>Applicable Law</u>
        </b>{" "}
        <br />
        These Terms and Conditions are governed by the laws of the Province of
        New Brunswick. <br /><br />
        <b>
          <u>Dispute Resolution</u>
        </b> <br />
        Subject to any exceptions specified in these Terms and Conditions, if
        you and Tesseract Clothings Inc. are unable to resolve any dispute
        through informal discussion, then you and Tesseract Clothings Inc. agree
        to submit the issue first before a non-binding mediator and to an
        arbitrator in the event that mediation fails. The decision of the
        arbitrator will be final and binding. Any mediator or arbitrator must be
        a neutral party acceptable to both you and Tesseract Clothings Inc. The
        costs of any mediation or arbitration will be paid by the unsuccessful
        party.
        <br />
        Notwithstanding any other provision in these Terms and Conditions, you
        and Tesseract Clothings Inc. agree that you both retain the right to
        bring an action in small claims court and to bring an action for
        injunctive relief or intellectual property infringement.
        <br /><br />
        <b>
          <u>Severability</u>
        </b><br />
        ©2002-2022 LawDepot.ca® <br />
        If at any time any of the provisions set forth in these Terms and
        Conditions are found to be inconsistent or invalid under applicable
        laws, those provisions will be deemed void and will be removed from
        these Terms and Conditions. All other provisions will not be affected by
        the removal and the rest of these Terms and Conditions will still be
        considered valid.
        <br /><br />
        <b>
          <u>Changes</u>
        </b><br />
        These Terms and Conditions may be amended from time to time in order to
        maintain compliance with the law and to reflect any changes to the way
        we operate our Site and the way we expect users to behave on our Site.
        We will notify users by email of changes to these Terms and Conditions
        or post a notice on our Site.
        <br /><br />
        <b>
          <u>Contact Details</u>
        </b><br />
        Please contact us if you have any questions or concerns. Our contact
        details are as follows: <br />
        (506) 898-2645 <br />
        CEO@tesseractclothings.com  or extremis@tesseractclothings.com<br />
        190 Adelaide Street, Suite#5 <br />
        You can also contact us through the feedback form available on our Site.{" "}
        <br /><br />
        <span >
          Effective Date: <br /> 23rd day of September, 2022
        </span>
      </p>
    </div>
  );
};

export default TermsOfUse;
