import { Auth } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { authContext } from "../../App";
import Loader from "../Shared/Loader/Loader";

export function RequireAuthPro({ children }) {
  const [authentication, setAuthentication] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    let getUser = async () => {
      try {
        let user = await Auth.currentAuthenticatedUser();
        const admin =
          user?.signInUserSession?.idToken?.payload["cognito:groups"];

        if (admin.includes("Admin")) {
          setAuthentication(true);

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }
    };
    getUser();
  }, [location]);

  if (isLoading) {
    return (
      <div>
        <Loader></Loader>
      </div>
    );
  }
  return authentication ? children : <Navigate to="/" />;
}
