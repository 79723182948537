import React, { useEffect, useState } from "react";
import ProfileForm from "./ProfileForm/ProfileForm";
import user from "../Images/user.png";
import { Auth } from "aws-amplify";
import * as AWS from "aws-sdk";
import { useContext } from "react";
import { addressContext, profilePicContext } from "../../../../../App";

function ProfileEdit() {
  const [accessKeyId, setAccessKey] = useState();
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [imageSrc,setImageSrc]=useContext(profilePicContext)

 const replaceImage = (error) => {
   //replacement of broken Image
   error.target.src = "/ErrorImage/avatar.png";
 };
  useEffect(() => {
    const cred = async () => {
      const auth = await Auth.currentCredentials();
      setAccessKey(auth);
    };
    cred();
  }, []);


  var loadFile = function (event) {
    var output = document.getElementById("output");
    output.src = URL.createObjectURL(event.target.files[0]);
    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };
    const Image = event.target.files[0];
   
    async function upload() {   
      const s3 = new AWS.S3({
        credentials: accessKeyId,
      });
      const uploadedImage = await s3
        .upload({
          Bucket: "extremis-user-profilepics/ProfilePicture",
          // dirName: "/",
          Key: currentAddress,
          Body: Image,
          ContentType: "image/*",
        })
        .promise();
      // console.log(uploadedImage,"photo");
      if (uploadedImage.Location) {
        window.location.reload();

      }
    }
    upload();
  };
  return (
    <div>
      <section className="profile_edit">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="edit_image">
                <h2>ACCOUNT SETTINGS</h2>

                <div className="edit_icon">
                  {/* <img src={user} id="output" alt="" /> */}
                  <img id="output" 
                        className="rounded-circle header-profile-user"
                        // src={`https://tokencharacterimages.s3.amazonaws.com/ProfilePicture/${currentAddress}`}
                        src={`data:image/jpeg;base64,${imageSrc}`}
                        alt="" onError={replaceImage}
                      />

                  <div class="image-upload">
                    <label for="file-input">
                      <i class="far fa-edit"></i>
                    </label>

                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={loadFile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProfileForm />
    </div>
  );
}
export default ProfileEdit;
