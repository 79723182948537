import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../aws-exports";
import Pagination from "../../../../Shared/Pagination";
import MarketPlaceCard from "../../../../Shared/MarketPlaceCard/MarketPlaceCard";
import { Link } from "react-router-dom";
let provider;
if (window.ethereum) {
  // console.log("window wallet connected....");
  provider = new ethers.providers.Web3Provider(window.ethereum);
} else {
  // prompt user to install metamask
}

const ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newContract",
        type: "address",
      },
    ],
    name: "ContractUpgrade",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "Pause",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "Unpause",
    type: "event",
  },
  {
    inputs: [],
    name: "Auc_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_cardIdArray",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "_numNewCards",
        type: "uint256",
      },
    ],
    name: "AutoCardMaker",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_fatherarray",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "_motherarray",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "_newCardNum",
        type: "uint256",
      },
    ],
    name: "AutoCardMakerHuman",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "BreedingAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Card_Maker_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Fixed_MarketPlace_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GameCentreAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GameCntrPaused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Gene_Mix_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "GetCardFraction",
    outputs: [
      {
        internalType: "string",
        name: "Frac",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "GetCardInfo",
    outputs: [
      {
        internalType: "string",
        name: "Name",
        type: "string",
      },
      {
        internalType: "string",
        name: "Rank",
        type: "string",
      },
      {
        internalType: "string",
        name: "Gender",
        type: "string",
      },
      {
        internalType: "uint64",
        name: "Last_Mating_Time",
        type: "uint64",
      },
      {
        internalType: "uint32",
        name: "Gen",
        type: "uint32",
      },
      {
        internalType: "uint16",
        name: "Battle_Points",
        type: "uint16",
      },
      {
        internalType: "bool",
        name: "isOnAuction",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "isOnWarZone",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "isBattleReady",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "isOnFixedPriceMarket",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "IsOnBreedList",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "isBreedReady",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "GetCardRank",
    outputs: [
      {
        internalType: "uint16",
        name: "bp",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "GetCardType",
    outputs: [
      {
        internalType: "string",
        name: "Type",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "GetGender",
    outputs: [
      {
        internalType: "string",
        name: "result",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "GetRarityCodeVal",
    outputs: [
      {
        internalType: "uint64[20]",
        name: "",
        type: "uint64[20]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "GetStats",
    outputs: [
      {
        internalType: "uint256",
        name: "IQ",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "EQ",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "Wisdom",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "MeleeAttck",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "WeaponSkill",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "DeffSkill",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "LeadershipSkill",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "NaturalAdpt",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "FuturePotential",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "GetWeaponCode",
    outputs: [
      {
        internalType: "uint64",
        name: "WeaponGene",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "array",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "_startPrice",
        type: "uint256",
      },
      {
        internalType: "uint64",
        name: "_duration",
        type: "uint64",
      },
    ],
    name: "PutMultiCardAuc",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "RandomNumbers",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "RandomNumbers2",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetAucContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_NewAdd",
        type: "address",
      },
    ],
    name: "SetCardMakerContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetGene_Mix_ContractAdress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetWarContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "WarZonechecker",
    outputs: [
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "War_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "battlelistchecker",
    outputs: [
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ceoAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cfoAddress",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cooAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "deleteRandomArray",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "fee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "num",
        type: "uint256",
      },
    ],
    name: "getRandomNumber",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "pauseGameCentre",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "requestId",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "randomness",
        type: "uint256",
      },
    ],
    name: "rawFulfillRandomness",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setBreedingAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newCEO",
        type: "address",
      },
    ],
    name: "setCEO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_newCFO",
        type: "address",
      },
    ],
    name: "setCFO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newCOO",
        type: "address",
      },
    ],
    name: "setCOO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setFixedMarketAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setGameCntrAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_addr",
        type: "address",
      },
    ],
    name: "setLinkTokenAddr",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_fee",
        type: "uint256",
      },
    ],
    name: "setRandomNumGenfee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_addr",
        type: "address",
      },
    ],
    name: "setVRFCordinatorAddr",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_keyHash",
        type: "bytes32",
      },
    ],
    name: "setkeyHash",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpauseGameCentre",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "withdrawLink",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
const address = process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR;
const GameCentreContract = new ethers.Contract(address, ABI, provider);

async function getGeneralDetails(TokenId) {
  const Value = await GameCentreContract.GetCardType(TokenId);

  return Value;
}

function MarketGenesis() {
  const [TokenId, setTokenId] = useState([]);
  const [TokenArray, setTokenArray] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_PURCHASE_TABLE_NAME,
    Limit: 30,

    ProjectionExpression: "TokenId",
  });

  async function GetTokenId() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    // console.log(param);
    const Id = [];
    try {
      const Response = await documentClient.scan(param).promise();
      if (Response.LastEvaluatedKey) {
        setParam({
          TableName: process.env.REACT_APP_PURCHASE_TABLE_NAME,
          Limit: 30,

          ExclusiveStartKey: Response.LastEvaluatedKey,
          ProjectionExpression: "TokenId",
        });
      }
      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        const type = await getGeneralDetails(element.TokenId);
        // console.log(type);
        if (type === "Science Br.") {
          if (TokenId.indexOf(element.TokenId) === -1) {
            Id.push(element.TokenId);
            setNext(true);
          } else {
            setNext(false);
          }
        }
      }
    } catch (err) {
      // console.log(err);
    }
    return Id;
  }

  const Next = async () => {
    if (page === TokenArray.length) {
      const Id = await GetTokenId();
      if (Id.length === 0) {
        setNext(false);
      } else {
        setNext(true);
        setTokenId((TokenId) => [...TokenId, ...Id]);
        setTokenArray((TokenArray) => [...TokenArray, Id]);
        setPage((page) => page + 1);
      }
    } else {
      setPage((page) => page + 1);
    }
  };
  const Previous = () => {
    setNext(true);
    setPage((page) => page - 1);
  };
  useEffect(() => {
    const TokenArr = async () => {
      const Id = await GetTokenId();
      setTokenId((TokenId) => [...TokenId, ...Id]);
      setTokenArray((TokenArray) => [...TokenArray, Id]);
    };
    TokenArr();
  }, []);
  return (
    <div>
       <section className="cards_menu_list mt-3">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <div className="nav_menu">
            <ul>
                  <li className="nav-item">
                     <Link className="nav-link" to="/marketplace">
                     ALL CARDS</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link" to="/marketplace/blacknet">
                     BLACKNET</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link " to="/marketplace/alice">
                     ALICE</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link" to="/marketplace/humanity">
                     HUMANITY</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link active" to="/marketplace/genesis">
                     GENESIS</Link>
                  </li>
                  </ul>
            </div>
         </div>
      </div>
   </div>
</section>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="content_box">
            <h4>GENESIS</h4>
              <div className="row">
                {TokenArray[page - 1]?.map((TokenId) => (
                  <MarketPlaceCard TokenId={TokenId} key={TokenId}></MarketPlaceCard>
                ))}
              </div>
              <Pagination
                page={page}
                setPage={setPage}
                length={TokenArray.length}
                Previous={Previous}
                Next={Next}
                next={next}
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MarketGenesis;
