import React from 'react';
import MutateAllcardsList from './Sections/MutateAllcardsList';
import AllcardsList from './Sections/MutateAllcardsList';
import MutateAllCardsText from './Sections/MutateAllcardsText';
import AllCardsText from './Sections/MutateAllcardsText';

const MutateAllCard = () => {
    return (
        <div>
            <MutateAllcardsList></MutateAllcardsList>
            <MutateAllCardsText></MutateAllCardsText>
        </div>
    );
};

export default MutateAllCard;