import React, { useEffect, useState } from "react";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../aws-exports";
import Pagination from "../../../../../Shared/Pagination";
// import AuctionCard from "../../../../../Shared/AuctionCard/AuctionCard";
import SellCard from "../../../../../Shared/SellCard/SellCard";
import useGameCentreContract from "../../../../../Hooks/GameCentreHook";
import {  GetCardType } from "../../../../../UserPage/BlockChainFunctions";
import useGetAllCardByType from "../../../../../Hooks/useGetAllCardByType";
import Loader from "../../../../../Shared/Loader/Loader";

function AuctionSellGenesisText() {
  const [TokenId,page,setPage,next,Next,Previous,loader]=useGetAllCardByType("Genesis")

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
          {
              loader?
              <Loader></Loader>:     <div className="content_box">
              <h4>GENESIS</h4>

              <div className="row">
              {TokenId.slice((page - 1) * 10, page * 10)?.map((TokenId) => (
                  <SellCard TokenId={TokenId} key={TokenId}>auction</SellCard>
                ))}
                 {TokenId.length === 0 && <h1>NO CARD</h1>}
              </div>
              <Pagination
                page={page}
                setPage={setPage}
                length={TokenId.length}
                Previous={Previous}
                Next={Next}
                next={next}
              ></Pagination>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
export default AuctionSellGenesisText;
