import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSellCard from "../../../Shared/SellCard/useSellCard";
import { transferCard } from "../../../UserPage/BlockChainFunctions";
import TransferCardsBanner from "./Sections/TransferCardsBanner/TransferCardsBanner";
import * as AWS from "aws-sdk";
import {
  addressContext,
  navigationContext,
  providerContext,
} from "../../../../App";
import useStats from "../../../Shared/useStates";
import useCustomerInfo from "../../../Hooks/useCustomerInfo";
import PopUp from "../../../Shared/PopUp";
import LoaderFloating from "../../../Shared/LoaderFloating";
import useRankImage from "../../../Shared/useRankImage";
import useColor from "../../../Shared/useColor";

const TransferIndivisual = () => {
  const ssm = new AWS.SSM({ region: "us-east-1" });

  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);

  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [loading, setLoading] = useState();
  const TokenId = useParams();
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);
  const [navConfirm, setNavConfirm] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  const [CardFaction, CardName, CardType, CardRank, CardGender] = useSellCard(
    TokenId.TokenId
  );
  const [popUpText1, setPopUpText1] = useState("");

  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(TokenId.TokenId);
  const rankImage = useRankImage(CardRank);
  const color = useColor(CardRank, CardType);

  const Transfer = async (e) => {
    e.preventDefault();
    setLoading(true);
    setPopUpSubject("")
    setPopUpText("")
    const From = currentAddress;
    const To = e.target.to.value;
    // console.log(TokenId, From, To);
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      const result = await transferCard(
        From,
        To,
        TokenId.TokenId,
        walletProvider,
        ssm
      );
      // console.log(result);
      setLoading(false);
      if (result) {
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.txreceipt?.blockHash) {
          setNavConfirm(true);
          setPopUpSubject(
            `You have successfully transferred the card to ${To}`
          );
          // setPopUpText();
          setPopUpText(`          
      Transaction Hash: ${result.txreceipt.transactionHash}                              
          `);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
        }

        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      }
    } else {
      setLoading(false);
      setPopUp(true);
      // setPopUpSubject("Oops");
      setPopUpSubject("Connect your registered wallet ");
    }
  };
  useEffect(() => {
    if (navigationCntxt === true && navConfirm === true) {
      navigate("/transfer-single-card");
      window.location.reload();
    }
  }, [navigationCntxt,navConfirm]);
  return (
    <div>
      <TransferCardsBanner></TransferCardsBanner>
      <section className="sell_card_text word-wrapping">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sell_card_heading">
                <h2>TRANSFER CARD</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="sell_card_succ_img card_img">
                <img
                  src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${TokenId.TokenId}`}
                  alt=""
                  className={color}
                />
                <img
                  className="rankImage"
                  src={`/Rank/${rankImage}.png`}
                  alt=""
                />{" "}
                <img
                  className="extremis-logo"
                  src="/LOGO.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="container">
                <div className="transfer-card-body">
                  <div className="sell_card_succ_info">
                    <h4>{CardName}</h4>
                    <h5>{CardFaction}</h5>
                    <div class="sell_card_list_box">
                      <ul>
                        <li>
                          <span>ID:</span> <strong>{TokenId.TokenId}</strong>
                        </li>
                        {/* <li>
                      <span>Faction:</span> <strong>{CardFaction}</strong>
                    </li> */}
                        <li>
                          <span>Rank: </span> {CardRank}
                        </li>
                        <li>
                          <span>Type: </span>
                          {CardType}
                        </li>
                        <li>
                          <span>IQ: </span>
                          {IQ}
                        </li>
                        <li>
                          <span>Potential: </span> {Potential}
                        </li>
                        <li>
                          <span>EQ: </span> {EQ}
                        </li>
                        <li>
                          <span>Wisdom: </span>
                          {Wisdom}
                        </li>
                        <li>
                          <span>Melee: </span> {Melee}
                        </li>
                        <li>
                          <span>Leadership: </span>
                          {Leadership}
                        </li>
                        <li>
                          <span>Weapon: </span>
                          {Weapon}
                        </li>
                        <li>
                          <span>Defense: </span> {Defense}
                        </li>
                        {CardFaction === "Humanity" ? (
                          <li>
                            <span>N. Adaptability: </span>
                            {NaturalAdpt}
                          </li>
                        ) : (
                          <li>
                            <span>AI Influence: </span>
                            {NaturalAdpt}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <h5>Transfer singular card</h5>
                  <form onSubmit={Transfer}>
                    <div className="transfer-card-input">
                      <div>
                        <p>Token ID</p>

                        <input
                          type="text"
                          name="token"
                          value={TokenId.TokenId}
                          id=""
                          required
                        />
                      </div>
                      {/* <div>
                        <p>From address</p>
                        <input type="text" name="from" id="" required/>
                      </div> */}
                      <div>
                        <p>To address</p>
                        <input
                          type="text"
                          name="to"
                          id=""
                          required
                          placeholder="Ex: 0x64E5274cfe9e4..."
                        />
                      </div>
                    </div>
                    <div className="transfer-card-button">
                      <button className="btn" type="submit">
                        {!loading ? " CONFIRM" : "PLEASE WAIT..."}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {popup && (
              <PopUp
                popUpSubject={popUpSubject}
                popUpText={popUpText}
                popUpText1={popUpText1}
                setPopUp={setPopUp}
              ></PopUp>
            )}
            {loading && <LoaderFloating></LoaderFloating>}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TransferIndivisual;
