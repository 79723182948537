import React from 'react';
// import Humanitybanner from '../Cardcollection/SubPages/Humanity/Sections/Humanitybanner';
import HumanityText from './SubPages/Humanity/Sections/MarketPlaceHumanityText';
import HumanityList from './SubPages/Humanity/Sections/MarketPlaceHumanityList';
import '../MarketPlaceCollection/CardCollection.css';
import MarketPlaceHumanityList from './SubPages/Humanity/Sections/MarketPlaceHumanityList';
import MarketPlaceHumanityText from './SubPages/Humanity/Sections/MarketPlaceHumanityText';

function MarketHumanityCard () {
	return <div>
		{/* <Humanitybanner/> */}
		<MarketPlaceHumanityList/>
		<MarketPlaceHumanityText/>

        
	</div>
}
export default MarketHumanityCard;