import React from "react";
import PlayBanner from "./PlayBanner";
import './Play.css'
import PlayContainer from "./PlayContainer";
const Play = () => {
  return (
    <div>
      <PlayBanner></PlayBanner>
      <PlayContainer></PlayContainer>

    </div>
  );
};

export default Play;
