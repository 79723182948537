import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "./SalesRecord.css";
import SalesRecordBanner from "./Sections/SalesRecordBanner/SalesRecordBanner";
import { useNavigate } from "react-router-dom";
const SalesRecord = () => {
  return (
    <div className="sales-record">
      <SalesRecordBanner></SalesRecordBanner>
      <h1>Card sales record</h1>

      <Outlet></Outlet>
    </div>
  );
};

export default SalesRecord;
