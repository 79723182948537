import React from 'react';
// import Blacknetbanner from '../Cardcollection/SubPages/Blacknet/Sections/Blacknetbanner';
import BlacknetText from './SubPages/Blacknet/Sections/MarketPlaceBlacknetText';
import BlacknetList from './SubPages/Blacknet/Sections/MarketPlaceBlacknetList';
import '../MarketPlaceCollection/CardCollection.css';
import MarketPlaceBlacknetList from './SubPages/Blacknet/Sections/MarketPlaceBlacknetList';
import MarketPlaceBlacknetText from './SubPages/Blacknet/Sections/MarketPlaceBlacknetText';

function MarketBlacknetCard () {
	return <div>
		{/* <Blacknetbanner/> */}
		<MarketPlaceBlacknetList/>
		<MarketPlaceBlacknetText/>

        
	</div>
}
export default MarketBlacknetCard;