import React, { useEffect } from "react";
import IndividualPurchase from "../PurchaseCards/MarketPlace/PurchaseIndividual/Sections/IndividualPurchase";
import CurrentStatus from "../PurchaseCards/MarketPlace/PurchaseIndividual/Sections/CurrentStatus";
import "../PurchaseCards/PurchaseCards.css";
import { useParams } from "react-router-dom";
// import CardDetails from "./MarketPlace/PurchaseIndividual/Sections/CardDetails";
import FeaturesCard from "../Profile/Pages/IndividualCard/Sections/FeaturesCard";
import CardDetails from "../Profile/Pages/IndividualCard/Sections/CardDetails";
import useCard from "../Shared/Card/useCard";
import { useState } from "react";
import { IsOnFixedMarketList } from "../UserPage/BlockChainFunctions";
import useFixedMarketContract from "../Hooks/FixedMarketHook";
function PurchaseIndividual() {
  const [isOnBreed, setIsOnBreed] = useState(false);
  const [isOnBattle, setIsOnbattle] = useState(false);
  const [isOnWar, setIsOnWar] = useState(false);
  const [isOnAuction, setIsOnAuction] = useState(false);
  const [isOnFixed, setIsOnFixed] = useState(false);
  
  const [fixedMarketContract, setFixedMarketContract] =
    useFixedMarketContract();
  const TokenId = useParams();
  // console.log("tok", TokenId);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardOwner,
    CardOwnerUsername,
  ] = useCard(TokenId.TokenId);
  const IsOnFixed = async () => {
    const result = await IsOnFixedMarketList(
      TokenId.TokenId,
      fixedMarketContract
    );
    setIsOnFixed(result);
  };
  
  useEffect(() => {
    if (fixedMarketContract) {
      IsOnFixed();
    }
  }, [fixedMarketContract]);

  return (
    <div>
      <IndividualPurchase />
      <CardDetails
       TokenId={TokenId.TokenId}
       isOnBreed={isOnBreed}
       isOnBattle={isOnBattle}
       isOnWar={isOnWar}
       isOnAuction={isOnAuction}
       isOnFixed={isOnFixed}
      />
      <CurrentStatus TokenId={TokenId.TokenId} CardOwner={CardOwner}/>
      <FeaturesCard TokenId={TokenId.TokenId} />
    </div>
  );
}
export default PurchaseIndividual;
