import React, { useEffect, useState } from "react";
import useGameCentreContract from "../../Hooks/GameCentreHook";
import { GetCardFaction, GetCardInfo, GetCardType, GetOwnerOf } from "../../UserPage/BlockChainFunctions";
// async function getGeneralDetails(
//   gameCentreContract,
//   TokenId
// ) {
//   // console.log(TokenId, "hook");
//   try {
//     const Faction = await gameCentreContract.GetCardFraction(TokenId);
//     const Value = await gameCentreContract.GetCardInfo(TokenId);
//     const Type = await gameCentreContract.GetCardType(TokenId);
//     const Gender = await gameCentreContract.GetGender(TokenId);
//     const Result = {
//       faction: Faction,
//       rank: Value.Rank,
//       type: Type,
//       name: Value.Name,
//       gender: Gender,
//     };

//     return Result;
//   } catch (err) {
//     console.log(err, "usecard");
//   }
// }

const useSellCard = (TokenId) => {
  // console.log(TokenId,"sell");
  const [CardName, setCardName] = useState("");
  const [CardFaction, setCardFaction] = useState("");
  const [CardType, setCardType] = useState("");
  const [CardRank, setCardRank] = useState("");
  const [CardGender, setCardGender] = useState("");

  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  


  // useEffect(() => {
  //   async function CardDetails() {
  //     const GeneralCardDetails = await getGeneralDetails(
  //       gameCentreContract,
       
  //       TokenId
  //     );

  //     setCardName(GeneralCardDetails.name);
  //     setCardFaction(GeneralCardDetails.faction);
  //     setCardType(GeneralCardDetails.type);
  //     setCardRank(GeneralCardDetails.rank);
  //     setCardGender(GeneralCardDetails.gender);
  //   }
  //   CardDetails();
  // }, [TokenId, gameCentreContract]);

 

  if (TokenId && gameCentreContract) {
    const Info = async () => {
      const faction = await GetCardFaction(TokenId, gameCentreContract);
      const info = await GetCardInfo(TokenId, gameCentreContract);
      const type = await GetCardType(TokenId, gameCentreContract);
      setCardFaction(faction);
      setCardName(info?.Name);
      setCardRank(info?.Rank);
      setCardGender(info?.Gender)
      setCardType(type);
    };
    Info();
  }
 

  return [CardFaction, CardName, CardType, CardRank, CardGender];
};

export default useSellCard;
