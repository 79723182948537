import React, { useContext, useState } from "react";
import blacknet_logo from "../Images/blacknet_logo.png";
import humanity_logo from "../Images/humanity_logo.png";
import collect_cards from "../Images/collect_cards.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import WarConfirmCard from "../../../../../../Shared/WarCard/WarConfirmCard";
import awsmobile from "../../../../../../../aws-exports";

import {
  addToWarZone,
  addToWarZoneMulti,
  GetCardFaction,
  GetOwnerOf,
  InitiateWar,
} from "../../../../../../UserPage/BlockChainFunctions";
import {
  addressContext,
  navigationContext,
  providerContext,
} from "../../../../../../../App";
import useWarContract from "../../../../../../Hooks/WarContractHook";
import * as AWS from "aws-sdk";
import useCustomerInfo from "../../../../../../Hooks/useCustomerInfo";
import PopUp from "../../../../../../Shared/PopUp";
import useGameCentreContract from "../../../../../../Hooks/GameCentreHook";
import useCardOwnerShipContract from "../../../../../../Hooks/CardOwnershipHook";
import LoaderFloating from "../../../../../../Shared/LoaderFloating";
import { useEffect } from "react";
function ConfirmationText() {
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);
  const [navConfirm,setNavConfirm]=useState(false)

  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [warContract, setWarContract] = useWarContract();
  const ssm = new AWS.SSM();
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const location = useLocation();
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [cardOwnerShipContract, setCardOwnerShipContract] =
    useCardOwnerShipContract();
  const [warTokenId, setWarTokenId] = useState([]);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [popUpText1, setPopUpText1] = useState("");
  const [loading, setLoading] = useState();
  const [Token1, setToken1] = useState([]);
  const [Token2, setToken2] = useState([]);
  const [Add1, setAdd1] = useState([]);
  const [Add2, setAdd2] = useState([]);
  const [battleId, setBattleId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state.warCard) {
      const Id = location.state.warCard;
      let warToken = [...new Set(Id)];
      setWarTokenId((warTokenId) => [...warTokenId, ...warToken]);
    }
  }, [location]);

  useEffect(() => {
    const BattleId = async () => {
      const Id = await GetBattleId();
      setBattleId((parseInt(Id) + 1).toString());
    };
    BattleId();
  }, []);


  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_BATTLE_COUNTER_TABLE_NAME,
    FilterExpression: "contains(BattleFieldID,:fName)",
    ExpressionAttributeValues: {
      ":fName": "Counter",
    },
  });

  async function GetBattleId() {
    // console.log(param, "inside function");
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    try {
      const Response = await documentClient.scan(param).promise();
      // console.log(Response, "response");
      return Response.Items[0].Value;
    } catch (err) {
      // console.log(err);
    }
    // return Id;
  }
  async function PutId(props) {
    // console.log(props, "props ");
    const Id = props;
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    const param = {
      TableName: process.env.REACT_APP_BATTLE_COUNTER_TABLE_NAME,
      Item: {
        BattleFieldID: "Counter",
        Value: Id,
      },
    };
    try {
      // console.log(param, "param");
      const Response = await documentClient.put(param).promise();
      return Response;
    } catch (err) {
      // console.log(err);
    }
  }
  const PutWar = async (props) => {
    const Id = props;
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    const param = {
      TableName: process.env.REACT_APP_BATTLE_COUNTER_TABLE_NAME,
      Item: {
        BattleFieldID: Id,
        Value: location.state.battleFaction,
      },
    };
    try {
      // console.log(param, "param");
      const Response = await documentClient.put(param).promise();
      return Response;
    } catch (err) {
      // console.log(err);
    }
  };
  // console.log(location.state.Id);
  const AddToWar = async () => {
    setLoading(true);
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      if (location.state.Id) {
        if (warTokenId.length === 1) {
          // try {
          const result = await addToWarZone(
            warTokenId[0],
            location.state.Id,
            walletProvider,
            warContract,
            ssm
          );
          // console.log(result, "single war");
          setLoading(false);
          if (result) {
            if (result?.error?.code) {
              setPopUpSubject("Oops");
              setPopUpText(result.message);
              setPopUp(true);
            }
            if (result.txreceipt?.blockHash) {
              setNavConfirm(true)
              setPopUpSubject("You've successfully added the card");
              setPopUpText(`          
Transaction Hash: ${result.txreceipt.transactionHash}                              
   `);
              setPopUpText1(` Aws Response :${result.Awsresponse}`);
              setPopUp(true);
            }
            if (result.data?.message) {
              setPopUpSubject("Oops");
              setPopUpText(result.data.message);
              setPopUp(true);
            }
          }
          // } catch (error) {
          //   console.log(error);
          //   setLoading(false);
          //   setPopUpSubject("Insufficient funds")
          //   setPopUp(true);
          // }
        } else {
          // try {
          const result = await addToWarZoneMulti(
            warTokenId,
            location.state.Id,
            walletProvider,
            warContract,
            ssm
          );
          // console.log(result, "multiple war");
          setLoading(false);
          if (result) {
            if (result?.error?.code) {
              setPopUpSubject("Oops");
              setPopUpText(result.message);
              setPopUp(true);
            }
            if (result.txreceipt?.blockHash) {
              setNavConfirm(true)
              setPopUpSubject("You've successfully added the cards");
              setPopUpText(`          
      Transaction Hash: ${result.txreceipt.transactionHash}                              
           `);
              setPopUpText1(` Aws Response :${result.Awsresponse}`);
              setPopUp(true);
            }
            if (result.data?.message) {
              setPopUpSubject("Oops");
              setPopUpText(result.data.message);
              setPopUp(true);
            }
          }
          // } catch (error) {
          //   console.log(error);
          //   setLoading(false);
          //   setPopUpSubject("Insufficient funds")
          //   setPopUp(true);
          // }
        }
      } else {
        const Id = await GetBattleId();
        // console.log(Id, "getbattle");
        const props = (parseInt(Id) + 1).toString();

        // console.log(response, "war data");
        // if (result) {
        if (warTokenId.length === 1) {
          // console.log(typeof props, "single war checking");
          // try {
          const result = await addToWarZone(
            warTokenId[0],
            props,
            walletProvider,
            warContract,
            ssm
          );
          // console.log(result, "single war");
          setLoading(false);
          if (result) {
            if (result?.error?.code) {
              setPopUpSubject("Oops");
              setPopUpText(result.message);
              setPopUp(true);
            }
            if (result.txreceipt?.blockHash) {
              setNavConfirm(true)
              setLoading(true);
              const responseId = await PutId(props);
              const responseWar = await PutWar(props);
              setLoading(false);
              setPopUpSubject("You've successfully added the card");
              setPopUpText(`          
          Transaction Hash: ${result.txreceipt.transactionHash}                              
               `);
              setPopUpText1(` Aws Response :${result.Awsresponse}`);
              setPopUp(true);
            }
            if (result.data?.message) {
              setPopUpSubject("Oops");
              setPopUpText(result.data.message);
              setPopUp(true);
            }
          }
          // } catch (error) {
          //   console.log(error);
          //   setLoading(false);
          //   setPopUpSubject("Insufficient funds")
          //   setPopUp(true);
          // }
        } else {
          // console.log("multiple war checking");
          // try {
          const result = await addToWarZoneMulti(
            warTokenId,
            props,
            walletProvider,
            warContract,
            ssm
          );
          // console.log(result, "multiple war");
          setLoading(false);
          if (result) {
            if (result?.error?.code) {
              setPopUpSubject("Oops");
              setPopUpText(result.message);
              setPopUp(true);
            }
            if (result.txreceipt?.blockHash) {
              setNavConfirm(true)
              setLoading(true);
              const responseId = await PutId(props);
              const responseWar = await PutWar(props);
              setLoading(false);
              setPopUpSubject("You've successfully added the cards");
              setPopUpText(`          
        Transaction Hash: ${result.txreceipt.transactionHash}                              
             `);
              setPopUpText1(` Aws Response :${result.Awsresponse}`);
              setPopUp(true);
            }
            if (result.data?.message) {
              setPopUpSubject("Oops");
              setPopUpText(result.data.message);
              setPopUp(true);
            }
          }
          // } catch (error) {
          //   console.log(error);
          //   setLoading(false);
          //   setPopUpSubject("Insufficient funds")
          //   setPopUp(true);
          // }
        }
        // }
        // }
      }
    } else {
      setLoading(false);
      setPopUp(true);
      // setPopUpSubject("Oops");
      setPopUpSubject("Connect your registered wallet ");
    }
  };
  useEffect(() => {
    if (navigationCntxt === true && navConfirm===true) {
      navigate("/war-zone");
      window.location.reload()

    }
  }, [navigationCntxt,navConfirm]);
  return (
    <div>
      <section className="war_confirmation">
        <div className="container d-flex align-items-center flex-column">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="confirmation_heading text-center">
                {!location?.state?.Id ? (
                  <h2 className="text-center fs-1">
                    New Battlefield {battleId}
                  </h2>
                ) : (
                  <h2 className="text-center fs-1">
                    Battlefield {location.state.Id}
                  </h2>
                )}
                <ul>
                  <li>
                    <img src={blacknet_logo} />
                  </li>
                  <li>
                    <div className="confirmation_vs">
                      <strong className="align-middle">VS</strong>
                    </div>
                  </li>
                  <li>
                    <img src={humanity_logo} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="war-confirm-container">
            {warTokenId.map((TokenId) => (
              <WarConfirmCard TokenId={TokenId} key={TokenId}></WarConfirmCard>
            ))}
          </div>
          {warTokenId.length > 0 && (
            <button className="war-button" onClick={AddToWar}>
              {!loading ? " CONFIRM SELECTION" : "PLEASE WAIT..."}
            </button>
          )}
        </div>
      </section>
      {popup && (
        <PopUp
          popUpSubject={popUpSubject}
          popUpText={popUpText}
          setPopUp={setPopUp}
          popUpText1={popUpText1}
        ></PopUp>
      )}
      {loading && <LoaderFloating></LoaderFloating>}
    </div>
  );
}
export default ConfirmationText;
