import React from "react";
import { Link } from "react-router-dom";

const PromoAuctionList = () => {
  return (
    <div className="">
      <div className="col-md-12">
        <div className="nav_menu ">
          <ul className="promo-list">
            <li className="nav-item">
              <Link className="nav-link" to="/promoCode">
                FIXED MARKETPLACE
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/promoCode/auction">
                AUCTION
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PromoAuctionList;
