import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";

export const putDataCustomerInfoTbl = async (
  tableName,
  formData,
  AuthUserCreds
) => {
  // console.log(tableName, formData, AuthUserCreds);
  AWS.config.update({
    credentials: AuthUserCreds,
    region: awsmobile.aws_cognito_region,
  });

  const documentClient = new AWS.DynamoDB.DocumentClient({
    region: awsmobile.aws_cognito_region,
  });

  const params = {
    TableName: tableName,
    Item: {
      CustomerID: formData.wallet,
      Email: formData.email,
      Firstname: formData.firstName,
      Lastname: formData.lastName,
      Phone: formData.phoneNumber,
      Username: formData.userName.toUpperCase(),
      BirthDate: `${formData.day}/${formData.month}/${formData.year}`,
      Time: Date.now()
    },
  };
  // const params = JSON.stringify(data);
  // console.log(params);
  try {
    const response = await documentClient.put(params).promise();
    return response;
  } catch (err) {
    // console.log(err);
  }
};

