import React from 'react';
import Alicebanner from '../MarketPlaceCollection/SubPages/AliceCard/Sections/Alicebanner';
import AliceText from './SubPages/AliceCard/Sections/MarketPlaceAliceText';
import AliceList from './SubPages/AliceCard/Sections/MarketPlaceAliceList';
import '../MarketPlaceCollection/CardCollection.css';
import MarketPlaceAliceList from './SubPages/AliceCard/Sections/MarketPlaceAliceList';
import MarketPlaceAliceText from './SubPages/AliceCard/Sections/MarketPlaceAliceText';

function MarketAliceCard () {
	return <div>
		{/* <Alicebanner/> */}
		<MarketPlaceAliceList/>
		<MarketPlaceAliceText/>

        
	</div>
}
export default MarketAliceCard;