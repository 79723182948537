import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addressContext, navigationContext } from "../../../../../App";
import useAuctionContract from "../../../../Hooks/AuctionContractHook";
import { GetAuction, sellOut } from "../../../../UserPage/BlockChainFunctions";
import { providerContext } from "../../../../../App";
import * as AWS from "aws-sdk";
import PopUp from "../../../../Shared/PopUp";
import LoaderFloating from "../../../../Shared/LoaderFloating";

function CurrentStatus(props) {
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);
  const [navConfirm, setNavConfirm] = useState(false);
  const [price, setPrice] = useState();
  const [time, setTime] = useState();
  const [remainingTimes, setRemainingTimes] = useState();
  const [auctionContract, setAuctionContract] = useAuctionContract();
  const { ethers } = require("ethers");
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [selfCard, setSelfCard] = useState(false);
  const [seller, setSeller] = useState();
  const ssm = new AWS.SSM();
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText1, setPopUpText1] = useState();

  const [popUpText, setPopUpText] = useState("");
  const [loading, setLoading] = useState();

  const [highest, setHighest] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const Auction = async () => {
      const auction = await GetAuction(props.TokenId, auctionContract);
      // console.log(seller);
      setSeller(auction.seller);
      setTime(
        new Date(auction?.startedAt.toString() * 1000).toLocaleString("en-US")
      );
      setPrice(ethers.utils.formatEther(auction?.startingPrice));
      setHighest(ethers.utils.formatEther(auction?.HighestBidPrice));
      setInterval(() => {
        const duration = auction?.duration.toString();
        const Ending = new Date(
          auction?.startedAt.toString() * 1000 + duration * 1000
        );
        const Now = new Date();
        const remainingTime = Ending - Now;
        if (remainingTime >= 0) {
          const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

          setRemainingTimes(`${days}D : ${hours}H : ${minutes}M : ${seconds}S`);
        } else {
          setRemainingTimes("EXPIRED");
        }
      }, 1000);
    };
    Auction();
  }, [props.TokenId, auctionContract]);

  useEffect(() => {
    if (currentAddress === seller) {
      setSelfCard(true);
    }
  }, [currentAddress, seller]);

  const SellOut = async () => {
    setLoading(true);
    const result = await sellOut(props.TokenId, walletProvider, ssm);
    setLoading(false);
    if (result) {
      if (result?.error?.code) {
        setPopUpSubject("Oops");
        setPopUpText(result.message);
        setPopUp(true);
      }
      if (result?.txreceipt?.blockHash) {
        setNavConfirm(true);

        setPopUp(true);
        setPopUpSubject("The card is successfully sold out");
        setPopUpText(`Transaction Hash: ${result?.txreceipt?.transactionHash}`);
        setPopUpText1(` Aws Response :${result?.Awsresponse}`);
      }
      if (result.data?.message) {
        setPopUpSubject("Oops");
        setPopUpText(result.data.message);
        setPopUp(true);
      }
    }
  };
  useEffect(() => {
    if (navigationCntxt === true && navConfirm === true) {
      navigate("/");    
    }
  }, [navigationCntxt,navConfirm]);
  return (
    <div>
      <section className="card_details_purchase mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="purchase_current_status">
                <ul>
                  <li>
                    <span>Time on sale</span>
                    <br></br>
                    <strong>{time}</strong>
                  </li>
                  <li>
                    <span>Market price</span>
                    <br></br>
                    <strong>{price} MATIC</strong>
                  </li>
                  <li>
                    <span>Highest bid price</span>
                    <br></br>
                    <strong>{highest} MATIC</strong>
                  </li>
                  <li>
                    <span>Time left</span>
                    <br></br>
                    <strong className="text-danger">{remainingTimes}</strong>
                  </li>
                  {(selfCard && parseInt(highest) !==0 ) && (
                    <li>
                      <button onClick={SellOut}>
                        {loading ? "PLEASE WAIT" : "SELL OUT"}
                      </button>
                    </li>
                  )}
                  <li>
                    {!selfCard ? (
                      <Link to={`/auction-confirmation/${props.TokenId}`}>
                        NEW BID
                      </Link>
                    ) : (
                      <Link to={`/sell-auction-confirmation/${props.TokenId}`}>
                        WITHDRAW
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
              {popup && (
                <PopUp
                  popUpSubject={popUpSubject}
                  popUpText={popUpText}
                  popUpText1={popUpText1}
                  setPopUp={setPopUp}
                ></PopUp>
              )}
            </div>
          </div>
        </div>
        {loading && <LoaderFloating></LoaderFloating>}
      </section>
    </div>
  );
}
export default CurrentStatus;
