import { Auth } from "aws-amplify";
import { Logger } from "ethers/lib/utils";
import {
  DeleteWar,
  UpdateWarTxnHash,
} from "../Profile/Pages/Warzone/Sections/WarContentBox/Battlefield";
const { ethers } = require("ethers");

const WAR_ABI = [
	{
		"inputs": [
			{
				"internalType": "uint256[11]",
				"name": "_WinningArray",
				"type": "uint256[11]"
			},
			{
				"internalType": "uint256[11]",
				"name": "_LossingArray",
				"type": "uint256[11]"
			}
		],
		"name": "CalcWar",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "EnterWar",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_operator",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_from",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "onERC721Received",
		"outputs": [
			{
				"internalType": "bytes4",
				"name": "",
				"type": "bytes4"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "requestId",
				"type": "bytes32"
			},
			{
				"internalType": "uint256",
				"name": "randomness",
				"type": "uint256"
			}
		],
		"name": "rawFulfillRandomness",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			}
		],
		"name": "setBattleFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setBreedingAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newCEO",
				"type": "address"
			}
		],
		"name": "setCEO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "_newCFO",
				"type": "address"
			}
		],
		"name": "setCFO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newCOO",
				"type": "address"
			}
		],
		"name": "setCOO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setGameCntrAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_keyHash",
				"type": "bytes32"
			}
		],
		"name": "setkeyHash",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addr",
				"type": "address"
			}
		],
		"name": "setLinkTokenAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			}
		],
		"name": "setRandomNumGenfee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newFee",
				"type": "uint256"
			}
		],
		"name": "setTessPercentageInt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addr",
				"type": "address"
			}
		],
		"name": "setVRFCordinatorAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint16",
				"name": "_bp",
				"type": "uint16"
			}
		],
		"name": "setWarBp",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_Fee",
				"type": "uint256"
			}
		],
		"name": "setWarFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "x",
				"type": "uint256"
			}
		],
		"name": "PRBMathUD60x18__FromUintOverflow",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "prod1",
				"type": "uint256"
			}
		],
		"name": "PRBMath__MulDivFixedPointOverflow",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "prod1",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "denominator",
				"type": "uint256"
			}
		],
		"name": "PRBMath__MulDivOverflow",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "id",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "addr",
				"type": "address"
			}
		],
		"name": "BattleListPut",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "id",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "addr",
				"type": "address"
			}
		],
		"name": "BattleListRemove",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "winner",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "losser",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "addrW",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "addrL",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "FacW",
				"type": "string"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "RankW",
				"type": "string"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "FacL",
				"type": "string"
			},
			{
				"indexed": false,
				"internalType": "uint16",
				"name": "bp",
				"type": "uint16"
			}
		],
		"name": "DeclareWinner",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_tokenIdArray",
				"type": "uint256[]"
			}
		],
		"name": "EnterWarMulti",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "ExitWar",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_tokenIdArray",
				"type": "uint256[]"
			}
		],
		"name": "ExitWarMulti",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "GetRandomNumber",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_WTknId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_LTknId",
				"type": "uint256"
			}
		],
		"name": "InitiateBattle",
		"outputs": [
			{
				"internalType": "uint16",
				"name": "",
				"type": "uint16"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "MakeBattleReady",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "MakeNotBattleReady",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "MakeNotBattleReadyWhenPaused",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "TKID",
				"type": "uint256"
			}
		],
		"name": "Received",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "RemoveAllCardsFromWarZone",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "RemoveCardFromWarZone",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "num",
				"type": "uint256"
			}
		],
		"name": "RemoveRandomNumber",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_NewAdd",
				"type": "address"
			}
		],
		"name": "SetCardMakerContractAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "bool",
				"name": "b",
				"type": "bool"
			},
			{
				"indexed": false,
				"internalType": "uint256[11]",
				"name": "winner",
				"type": "uint256[11]"
			},
			{
				"indexed": false,
				"internalType": "uint256[11]",
				"name": "losser",
				"type": "uint256[11]"
			}
		],
		"name": "WarEnded",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "ArrayIndex",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "TokenId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "addr",
				"type": "address"
			}
		],
		"name": "WarEntered",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "TokenId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "addr",
				"type": "address"
			}
		],
		"name": "WarExited",
		"type": "event"
	},
	{
		"stateMutability": "nonpayable",
		"type": "fallback"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawBalance",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawLink",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [],
		"name": "battleFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "BreedingAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Card_Maker_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ceoAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cfoAddress",
		"outputs": [
			{
				"internalType": "address payable",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cooAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "fee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "GameCentreAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getContractBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "Balance",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getLinkBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "Balance",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getRandomNumberArray",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getWarDatabase",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "money",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "Id",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "string",
						"name": "faction",
						"type": "string"
					},
					{
						"internalType": "bool",
						"name": "exists",
						"type": "bool"
					}
				],
				"internalType": "struct War.WarObj[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "getWarObj",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "money",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "Id",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "string",
						"name": "faction",
						"type": "string"
					},
					{
						"internalType": "bool",
						"name": "exists",
						"type": "bool"
					}
				],
				"internalType": "struct War.WarObj",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_id",
				"type": "uint256"
			}
		],
		"name": "IsBattleReady",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "IsOnWarZone",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "PercentageInt",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "RandomNumber",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "WarArray",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "money",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "Id",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "faction",
				"type": "string"
			},
			{
				"internalType": "bool",
				"name": "exists",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "WarBp",
		"outputs": [
			{
				"internalType": "uint16",
				"name": "",
				"type": "uint16"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "WarFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]

const GAMECENTRE_ABI = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "newContract",
				"type": "address"
			}
		],
		"name": "ContractUpgrade",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "Auc_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_cardIdArray",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256",
				"name": "_numNewCards",
				"type": "uint256"
			}
		],
		"name": "AutoCardMaker",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_fatherarray",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_motherarray",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256",
				"name": "_newCardNum",
				"type": "uint256"
			}
		],
		"name": "AutoCardMakerHuman",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "BreedingAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Card_Maker_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Fixed_MarketPlace_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "GameCentreAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Gene_Mix_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardFraction",
		"outputs": [
			{
				"internalType": "string",
				"name": "Frac",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardInfo",
		"outputs": [
			{
				"internalType": "string",
				"name": "Name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "Rank",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "Gender",
				"type": "string"
			},
			{
				"internalType": "uint64",
				"name": "Last_Mating_Time",
				"type": "uint64"
			},
			{
				"internalType": "uint32",
				"name": "Gen",
				"type": "uint32"
			},
			{
				"internalType": "uint16",
				"name": "Battle_Points",
				"type": "uint16"
			},
			{
				"internalType": "bool",
				"name": "isOnAuction",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isOnWarZone",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isBattleReady",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isOnFixedPriceMarket",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "IsOnBreedList",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isBreedReady",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardRank",
		"outputs": [
			{
				"internalType": "uint16",
				"name": "bp",
				"type": "uint16"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardType",
		"outputs": [
			{
				"internalType": "string",
				"name": "Type",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetGender",
		"outputs": [
			{
				"internalType": "string",
				"name": "result",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetRarityCodeVal",
		"outputs": [
			{
				"internalType": "uint64[20]",
				"name": "",
				"type": "uint64[20]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetStats",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "IQ",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "EQ",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "Wisdom",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "MeleeAttck",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "WeaponSkill",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "DeffSkill",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "LeadershipSkill",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "NaturalAdpt",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "FuturePotential",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetWeaponCode",
		"outputs": [
			{
				"internalType": "uint64",
				"name": "WeaponGene",
				"type": "uint64"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "array",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256",
				"name": "_startPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint64",
				"name": "_duration",
				"type": "uint64"
			}
		],
		"name": "PutMultiCardAuc",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "RandomNumbers",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "RandomNumbers2",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newAdrr",
				"type": "address"
			}
		],
		"name": "SetAucContractAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_NewAdd",
				"type": "address"
			}
		],
		"name": "SetCardMakerContractAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newAdrr",
				"type": "address"
			}
		],
		"name": "SetGene_Mix_ContractAdress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newAdrr",
				"type": "address"
			}
		],
		"name": "SetWarContractAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "genecode",
				"type": "uint256"
			}
		],
		"name": "UpdateWeapon",
		"outputs": [
			{
				"internalType": "uint64",
				"name": "result",
				"type": "uint64"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "WarZonechecker",
		"outputs": [
			{
				"internalType": "bool",
				"name": "flag",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "War_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "WeaponUpdateFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "battlelistchecker",
		"outputs": [
			{
				"internalType": "bool",
				"name": "flag",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ceoAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cfoAddress",
		"outputs": [
			{
				"internalType": "address payable",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cooAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deleteRandomArray",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "fee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getLinkBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "Balance",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "num",
				"type": "uint256"
			}
		],
		"name": "getRandomNumber",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "requestId",
				"type": "bytes32"
			},
			{
				"internalType": "uint256",
				"name": "randomness",
				"type": "uint256"
			}
		],
		"name": "rawFulfillRandomness",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setBreedingAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newCEO",
				"type": "address"
			}
		],
		"name": "setCEO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "_newCFO",
				"type": "address"
			}
		],
		"name": "setCFO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newCOO",
				"type": "address"
			}
		],
		"name": "setCOO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setFixedMarketAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setGameCntrAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addr",
				"type": "address"
			}
		],
		"name": "setLinkTokenAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			}
		],
		"name": "setRandomNumGenfee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addr",
				"type": "address"
			}
		],
		"name": "setVRFCordinatorAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			}
		],
		"name": "setWeaponUpdateFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_keyHash",
				"type": "bytes32"
			}
		],
		"name": "setkeyHash",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawLink",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]

const BREEDING_ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "x",
        type: "uint256",
      },
    ],
    name: "PRBMathUD60x18__FromUintOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivFixedPointOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "denominator",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivOverflow",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "childId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "fac",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "Type",
        type: "string",
      },
      {
        indexed: false,
        internalType: "address",
        name: "childOwnerAddr",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "mId",
        type: "uint256",
      },
    ],
    name: "BreedEvnt",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newContract",
        type: "address",
      },
    ],
    name: "ContractUpgrade",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "addr",
        type: "address",
      },
    ],
    name: "JoinBreedListEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "Chromseq_ment_att",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "Chromseq_phys_att",
        type: "uint64",
      },
    ],
    name: "Mutated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "addr",
        type: "address",
      },
    ],
    name: "RemoveBreedListEvent",
    type: "event",
  },
  {
    stateMutability: "nonpayable",
    type: "fallback",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_fathersCardId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_motherCardId",
        type: "uint256",
      },
    ],
    name: "Breed",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "BreedFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_fathersCardId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_motherCardId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_nonCallerOwnerAdrress",
        type: "address",
      },
    ],
    name: "BreedWithOthers",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_fathersCardId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_motherCardId",
        type: "uint256",
      },
    ],
    name: "Breed_Ex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "BreedingAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "_bp",
        type: "uint16",
      },
    ],
    name: "CalcRank",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "Card_Maker_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ChangeNameFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ClearBreedingListWhenPaused",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "FinalBreedFeeList",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GameCentreAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Gene_Mix_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "IsOnBreedList",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_CardId",
        type: "uint256",
      },
    ],
    name: "MutateCard",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "MutateFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PercentageInt",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "PutOnBreedList",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "RankList_Num",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "RankUpFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "RemoveBreedList",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_NewAdd",
        type: "address",
      },
    ],
    name: "SetCardMakerContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetGene_Mix_ContractAdress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetWarContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "War_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ceoAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cfoAddress",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "_New_Name",
        type: "string",
      },
    ],
    name: "changeCardName",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "a",
        type: "string",
      },
      {
        internalType: "string",
        name: "b",
        type: "string",
      },
    ],
    name: "concatenate",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "cooAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getContractBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "Balance",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "rankUp",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_Fee",
        type: "uint256",
      },
    ],
    name: "setBreedFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setBreedingAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newCEO",
        type: "address",
      },
    ],
    name: "setCEO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_newCFO",
        type: "address",
      },
    ],
    name: "setCFO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newCOO",
        type: "address",
      },
    ],
    name: "setCOO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_Fee",
        type: "uint256",
      },
    ],
    name: "setChangeNameFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setGameCntrAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_Fee",
        type: "uint256",
      },
    ],
    name: "setMutateFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_Fee",
        type: "uint256",
      },
    ],
    name: "setRankUpFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_newFee",
        type: "uint256",
      },
    ],
    name: "setTessPercentageInt",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "withdrawBalance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

const FIXEDMARKET_ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "x",
        type: "uint256",
      },
    ],
    name: "PRBMathUD60x18__FromUintOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivFixedPointOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "denominator",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivOverflow",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "Owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "str",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "promocode",
        type: "string",
      },
    ],
    name: "CardBought",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "Owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "str",
        type: "string",
      },
    ],
    name: "CardOnSaleList",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "Owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "str",
        type: "string",
      },
    ],
    name: "CardRemoveSaleList",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "str",
        type: "string",
      },
    ],
    name: "CardRemoveSaleListWhnPaused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newContract",
        type: "address",
      },
    ],
    name: "ContractUpgrade",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "Owner",
        type: "address",
      },
    ],
    name: "FixedPriceCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "Owner",
        type: "address",
      },
    ],
    name: "FixedPriceCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: "address payable",
            name: "seller",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "Id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "Price",
            type: "uint256",
          },
          {
            internalType: "uint64",
            name: "startedAt",
            type: "uint64",
          },
        ],
        indexed: false,
        internalType: "struct FixedMarketPlace.FixedPrice[]",
        name: "fp",
        type: "tuple[]",
      },
    ],
    name: "FixedPriceDatabaseRetrive",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "code",
        type: "string",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "discount",
        type: "uint256",
      },
    ],
    name: "PromoCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "code",
        type: "string",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "discount",
        type: "uint256",
      },
    ],
    name: "PromoDeleted",
    type: "event",
  },
  {
    inputs: [],
    name: "Auc_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "BreedingAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "code",
        type: "string",
      },
    ],
    name: "BuyCard",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "CancelFixedSaleWhenPaused",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "CancelSale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "Card_Maker_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ClearFixedPriceDatabase",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "code",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "discount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "use_count",
        type: "uint256",
      },
    ],
    name: "CreatePromo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "code",
        type: "string",
      },
    ],
    name: "DeletePromo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "FPdatabase",
    outputs: [
      {
        internalType: "address payable",
        name: "seller",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "Id",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "Price",
        type: "uint256",
      },
      {
        internalType: "uint64",
        name: "startedAt",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Fixed_MarketPlace_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Gene_Mix_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GetFixedPriceDatabase",
    outputs: [
      {
        components: [
          {
            internalType: "address payable",
            name: "seller",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "Id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "Price",
            type: "uint256",
          },
          {
            internalType: "uint64",
            name: "startedAt",
            type: "uint64",
          },
        ],
        internalType: "struct FixedMarketPlace.FixedPrice[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GetSaleRecordSize",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "code",
        type: "string",
      },
    ],
    name: "GetUseCount",
    outputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    name: "PromoList",
    outputs: [
      {
        internalType: "string",
        name: "Name",
        type: "string",
      },
      {
        internalType: "string",
        name: "Code",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "DiscountPercentage",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "UseCount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "TotalValue",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "Exists",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "Record",
    outputs: [
      {
        internalType: "uint256",
        name: "Price",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "SaleTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "TokenId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "Buyer",
        type: "address",
      },
      {
        internalType: "address",
        name: "Seller",
        type: "address",
      },
      {
        internalType: "string",
        name: "Code",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "code",
        type: "string",
      },
    ],
    name: "ResetPromoValAndCount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_price",
        type: "uint256",
      },
    ],
    name: "SellCard",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetAucContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_NewAdd",
        type: "address",
      },
    ],
    name: "SetCardMakerContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetGene_Mix_ContractAdress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetWarContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "War_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ceoAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cfoAddress",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cooAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getContractBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "Balance",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "getFixedPrice",
    outputs: [
      {
        internalType: "address payable",
        name: "addr",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "Id",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_id",
        type: "uint256",
      },
    ],
    name: "isOnFixedMarketPlace",
    outputs: [
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "percentageInt",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setBreedingAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newCEO",
        type: "address",
      },
    ],
    name: "setCEO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_newCFO",
        type: "address",
      },
    ],
    name: "setCFO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newCOO",
        type: "address",
      },
    ],
    name: "setCOO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setFixedMarketAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_input",
        type: "uint256",
      },
    ],
    name: "setpercentageInt",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "withdrawBalance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const AUCTION_ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "x",
        type: "uint256",
      },
    ],
    name: "PRBMathUD60x18__FromUintOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivFixedPointOverflow",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "denominator",
        type: "uint256",
      },
    ],
    name: "PRBMath__MulDivOverflow",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: "address payable",
            name: "seller",
            type: "address",
          },
          {
            internalType: "address payable",
            name: "highbidaddr",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "startingPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "HighestBidPrice",
            type: "uint256",
          },
          {
            internalType: "uint64",
            name: "duration",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "startedAt",
            type: "uint64",
          },
          {
            internalType: "bool",
            name: "exists",
            type: "bool",
          },
          {
            internalType: "string",
            name: "PromoCode",
            type: "string",
          },
        ],
        indexed: false,
        internalType: "struct Auction_House.Auction[]",
        name: "array",
        type: "tuple[]",
      },
    ],
    name: "AucStorageRetrive",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "Owner",
        type: "address",
      },
    ],
    name: "AuctionCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "Owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "duration",
        type: "uint256",
      },
    ],
    name: "AuctionCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "Buyer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "Seller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "promocode",
        type: "string",
      },
    ],
    name: "AuctionSuccessful",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newContract",
        type: "address",
      },
    ],
    name: "ContractUpgrade",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "code",
        type: "string",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "discount",
        type: "uint256",
      },
    ],
    name: "PromoCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "code",
        type: "string",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "discount",
        type: "uint256",
      },
    ],
    name: "PromoDeleted",
    type: "event",
  },
  {
    stateMutability: "nonpayable",
    type: "fallback",
  },
  {
    inputs: [],
    name: "Auc_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "promocode",
        type: "string",
      },
    ],
    name: "Bid",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "BreedingAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "CancelAuction",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "CancelAuctionWhenPaused",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "Card_Maker_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ClearCheckUpkeepDatabase",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_startPrice",
        type: "uint256",
      },
      {
        internalType: "uint64",
        name: "_duration",
        type: "uint64",
      },
    ],
    name: "CreateAuction",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "code",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "discount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "use_count",
        type: "uint256",
      },
    ],
    name: "CreatePromo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "DeleteAucDatabase",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "code",
        type: "string",
      },
    ],
    name: "DeletePromo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "Fixed_MarketPlace_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GameCentreAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Gene_Mix_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "startIndex",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "endIndex",
        type: "uint256",
      },
    ],
    name: "GetAucDatabase",
    outputs: [
      {
        components: [
          {
            internalType: "address payable",
            name: "seller",
            type: "address",
          },
          {
            internalType: "address payable",
            name: "highbidaddr",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "startingPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "HighestBidPrice",
            type: "uint256",
          },
          {
            internalType: "uint64",
            name: "duration",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "startedAt",
            type: "uint64",
          },
          {
            internalType: "bool",
            name: "exists",
            type: "bool",
          },
          {
            internalType: "string",
            name: "PromoCode",
            type: "string",
          },
        ],
        internalType: "struct Auction_House.Auction[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "GetAuction",
    outputs: [
      {
        internalType: "address",
        name: "seller",
        type: "address",
      },
      {
        internalType: "address",
        name: "highbidaddr",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "startingPrice",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "HighestBidPrice",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "duration",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "startedAt",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GetCheckUpKeepDatabase",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "TokenId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "Endtime",
            type: "uint256",
          },
        ],
        internalType: "struct Auction_House.CheckUpKeepStrct[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "GetCurrentPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GetSaleRecordSize",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "code",
        type: "string",
      },
    ],
    name: "GetUseCount",
    outputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "IsOnAuction",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    name: "PromoList",
    outputs: [
      {
        internalType: "string",
        name: "Name",
        type: "string",
      },
      {
        internalType: "string",
        name: "Code",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "DiscountPercentage",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "UseCount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "TotalValue",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "Exists",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "Record",
    outputs: [
      {
        internalType: "uint256",
        name: "Price",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "SaleTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "TokenId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "Buyer",
        type: "address",
      },
      {
        internalType: "address",
        name: "Seller",
        type: "address",
      },
      {
        internalType: "string",
        name: "Code",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "code",
        type: "string",
      },
    ],
    name: "ResetPromoValAndCount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "SellOut",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetAucContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_NewAdd",
        type: "address",
      },
    ],
    name: "SetCardMakerContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetGene_Mix_ContractAdress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetWarContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "War_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ceoAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cfoAddress",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "checkUpKeepArray",
    outputs: [
      {
        internalType: "uint256",
        name: "TokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "Endtime",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "checkUpkeep",
    outputs: [
      {
        internalType: "bool",
        name: "upkeepNeeded",
        type: "bool",
      },
      {
        internalType: "bytes",
        name: "performData",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cooAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getContractBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "Balance",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_operator",
        type: "address",
      },
      {
        internalType: "address",
        name: "_from",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes",
      },
    ],
    name: "onERC721Received",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "percentageInt",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "performData",
        type: "bytes",
      },
    ],
    name: "performUpkeep",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setBreedingAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newCEO",
        type: "address",
      },
    ],
    name: "setCEO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_newCFO",
        type: "address",
      },
    ],
    name: "setCFO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newCOO",
        type: "address",
      },
    ],
    name: "setCOO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setFixedMarketAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setGameCntrAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_input",
        type: "uint256",
      },
    ],
    name: "setpercentageInt",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "withdrawBalance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

const CARDOWNERSHIP_ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "seller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "buyer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "TokenId",
        type: "uint256",
      },
    ],
    name: "CardBought",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "Owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "TokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "Chromseq_phys_att",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "Chromseq_ment_att",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "Fac",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "Type",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "Rank",
        type: "string",
      },
    ],
    name: "CardCreation",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "Owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "TokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "Frac",
        type: "string",
      },
    ],
    name: "CardDestroyed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "Owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "Receiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "TokenId",
        type: "uint256",
      },
    ],
    name: "CardTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newContract",
        type: "address",
      },
    ],
    name: "ContractUpgrade",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256[]",
        name: "TokenId",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "address",
        name: "To",
        type: "address",
      },
    ],
    name: "MultipleTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "TokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "To",
        type: "address",
      },
    ],
    name: "SingleTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [],
    name: "ALCCounter",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Auc_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "BNCounter",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "BreedingAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Card_Maker_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_Name",
        type: "string",
      },
      {
        internalType: "string",
        name: "_Type",
        type: "string",
      },
      {
        internalType: "string",
        name: "_Faction",
        type: "string",
      },
      {
        internalType: "uint64",
        name: "_Chromseq_phys_att",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "_Chromseq_ment_att",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "_Father_Id",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "_Mother_Id",
        type: "uint64",
      },
      {
        internalType: "uint32",
        name: "_Gen",
        type: "uint32",
      },
    ],
    name: "CreateCard",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "Database",
    outputs: [
      {
        internalType: "string",
        name: "Name",
        type: "string",
      },
      {
        internalType: "string",
        name: "Rank",
        type: "string",
      },
      {
        internalType: "string",
        name: "Type",
        type: "string",
      },
      {
        internalType: "string",
        name: "Faction",
        type: "string",
      },
      {
        internalType: "uint64",
        name: "Chromseq_phys_att",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "Chromseq_ment_att",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "Birth_Time",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "Last_Mating_Time",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "Father_Id",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "Mother_Id",
        type: "uint64",
      },
      {
        internalType: "uint32",
        name: "Gen",
        type: "uint32",
      },
      {
        internalType: "uint16",
        name: "Battle_Points",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Fixed_MarketPlace_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GameCentreAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Gen0counter",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Gene_Mix_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GenesisCardCounter",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HSCounter",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "uint64",
        name: "_Chromseq_ment_att",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "_Chromseq_phys_att",
        type: "uint64",
      },
    ],
    name: "MutateUpdate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "OwnerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_id",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "_str",
        type: "string",
      },
      {
        internalType: "uint16",
        name: "_bp",
        type: "uint16",
      },
    ],
    name: "RankUpUpdate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetAucContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_NewAdd",
        type: "address",
      },
    ],
    name: "SetCardMakerContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetGene_Mix_ContractAdress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdrr",
        type: "address",
      },
    ],
    name: "SetWarContractAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "TransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "UpdateBreedTime",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
    ],
    name: "UpdateName",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "uint64",
        name: "genecode",
        type: "uint64",
      },
    ],
    name: "UpdatePhyGene",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "War_ContractAdress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve_AucToOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve_ex",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_TokenId",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "_type",
        type: "string",
      },
    ],
    name: "burnCard",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "ceoAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cfoAddress",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cooAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_Name",
        type: "string",
      },
      {
        internalType: "string",
        name: "_Rank",
        type: "string",
      },
      {
        internalType: "string",
        name: "_Faction",
        type: "string",
      },
      {
        internalType: "uint64",
        name: "_Chromseq_phys_att",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "_Chromseq_ment_att",
        type: "uint64",
      },
      {
        internalType: "uint16",
        name: "_Battle_Points",
        type: "uint16",
      },
    ],
    name: "create_genesisCard",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "exists",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    name: "getAllCards",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "getCardById",
    outputs: [
      {
        components: [
          {
            internalType: "string",
            name: "Name",
            type: "string",
          },
          {
            internalType: "string",
            name: "Rank",
            type: "string",
          },
          {
            internalType: "string",
            name: "Type",
            type: "string",
          },
          {
            internalType: "string",
            name: "Faction",
            type: "string",
          },
          {
            internalType: "uint64",
            name: "Chromseq_phys_att",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "Chromseq_ment_att",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "Birth_Time",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "Last_Mating_Time",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "Father_Id",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "Mother_Id",
            type: "uint64",
          },
          {
            internalType: "uint32",
            name: "Gen",
            type: "uint32",
          },
          {
            internalType: "uint16",
            name: "Battle_Points",
            type: "uint16",
          },
        ],
        internalType: "struct Card_Maker.Character",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getDatabase",
    outputs: [
      {
        components: [
          {
            internalType: "string",
            name: "Name",
            type: "string",
          },
          {
            internalType: "string",
            name: "Rank",
            type: "string",
          },
          {
            internalType: "string",
            name: "Type",
            type: "string",
          },
          {
            internalType: "string",
            name: "Faction",
            type: "string",
          },
          {
            internalType: "uint64",
            name: "Chromseq_phys_att",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "Chromseq_ment_att",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "Birth_Time",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "Last_Mating_Time",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "Father_Id",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "Mother_Id",
            type: "uint64",
          },
          {
            internalType: "uint32",
            name: "Gen",
            type: "uint32",
          },
          {
            internalType: "uint16",
            name: "Battle_Points",
            type: "uint16",
          },
        ],
        internalType: "struct Card_Maker.Character[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getDatabaseLen",
    outputs: [
      {
        internalType: "uint256",
        name: "length",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "isApprovedOrOwner",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "flag",
        type: "uint256",
      },
    ],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setBreedingAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newCEO",
        type: "address",
      },
    ],
    name: "setCEO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_newCFO",
        type: "address",
      },
    ],
    name: "setCFO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newCOO",
        type: "address",
      },
    ],
    name: "setCOO",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newAddr",
        type: "address",
      },
    ],
    name: "setFixedMarketAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "transferCardFromAuc",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
    ],
    name: "transferCardFromWar",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_to",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "_tokenIdArray",
        type: "uint256[]",
      },
    ],
    name: "transferMultipleCard",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "flag",
        type: "uint256",
      },
    ],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const pauseGame = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const CardOwnerShipContract = new ethers.Contract(
      process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR,
      CARDOWNERSHIP_ABI,
      signer
    );
    const response = await CardOwnerShipContract.pause(1);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      pauseGame(walletProvider);
    }
  }
};

export const unPauseGame = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const CardOwnerShipContract = new ethers.Contract(
      process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR,
      CARDOWNERSHIP_ABI,
      signer
    );
    const response = await CardOwnerShipContract.unpause(1);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      unPauseGame(walletProvider);
    }
  }
};

//////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//WAR Contract
//////////////////////////////////////////////////////////////

export const addToBattleList = async (
  TokenId,
  walletProvider,
  WarContract,
  ssm
) => {
  let Result;
  let EnvSecret;
  let Money;
  try {
    const params = {
      Name: process.env.REACT_APP_ADD_TO_BATTLELIST_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    Money = await WarContract.battleFee();
    Money = Money.toString();
    Money = ethers.utils.formatEther(Money);
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      addToBattleList(TokenId, walletProvider, WarContract, ssm);
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.MakeBattleReady(TokenId, {
      value: ethers.utils.parseUnits(Money, "ether"),
    });
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      addToBattleList(TokenId, walletProvider, WarContract, ssm);
    } else {
      return err;
    }
  }
};

export const removeFromBattleList = async (TokenId, walletProvider, ssm) => {
  let Result;
  let EnvSecret;
  try {
    const params = {
      Name: process.env.REACT_APP_EXIT_BATTLELIST_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.MakeNotBattleReady(TokenId);
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      removeFromBattleList(TokenId, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

export const addToWarZone = async (
  TokenId,
  BattleFieldId,
  walletProvider,
  WarContract,
  ssm
) => {
  // console.log(TokenId,BattleFieldId);
  let Result;
  let EnvSecret;
  let Money;
  try {
    const params = {
      Name: process.env.REACT_APP_ENTER_WAR_ZONE_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
    EnvSecret = EnvSecret.concat("?BattleFieldId=" + BattleFieldId);
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    Money = await WarContract.WarFee();
    Money = Money.toString();
    Money = ethers.utils.formatEther(Money);
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      addToWarZone(TokenId, BattleFieldId, walletProvider, WarContract, ssm);
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.EnterWar(TokenId, {
      value: ethers.utils.parseUnits(Money, "ether"),
    });
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      addToWarZone(TokenId, BattleFieldId, walletProvider, WarContract, ssm);
    } else {
      return err;
    }
  }
};

export const addToWarZoneMulti = async (
  TokenIdArray,
  BattleFieldId,
  walletProvider,
  WarContract,
  ssm
) => {
  let Result;
  let EnvSecret;
  let Money;
  try {
    const params = {
      Name: process.env.REACT_APP_ENTER_WAR_ZONE_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
    EnvSecret = EnvSecret.concat("?BattleFieldId=" + BattleFieldId);
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const length = TokenIdArray.length;
    Money = await WarContract.WarFee();
    Money = Money * length;
    Money = Money.toString();
    Money = ethers.utils.formatEther(Money);
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      addToWarZoneMulti(
        TokenIdArray,
        BattleFieldId,
        walletProvider,
        WarContract,
        ssm
      );
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.EnterWarMulti(TokenIdArray, {
      value: ethers.utils.parseUnits(Money, "ether")
    });
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);

    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      addToWarZoneMulti(
        TokenIdArray,
        BattleFieldId,
        walletProvider,
        WarContract,
        ssm
      );
    } else {
      return err;
    }
  }
};

export const exitWar = async (TokenId, BattleFieldID, walletProvider, ssm) => {
  let Result;
  let EnvSecret;
  try {
    const params = {
      Name: process.env.REACT_APP_EXIT_WAR_ZONE_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
    EnvSecret = EnvSecret + "?BattleFieldId=" + BattleFieldID;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.ExitWar(TokenId);
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);

    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      exitWar(TokenId, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

export const InitiateBattle = async (TokenId1, TokenId2, CA, ssm) => {
  let EnvSecret;
  try {
    const params = {
      Name: process.env.REACT_APP_INITIATE_BATTLE_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
    EnvSecret = EnvSecret.concat(
      "TokenId1=" + TokenId1 + "&TokenId2=" + TokenId2 + "&CA=" + CA
    );
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }
  try {
    const session = await Auth.currentSession();
    const options = {
      headers: {
        authorization: session.getAccessToken().getJwtToken(),
      },
    };
    const response = await fetch(EnvSecret, options);
    return await response.text();
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const InitiateWar = async (
  Array1,
  Array2,
  Array1Addr,
  Array2Addr,
  BattleFieldID,
  ssm
) => {
  Array1 = JSON.stringify(Array1);
  Array2 = JSON.stringify(Array2);
  Array1Addr = JSON.stringify(Array1Addr);
  Array2Addr = JSON.stringify(Array2Addr);

  let EnvSecret;
  try {
    const params = {
      Name: process.env.REACT_APP_INITIATE_WAR_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
    EnvSecret = EnvSecret.concat(
      "Array1=" +
        Array1 +
        "&Array2=" +
        Array2 +
        "&Array1Addr=" +
        Array1Addr +
        "&Array2Addr=" +
        Array2Addr +
        "&BattleFieldId=" +
        BattleFieldID
    );
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }
  try {
    const session = await Auth.currentSession();
    const options = {
      headers: {
        authorization: session.getAccessToken().getJwtToken(),
      },
    };
    const response = await fetch(EnvSecret, options);
    // console.log(response,"response from blockchain1");
    return await response.text();


  } catch (err) {
    // console.log(err);
    return err;
  }
};

export const IsOnBattleList = async (TokenId, WarContract) => {
  try {
    const txReceipt = await WarContract.IsBattleReady(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsOnBattleList(TokenId, WarContract);
      return result;
    }
  }
};

export const IsOnWarZone = async (TokenId, WarContract) => {
  try {
    const txReceipt = await WarContract.IsOnWarZone(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsOnWarZone(TokenId, WarContract);
      return result;
    }
  }
};

////////////////ADMIN PAGE FUNCTIONS///////////////////////////////////////////////////

export const withdrawBalanceWar = async (Amount, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.withdrawBalance(Amount);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      withdrawBalanceWar(Amount, walletProvider);
    }
  }
};

export const withdrawBalanceWarLink = async (Amount, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.withdrawLink(Amount);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      withdrawBalanceWarLink(Amount, walletProvider);
    }
  }
};

export const GetContractBalanceWar = async (WarContract) => {
  try {
    let txReceipt = await WarContract.getContractBalance();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetContractBalanceWar(WarContract);
      return result;
    }
  }
};

export const GetContractBalanceWarLink = async (WarContract) => {

  try {
    let txReceipt = await WarContract.getLinkBalance();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetContractBalanceWarLink(WarContract);

      return result;
    }
  }
};

export const GetCFOAddrWar = async (WarContract) => {
  try {
    const txReceipt = await WarContract.cfoAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCFOAddrWar(WarContract);
      return result;
    }
  }
};

export const setCFOAddrWar = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.setCFO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCFOAddrWar(Address, walletProvider);
    }
  }
};

export const setCOOAddrWar = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.setCOO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCOOAddrWar(Address, walletProvider);
    }
  }
};

export const GetCOOAddrWar = async (WarContract) => {
  try {
    const txReceipt = await WarContract.cooAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCOOAddrWar(WarContract);
      return result;
    }
  }
};

export const pauseWarContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.pause();
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      pauseWarContract(walletProvider);
    }
  }
};

export const unPauseWarContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.unpause();
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      unPauseWarContract(walletProvider);
    }
  }
};

export const IsPausedWar = async (WarContract) => {
  try {
    const txReceipt = await WarContract.paused();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsPausedWar(WarContract);
      return result;
    }
  }
};

export const setWarFee = async (Fee, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.setWarFee(Fee);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setWarFee(Fee, walletProvider);
    }
  }
};

export const setBattleFee = async (Fee, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.setBattleFee(Fee);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setBattleFee(Fee, walletProvider);
    }
  }
};

export const setWarFeeTessPecentage = async (Percentage, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await WarContract.setTessPercentageInt(Percentage);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setWarFeeTessPecentage(Percentage, walletProvider);
    }
  }
};

export const setWarBp = async (BP, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const WarContract = new ethers.Contract(
      process.env.REACT_APP_WAR_CONTRACT_ADDR,
      WAR_ABI,
      signer
    );
    const response = await WarContract.setWarBp(BP);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setWarBp(BP, walletProvider);
    }
  }
};

export const GetWarBp = async (WarContract) => {
  try {
    let txReceipt = await WarContract.WarBp();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetWarBp(WarContract);
      return result;
    }
  }
};

export const GetBattleFee = async (WarContract) => {
  try {
    let txReceipt = await WarContract.fee();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetBattleFee(WarContract);
      return result;
    }
  }
};

export const GetWarFee = async (WarContract) => {
  try {
    let txReceipt = await WarContract.WarFee();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetWarFee(WarContract);
      return result;
    }
  }
};

export const GetTesseractPercentageWar = async (WarContract) => {
  try {
    let txReceipt = await WarContract.PercentageInt();
    // txReceipt = txReceipt.toString();
    // const fee = ethers.utils.formatEther(txReceipt);
    // return fee;
    return txReceipt.toString();
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetTesseractPercentageWar(WarContract);
      return result;
    }
  }
};

//////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//Breeding Contract
//////////////////////////////////////////////////////////////

export const IsOnBreedingList = async (TokenId, BreedingContract) => {
  try {
    const txReceipt = await BreedingContract.IsOnBreedList(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsOnBreedingList(TokenId, BreedingContract);
      return result;
    }
  }
};

export const putOnBreedList = async (TokenId, value, walletProvider, ssm) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_PUT_ONBREEDINGLIST_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.PutOnBreedList(TokenId, value);
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      putOnBreedList(TokenId, value, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

export const exitBreedList = async (TokenId, walletProvider, ssm) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_EXIT_BREEDLIST_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const GameCentreContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await GameCentreContract.RemoveBreedList(TokenId);
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);

    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      exitBreedList(TokenId, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

//@dev Make sure the fatherid and motherid is correct, according to gender if human, for Alc and BN it doesn't matter
export const breed = async (FatherId, MotherId, walletProvider, ssm) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_BREED_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.Breed(FatherId, MotherId);
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);

    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      breed(FatherId, MotherId, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

//@dev Make sure the fatherid and motherid is correct, according to gender if human, for Alc and BN it doesn't matter
//popup needed
export const breedWithOthers = async (
  FatherId,
  MotherId,
  nonCaller,
  walletProvider,
  BreedingContract,
  CardOwnerShipContract,
  ssm
) => {
  console.log(nonCaller, "block");

  let Result;
  let EnvSecret;
  let fee1;
  let fee2;
  let finalbreedfee;
  try {
    const params = {
      Name: process.env.REACT_APP_BREEDWITHOTHERS_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    fee1 = await BreedingContract.FinalBreedFeeList(MotherId);
    fee2 = await BreedingContract.FinalBreedFeeList(FatherId);
    finalbreedfee = Math.max(fee1, fee2);
    finalbreedfee = finalbreedfee.toString();
    finalbreedfee = ethers.utils.formatEther(finalbreedfee);
  } catch (err) {
    console.log(err);
  }

  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.BreedWithOthers(
      FatherId,
      MotherId,
      nonCaller,
      { value: ethers.utils.parseUnits(finalbreedfee, "ether") }
    );
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);

    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      breedWithOthers(
        FatherId,
        MotherId,
        nonCaller,
        walletProvider,
        BreedingContract,
        CardOwnerShipContract,
        ssm
      );
    } else {
      return err;
    }
  }
};

export const ChangeName = async (
  TokenId,
  NewName,
  walletProvider,
  BreedingContract
) => {
  let fee;

  try {
    fee = await BreedingContract.ChangeNameFee();
    fee = fee.toString();
    fee = ethers.utils.formatEther(fee);
  } catch (err) {
    console.log("Error getting name update fee");
    return err;
  }

  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.changeCardName(TokenId, NewName, {
      value: ethers.utils.parseUnits(fee, "ether"),
    });
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    return err;
  }
};

export const RankUp = async (TokenId, walletProvider, BreedingContract) => {
  let fee;

  try {
    fee = await BreedingContract.RankUpFee();
    fee = fee.toString();
    fee = ethers.utils.formatEther(fee);
  } catch (err) {
    console.log("Error getting name RankUp fee");
    return err;
  }

  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.rankUp(TokenId, {
      value: ethers.utils.parseUnits(fee, "ether"),
    });
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    return err;
  }
};

export const MutateCard = async (TokenId, walletProvider, BreedingContract) => {
  let fee;

  try {
    fee = await BreedingContract.MutateFee();
    fee = fee.toString();
    fee = ethers.utils.formatEther(fee);
  } catch (err) {
    console.log("Error getting name RankUp fee");
    return err;
  }

  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.MutateCard(TokenId, {
      value: ethers.utils.parseUnits(fee, "ether"),
    });
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    return err;
  }
};

////////////////ADMIN PAGE FUNCTIONS///////////////////////////////////////////////////

export const withdrawBalanceBreed = async (Amount, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.withdrawBalance(Amount);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      withdrawBalanceBreed(Amount, walletProvider);
    }
  }
};

export const pauseBreedContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.pause();
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      pauseBreedContract(walletProvider);
    }
  }
};

export const unPauseBreedContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.unpause();
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      unPauseBreedContract(walletProvider);
    }
  }
};

export const setBreedingFee = async (Fee, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.setBreedFee(Fee);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setBreedingFee(Fee, walletProvider);
    }
  }
};

export const setRankUpFee = async (Fee, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.setRankUpFee(Fee);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setRankUpFee(Fee, walletProvider);
    }
  }
};

export const setMutateFee = async (Fee, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.setMutateFee(Fee);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setMutateFee(Fee, walletProvider);
    }
  }
};
export const setRenameFee = async (Fee, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.setChangeNameFee(Fee);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setRenameFee(Fee, walletProvider);
    }
  }
};

export const setBreedFeeTessPercentage = async (Percentage, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.setTessPercentageInt(Percentage);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setBreedFeeTessPercentage(Percentage, walletProvider);
    }
  }
};

export const setCOOAddrBreed = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.setCOO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCOOAddrBreed(Address, walletProvider);
    }
  }
};

export const GetCOOAddrBreed = async (BreedingContract) => {
  try {
    const txReceipt = await BreedingContract.cooAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCOOAddrBreed(BreedingContract);
      return result;
    }
  }
};

export const setCFOAddrBreed = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const BreedingContract = new ethers.Contract(
      process.env.REACT_APP_BREEDING_CONTRACT_ADDR,
      BREEDING_ABI,
      signer
    );
    const response = await BreedingContract.setCFO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    setCFOAddrBreed(Address, walletProvider);
  }
};

export const GetCFOAddrBreed = async (BreedingContract) => {
  try {
    const txReceipt = await BreedingContract.cfoAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCFOAddrBreed(BreedingContract);
      return result;
    }
  }
};

export const GetContractBalanceBreed = async (BreedingContract) => {
  try {
    let txReceipt = await BreedingContract.getContractBalance();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetContractBalanceBreed(BreedingContract);
      return result;
    }
  }
};

export const GetTesseractPercentageBreed = async (BreedingContract) => {
  try {
    let txReceipt = await BreedingContract.PercentageInt();
    return txReceipt.toString();
    // txReceipt = txReceipt.toString();
    // const fee = ethers.utils.formatEther(txReceipt);
    // return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetTesseractPercentageBreed(BreedingContract);
      return result;
    }
  }
};

export const IsPausedBreed = async (BreedingContract) => {
  try {
    const txReceipt = await BreedingContract.paused();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsPausedBreed(BreedingContract);
      return result;
    }
  }
};

export const GetRankUpFee = async (BreedingContract) => {
  try {
    let txReceipt = await BreedingContract.RankUpFee();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
    // return txReceipt.toString();
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetRankUpFee(BreedingContract);
      return result;
    }
  }
};
export const GetMutateFee = async (BreedingContract) => {
  try {
    let txReceipt = await BreedingContract.MutateFee();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
    // return txReceipt.toString();
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetMutateFee(BreedingContract);
      return result;
    }
  }
};
export const GetRenameFee = async (BreedingContract) => {
  try {
    let txReceipt = await BreedingContract.ChangeNameFee();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
    // return txReceipt.toString();
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetRenameFee(BreedingContract);
      return result;
    }
  }
};

export const GetBreedFee = async (BreedingContract) => {
  try {
    let txReceipt = await BreedingContract.BreedFee();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetBreedFee(BreedingContract);
      return result;
    }
  }
};
export const GetCardFinalBreedFee = async (TokenId, BreedingContract) => {
  try {
    let txReceipt = await BreedingContract.FinalBreedFeeList(TokenId);
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCardFinalBreedFee(TokenId, BreedingContract);
      return result;
    }
  }
};

//////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//FixedMarket Contract
//////////////////////////////////////////////////////////////

export const IsOnFixedMarketList = async (TokenId, FixedMarketContract) => {
  try {
    const txReceipt = await FixedMarketContract.isOnFixedMarketPlace(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsOnFixedMarketList(TokenId, FixedMarketContract);
      return result;
    }
  }
};

export const GetFixedMarketPlacePrice = async (
  TokenId,
  FixedMarketContract
) => {
  try {
    const txReceipt = await IsOnFixedMarketList(TokenId, FixedMarketContract);
    if (txReceipt) {
      const txreceipt = await FixedMarketContract.getFixedPrice(TokenId);
      return txreceipt;
    } else {
      console.log(
        "Error getting Fixed market place status, Token doesnt exist in fixed market place"
      );
    }
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetFixedMarketPlacePrice(
        TokenId,
        FixedMarketContract
      );
      return result;
    }
  }
};

export const putOnFixedMarketPlace = async (
  TokenId,
  Price,
  walletProvider,
  ssm
) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_SELLCARD_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketContract.SellCard(TokenId, Price);
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    // return err;
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      putOnFixedMarketPlace(TokenId, Price, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

export const removeFromFixedMarketPlace = async (
  TokenId,
  walletProvider,
  ssm
) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_CANCELSALE_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketContract.CancelSale(TokenId);
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    if (err?.error?.data?.code === -32000) {
      removeFromFixedMarketPlace(TokenId, walletProvider, ssm);
    } else {
      return err;
    }
  }
};
//Check exception catching
export const buyCard = async (
  TokenId,
  PromoCode,
  walletProvider,
  FixedMarketContract,
  ssm
) => {
  let Result;
  let EnvSecret;
  let Money;
  try {
    const params = {
      Name: process.env.REACT_APP_BUYCARD_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const txReceipt = await FixedMarketContract.getFixedPrice(TokenId);
    Money = txReceipt.price;
    Money = Money.toString();
    Money = ethers.utils.formatEther(Money);
  } catch (err) {
    console.log(err);
    if (err.code == Logger.errors.CALL_EXCEPTION) {
      console.log("works_!!");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketContract.BuyCard(TokenId, PromoCode, {
      value: ethers.utils.parseUnits(Money, "ether"),
    });
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);

    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
      }
    } else if (err.code == Logger.errors.CALL_EXCEPTION) {
      console.log("works_!!");
    } else {
      return err;
    }
    // return Result;
  }
};

export const GetSaleRecordByIndexFM = async (Index, FixedMarketContract) => {
  try {
    const txReceipt = await FixedMarketContract.Record(Index);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetSaleRecordByIndexFM(Index, FixedMarketContract);
      return result;
    }
  }
};

export const GetSaleRecordSizeFM = async (FixedMarketContract) => {
  try {
    const txReceipt = await FixedMarketContract.GetSaleRecordSize();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetSaleRecordSizeFM(FixedMarketContract);
      return result;
    }
  }
};

////////////////ADMIN PAGE FUNCTIONS///////////////////////////////////////////////////

export const IsPausedFixedMarketPlace = async (FixedMarketContract) => {
  try {
    const txReceipt = await FixedMarketContract.paused();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsPausedFixedMarketPlace(FixedMarketContract);
      return result;
    }
  }
};

export const pauseFixedMarketContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketPlaceContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketPlaceContract.pause();
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      pauseFixedMarketContract(walletProvider);
    }
  }
};

export const unPauseFixedMarketContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketPlaceContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketPlaceContract.unpause();
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      unPauseFixedMarketContract(walletProvider);
    }
  }
};

export const setCOOAddrFixedMarketPlace = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketContract.setCOO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCOOAddrFixedMarketPlace(Address, walletProvider);
    }
  }
};

export const GetCOOAddrFixedMarketPlace = async (FixedMarketContract) => {
  try {
    const txReceipt = await FixedMarketContract.cooAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCOOAddrFixedMarketPlace(FixedMarketContract);
      return result;
    }
  }
};

export const setCFOAddrFixedMarketPlace = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketPlaceContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketPlaceContract.setCFO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCFOAddrFixedMarketPlace(Address, walletProvider);
    }
  }
};

export const GetCFOAddrFixedMarketPlace = async (FixedMarketPlaceContract) => {
  try {
    const txReceipt = await FixedMarketPlaceContract.cfoAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCFOAddrFixedMarketPlace(FixedMarketPlaceContract);
      return result;
    }
  }
};

export const createPromoCodeFM = async (
  Name,
  Code,
  Discount,
  Count,
  walletProvider,
  ssm
) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_CREATEPROMO_FM_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketContract.CreatePromo(
      Name,
      Code,
      Discount,
      Count
    );
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      createPromoCodeFM(Name, Code, Discount, Count, walletProvider, ssm);
    }
  }
};

export const deletePromoCodeFM = async (Code, walletProvider, ssm) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_DELETEPROMO_AUC_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketContract.DeletePromo(Code);
    const txReceipt = await response.wait();
    // setTimeout(async () => {
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
    // }, [3000]);
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      deletePromoCodeFM(Code, walletProvider, ssm);
    }
  }
};

export const GetPromoCodeDetailsFM = async (Code, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const txReceipt = await AuctionContract.PromoList(Code);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      GetPromoCodeDetailsFM(Code, walletProvider);
    }
  }
};

export const resetPromoCodeFM = async (Code, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketContract.ResetPromoValAndCount(Code);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      resetPromoCodeFM(Code, walletProvider);
    }
  }
};

export const setFixedMarketFeeTessPercentage = async (
  Percentage,
  walletProvider
) => {
  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketContract.setpercentageInt(Percentage);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setFixedMarketFeeTessPercentage(Percentage, walletProvider);
    }
  }
};

export const GetTesseractPercentageFixedMarket = async (
  FixedMarketContract
) => {
  try {
    let txReceipt = await FixedMarketContract.percentageInt();
    return txReceipt.toString();
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetTesseractPercentageFixedMarket(
        FixedMarketContract
      );
      return result;
    }
  }
};

export const withdrawBalanceFixedMarket = async (Amount, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const FixedMarketContract = new ethers.Contract(
      process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await FixedMarketContract.withdrawBalance(Amount);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      withdrawBalanceFixedMarket(Amount, walletProvider);
    }
  }
};

export const GetBalanceFM = async (FixedMarketContract) => {
  try {
    let txReceipt = await FixedMarketContract.getContractBalance();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetBalanceFM(FixedMarketContract);
      return result;
    }
  }
};

//////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//Auction Contract
//////////////////////////////////////////////////////////////

export const IsOnAuctionList = async (TokenId, AuctionContract) => {
  try {
    const txReceipt = await AuctionContract.IsOnAuction(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsOnAuctionList(TokenId, AuctionContract);
      return result;
    }
  }
};

export const createAuction = async (
  TokenId,
  Price,
  Duration,
  walletProvider,
  ssm
) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_CREATEAUCTION_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.CreateAuction(
      TokenId,
      Price,
      Duration
    );
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    // return err;
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      createAuction(TokenId, Price, Duration, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

export const cancelAuction = async (TokenId, walletProvider, ssm) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_CANCELAUCTION_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.CancelAuction(TokenId);
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);

    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      cancelAuction(TokenId, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

export const sellOut = async (TokenId, walletProvider, ssm) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_SELLOUT_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.SellOut(TokenId);
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);

    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      sellOut(TokenId, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

export const GetAuction = async (TokenId, AuctionContract) => {
  try {
    const txReceipt = await AuctionContract.GetAuction(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetAuction(TokenId, AuctionContract);
      return result;
    }
  }
};

export const bid = async (TokenId, PromoCode, Money, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.Bid(TokenId, PromoCode, {
      value: ethers.utils.parseUnits(Money, "ether"),
    });
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    // if (err?.error?.data?.code === -32000) {
    //   bid(TokenId, PromoCode, Money, walletProvider);
    // }
    return err;
  }
};

export const GetPromoDetails = async (Code, AuctionContract) => {
  try {
    const txReceipt = await AuctionContract.PromoList(Code);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetPromoDetails(Code, AuctionContract);
      return result;
    }
  }
};

export const GetSaleRecordByIndexAuc = async (Index, AuctionContract) => {
  // console.log(Index,AuctionContract);
  try {
    const txReceipt = await AuctionContract.Record(Index);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetSaleRecordByIndexAuc(Index, AuctionContract);
      return result;
    }
  }
};

export const GetSaleRecordSizeAuc = async (AuctionContract) => {
  try {
    const txReceipt = await AuctionContract.GetSaleRecordSize();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetSaleRecordByIndexAuc(AuctionContract);
      return result;
    }
  }
};

////////////////ADMIN PAGE FUNCTIONS///////////////////////////////////////////////////

export const IsPausedAuctionHouse = async (AuctionContract) => {
  try {
    const txReceipt = await AuctionContract.paused();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsPausedAuctionHouse(AuctionContract);
      return result;
    }
  }
};

export const pauseAuctionContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.pause();
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      pauseAuctionContract(walletProvider);
    }
  }
};

export const unPauseAuctionContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      FIXEDMARKET_ABI,
      signer
    );
    const response = await AuctionContract.unpause();
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      unPauseAuctionContract(walletProvider);
    }
  }
};

export const setCOOAddrAuction = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.setCOO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCOOAddrAuction(Address, walletProvider);
    }
  }
};

export const GetCOOAddrAuction = async (AuctionContract) => {
  try {
    const txReceipt = await AuctionContract.cooAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCOOAddrAuction(AuctionContract);
      return result;
    }
  }
};

export const setCFOAddrAuction = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.setCFO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCFOAddrAuction(Address, walletProvider);
    }
  }
};

export const GetCFOAddrAuction = async (AuctionContract) => {
  try {
    const txReceipt = await AuctionContract.cfoAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCFOAddrAuction(AuctionContract);
      return result;
    }
  }
};

export const createPromoCodeAuc = async (
  Name,
  Code,
  Discount,
  Count,
  walletProvider,
  ssm
) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_CREATEPROMO_AUC_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.CreatePromo(
      Name,
      Code,
      Discount,
      Count
    );
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      createPromoCodeAuc(Name, Code, Discount, Count, walletProvider, ssm);
    }
  }
};

export const deletePromoCodeAuc = async (Code, walletProvider, ssm) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_DELETEPROMO_AUC_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.DeletePromo(Code);
    const txReceipt = await response.wait();
    // setTimeout(async () => {
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
    // }, [3000]);
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      deletePromoCodeAuc(Code, walletProvider, ssm);
    }
  }
};

export const GetPromoCodeDetailsAuc = async (Code, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const txReceipt = await AuctionContract.PromoList(Code);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      GetPromoCodeDetailsAuc(Code, walletProvider);
    }
  }
};

export const resetPromoCodeAuc = async (Code, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.ResetPromoValAndCount(Code);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      resetPromoCodeAuc(Code, walletProvider);
    }
  }
};

export const setAuctionFeeTessPecentage = async (
  Percentage,
  walletProvider
) => {
  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.setpercentageInt(Percentage);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setAuctionFeeTessPecentage(Percentage, walletProvider);
    }
  }
};

export const GetTessractPercentageAuction = async (AuctionContract) => {
  try {
    let txReceipt = await AuctionContract.percentageInt();
    // txReceipt = txReceipt.toString();
    // const fee = ethers.utils.formatEther(txReceipt);
    // return fee;
    return txReceipt.toString();
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetTessractPercentageAuction(AuctionContract);
      return result;
    }
  }
};

export const withdrawBalanceAuction = async (Amount, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const AuctionContract = new ethers.Contract(
      process.env.REACT_APP_AUCTION_CONTRACT_ADDR,
      AUCTION_ABI,
      signer
    );
    const response = await AuctionContract.withdrawBalance(Amount);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      withdrawBalanceAuction(Amount, walletProvider);
    }
  }
};

export const GetBalanceAuc = async (AuctionContract) => {
  try {
    let txReceipt = await AuctionContract.getContractBalance();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetBalanceFM(AuctionContract);
      return result;
    }
  }
};

//////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//CardOwnership Contract
//////////////////////////////////////////////////////////////

export const GetOwnerOf = async (TokenId, CardOwnerShipContract) => {
  try {
    const txReceipt = await CardOwnerShipContract.OwnerOf(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetOwnerOf(TokenId, CardOwnerShipContract);
      return result;
    }
  }
};

export const GetAllCardsByAddr = async (Address, CardOwnerShipContract) => {
  try {
    const txReceipt = await CardOwnerShipContract.getAllCards(Address);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetAllCardsByAddr(Address, CardOwnerShipContract);
      return result;
    }
  }
};

export const GetToken = async (TokenId, CardOwnerShipContract) => {
  try {
    const txReceipt = await CardOwnerShipContract.Database(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetToken(TokenId, CardOwnerShipContract);
      return result;
    }
  }
};

export const transferCard = async (From, To, TokenId, walletProvider, ssm) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_TRANSFERCARD_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const CardOwnerShipContract = new ethers.Contract(
      process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR,
      CARDOWNERSHIP_ABI,
      signer
    );
    const response = await CardOwnerShipContract.TransferFrom(
      From,
      To,
      TokenId
    );
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      transferCard(From, To, TokenId, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

export const transferMultipleCards = async (
  To,
  TokenIdArray,
  walletProvider,
  ssm
) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_TRANSFERCARD_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const CardOwnerShipContract = new ethers.Contract(
      process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR,
      CARDOWNERSHIP_ABI,
      signer
    );
    const response = await CardOwnerShipContract.transferMultipleCard(
      To,
      TokenIdArray
    );
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      transferMultipleCards(To, TokenIdArray, walletProvider, ssm);
    } else {
      return err;
    }
  }
};

export const createCard = async (obj, walletProvider, ssm) => {
  let Result;
  let EnvSecret;

  try {
    const params = {
      Name: process.env.REACT_APP_CREATECARD_API_URL,
      WithDecryption: true,
    };
    EnvSecret = await ssm.getParameter(params).promise();
    EnvSecret = EnvSecret.Parameter.Value;
  } catch (err) {
    console.log(err);
    if (err) {
      return Error("Aws ssm error");
    }
  }

  try {
    const signer = await walletProvider.getSigner();
    const CardOwnerShipContract = new ethers.Contract(
      process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR,
      CARDOWNERSHIP_ABI,
      signer
    );
    const response = await CardOwnerShipContract.CreateCard(
      obj.Name,
      obj.Type,
      obj.Faction,
      obj.Phy_Att,
      obj.Men_Att,
      obj.FatherId,
      obj.MotherId,
      obj.Gen
    );
    const txReceipt = await response.wait();
    if (txReceipt.blockHash) {
      console.log("Wallet tx sucessful");
      const AwsRes = await fetch(EnvSecret);
      const data = await AwsRes.text();

      Result = {
        txreceipt: txReceipt,
        Awsresponse: data,
      };
    } else {
      Result = {
        txreceipt: "Tx error",
        Awsresponse: null,
      };
      console.log("Wallet tx failed");
    }

    return Result;
  } catch (err) {
    console.log(err);
    if (err.code === Logger.errors.TRANSACTION_REPLACED) {
      if (err.cancelled) {
        console.log("The Transaction Was Canceled");
      } else if (err.reason === "repriced") {
        console.log("Transaction replaced, Wallet tx sucessful");
        const AwsRes = await fetch(EnvSecret);
        const data = await AwsRes.text();

        Result = {
          txreceipt: err.receipt,
          Awsresponse: data,
        };
        return Result;
      }
    } else if (err?.error?.data?.code === -32000) {
      createCard(obj, walletProvider, ssm);
    }
  }
};

export const GetCardCount = async (Address, CardOwnerShipContract) => {
  try {
    const txReceipt = await CardOwnerShipContract.balanceOf(Address);
    return txReceipt.toString();
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCardCount(Address, CardOwnerShipContract);
      return result;
    }
  }
};

////////////////ADMIN PAGE FUNCTIONS///////////////////////////////////////////////////
export const pauseCardOwnershipContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const CardOwnerShipContract = new ethers.Contract(
      process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR,
      CARDOWNERSHIP_ABI,
      signer
    );
    const response = await CardOwnerShipContract.pause(0);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      pauseCardOwnershipContract(walletProvider);
    }
  }
};

export const unPauseCardOwnershipContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const CardOwnerShipContract = new ethers.Contract(
      process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR,
      CARDOWNERSHIP_ABI,
      signer
    );
    const response = await CardOwnerShipContract.unpause(0);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      unPauseCardOwnershipContract(walletProvider);
    }
  }
};

export const setCOOAddrCardOwnerShip = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const CardOwnerShipContract = new ethers.Contract(
      process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR,
      CARDOWNERSHIP_ABI,
      signer
    );
    const response = await CardOwnerShipContract.setCOO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCOOAddrCardOwnerShip(Address, walletProvider);
    }
  }
};

export const GetCOOAddrCardOwnerShip = async (CardOwnerShipContract) => {
  try {
    const txReceipt = await CardOwnerShipContract.cooAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCOOAddrCardOwnerShip(CardOwnerShipContract);
      return result;
    }
  }
};

export const setCFOAddrCardOwnerShip = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const CardOwnerShipContract = new ethers.Contract(
      process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR,
      CARDOWNERSHIP_ABI,
      signer
    );
    const response = await CardOwnerShipContract.setCFO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCFOAddrCardOwnerShip(Address, walletProvider);
    }
  }
};

export const GetCFOAddrCardOwnerShip = async (CardOwnerShipContract) => {
  try {
    const txReceipt = await CardOwnerShipContract.cfoAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCFOAddrCardOwnerShip(CardOwnerShipContract);
      return result;
    }
  }
};

export const IsPausedCardOwnerShip = async (CardOwnerShipContract) => {
  try {
    const txReceipt = await CardOwnerShipContract.paused();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsPausedCardOwnerShip(CardOwnerShipContract);
      return result;
    }
  }
};

//////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//GameCentre Contract
//////////////////////////////////////////////////////////////

export const GetCardFaction = async (TokenId, GameCentreContract) => {
  //   console.log(TokenId, "faction functio");
  try {
    const txReceipt = await GameCentreContract.GetCardFraction(TokenId);
    return txReceipt;
  } catch (err) {
    // console.log(err,"card faction console");
    if (err?.error?.data?.code === -32000) {
      const result = await GetCardFaction(TokenId, GameCentreContract);
      //   console.log(result, "testing recursive");
      return result;
    }
  }
};

export const GetCardInfo = async (TokenId, GameCentreContract) => {
  try {
    const txReceipt = await GameCentreContract.GetCardInfo(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCardInfo(TokenId, GameCentreContract);
      //   console.log(result, "testing recursive");
      return result;
    }
  }
};

export const GetCardType = async (TokenId, GameCentreContract) => {
  try {
    const txReceipt = await GameCentreContract.GetCardType(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err, "error");
    const result = await GetCardType(TokenId, GameCentreContract);
    // console.log(result, "testing recursive");
    return result;
  }
};

export const GetStats = async (TokenId, GameCentreContract) => {
  try {
    const txReceipt = await GameCentreContract.GetStats(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetStats(TokenId, GameCentreContract);
      //   console.log(result, "testing recursive");
      return result;
    }
  }
};

export const GetGender = async (TokenId, GameCentreContract) => {
  try {
    const txReceipt = await GameCentreContract.GetGender(TokenId);
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetGender(TokenId, GameCentreContract);
      return result;
    }
  }
};

////////////////ADMIN PAGE FUNCTIONS///////////////////////////////////////////////////

export const IsPausedGameCentre = async (GameCentreContract) => {
  try {
    const txReceipt = await GameCentreContract.paused();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await IsPausedGameCentre(GameCentreContract);
      return result;
    }
  }
};

export const pauseGameCentreContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const GameCentreContract = new ethers.Contract(
      process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR,
      GAMECENTRE_ABI,
      signer
    );
    const response = await GameCentreContract.pause();
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      pauseGameCentreContract(walletProvider);
    }
  }
};

export const unPauseGameCentreContract = async (walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const GameCentreContract = new ethers.Contract(
      process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR,
      GAMECENTRE_ABI,
      signer
    );
    const response = await GameCentreContract.unpause();
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      unPauseGameCentreContract(walletProvider);
    }
  }
};

export const setCOOAddrGameCentre = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const GameCentreContract = new ethers.Contract(
      process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR,
      GAMECENTRE_ABI,
      signer
    );
    const response = await GameCentreContract.setCOO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCOOAddrGameCentre(Address, walletProvider);
    }
  }
};

export const GetCOOAddrGameCentre = async (GameCentreContract) => {
  try {
    const txReceipt = await GameCentreContract.cooAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCOOAddrGameCentre(GameCentreContract);
      return result;
    }
  }
};

export const setCFOAddrGameCentre = async (Address, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const GameCentreContract = new ethers.Contract(
      process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR,
      GAMECENTRE_ABI,
      signer
    );
    const response = await GameCentreContract.setCFO(Address);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      setCFOAddrGameCentre(Address, walletProvider);
    }
  }
};

export const GetCFOAddrGameCentre = async (GameCentreContract) => {
  try {
    const txReceipt = await GameCentreContract.cfoAddress();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetCFOAddrGameCentre(GameCentreContract);

      return result;
    }
  }
};

export const GetContractBalanceGameCentreLink = async (GameCentreContract) => {
  try {
    let txReceipt = await GameCentreContract.getLinkBalance();
    txReceipt = txReceipt.toString();
    const fee = ethers.utils.formatEther(txReceipt);
    return fee;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      const result = await GetContractBalanceGameCentreLink(GameCentreContract);
      return result;
    }
  }
};

export const withdrawBalanceGameCentreLink = async (Amount, walletProvider) => {
  try {
    const signer = await walletProvider.getSigner();
    const GameCentreContract = new ethers.Contract(
      process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR,
      GAMECENTRE_ABI,
      signer
    );
    const response = await GameCentreContract.withdrawLink(Amount);
    const txReceipt = await response.wait();
    return txReceipt;
  } catch (err) {
    console.log(err);
    if (err?.error?.data?.code === -32000) {
      withdrawBalanceGameCentreLink(Amount, walletProvider);
    }
  }
};
