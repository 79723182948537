import React from "react";
import "../../../PurchaseCards.css";
import { Link, Outlet } from "react-router-dom";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../aws-exports";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "../../../../Shared/Pagination";
import MarketPlaceCard from "../../../../Shared/MarketPlaceCard/MarketPlaceCard";

function MarketHumanity() {
  const [TokenId, setTokenId] = useState([]);
  const [TokenArray, setTokenArray] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_PURCHASE_TABLE_NAME,
    Limit: 30,
    FilterExpression: "contains(Faction,:fName)",

    ExpressionAttributeValues: {
      ":fName": "Humanity",
    },

    ProjectionExpression: "TokenId",
  });

  async function GetTokenId() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    const Id = [];
    try {
      const Response = await documentClient.scan(param).promise();

      if (Response.LastEvaluatedKey) {
        setParam({
          TableName: process.env.REACT_APP_PURCHASE_TABLE_NAME,
          Limit: 30,
          FilterExpression: "contains(Faction,:fName)",

          ExpressionAttributeValues: {
            ":fName": "Humanity",
          },

          ExclusiveStartKey: Response.LastEvaluatedKey,
          ProjectionExpression: "TokenId",
        });
      }
      //   console.log(Response.Items.tokenId, "resalice");
      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        if (TokenId.indexOf(element.TokenId) === -1) {
          Id.push(element.TokenId);
          setNext(true);
        } else {
          setNext(false);
        }
      }
    } catch (err) {
      // console.log(err);
    }
    return Id;
  }

  const Next = async () => {
    if (page === TokenArray.length) {
      const Id = await GetTokenId();
      if (Id.length === 0) {
        setNext(false);
      } else {
        setNext(true);
        setTokenId((TokenId) => [...TokenId, ...Id]);
        setTokenArray((TokenArray) => [...TokenArray, Id]);
        setPage((page) => page + 1);
      }
    } else {
      setPage((page) => page + 1);
    }
  };
  const Previous = () => {
    setNext(true);
    setPage((page) => page - 1);
  };
  useEffect(() => {
    const TokenArr = async () => {
      const Id = await GetTokenId();
      setTokenId((TokenId) => [...TokenId, ...Id]);
      setTokenArray((TokenArray) => [...TokenArray, Id]);
    };
    TokenArr();
  }, []);
  //   console.log(TokenId);
  // console.log(page, "page");
  // console.log(TokenArray.length, "array");
  // console.log(next, "next");
  return (
    <div>
       <section className="cards_menu_list mt-3">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <div className="nav_menu">
            <ul>
                  <li className="nav-item">
                     <Link className="nav-link" to="/marketplace">
                     ALL CARDS</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link" to="/marketplace/blacknet">
                     BLACKNET</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link " to="/marketplace/alice">
                     ALICE</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link active" to="/marketplace/humanity">
                     HUMANITY</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link" to="/marketplace/genesis">
                     GENESIS</Link>
                  </li>
                  </ul>
            </div>
         </div>
      </div>
   </div>
</section>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="content_box">
            <h4>Humanity</h4>
              <div className="row">
                {TokenArray[page - 1]?.map((TokenId) => (
                  <MarketPlaceCard TokenId={TokenId} key={TokenId}></MarketPlaceCard>
                ))}
              </div>
              <Pagination
                page={page}
                setPage={setPage}
                length={TokenArray.length}
                Previous={Previous}
                Next={Next}
                next={next}
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MarketHumanity;
