import React from 'react';
function MetaverseText () {
	return <div>
<section className="metaversetext_section">
         <div className="container">
            <div className="row">
              
               <div className="col-md-10 offset-md-1">
                  <div className="metaverse_text_box text-center">
                      <h2>THE EXTREMIS METAVERSE</h2>
                     <p>The world of EXTREMIS brims with adventure and conflicts as three conscious beings, engaged in a war, are paving the way to create their versions of an ideal world. The metaverse consists of three races being allies and foes: Humanity, Blacknet and Alice.</p>
                     
                  </div>
               </div>
                
            </div>
         </div>
      </section>
    </div>
    }
    export default MetaverseText;