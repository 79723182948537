import React, { useEffect, useState } from "react";

const useColor = (CardRank, CardType) => {
  const [color, setColor] = useState("");

  const Color = (CardRank) => {
    if (CardRank === "Private") {
      setColor("Private");
    } else if (CardRank === "2nd Lieutenant") {
      setColor("Second-Lieutenant");
    } else if (CardRank === "1nd Lieutenant") {
      setColor("First-Lieutenant");
    } else if (CardRank === "Captain") {
      setColor("Captain");
    } else if (CardRank === "Major") {
      setColor("Major");
    } else if (CardRank === "Lieutenant Colonel") {
      setColor("Lieutenant-Colonel ");
    } else if (CardRank === "Colonel") {
      setColor("Colonel");
    } else if (CardRank === "Brigadier General") {
      setColor("Brigadier-General");
    } else if (CardRank === "Major General") {
      setColor("Major-General");
    } else if (CardRank === "Lieutenant General") {
      setColor("Lieutenant-General");
    } else if (CardRank === "General") {
      setColor("General");
    } else if (CardRank === "Legendary") {
      setColor("Legendary");
    } else if (CardRank === "Deserter") {
      setColor("Deserter");
    }
  };
  const Color2 = (CardType) => {
    if (CardType === "Genesis") {
      setColor("Genesis");
    }
  };

  useEffect(() => {
    Color(CardRank);
  }, [CardRank]);

  useEffect(() => {
    Color2(CardType);
  }, [CardType]);

  return color;
};

export default useColor;
