import React from 'react';
import BreedOthersBanner from '../BreedOthers/Sections/BreedOthersBanner';
import BreedOthersText from '../BreedOthers/Sections/BreedOthersText';
import BreedOtherCards from '../BreedOthers/Sections/BreedOtherCards';
import '../BreedOthers/BreedOthers.css';
import { Outlet } from 'react-router-dom';
function BreedOthers(){
 return <div>
<BreedOthersBanner/>
{/* <BreedOthersText/>
<BreedOtherCards/> */}
<Outlet></Outlet>
</div>


}
export default BreedOthers;