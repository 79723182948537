import React, { useEffect, useState } from "react";
import LeaderBoardChild from "./LeaderBoardChild";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../aws-exports";
import Pagination from "../../../../../Shared/Pagination";
import Leaderboard from "../../../Leaderboard";
import Loader from "../../../../../Shared/Loader/Loader";
function LeaderboardText() {
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [TokenId, setTokenId] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [loader, setLoader] = useState(true);

  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_BOARD_TABLE_NAME,
  });

  async function GetLeaderBoard() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    try {
      const Response = await documentClient.scan(param).promise();
      const result = Response.Items.sort((a, b) => {
        return b.WinCount - a.WinCount;
      });
     
      return result;
    } catch (err) {
      // console.log(err);
    }
  }
  useEffect(() => {
    if (Math.ceil(leaderBoard.length / 20) === page-1 || leaderBoard.length===0) {
      setNext(false);
    }
  }, [leaderBoard, page]);
  useEffect(() => {
    if (AWS) {
      const Token = async () => {
        const Id = await GetLeaderBoard();
        setLoader(false);
        setLeaderBoard((TokenId) => [...leaderBoard, ...Id]);
      };
      Token();
    }
  }, [AWS]);
  const Next = async () => {
    setNext(false);
    setPage((page) => page + 1);
    setNext(true);
  };
  const Previous = async () => {
    setPage((page) => page - 1);
  };
  return (
    <div>
      <section className="board_section">
        <div className="container">
          <div className="row align-items-center">
            {loader ? (
              <Loader></Loader>
            ) : (
              <div className="col-md-10 offset-md-1">
                <div className="board_text_section">
                  <h2 className="text-center">LEADERBOARD</h2>
                  {leaderBoard.map((position, i) => (
                    <LeaderBoardChild
                      position={position}
                      key={(page - 1) * 20 + i + 1}
                      i={(page - 1) * 20 + i}
                    ></LeaderBoardChild>
                  ))}
                   {
                  leaderBoard?.length===0 && <h1>NOT AVAILABLE</h1>
                }
                </div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  length={leaderBoard.length / 20}
                  Previous={Previous}
                  Next={Next}
                  next={next}
                ></Pagination>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
export default LeaderboardText;
