import React from 'react';
import SellAllcardsList from './Sections/SellAllcardsList';
// import AllcardsList from './Sections/SellAllcardsList';
import SellAllCardsText from './Sections/SellAllcardsText';
// import AllCardsText from './Sections/SellAllcardsText';

const SellAllCard = () => {
    return (
        <div>
            <SellAllcardsList></SellAllcardsList>
            <SellAllCardsText></SellAllCardsText>
        </div>
    );
};

export default SellAllCard;