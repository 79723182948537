import React, { useContext } from "react";
import { useState, useEffect } from "react";

// components
import ArrowIcon from "./AdminComponent/ArrowIcon";
import BasicButton from "./AdminComponent/BasicButton";
import FormOneInput from "./AdminComponent/FormOneInput";
import FormSubmit from "./AdminComponent/FormSubmit";
import FormTowInput from "./AdminComponent/FormTowInput";
import Form from "./AdminComponent/Form";
import TitleForm from "./AdminComponent/TitleForm";
import InputForm from "./AdminComponent/InputForm";
import LoadingSpinner from "./AdminComponent/LoadingSpinner";
import {
  createCard,
  GetAllCardsByAddr,
  GetCFOAddrCardOwnerShip,
  GetCOOAddrCardOwnerShip,
  GetOwnerOf,
  IsPausedCardOwnerShip,
  pauseCardOwnershipContract,
  setCFOAddrCardOwnerShip,
  setCOOAddrCardOwnerShip,
  transferCard,
  transferMultipleCards,
  unPauseCardOwnershipContract,
} from "../../UserPage/BlockChainFunctions";
import { providerContext } from "../../../App";
import * as AWS from "aws-sdk";
import useCardOwnerShipContract from "../../Hooks/CardOwnershipHook";

//const API Calls from ethers
/////////////////////////////////////////////////////////////////////

function CardOwnership(props) {
  // const [loading, setLoading] = useState(false);
  const [rotate, setRotate] = useState("");
  const [dropDownClass, setDropDownClass] = useState("drop-down-close");
  const {
    setPopUp,
    popUpSubject,
    popUpText,
    openModal,
    dropDown,
    handlerOwner,
    CardIsPause,
    cardPause,
    setLoading,
  } = props;
  const [inputE, setInputE] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
    input7: "",
    input8: "",
  });
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [cardOwnerShipContract, setCardOwnerShipContract] =
    useCardOwnerShipContract();
  const ssm = new AWS.SSM();

  const [cfo, setCfo] = useState("");
  const [coo, setCoo] = useState("");
  const [cardDetails, setCardDetails] = useState();

  const CreateCard = async (props) => {
    const obj = {
      Name: props.input1,
      Type: props.input2,
      Faction: props.input3,
      Phy_Att: props.input4,
      Men_Att: props.input5,
      FatherId: props.input6,
      MotherId: props.input7,
      Gen: props.input8,
    };
    // console.log(obj);

    // console.log(result, "create card");
    setLoading(true);
    try {
      const result = await createCard(obj, walletProvider, ssm);
      if (result?.txreceipt?.blockHash) {
        setCardDetails(obj);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cardOwnerShipContract) {
      CardIsPause();
    }
  }, [cardOwnerShipContract]);

  const Pause = async () => {
    setLoading(true);
    try {
      const result = await pauseCardOwnershipContract(walletProvider);
      // setPauseOn(true)
      CardIsPause();
      // console.log(result);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const Unpause = async () => {
    setLoading(true);
    try {
      const result = await unPauseCardOwnershipContract(walletProvider);
      // console.log(result);
      CardIsPause();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getCOOAddr = async () => {
    const result = await GetCOOAddrCardOwnerShip(cardOwnerShipContract);
    // console.log(result, "get coo ");
    setCoo(result);
  };

  const getCFOAddr = async () => {
    const result = await GetCFOAddrCardOwnerShip(cardOwnerShipContract);
    // console.log(result, "get cfo ");
    setCfo(result);
  };

  useEffect(() => {
    if (cardOwnerShipContract) {
      getCOOAddr();
    }
  }, [cardOwnerShipContract]);
  useEffect(() => {
    if (cardOwnerShipContract) {
      getCFOAddr();
    }
  }, [cardOwnerShipContract]);

  const SetCOO = async (Address) => {
    setLoading(true);
    try {
      const result = await setCOOAddrCardOwnerShip(Address, walletProvider);
      getCOOAddr();
      // console.log(result, "set coo");

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const SetCFO = async (Address) => {
    setLoading(true);
    try {
      const result = await setCFOAddrCardOwnerShip(Address, walletProvider);
      getCFOAddr();
      // console.log(result, "set cFo");

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  //End API call

  const onArrowHandler = () => {
    handlerOwner();
  };
  useEffect(() => {
    if (dropDown) {
      setDropDownClass("drop-down-open");
      setRotate("rotate");
    } else {
      setDropDownClass("drop-down-close");
      setRotate("");
    }
  }, [dropDown]);

  return (
    <div>
      {/* {loading && <LoadingSpinner />} */}

      {/* Start Navigation Bar  */}
      <div className="a-main-container">
        <div className="a-first-section">
          <ArrowIcon rotate={rotate} onClick={onArrowHandler} />
          <div className="a-title">Card Ownership</div>
        </div>
        <div className="a-second-section">
          <div className="text-holder">
            {cardPause ? "Pause" : "Unpause"}
          </div>
          {!cardPause ? (
            <BasicButton onClick={Pause}>Pause</BasicButton>
          ) : (
            <BasicButton onClick={Unpause}>unpause</BasicButton>
          )}
        </div>
      </div>
      {/* End Navigation Bar  */}

      <div className={dropDownClass}>
        <div className="section">
          <div className="main">
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                CreateCard(inputE);
                setInputE({
                  input1: "",
                  input2: "",
                  input3: "",
                  input4: "",
                  input5: "",
                  input6: "",
                  input7: "",
                  input8: "",
                });
              }}
            >
              <TitleForm text="Used to create new cards manually">
                Create card
              </TitleForm>
              <div className="input-content-start">
                <InputForm
                  value={inputE.input1}
                  onChange={(ev) => {
                    setInputE({ ...inputE, input1: ev });
                  }}
                  label="Name"
                />

                <InputForm
                  value={inputE.input2}
                  onChange={(ev) => {
                    setInputE({ ...inputE, input2: ev });
                  }}
                  label="Type"
                />

                <InputForm
                  value={inputE.input3}
                  onChange={(ev) => {
                    setInputE({ ...inputE, input3: ev });
                  }}
                  label="Faction"
                />

                <InputForm
                  value={inputE.input4}
                  onChange={(ev) => {
                    setInputE({ ...inputE, input4: ev });
                  }}
                  label="Chromosome: Physical Features"
                />

                <InputForm
                  value={inputE.input5}
                  onChange={(ev) => {
                    setInputE({ ...inputE, input5: ev });
                  }}
                  label="Chromosome: Mental Attributes"
                />

                <InputForm
                  value={inputE.input6}
                  onChange={(ev) => {
                    setInputE({ ...inputE, input6: ev });
                  }}
                  label="Father’s Token ID"
                />

                <InputForm
                  value={inputE.input7}
                  onChange={(ev) => {
                    setInputE({ ...inputE, input7: ev });
                  }}
                  label="Mother’s Token ID"
                />
                <InputForm
                  value={inputE.input8}
                  onChange={(ev) => {
                    setInputE({ ...inputE, input8: ev });
                  }}
                  label="Generation"
                />
              </div>
              <BasicButton>Transact</BasicButton>
            </Form>
          </div>
          <div className="sub">
            <h5>New card created</h5>
            {/* <p>
              Token ID: <span>{}</span>
            </p> */}
            <p>
              Type: <span>{cardDetails?.Type}</span>
            </p>
            <p>
              Faction: <span>{cardDetails?.Faction}</span>
            </p>
            <p>
              Features gene: <span>{cardDetails?.Phy_Att}</span>
            </p>
            <p>
              Attributes gene: <span>{cardDetails?.Men_Att}</span>
            </p>
            <p>
              Generation: <span>{cardDetails?.Gen}</span>
            </p>
          </div>
        </div>
        {/* <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getCOOAddr();
              }}
              textInfo="Get COO ownership address          "
              formTitle="Get COO ownership address          "
            />
          </div>
          <div className="sub">
            <h5>Current COO ownership address</h5>
            <p>
              Address: <span>{}</span>
            </p>
          </div>
        </div>
       
      
        <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getCFOAddr();
              }}
              textInfo="Get CFO ownership address          "
              formTitle="Get CFO ownership address          "
            />
          </div>
          <div className="sub">
            <h5>Current CFO ownership address</h5>
            <p>
              Address: <span>{}</span>
            </p>
          </div>
        </div> */}

        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCFO(ev);
              }}
              textInfo="Set CFO ownership address          "
              formTitle="Set CFO ownership address          "
              label="Address"
            />
          </div>
          <div className="sub">
            <h5>Current CFO ownership address</h5>
            <p>
              Address: <span>{cfo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCOO(ev);
              }}
              textInfo="Set COO ownership address          "
              formTitle="Set COO ownership address          "
              label="Address"
            />
          </div>
          <div className="sub">
            <h5>Current COO ownership address</h5>
            <p>
              Address: <span>{coo}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardOwnership;
