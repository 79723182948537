import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useAuctionContract from "../../../../Hooks/AuctionContractHook";
import useFixedMarketContract from "../../../../Hooks/FixedMarketHook";
import {
  GetSaleRecordByIndexAuc,
  GetSaleRecordByIndexFM,
} from "../../../../UserPage/BlockChainFunctions";

const DataTableRowAuction = ({ record, index }) => {
  const [auctionContract, setAuctionContract] = useAuctionContract();
  const [sales, setSales] = useState();
  const { ethers } = require("ethers");
  const [price, setPrice] = useState();
  const [time,setTime]=useState()
  useEffect(() => {
    // if (record && auctionContract) {
    const func = async () => {
      const result = await GetSaleRecordByIndexAuc(record, auctionContract);
      setPrice(ethers.utils.formatEther(result?.Price?.toString()));
      setSales(result);
      setTime( new Date(result.SaleTime.toString() * 1000).toLocaleString(
        "en-US"
      ))
    };
    func();
    // }
  }, [record, auctionContract]);
  // console.log(sales, record, "sales result auction");
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{sales?.TokenId.toString()}</td>
      <td>{sales?.Buyer}</td>
      <td>{sales?.Seller}</td>
      <td>{time}</td>
      <td>{price}</td>
    </tr>
  );
};

export default DataTableRowAuction;
