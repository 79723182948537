import React from "react";
import { useDrag } from "react-dnd";
import { Link, useNavigate } from "react-router-dom";
import useCard from "../Card/useCard";
import useStats from "../useStates";
import * as AWS from "aws-sdk";
import awsmobile from "../../../aws-exports";
import "../../Profile/Pages/Breeding/BreedSelfCollection/breed.css";
import useWarCard from "./useWarCard";
import Loader from "../Loader/Loader";
import "../Card.css";
import useImage from "../Card/useImage";
import useColor from "../useColor";

import useSellCard from "../SellCard/useSellCard";
import { useEffect } from "react";
import { useState } from "react";
import useRankImage from "../useRankImage";
import { useContext } from "react";
import {
  addressContext,
  navigationContext,
  providerContext,
} from "../../../App";
import WarExit from "../../Profile/Pages/IndividualCard/Sections/WarExit";
import LoaderFloating from "../LoaderFloating";
import PopUp from "../PopUp";
import { exitWar } from "../../UserPage/BlockChainFunctions";
const GetCustomerInfo = async (TokenId, BattleId) => {
  const documentClient = new AWS.DynamoDB.DocumentClient({
    region: awsmobile.aws_cognito_region,
    credentials: AWS.config.credentials,
  });
  const param = {
    TableName: process.env.REACT_APP_WAR_TABLE_NAME,
    Key: {
      TokenId: TokenId,
      BattleFieldID: BattleId,
    },
  };
  // console.log(param, "hello param");
  const response = await documentClient.get(param).promise();
  // console.log(response, "ffffffffff");
  return response;
};
const getUserName = async (address) => {
  const documentClient = new AWS.DynamoDB.DocumentClient({
    region: awsmobile.aws_cognito_region,
    credentials: AWS.config.credentials,
  });
  const param = {
    TableName: process.env.REACT_APP_CUSTOMERINFO_TABLE_NAME,
    Key: {
      CustomerID: address,
    },
  };
  const response = await documentClient.get(param).promise();
  return response
};
const WarCard = (props) => {
  const [CardFaction, CardName, CardType, CardRank, CardGender] = useSellCard(
    props.TokenId
  );
  const [image, setImage] = useState(false);
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [exitWarConfirmation, setExitWarConfirmation] = useState(false);
  const [warLoading, setWarLoading] = useState(false);
  const [confirmWarExit, setConfirmWarExit] = useState(false);
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState();
  const [popUpText1, setPopUpText1] = useState();
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);
  const [navConfirm,setNavConfirm]=useState(false)

  const [userName, setUserName] = useState("");
  const navigate = useNavigate();

  const ssm = new AWS.SSM();
  useEffect(() => {
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);
  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(props.TokenId);
  useEffect(() => {
    const CustomerInfo = async () => {
      if (
        awsmobile.aws_cognito_region &&
        AWS.config.credentials &&
        currentAddress
      ) {
        const customerInfo = await GetCustomerInfo(
          props.TokenId,
          props.BattleId
        );
        const ownerUserName = await getUserName(customerInfo?.Item?.CustomerId);
        setUserName(ownerUserName?.Item?.Username);
        if (customerInfo?.Item?.CustomerId === currentAddress) {
          setExitWarConfirmation(true);
        }
      }
    };
    CustomerInfo();
  }, [awsmobile.aws_cognito_region, AWS.config.credentials, currentAddress]);
  const ExitFromWar = async () => {
    // console.log("hello from function");
    // if (currentAddress === customerInfo?.Item?.CustomerID) {
    setConfirmWarExit(false);
    setWarLoading(true);
    try {
      // console.log("inside try");
      const result = await exitWar(
        props.TokenId,
        props.BattleId,
        walletProvider,
        ssm
      );
      // exitWar = async (TokenId, BattleFieldID, walletProvider, ssm)
      setWarLoading(false);
      if (result.txreceipt?.blockHash) {
        setNavConfirm(true)
        setPopUpSubject("REMOVED FROM WAR");
        setPopUpText(`Transaction Hash: ${result.txreceipt.transactionHash}`);
        setPopUpText1(` Aws Response :${result.Awsresponse}`);
        setPopUp(true);
      }
      if (result?.message) {
        setPopUpSubject("Oops");
        setPopUpText(result.message);
        setPopUp(true);
      }
      if (result.data?.message) {
        setPopUpSubject("Oops");
        setPopUpText(result.data.message);
        setPopUp(true);
      }
    } catch (err) {
      setWarLoading(false);
    }
    // } else {
    //   setPopUpSubject("Connect your registered wallet");
    //   setPopUp(true);
    // }
  };

  useEffect(() => {
    if (navigationCntxt === true && navConfirm===true) {
      navigate("/war-zone");
      window.location.reload();
    }
  }, [navigationCntxt,navConfirm]);
  return (
    <div className="card_section my-5 word-wrapping">
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_img">
          <img
            style={
              CardFaction &&
              CardType &&
              CardRank &&
              IQ &&
              EQ &&
              Wisdom &&
              Defense &&
              Weapon &&
              Leadership &&
              Melee &&
              Potential &&
              NaturalAdpt
                ? {}
                : { display: "none" }
            }
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />
          <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />{" "}
          <img
            className="extremis-logo"
            src="/LOGO.png"
            alt=""
          />
          {CardFaction &&
          CardType &&
          CardRank &&
          IQ &&
          EQ &&
          Wisdom &&
          Defense &&
          Weapon &&
          Leadership &&
          Melee &&
          Potential &&
          NaturalAdpt ? (
            <>
              <div className="card_info text-white">
                <h3>{CardName}</h3>
                <p className=" text-white">{CardFaction}</p>
              </div>
              <h5 className="text-white">Owner: {userName}</h5>
              <div class="list_box">
                <ul>
                  <li>
                    <span>ID: </span>
                    {props.TokenId}
                  </li>
                  <li>
                    <span>Rank: </span> {CardRank}
                  </li>

                  <li>
                    <span>Type: </span>
                    {CardType}
                  </li>
                  <li>
                    <span>IQ: </span> {IQ}
                  </li>
                  <li>
                    <span>Potential: </span> {Potential}
                  </li>
                  <li>
                    <span>EQ: </span> {EQ}
                  </li>
                  <li>
                    <span>Wisdom: </span>
                    {Wisdom}
                  </li>
                  <li>
                    <span>Melee: </span> {Melee}
                  </li>
                  <li>
                    <span>Leadership: </span> {Leadership}
                  </li>
                  <li>
                    <span>Weapon: </span> {Weapon}
                  </li>
                  <li>
                    <span>Defense: </span> {Defense}
                  </li>
                  {CardFaction === "Humanity" ? (
                    <li>
                      <span>N. Adaptability: </span>
                      {NaturalAdpt}
                    </li>
                  ) : (
                    <li>
                      <span>AI Influence: </span>
                      {NaturalAdpt}
                    </li>
                  )}
                </ul>
              </div>
              {exitWarConfirmation && (
                <button
                  style={{ width: "100%" }}
                  className="war-button"
                  onClick={() => setConfirmWarExit(true)}
                >
                  {!warLoading ? "EXIT WAR" : "PLEASE WAIT ..."}
                </button>
              )}
            </>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
      {confirmWarExit && (
        <WarExit
          ExitFromWar={ExitFromWar}
          setConfirmWarExit={setConfirmWarExit}
        ></WarExit>
      )}
      {warLoading && <LoaderFloating></LoaderFloating>}
      {popup && (
        <PopUp
          popUpSubject={popUpSubject}
          popUpText={popUpText}
          popUpText1={popUpText1}
          setPopUp={setPopUp}
        ></PopUp>
      )}
    </div>
  );
};

export default WarCard;
