import React from 'react';
import breed1 from "../../../../../../../assets/Picture1.png"
import breed2 from "../../../../../../../assets/Picture2.png"
function Faq5 () {
	return <div>

<div className="faq_box">
   <div className="row">
   <div class="accordion" id="accordionPanelsStayOpenExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingThirtyEight">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThirtyEight" aria-expanded="true" aria-controls="panelsStayOpen-collapseThirtyEight">
      Q1: How do I breed my cards?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThirtyEight" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThirtyEight">
      <div class="accordion-body">
        <p>A: : Players can add more cards to their collection by breeding between existing cards. Breeding is only possible between cards belonging to the same faction. There are two ways to breed cards in EXTREMIS: Breeding with own cards and Breeding with others.
<br /> To breed new cards, players must first put potential parent cards on the 'Breeding list' if Breeding with other player's card. The 'Breeding list' can be found under the 'Play' section in the header menu. Putting cards on the 'Breeding list' does not require any money.
<br />

<img src={breed1} alt="" />
Once cards are put on the breeding list, they can be found in the ‘Breeding Lab’. This function can be accessed from the ‘Play’ section in the header menu. In the ‘Breeding With Others’ section, players can drag and drop cards in the specific slots and click ‘Initiate breeding’ to complete the breeding process.
<br />

<img src={breed2} alt="" />
After each breeding session, the offspring need 8 hrs from birth to reach maturity so that they can breed with others, and parents cards need 8 hours recovery time. In the case of cards belonging to factions Blacknet and Alice, there are no gender-based restrictions for breeding. On the other hand, for faction Humanity, players need to choose a male and female card for breeding a new card.
<br />
Breeding cards within your own collection are free of cost.
<br />
Players can charge money from others to breed with their cards in addition to the standard ‘Breed fee’ within the game.


 </p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingThirtyNine">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThirtyNine" aria-expanded="false" aria-controls="panelsStayOpen-collapseThirtyNine">
      Q2: Are there any limitations while breeding cards?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThirtyNine" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThirtyNine">
      <div class="accordion-body">
        <p>A: Breeding cannot be done among cards belonging to different factions. After each breeding sessions, offspring needs 8 hrs from birth to reach maturity so that they can breed with others, and parents need 8 hours to recover as well before they can be bred again.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingForty">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseForty" aria-expanded="false" aria-controls="panelsStayOpen-collapseForty">
      Q3: Why does it cost MATIC to breed with others?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseForty" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingForty">
      <div class="accordion-body">
       <p>A: While breeding with others, initiators may assign a price for breeding their cards with potential partners. If the fees are reasonable, card owners of a potential partner card can pay up the fee to the initiator so that they can be owners of the offspring cards. After each breed session cards are automatically removed from the breed list.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingFortyOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFortyOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseFortyOne">
      Q4: How much does it cost to breed with others?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFortyOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFortyOne">
      <div class="accordion-body">
        <p>A: The standard breed fee is 5 MATIC excluding gas fees and the price assigned by breed initiators.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingFortyTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFortyTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseFortyTwo">
      Q5: Where’s my new card after breeding?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFortyTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFortyTwo">
      <div class="accordion-body">
        <p>A: New cards or offspring cards should automatically be added to your personal card collection directory after the breeding process is complete. Sometimes the procedure may take some time due to delays in the blockchain network. In case your new cards do not show up at all, feel free to contact with the EXTREMIS customer care/help centre.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingFortyThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFortyThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseFortyThree">
      Q6: How often can I breed my cards?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFortyThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFortyThree">
      <div class="accordion-body">
        <p>A: There are no restrictions to how often you can breed your cards apart from the 8 hour resting period between each breeding sessions.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingFortyFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFortyFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFortyFour">
      Q7: How does the cards’ genealogy work?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFortyFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFortyFour">
      <div class="accordion-body">
        <p>A: Every card in EXTREMIS metaverse has a unique gene-code that hints upon its functional and physical attributes. Each segment of the code represents a specific feature.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingFortyFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFortyFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFortyFive">
      Q8: Is it possible for cards to become sterile?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFortyFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFortyFive">
      <div class="accordion-body">
        <p>A: No, cards in EXTREMIS can never become sterile.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingFortySix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFortySix" aria-expanded="false" aria-controls="panelsStayOpen-collapseFortySix">
      Q9: Can I breed cards between factions? Can I breed Genesis Cards?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFortySix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFortySix">
      <div class="accordion-body">
        <p>A: No, inter-faction breeding is not possible in EXTREMIS. No you can't breed genesis cards.</p>
      </div>
    </div>
  </div>
</div>
   </div>
</div>
     </div>
     }
     export default Faq5;