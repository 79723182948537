import React from 'react';
// import Humanitybanner from '../Cardcollection/SubPages/Humanity/Sections/Humanitybanner';
import HumanityText from './SubPages/Humanity/Sections/MutateHumanityText';
import HumanityList from './SubPages/Humanity/Sections/MutateHumanityList';
import '../MutateCollection/CardCollection.css';
import MutateHumanityList from './SubPages/Humanity/Sections/MutateHumanityList';
import MutateHumanityText from './SubPages/Humanity/Sections/MutateHumanityText';

function MutateHumanityCard () {
	return <div>
		{/* <Humanitybanner/> */}
		<MutateHumanityList/>
		<MutateHumanityText/>

        
	</div>
}
export default MutateHumanityCard;