import React, { useContext, useEffect, useState } from "react";
import { addressContext, providerContext } from "../../App";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
const useBattleListOthersCard = () => {
  // console.log("hello,hhh");
  const [TokenId, setTokenId] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [loader, setLoader] = useState(true);
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);

  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_BATTLE_CARD_TABLE_NAME,

    FilterExpression: "not contains(CustomerId,:fName)",
    ExpressionAttributeValues: {
      ":fName": currentAddress,
    },
    ProjectionExpression: "TokenId",
  });

  async function GetTokenId() {
    // console.log(param, "inside function");
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    const Id = [];
    try {
      const Response = await documentClient.scan(param).promise();

  //  console.log(Response,param,"battle other");

      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        // console.log(element.TokenId);
        Id.push(element.TokenId);
      }
    } catch (err) {
      // console.log(err);
    }
    return Id;
  }
  useEffect(() => {
    if (page === Math.ceil(TokenId.length / 10) || TokenId.length === 0) {
      setNext(false);
    } else {
      setNext(true);
    }
  }, [TokenId, page]);
  const Next = async () => {
    setNext(false);
    setPage((page) => page + 1);
    setNext(true);
  };
  const Previous = async () => {
    setPage((page) => page - 1);
  };
  useEffect(() => {
    if (AWS.config.credentials && awsmobile.aws_cognito_region) {
      const Token = async () => {
        const Id = await GetTokenId();
        setLoader(false);
        setTokenId((TokenId) => [...TokenId, ...Id]);
      };
      Token();
    }
  }, [
    walletProvider,
    currentAddress,
    awsmobile.aws_cognito_region,
    AWS.config.credentials,
  ]);

  // console.log(TokenId);
  return [TokenId, page, setPage, next, Next, Previous, loader];
};

export default useBattleListOthersCard;
