import React from "react";
import entrance from "../Images/DAIRL entrance.png";
import desk from "../Images/Doctors desk.jpg";
import mancar from "../Images/Labrinth on his way to meet hacker.png";
import yellowcab from "../Images/Cab stopping infront of restaurant.jpg";
function Chapters1() {
  return (
    <div>
      <h4>Chapter 1: THE DOCTOR</h4>
      <h5 className="text-light">
        <u>Timeline 1</u>
      </h5>
      <p>
        Amidst all the chaos of the powerful, menacing, and dangerous storm that
        blotted out the blazing sun and cast shadows down the mountainside,
        breathed another soul that was not concerned with the raging rainstorm
        outside because a much bigger hurricane was brewing inside his head.
        Here, an abundance of mountains lay in Far away in the land of
        mountains, where few lives existed except for famished wild snow
        leopards, mountain goats, and golden eagles raged a storm which grew to
        such a thing of force and fury that even the tips of mountains, coated
        with months of old ice, shook with a vengeance. The whole world seemed
        unnaturally dark as if all the light in the world had been sucked out. A
        gigantic bank of dark clouds was massing high above, in the roaring sky,
        casting shadows and it seemed to writhe and &nbsp;{" "}
        <img src={entrance} alt="" id="entrance" />
        twist, growing and swelling, breathing and hissing as if it were alive.
        The layer of shadowy, dark clouds rushed towards the mountains, trailing
        veils of rain in their wake. Overhead, the lightning tore through one of
        the dark clouds, loud and fast, and the thunder crashed over the
        snow-coated mountains like a huge fist pounding in the sky. The bitter
        winds and the harsh whispers rattled the bare branches of the few
        unlucky trees that were rebellious enough to grow in the gaps between
        the mountains. The tops of the trees were bending and arching from side
        to side, whistling and sighing as the wind wound itself around the
        trunks, tearing at the bark. One after another, white bursts of light
        cut through the ominous darkness like camera flashes. <br />
        A series of zigzagged lines, one overlapping the other, and deep in one
        of the mountains, there was a low overhead opening of rock and stone
        that was the entry to the mountain cave. The entrance of the cave had a
        musty, damp scent from years of still life in darkness. Deep, deep
        inside the cave, as you travel through withered rocks and uneven
        grounds, there exists an unanticipated scene. After a ten-kilometers-long
        walk inside the cave, there was an upgraded underground tunnel
        containing a secret elevator which connected the underground tunnel with
        the secret laboratory. The cave was connected by a secret stairway to
        the entrance, which was concealed from any lurking eyes. In addition, a
        security alarm was installed in the cave in case an unwelcome visitor
        infiltrated the hidden base of operations. The secret laboratory named
        the DAIRL (Doctor’s Artificial Intelligence Research Lab), was in the
        main area of the cave, supported by a metallic structure, and it
        provided a large space for conducting experiments. The laboratory was
        equipped with sophisticated and advanced tools of modern science,
        imported from different parts of the world and readily available in the
        year 2040. There are several stations filled with paper works, some
        neatly stacked, others strewn about, highlighted with bright neon ink
        and sticky notes with programming language codes scribbled haphazardly
        all over the cold metallic walls of the laboratory. A big LED screen
        took up a place in the right corner of the room, filled with complicated
        equations, formulas, indecipherable diagrams, and abstruse symbols.
        <img src={desk} id="desk" alt="" />
        Rare books about artificial intelligence, biology, mathematics, and
        software were stacked on shelves and arranged in rows that covered half
        the walls of the lab. There are about a dozen custom-built computers,
        each consisting of 1 terabyte of RAM and high processing speed, set up
        around the room on every side. The software on these customized
        computers is newly updated to the latest software. Peripherals in these
        rooms are the external keyboards and mice used for iMacs to operate with
        them. All the laptops and computers are connected to Wi-Fi and a printer
        is located inside the laboratory.
        <br />
        And bent over on one of these beeping computers was the doctor. The aged
        male was furrowing his bushy eyebrows in concentration, tapping his
        purple sock-clad feet in restlessness. His waning blond hair was messily
        laid all over his scalp and some were plastered on his sweat-clad
        forehead. Even in this cold room, under his oversized brick-red hoodie
        with the boldly printed quote "I Know Enough Science to Be Dangerous,"
        his armpits were sweating. After a while, he roughly tugged off his
        lightly knotted, coral-coloured silken scarf from his neck in sheer
        frustration. His fingers moved at lightning speed on the keyboards as
        his grey, largely rimmed spectacles clad in sapphire blue eyes ran over
        the multiple screens of his computer like a pendulum: inhibited,
        agitated and engaged. The doctor was an expert in mathematics and
        software engineering. He was one of a kind in his field. His works and
        research papers have received praise and acclaim from scientists all
        over the world. Even then, he is ruthless in the pursuit of knowledge.
        His long-standing obsession was studying consciousness. He has been
        working for ages to create artificial intelligence that would help to
        save human lives. After years of constant hard work and determination,
        he brainstormed and collaborated with (Integrated Information Theory)
        IIT to devise complex mathematical equations that interacted with one
        another to generate the exact conditions required to give the ability of
        consciousness to an AI system. However, every attempt seems to be of no
        avail. It eventually made the Doctor realize that he must solve the
        fundamental problem of consciousness, the so-called "Hard Problem of
        Consciousness." He would have to answer a far more fundamental question
        than that. The question is, how did we all get here? How does inanimate
        matter conspire to give stunning patterns, beauty, and structure? Chaos
        theory gave him a glimpse of how it might be possible, but he had a long
        way to go to get the complete picture. Chaos theory says that in the
        apparent randomness of any chaotic system, there are underlying
        patterns. Self-similarity is the principle that gives rise to these
        patterns. This principle, in turn, is based on simple mathematical
        rules. Under certain initial conditions, a very simple stochastic system
        is more than capable of creating non-deterministic behavior given the
        system has a feedback loop. Most of the natural systems, from fluffy
        surfaces in the cloud to branches of trees and rivers to the arteries in
        our heart, follow the principle of self-similarity or better known as
        fractal geometry. This fascinating idea led the Doctor to investigate
        similar works. Doing so, he stumbled upon Alan Turing's work, His paper
        "The Chemical Basis of Morphogenesis" from 1954. The Doctor resonated
        with Turing's work and his life circumstances. Because perhaps both had
        been through similar life experiences. Alan Turing was a second world
        war hero, who cracked the Nazi naval communication system. But later in
        his life, he worked on far more important scientific problems. One of
        them being his work on morphogenesis. Seeing Turing's work and his prior
        knowledge about chaos theory and evolution, the Doctor quickly made an
        inference on how all this information may apply to the human brain
        because the human brain also follows the self-similarity principle to
        create the branching in our neurons and dendrites. The Doctor was
        getting very close to figuring out the exact origin of consciousness
        with all this new information, but he wasn't quite there yet. It may
        sound mighty and great, but his obsession was purely selfish and the
        result of anguish, grief and guilt rather than inspiration. Maybe that’s
        why the world’s most well-acclaimed and celebrated scientist has found
        his refuge in this secluded mountain cave to peacefully add fuel to his
        great fixation and secretly research his greatest project to date. He is
        tough as nails and works endless hours without needing rest or
        motivation. His lethal, agile mind works a hundred miles per second. It
        felt as if his unstoppable intellect comes up with solutions to problems
        faster than a rocket launching off. Average human beings discover their
        solace in sleeping eight hours a day. However, the doctor finds his
        peace in twenty hours of solid work with successful outcomes and in a
        glass of exquisite, buttery wine that has been aged in oak and generally
        is rich and flat, provided by Italy’s finest vineyards. He never craves
        human interaction or touch since his buzzing mind always keeps him
        occupied. As a result, he has no company in the form of a close group of
        friends, a beautiful family, or even a mediocre pet. The Doctor lives
        and breathes science as if his sole purpose in life is to unravel its
        mysteries. And now the Doctor has dared to venture into the world of
        artificial intelligence where many scientists have tried to unravel its
        secrets, but certainly not like the Doctor, who is going the extra mile
        and working rigorously at it to achieve his goal. Nonetheless, as the
        doctor with a mind sharper than a knife should have known, often things
        do not go as slickly as they are planned. <br /> <br /> <br />
        <h5 className="text-light">
          <u>Timeline 2</u>
        </h5>
        According to the ticking Rolex on his thick wrist, it is very late in
        the night but not to the souls breathing the air of this energetic,
        vibrant and very awake city. It is a municipality of more than 18
        million people, who are always on the go and has no time to spare the
        homeless man snoozing off in the corner of the traffic-filled streets of
        this high-paced city. It’s a jungle of high-rise buildings, but there is
        still the occasional 19th–century European architecture left over from
        the days of foreign concessions. Old men peddling coal-powered grills on
        the back of bikes sell sweet potatoes below the gleaming office
        buildings. You can go from eating Western food at trendy restaurants
        overlooking the city to tasting delicacies at street-side stalls squished
        between KFCs. This is where old meets new and East meets West, all
        blending into a bustling, cosmopolitan city. The city’s best qualities
        are its devotion to the arts. It’s a very culturally thriving city. From
        modern art, spray painted on the walls of old grocery store walls to
        intricately designed statues and fountains delicately placed in the
        parks and green picturesque gardens. When people ventured into the
        deepest part of the city, they can discover narrow passageways. These
        narrow alleys are fading remnants of the old culture and civilization of
        this city, full of parked bikes, dangling laundry, shabby windows, and
        old wooden restaurants from which the smell of exquisite spices and the
        aroma of freshly brewed coffee sways in the air. The alleys are often
        crammed between streets of high-rise office buildings, but despite the
        difficulty in finding them, they contain some of the most fascinating
        shops with luxury products like Gucci bags, Louis Vuitton and Zara
        dresses, and Adidas shoes on display. The city is a blur of bright
        lights, towering skyscrapers, old alleys, Parisian-style cafes, and, of
        course, shopping malls. Its elitist vibe and obsession with trends make
        this metropolis a notoriously materialistic and superficial city.
        However, its energy and shameless pursuit of modernity will always
        fascinate people. The City’s after-dark entertainment is just as
        electrifying as ever including sophisticated cocktail dens, friendly
        dive bars and bottle-service-only dance clubs. With roof-top bars,
        high-end restaurants, celebrity sightings, outdoor dining, luscious food
        and top fashion brands this is the capital of the world. This place is
        visually stunning, defined by the people and the buildings. The people
        are energy, feeling, and movement that create emotion splashed against
        the canvas of the architecture. The buildings provide a stark, static
        contrast and permanence to a town that is constantly evolving. The lone
        flower growing out of a crack in the sidewalk is beautiful. The giant
        trees in the park are beautiful. The rowboats and the ice skating on the
        outskirts of the city are exciting. The street carts are beautiful.
        <br />
        <img src={mancar} alt="" />
        And in this overwhelmingly beautiful city, as the rush hours began to
        calm down and people started losing themselves in expensive beer and
        wine, the well-built man dressed in a fitted coal black dinner jacket
        with a formal white shirt and black tie hailed a yellow cab from the
        extremely busy street and swiftly jumped in it as the traffic signal
        turned green once more. He inhaled deeply, took off his tinted shades,
        wove a hand through his silky-chestnut hair and gave the driver the
        address of his destination.
        <br />
        He sat back on the cab seat and was pensively looking into the distance,
        and his eyes reflected cool tranquillity. The eyes… Once sky-blue, now
        they were bearing the color of pale winter mornings. Yet, the look was
        not cold at all. His eyesight was sharp and alert. The man's eyes were
        wandering through the buildings and trees on the other side of the road,
        but it seemed that he did not see what was going on outside because he
        was thinking about his secrets. Obsessed with dreams, the man touched
        his cheek and leaned towards the cab’s window, and one could notice a
        deep scar on the left cheek, closer to the ear. The scar did not hurt
        anymore, however, it remained an everlasting memory of the past life he
        was still running from.
        <img src={yellowcab} alt="" />
        Eventually, the bright yellow cab stopped in front of a flashy little
        place called FineDine. This was a family-owned business run by a
        middle-aged couple. Warm orange and yellow-pomegranate furniture,
        sunny-coloured napkins and curtains, country-style hard wooden tables
        and stools decorated the restaurant—everything was solid and comfortable
        about the place. The food tasted the same as always- crispy and puffy
        home-made corn bread, finely marinated and well-cooked chicken breast
        with sautéed green vegetables and delicious barbecue sauce was their
        signature dish.
        <br /> The tall, masculine man gracefully came out of the cab, took out
        his pristine leather wallet to pay off the driver and walked towards the
        welcoming dining in his shiny, polished shoes. He paused for a second
        and rapidly typed off a message which said – “I am here.” The man
        entered the restaurant and in that instance, the air filled with the
        smell of oil and spices greeted his nose. He looked around and found his
        precise gaze located his target. He walked towards the young lad wearing
        a simple grey T-shirt and a sky-blue cap on his head seated at the far
        corner table in the restaurant, away from all the chattering and dining
        crowd. As the big man elegantly took his seat opposite the freckled face
        fellow, sweat started collecting on his temple. “Mr Labyrinth, pleasure
        to meet you Sir”, the young man stuttered and wiped his sweaty hands on
        his frayed jeans.
        <br />
        “Did you finally complete your assigned task?” Dr Labyrinth enquired in
        his serious, deep voice. “Y-es, yes Sir. I have all the necessary files
        in this memory card Sir. I collected all the data from his computer and
        transferred the copies of the files in here.”
        <br />
        “I see. Just a fair warning, if you made a mistake again and brought me
        here all the way from my workplace out of no valid reason then you will
        not get your desired amount” Dr Labyrinth grated out in a calm voice.
        <br />
        The young lad’s face paled a shade or two and with shaking hands he
        handed the memory card over to Dr Labyrinth.
        <br />
        “How did you eventually manage to hack into his computer?” Dr Labyrinth
        asked as he took the memory card and in the blink of an eye dropped it
        in the pocket of his coal-black jacket.
        <br /> “It was tough. The doctor had placed enriched passwords and an
        advanced verification system on all his devices. All devices use the
        latest versions of quantum encryptions. I couldn’t hack into any of his
        current devices. But I did get access to one of his old hard drives,
        which didn't have quantum encryption. The encryption on that hard drive
        is still RSA encryptions which took me like a minute to crack with my
        quantum computer. He used this hard drive during the period you
        mentioned to get all his work information. It falls within the date
        range you gave me.”
        <br /> Dr Labyrinth nodded his head, but his brows were pinched, and it
        seemed many thoughts were scrambling in his head.
        <br />
        “You will get your money in due time” stated Dr Labyrinth and stood up,
        buttoned up his jacket and without a backward glance promptly left
        through the sliding doors of the restaurant and eventually faded into
        the dark night.
      </p>
      <p></p>
    </div>
  );
}
export default Chapters1;
