import { Auth } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { authContext } from "../../App";
import Loader from "../Shared/Loader/Loader";

export function RequireAuth({ children }) {
  const [authentication, setAuthentication] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [signInUser, setSignInUser] = useContext(authContext);
  const location = useLocation();

  
  useEffect(() => {
    let getUser = async () => {
      try {
        const session = await Auth.currentSession();
        let user = await Auth.currentAuthenticatedUser();  
                  
        const AuthUserCreds = await Auth.currentCredentials();
        AWS.config.update({
          credentials: AuthUserCreds,
          region: awsmobile.aws_cognito_region,
        });
                
        const auth={user,session,isAuthenticated:true}
        const result=JSON.stringify(auth)
        setAuthentication(result);
        setSignInUser(result)
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // console.log(error);
      }

      const AuthUserCreds = await Auth.currentCredentials();

        try {
          AWS.config.update({
            credentials: AuthUserCreds,
            region: awsmobile.aws_cognito_region,
          });
        } catch (err) {
          // console.log(err);
        }

    };
    getUser();
  }, [location]);

  if (isLoading) {
    return <div ><Loader></Loader></div>
    //spinner will be added
  }
  return authentication ? (
    children
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
}