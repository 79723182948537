import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { providerContext } from "../../../../../App";
import { GetPromoCodeDetailsFM } from "../../../../UserPage/BlockChainFunctions";
const getPromoDetails = async (Code, walletProvider) => {
  const result = await GetPromoCodeDetailsFM(Code, walletProvider);
  return result;
};
const PromoDataTableRow = ({ promoCode, index }) => {
  // console.log(promoCode, "promo checking");
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [promoDetails, setPromoDetails] = useState();
  useEffect(() => {
    if (promoCode && walletProvider) {
      const PromoDetails = async () => {
        const result = await getPromoDetails(promoCode, walletProvider);
        // console.log(result, "promo details");
        setPromoDetails(result);
      };
      PromoDetails();
    }
  }, [promoCode, walletProvider]);

  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{promoDetails?.Name}</td>
      <td>{promoDetails?.Code}</td>
      {/* <td>{promoDetails?.DiscountPercentage?.toString()}</td> */}
      <td>{promoDetails?.TotalValue?.toString()}</td>
      <td>{promoDetails?.UseCount?.toString()}</td>
    </tr>
  );
};

export default PromoDataTableRow;
