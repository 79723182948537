import React from "react";

const TransferCardsBanner = () => {
  return (
    <div>
      <div className="TransferSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="promote_text">
                <h1>TRANSFER CARD(S)</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferCardsBanner;
