import React from "react";
import useWarAdminCard from "../../Hooks/useWarAdminCard";
import AuctionCard from "../../Shared/AuctionCard/AuctionCard";
import Loader from "../../Shared/Loader/Loader";
import Pagination from "../../Shared/Pagination";
import WarAdminCard from "../../Shared/WarCard/WarAdminCard";
import WarCard from "../../Shared/WarCard/WarCard";
const WarCardAdmin = () => {
  const [TokenId, page, setPage, next, Next, Previous, loader] =
    useWarAdminCard();

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="">
            {loader ? (
              <Loader></Loader>
            ) : (
              <div className="content_box">
                <div className="a-title">WAR CARDS-{TokenId.length}</div>
                <div className="row">
                  {TokenId.slice((page - 1) * 10, page * 10).map((TokenId) => (
                    <WarAdminCard TokenId={TokenId} key={TokenId}></WarAdminCard>
                  ))}
                  {TokenId?.length === 0 && <h1>NO CARD</h1>}
                </div>

                <Pagination
                  page={page}
                  setPage={setPage}
                  length={TokenId.length / 10}
                  Previous={Previous}
                  Next={Next}
                  next={next}
                ></Pagination>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarCardAdmin;
