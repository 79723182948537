import React from 'react';
import TransferAllcardsList from './Sections/TransferAllcardsList';
// import AllcardsList from './Sections/TransferAllcardsList';
import TransferAllCardsText from './Sections/TransferAllcardsText';
// import AllCardsText from './Sections/TransferAllcardsText';

const TransferAllCard = () => {
    return (
        <div>
            <TransferAllcardsList></TransferAllcardsList>
            <TransferAllCardsText></TransferAllCardsText>
        </div>
    );
};

export default TransferAllCard;