import React from 'react';
// import Blacknetbanner from '../Cardcollection/SubPages/Blacknet/Sections/Blacknetbanner';
import BlacknetText from './SubPages/Blacknet/Sections/RenameBlacknetText';
import BlacknetList from './SubPages/Blacknet/Sections/RenameBlacknetList';
import '../RenameCollection/CardCollection.css';
import RenameBlacknetList from './SubPages/Blacknet/Sections/RenameBlacknetList';
import RenameBlacknetText from './SubPages/Blacknet/Sections/RenameBlacknetText';

function RenameBlacknetCard () {
	return <div>
		{/* <Blacknetbanner/> */}
		<RenameBlacknetList/>
		<RenameBlacknetText/>

        
	</div>
}
export default RenameBlacknetCard;