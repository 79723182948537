import React from 'react';
// import Genesisbanner from '../Cardcollection/SubPages/Genesis/Sections/Genesisbanner';
import GenesisText from './SubPages/Genesis/Sections/MarketPlaceGenesisText';
import GenesisList from './SubPages/Genesis/Sections/MarketPlaceGenesisList';
import '../MarketPlaceCollection/CardCollection.css';
import MarketPlaceGenesisList from './SubPages/Genesis/Sections/MarketPlaceGenesisList';
import MarketPlaceGenesisText from './SubPages/Genesis/Sections/MarketPlaceGenesisText';

function MarketGenesisCard () {
	return <div>
		{/* <Genesisbanner/> */}
		<MarketPlaceGenesisList/>
		<MarketPlaceGenesisText/>

        
	</div>
}
export default MarketGenesisCard;