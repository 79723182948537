// import React from "react";
// import "./Play.css"
// const PlayBanner = () => {
//   return (
//     <div>
//       <div className="play_container_section">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="breeding_text mt-3">
//                 <h1>Play</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PlayBanner;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../../../assets/sliderImage/image12.png";
import image2 from "../../../../assets/sliderImage/image13.png";
import image3 from "../../../../assets/sliderImage/image14.png";
export default function PlayBanner() {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      {/* <section className="synopsis_section"> */}
      {/* <div className="container"> */}
      <div className="row mb-5">
        <div className="synopsis_slider">
          <Slider {...settings}>
            <div className="col-md-12">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img src={image1} alt=""/>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img src={image2} alt=""/>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <img src={image3} alt=""/>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      {/* </div> */}
      {/* </section> */}
    </div>
  );
}
