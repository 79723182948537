import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../UserPage/User.css";
import { Auth } from "aws-amplify";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { useContext } from "react";
import { addressContext, authContext } from "../../App";
import { useForm } from "react-hook-form";
import useCustomerInfo from "../Hooks/useCustomerInfo";
import PopUp from "../Shared/PopUp";
import LoaderFloating from "../Shared/LoaderFloating";
function Login() {
  // const [loading, setLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [signInUser, setSignInUser] = useContext(authContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);

  // useEffect(() => {
  //   if (currentAddress) {
  //     const DatabaseChecking = async () => {
  //       // console.log(customerInfo, currentAddress, "settimeout");
  //       if (customerInfo) {
  //         if (currentAddress === customerInfo.Item?.CustomerID) {
  //           const session = await Auth.currentSession();
  //           let user = await Auth.currentAuthenticatedUser();
  //           const auth = { user, session, isAuthenticated: true };
  //           const result = JSON.stringify(auth);
  //           setSignInUser(result);
  //           setLoading(false);
  //           // setSignInUser(user);
  //           navigate(from, { replace: true });
  //         } else {
  //           try {
  //             AWS.config.update({
  //               credentials: "",
  //             });
  //             Auth.signOut({ global: true });
  //             // console.log("Error: Connect to your registered wallet");
  //             setSignInUser(null);
  //           } catch (error) {
  //             // console.log("error signing out:" + error);
  //           }
  //           setLoading(false)
  //           setPopUp(true)
  //           setPopUpSubject("Please connect your registered wallet address")
  //         }
  //       }
  //     };
  //     DatabaseChecking();
      
  //   }
  // });
  
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "all" });
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const SignIn = async (data) => {
    if (currentAddress) {
      setLoading(true);
      const email = data.email;
      const password = data.password;

      let AuthUserCreds;
      try {
        const user = await Auth.signIn(email, password);
        if (user) {
          const AuthUserCreds = await Auth.currentCredentials();
          AWS.config.update({
            credentials: AuthUserCreds,
            region: awsmobile.aws_cognito_region,
          });
          const session = await Auth.currentSession();
          let user = await Auth.currentAuthenticatedUser();
          const auth = { user, session, isAuthenticated: true };
          const result = JSON.stringify(auth);
          setSignInUser(result);
          setLoading(false);
          // setSignInUser(user);
          navigate(from, { replace: true });
        }
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          const requiredAttributes = {
            family_name: "Dipto", /// needs editing discuss with me
            given_name: "Apurba", /// needs editing discuss with me
            preferred_username: "ABdipto", /// needs editing discuss with me
            email: "",
            birthdate: "DD/MM/YY",
            phone_number: "999999999",
          };

          try {
            const response = await Auth.completeNewPassword(
              user,
              "Lite216%",
              requiredAttributes
            );
          } catch (err) {
            // console.log(err);
          }
        }
        AuthUserCreds = await Auth.currentUserCredentials();
      } catch (err) {
        setLoading(false);
        setPopUpSubject(err.message);
        // console.log({err},"type");
        setPopUp(true);
      }

      try {
        AWS.config.update({
          credentials: AuthUserCreds,
          region: awsmobile.aws_cognito_region,
        });
      } catch (err) {
        // console.log(err);
      }
    } else {
      setPopUpSubject("Connect your registered wallet");
      setPopUp(true);
    }
  };
  const [passVisibility, setPassVisibility] = useState(false);
  const TogglePass = () => {
    setPassVisibility(!passVisibility);
  };
  return (
    <div>
      <div className="login_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 offset-md-6">
              <div className="login_form">
                <h2>SIGN IN</h2>
                <form onSubmit={handleSubmit(SignIn)} className="signin-form">
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      {...register("email", {
                        required: true,
                        pattern: /^[^\s@]+@[^\s@]{2,}$/i,
                      })}
                      placeholder="Email address*"
                    />
                    {errors.email?.type === "required" && (
                      <p className="error">Email is required</p>
                    )}
                    {errors.email?.type === "pattern" && (
                      <p className="error">Email is invalid</p>
                    )}
                  </div>
                  <div className="form-group mb-3 ">
                    <div className="toggle">
                      <input
                        type={passVisibility ? "text" : "password"}
                        className="form-control toggle"
                        {...register("password", {
                          required: true,
                          minLength: 8,
                        })}
                        placeholder="Password*"
                        id="id_password"
                      />
                      <i
                        onClick={TogglePass}
                        className={
                          passVisibility ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                        id="togglesignin"
                      ></i>
                    </div>
                    {errors.password?.type === "required" && (
                      <p className="error">Password is required</p>
                    )}
                    {errors.password?.type === "minLength" && (
                      <p className="error">
                        Your password must be at least 8 characters long
                      </p>
                    )}
                  </div>

                  <br></br>
                  <div className="form-group ">
                    <button
                      type="submit"
                      className="form-control btn btn-primary rounded submit px-3 submit"
                    >
                      {!loading ? "SIGN IN" : "SIGN IN..."}
                    </button>
                  </div>
                </form>
                <div className="signup_text text-center">
                  <p>
                    Forgot your password?{" "}
                    <Link to="/passwordRecovery">RESET</Link> <br></br>
                    Don't have an account? <Link to="/signup">SIGN UP</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {popup && (
            <PopUp
              popUpSubject={popUpSubject}
              popUpText={popUpText}
              setPopUp={setPopUp}
            ></PopUp>
          )}
          {loading && <LoaderFloating></LoaderFloating>}
        </div>
      </div>
    </div>
  );
}
export default Login;
