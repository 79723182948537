import React from "react";
import WarBattleBanner from "./Sections/WarBattleBanner";
import WarBattleText from "./Sections/BHWarBattleText";
import CardTab from "./Sections/CardTab";
import BreedSelfAliceCard from "../../../Breeding/BreedSelfCollection/BreedSelfAliceCard";
import BAWarBattleText from "./Sections/BAWarBattleText";
import { Outlet, useLocation } from "react-router-dom";
import BACard from "./BACard/BACard";
function BAWarBattlefield() {
  const location = useLocation();
  // console.log(location.state);
  return (
    <div>
      <WarBattleBanner />
      <BAWarBattleText state={location.state}/>
      {/* <CardTab/> */}
      {/* <BreedSelfAliceCard></BreedSelfAliceCard> */}
      {/* <Outlet></Outlet> */}
      <BACard></BACard>
    </div>
  );
}
export default BAWarBattlefield;
