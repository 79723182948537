import React from "react";
import { useContext } from "react";
import { navigationContext } from "../../../App";
import Card from "../Card/Card";
import "./ResultCard.css";
import ResultCardChild from "./ResultCardChild";
const ResultCard = ({ finalResult, setFinalResult }) => {
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);
// console.log(finalResult,finalResult?.winner)
  return (
    <div
      className="battle-information"
      onClick={() => {
        setNavigationCntxt(true);
        setFinalResult(null);
      }}
    >
      <div className="battle-info-child">
        <h2 className="text-center ">Battle results!</h2>
        <div className="battle-info-card">
          <div className="winner-card">
            <h2 className="text-center">Winner</h2>
            <ResultCardChild TokenId={finalResult?.winner}></ResultCardChild>
          </div>
          <div className="losser-card">
            <h2 className="text-center">Loser</h2>
            <ResultCardChild TokenId={finalResult?.losser}></ResultCardChild>
          </div>
        </div>
        <h5 className="text-center">Battle History</h5>

        <p className="text-center text-light">
          {finalResult?.Message} <br /> <br />
          Transaction Hash: {finalResult?.Receipt?.transactionHash} <br />
          Winner : {finalResult?.winner} and Loser : {finalResult?.losser}
        </p>
      </div>
    </div>
  );
};

export default ResultCard;
