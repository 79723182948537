import React from 'react';
import SellOutConfirmationHero from '../SellCards/SellOutConfirmation/Sections/SellOutConfirmationHero';
import SellOutConfirmationTaxt from '../SellCards/SellOutConfirmation/Sections/SellOutConfirmationTaxt';
import '../SellCards/SellCards.css';
function SellOutConfirmation () {
	return <div>
        <SellOutConfirmationHero/>
		<SellOutConfirmationTaxt/>
	</div>
}
export default SellOutConfirmation;