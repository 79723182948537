import React from "react";
import "../../../CardCollection.css";
import { Link, Outlet } from "react-router-dom";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../../../aws-exports";
import { useState } from "react";
import { useEffect } from "react";
import PRMCard from "../../../../../../../Shared/PRMCard/PRMCard";
import Pagination from "../../../../../../../Shared/Pagination";
import usePRMCards from "../../../../../../../Hooks/usePRMCards";
import Loader from "../../../../../../../Shared/Loader/Loader";

function MutateBlacknetText() {
  const [TokenId,page,setPage,next,Next,Previous,loader]=usePRMCards("BlackNet")

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
          {
              loader?
              <Loader></Loader>:     <div className="content_box">
              <h4>BLACKNET</h4>

              <div className="row">
              {TokenId.slice((page - 1) * 10, page * 10).map((TokenId) => (
                  <PRMCard TokenId={TokenId} key={TokenId}>mutate</PRMCard>
                ))}
                                  {TokenId.length === 0 && <h1>NO CARD</h1>}

              </div>
              <Pagination
                  page={page}
                  setPage={setPage}
                  length={TokenId.length / 10}
                  Previous={Previous}
                  Next={Next}
                  next={next}
                ></Pagination>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
export default MutateBlacknetText;
