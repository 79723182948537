import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LOGO from "../Header/LOGO.png";
import "../Header/Header.css";
import { useState } from "react";
import { Auth } from "aws-amplify";
import * as AWS from "aws-sdk";
import { addressContext, authContext, profilePicContext } from "../../App";
import useCustomerInfo from "../Hooks/useCustomerInfo";
import awsmobile from "../../aws-exports";

function Nav() {
  const [signInUser, setSignInUser] = useContext(authContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const [imageSrc, setImageSrc] = useContext(profilePicContext);
  // console.log(customerInfo);
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const Admin = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const admin = user?.signInUserSession?.idToken?.payload["cognito:groups"];
      if (admin.includes("Admin")) {
        setAdmin(true);
      }
    };
    Admin();
  }, []);

  const SignOut = async () => {
    try {
      AWS.config.update({
        credentials: "",
      });
      await Auth.signOut({ global: true });
      setSignInUser(null);
    } catch (error) {
      console.log("error signing out:" + error);
    }
  };
  //play
  const SearchCard = (e) => {
    e.preventDefault();
    const TokenId = e.target.search.value;
    navigate(`/individual-card/${TokenId}`);
    // navigate(/individual-card/:TokenId)
  };
  // const [imageSrc, setImageSrc] = useState("");
  const replaceImage = (error) => {
    error.target.src = "/ErrorImage/avatar.png";
  };

  const [accessKeyId, setAccessKey] = useState("");
  useEffect(() => {
    const cred = async () => {
      const auth = await Auth.currentCredentials();
      setAccessKey(auth);
    };
    cred();
  }, []);
  function encode(data) {
    var str = data.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  }
  useEffect(() => {
    if (
      awsmobile.aws_cognito_region &&
      AWS.config.credentials &&
      currentAddress &&
      accessKeyId
      ) {
      const s3 = new AWS.S3({
        credentials: accessKeyId,
      });
      var getParams = {
        Bucket: "extremis-user-profilepics",
        Key: `ProfilePicture/${currentAddress}`,
      };

      s3.getObject(getParams, function (err, data) {
        if (data?.Body) {
          const src = encode(data.Body);
          setImageSrc(src);
        } else if (err) {
          console.log(err);
        }
      });
    }
  }, [
    awsmobile.aws_cognito_region,
    AWS.config.credentials,
    currentAddress,
    accessKeyId,
  ]);

  // console.log(imageSrc);
  return (
    <div>
      <header className="header_section">
        <nav className="navbar navbar-expand-xl navbar-light">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src={LOGO} alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i class="fal fa-bars"></i>
              </span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/the-origin">
                    Story
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <hr></hr>
                    <li>
                      <Link className="dropdown-item" to="/the-origin">
                        The origin
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/the-chronicles">
                        The chronicles
                      </Link>
                    </li>
                    {/* <li><Link className="dropdown-item" to="/genesis">The Genesis</Link></li> */}
                    <hr></hr>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/metaverse">
                    Metaverse
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <hr></hr>
                    <li>
                      <Link className="dropdown-item" to="/metaverse">
                        The metaverse
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/blacknet">
                        Blacknet
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/alice">
                        Alice
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/humanity">
                        Humanity
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/sales-record">
                        Sales record
                      </Link>
                    </li>
                    <hr></hr>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/cardcollection"
                  >
                    Card collection
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <hr></hr>
                    <li>
                      <Link className="dropdown-item" to="/cardcollection">
                        All cards
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/cardcollection/blacknet-card"
                      >
                        Blacknet
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/cardcollection/alice-card"
                      >
                        Alice
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/cardcollection/humanity-card"
                      >
                        Humanity
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/cardcollection/genesis-card"
                      >
                        Genesis
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/purchase-cards">
                        Buy card
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/sell">
                        Sell card
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/auction-house">
                        Auction house
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/transfer-cards">
                        Transfer cards
                      </Link>
                    </li>
                    <hr></hr>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/play">
                    Play
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <hr></hr>
                    <li>
                      <Link className="dropdown-item" to="/breeding">
                        Breeding Lab
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/breeding-list">
                        Breeding List
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/battle-list">
                        Battle List
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/war-zone">
                        War Zone
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/promote">
                        Promote Card
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/mutate">
                        Mutate Card
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/rename">
                        Rename Card
                      </Link>
                    </li>

                    <hr></hr>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/guide">
                    Guide
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <hr></hr>
                    <li>
                      <Link className="dropdown-item" to="/getting-started">
                        Getting started
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/game-features">
                        How to play
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/card-anatomy">
                        Card Anatomy
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/market-place">
                        Marketplace
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/cost-fees">
                        Cost and fees
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/faq">
                        FAQ
                      </Link>
                    </li>
                    <hr></hr>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/leaderboard">
                    More
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <hr></hr>
                    <li>
                      <Link className="dropdown-item" to="/leaderboard">
                        Leaderboard
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/ranks">
                        Ranks
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/weapons">
                        Weapons’ list
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/multiplier">
                        Multiplier
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/whitepaper">
                        Whitepaper
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/roadmap">
                        Roadmap
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="/meet-team">
                        Meet the team
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/buy-nft">
                        Buy NFTs
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/nft-give-away">
                        NFT Giveway
                      </Link>
                    </li>
                    <hr></hr>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/tesseract-ltd">
                    Tesseract Inc.
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/connect-wallet">
                    Connect Wallet
                  </Link>
                </li>
                <li className="nav-item">
                  <form
                    className="form-inline md-form form-sm custom_search"
                    onSubmit={SearchCard}
                  >
                    <i class="fal fa-search"></i>
                    <input
                      className="form-control "
                      type="text"
                      placeholder="Token ID"
                      aria-label="Search"
                      name="search"
                    />
                    <input type="submit" hidden />
                  </form>
                </li>
                {!signInUser && (
                  <>
                    {" "}
                    <li className="nav-item login_btn">
                      <Link className="nav-link" to="/signin">
                        Sign In
                      </Link>
                    </li>
                    <li className="nav-item signup_btn">
                      <Link className="nav-link" to="/signup">
                        Sign Up
                      </Link>
                    </li>
                  </>
                )}
                {signInUser && (
                  <li className="nav-item dropdown profile_menu ">
                    <Link className="nav-link dropdown-toggle" to="/profile">
                      <img
                        className="rounded-circle header-profile-user"
                        // src={`https://tokencharacterimages.s3.amazonaws.com/ProfilePicture/${currentAddress}`}
                        src={`data:image/jpeg;base64,${imageSrc}`}
                        alt=""
                        onError={replaceImage}
                      />
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <hr></hr>
                      {admin && (
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/AdminPageOfExtremisVerse"
                          >
                            Admin
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link className="dropdown-item" to="/profile-setting">
                          {" "}
                          Account settings
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/SignIn"
                          onClick={SignOut}
                        >
                          Logout
                        </Link>
                      </li>
                      <hr></hr>
                    </ul>
                  </li>
                )}

                {/* <div className="search_box">
                           <div className="search-toggle">
                              <button className="search-icon icon-search"><i className="fal fa-search"></i></button>
                              <button className="search-icon icon-close"><i className="fal fa-times"></i></button>
                           </div>
                           <div className="search-container">
                              <form>
                                 <input type="text" name="q" id="search-terms" placeholder="Search terms..." />
                                 <button type="submit" name="submit" value="Go" className="search-icon"><i className="fal fa-search"></i></button>
                              </form>
                           </div>
                        </div> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
export default Nav;
//more
