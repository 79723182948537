import React from "react";
import tesseract_banner from "../Sections/images/tesseract_banner.png";
function TesseractBanner() {
  const Link = () => {
    window.location.href = "https://tesseractclothings.com/";
  };
  return (
      <div>
        <a className="anchor" href="https://tesseractclothings.com/" target="_blank" rel="noreferrer">
        <div className="Page_tesseract_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="page_title">
                  <h1 className="heading"></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
    </a>
      </div>
  );
}
export default TesseractBanner;
