import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import useAuctionContract from "../../../../Hooks/AuctionContractHook";
import useFixedMarketContract from "../../../../Hooks/FixedMarketHook";
import Pagination from "../../../../Shared/Pagination";
import {
  GetSaleRecordByIndexAuc,
  GetSaleRecordByIndexFM,
} from "../../../../UserPage/BlockChainFunctions";
import "./DataTable.css";
import DataTableRowFixed from "./DataTableRowFixed";
import DataTableRow from "./DataTableRowFixed";

const DataTableFixed = ({ saleRecord }) => {
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const Next = async () => {
    setNext(false);
    setPage((page) => page + 1);
    setNext(true);
  };
  const Previous = async () => {
    setPage((page) => page - 1);
  };
  useEffect(() => {
    if (page === Math.ceil(saleRecord?.length / 10) || saleRecord?.length === 0) {
      setNext(false);
    } else {
      setNext(true);
    }
  }, [saleRecord, page]);

  return (
    <div className="data-table">
      <Table responsive variant="dark">
        <thead>
          <tr>
            <th>#</th>
            <th>Token Id</th>
            <th>Buyer</th>
            <th>Seller</th>
            <th>Sale Time</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {saleRecord
            ?.slice((page - 1) * 10, page * 10)
            .map((record, index) => (
              <DataTableRowFixed
                record={record}
                index={index}
              ></DataTableRowFixed>
            ))}
        </tbody>
      </Table>
      <Pagination
        page={page}
        setPage={setPage}
        length={saleRecord?.length / 10}
        Previous={Previous}
        Next={Next}
        next={next}
      ></Pagination>
    </div>
  );
};
export default DataTableFixed;
