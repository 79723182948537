import React from 'react';
// import Humanitybanner from '../Cardcollection/SubPages/Humanity/Sections/Humanitybanner';
import HumanityText from './SubPages/Humanity/Sections/AuctionHumanityText';
import HumanityList from './SubPages/Humanity/Sections/AuctionHumanityList';
import '../AuctionCollection/CardCollection.css';
import AuctionHumanityList from './SubPages/Humanity/Sections/AuctionHumanityList';
import AuctionHumanityText from './SubPages/Humanity/Sections/AuctionHumanityText';

function AuctionHumanityCard () {
	return <div>
		{/* <Humanitybanner/> */}
		<AuctionHumanityList/>
		<AuctionHumanityText/>

        
	</div>
}
export default AuctionHumanityCard;