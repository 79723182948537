import React from "react";
import { useState } from "react";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { useEffect } from "react";

const useFixedMarketPromo = () => {
  const [promoList, setPromoList] = useState([]);
  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_FMPROMO_TABLE_NAME,
    ProjectionExpression: "PromoCode",
  });

  const PromoCode = async () => {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    const promo = [];
    try {
      const Response = await documentClient.scan(param).promise();

      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        promo.push(element.PromoCode);
      }
    } catch (err) {
      // console.log(err);
    }
    return promo;
  };
  useEffect(() => {
    if (AWS.config.credentials && awsmobile.aws_cognito_region) {
      const Promo = async () => {
        const result = await PromoCode();
        setPromoList((promoList) => [...promoList, ...result]);
      };
      Promo();
    }
  }, [awsmobile.aws_cognito_region, AWS.config.credentials]);

  return promoList;
};

export default useFixedMarketPromo;
