import React from "react";
import { Link } from "react-router-dom";

function BuyNftText() {
  return (
    <div>
      <section className="board_section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1">
              <div className="buynft_text_section">
                <h2 className="text-center">BUY NFTs</h2>
                <p>
                  Enter the world of EXTREMIS and get the experience of
                  collecting cards, upgrading them and battling them against
                  other players. Here, you chase for the truth: either choosing
                  for Humanity or Blacknet or Alice, build your own army and
                  make allies to defeat the enemy. Breed your cards to gain
                  ultimate powers to eliminate the last card of your opponent
                  and seize the trophy!
                </p>
                <div className="buy_button">
                  <a
                    className="btn "
                    href="https://opensea.io/collection/extremis-official"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    OPENSEA
                  </a>
                  <Link className="btn" to="/purchase-cards">
                    {" "}
                    TESSERACT MARKETPLACE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default BuyNftText;
