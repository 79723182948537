import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { providerContext } from "../../../App";
import {
  breed,
  exitBreedList,
  GetBreedFee,
  GetCFOAddrBreed,
  GetContractBalanceBreed,
  GetCOOAddrBreed,
  GetMutateFee,
  GetRankUpFee,
  GetRenameFee,
  GetTesseractPercentageBreed,
  IsOnBreedingList,
  IsPausedBreed,
  pauseBreedContract,
  putOnBreedList,
  setBreedFeeTessPercentage,
  setBreedingFee,
  setCFOAddrBreed,
  setCOOAddrBreed,
  setMutateFee,
  setRankUpFee,
  setRenameFee,
  unPauseBreedContract,
  withdrawBalanceBreed,
} from "../../UserPage/BlockChainFunctions";
import * as AWS from "aws-sdk";

// components
import ArrowIcon from "./AdminComponent/ArrowIcon";
import BasicButton from "./AdminComponent/BasicButton";
import FormOneInput from "./AdminComponent/FormOneInput";
import FormSubmit from "./AdminComponent/FormSubmit";
import FormTowInput from "./AdminComponent/FormTowInput";
import LoadingSpinner from "./AdminComponent/LoadingSpinner";
import useBreedingContract from "../../Hooks/BreedingContractHook";

/////////////////////////////////////////////////////////////////////
//const API Calls from ethers

function BreedingAd(props) {
  const [loading, setLoading] = useState(false);
  const [rotate, setRotate] = useState("");
  const [dropDownClass, setDropDownClass] = useState("drop-down-close");
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [breedingContract, setBreedingContract] = useBreedingContract();
  const ssm = new AWS.SSM();

  const [cfo, setCfo] = useState("");
  const [coo, setCoo] = useState("");
  const [balance, setBalance] = useState("");
  const [tpercentage, setTPer] = useState("");
  const [mutateFee, setMutate] = useState("");
  const [promoteFee, setPromote] = useState("");
  const [renameFee, setRename] = useState("");
  const [breedFee, setBreed] = useState("");

  useEffect(() => {
    if (breedingContract) {
      // console.log("hello");
      props.BreedIsPause();
    }
  }, [breedingContract]);

  const Pause = async () => {
    props.setLoading(true);
    try {
      const result = await pauseBreedContract(walletProvider);
      // setPauseOn(true)
      props.BreedIsPause();
      // console.log(result);

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const Unpause = async () => {
    props.setLoading(true);
    try {
      const result = await unPauseBreedContract(walletProvider);
      // console.log(result);
      props.BreedIsPause();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const getContractBalance = async () => {
    const result = await GetContractBalanceBreed(breedingContract);
    // console.log(result, "get contract balance");
    setBalance(result.toString());
  };
  const getCFOAddr = async () => {
    const result = await GetCFOAddrBreed(breedingContract);
    // console.log(result, "get cfo ");
    setCfo(result);
  };
  const getCOOAddr = async () => {
    const result = await GetCOOAddrBreed(breedingContract);
    // console.log(result, "get coo ");
    setCoo(result);
  };
  const getBreed = async () => {
    const result = await GetBreedFee(breedingContract);
    setBreed(result);
  };
  const GetTPercentage = async () => {
    const result = await GetTesseractPercentageBreed(breedingContract);
    setTPer(result);
    // console.log(result, "get t percentage");
  };
  const getMutate = async () => {
    const result = await GetMutateFee(breedingContract);
    // console.log(result,"mutate");
    setMutate(result);
  };
  const getRankUp = async () => {
    const result = await GetRankUpFee(breedingContract);
    // console.log(result);
    setPromote(result);
  };
  const getRename = async () => {
    const result = await GetRenameFee(breedingContract);
    // console.log(result);
    setRename(result);
  };

  useEffect(() => {
    if (breedingContract) {
      GetTPercentage();
    }
  }, [breedingContract]);
  useEffect(() => {
    if (breedingContract) {
      getMutate();
    }
  }, [breedingContract]);
  useEffect(() => {
    if (breedingContract) {
      getRankUp();
    }
  }, [breedingContract]);
  useEffect(() => {
    if (breedingContract) {
      getRename();
    }
  }, [breedingContract]);
  useEffect(() => {
    if (breedingContract) {
      getContractBalance();
    }
  }, [breedingContract]);
  useEffect(() => {
    if (breedingContract) {
      getCFOAddr();
    }
  }, [breedingContract]);
  useEffect(() => {
    if (breedingContract) {
      getCOOAddr();
    }
  }, [breedingContract]);
  useEffect(() => {
    if (breedingContract) {
      getBreed();
    }
  }, [breedingContract]);

  const SetBreedFee = async (Fee) => {
    props.setLoading(true);
    try {
      const result = await setBreedingFee(Fee, walletProvider);
      // console.log(result, "set breed fee");
      getBreed();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const SetRankFee = async (Fee) => {
    props.setLoading(true);
    try {
      const result = await setRankUpFee(Fee, walletProvider);
      // console.log(result, "SetRankFee");
      getRankUp();
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const SetMutateFee = async (Fee) => {
    props.setLoading(true);
    try {
      const result = await setMutateFee(Fee, walletProvider);
      // console.log(result, "set mutate");
      getMutate();
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const SetChangeFee = async (Fee) => {
    props.setLoading(true);
    try {
      const result = await setRenameFee(Fee, walletProvider);
      // console.log(result);
      getRename();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const SetTPercentage = async (Percentage) => {
    props.setLoading(true);
    try {
      // console.log(Percentage);
      const result = await setBreedFeeTessPercentage(
        Percentage,
        walletProvider
      );
      // console.log(result, "t percentage");
      GetTPercentage();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const WithdrawBalance = async (Amount) => {
    props.setLoading(true);
    try {
      // console.log(Percentage);
      const result = await withdrawBalanceBreed(Amount, walletProvider);
      // console.log(result, "withdrdaw");
      getContractBalance();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const SetCOOAddress = async (Address) => {
    props.setLoading(true);
    try {
      const result = await setCOOAddrBreed(Address, walletProvider);
      // console.log(result, "set coo address");
      getCOOAddr();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const SetCFOAddress = async (Address) => {
    props.setLoading(true);
    try {
      const result = await setCFOAddrBreed(Address, walletProvider);
      // console.log(result, "set cfo address");
      getCFOAddr();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  //End API call

  const onArrowHandler = () => {
    props.handlerFixed();
  };
  useEffect(() => {
    if (props.dropDown) {
      setDropDownClass("drop-down-open");
      setRotate("rotate");
    } else {
      setDropDownClass("drop-down-close");
      setRotate("");
    }
  }, [props.dropDown]);

  return (
    <div>
      {loading && <LoadingSpinner />}

      {/* Start Navigation Bar  */}
      <div className="a-main-container">
        <div className="a-first-section">
          <ArrowIcon rotate={rotate} onClick={onArrowHandler} />
          <div className="a-title">Breeding</div>
        </div>
        <div className="a-second-section">
          <div className="text-holder">
            {props.breedPause ? "Pause" : "Unpause"}
          </div>
          {!props.breedPause ? (
            <BasicButton onClick={Pause}>Pause</BasicButton>
          ) : (
            <BasicButton onClick={Unpause}>unpause</BasicButton>
          )}
        </div>
      </div>
      {/* End Navigation Bar  */}

      <div className={dropDownClass}>
        {/* <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getContractBalanceBreed();
              }}
              textInfo="Get contract balance          "
              formTitle="Get contract balance          "
            />
          </div>
          <div className="sub">
            <h5>Current balance in contract</h5>
            <p>
              Balance: <span>{}</span> Matic
            </p>
          </div>
        </div> */}
        {/* <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getCFOAddrBreed();
              }}
              textInfo="Get CFO Breed address         "
              formTitle="Get CFO Breed address         "
            />
          </div>
          <div className="sub">
            <h5>Current CFO breed address</h5>
            <p>
              Address: <span>{}</span>{" "}
            </p>
          </div>
        </div> */}
        {/* <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getCOOAddrBreed();
              }}
              textInfo="Get COO War address         "
              formTitle="Get COO War address         "
            />
          </div>
          <div className="sub">
            <h5>Current COO breed address</h5>
            <p>
              Address: <span>{}</span>{" "}
            </p>
          </div>
        </div> */}
        {/* <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                GetTPercentage();
              }}
              textInfo="Check value that will be deposited in the smart contract every time a paid function is run"
              formTitle="Get Tesseract percentage"
            />
          </div>
          <div className="sub">
            <h5>Current Tesseract Percentage input fee</h5>
            <p>
              Fee: <span>{}</span> Matic
            </p>
          </div>
        </div> */}
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                WithdrawBalance(ev);
              }}
              textInfo="Withdraw money that has been deposited in the contract address related to the specific game function"
              formTitle="Withdraw balance"
              label="Amount"
            />
          </div>
          <div className="sub">
            <h5>Current balance in contract</h5>{" "}
            <p>
              Balance: <span>{balance}</span> Matic
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCOOAddress(ev);
              }}
              textInfo="Insert a value that will be deposited in the smart contract every time a paid function is run"
              formTitle="Set COO address"
              label="new address"
            />
          </div>
          <div className="sub">
            <h5>Current COO breed address</h5>
            <p>
              Address: <span>{coo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCFOAddress(ev);
              }}
              textInfo="Insert a value that will be deposited in the smart contract every time a paid function is run"
              formTitle="Set CFO address"
              label="New fee"
            />
          </div>
          <div className="sub">
            <h5>Current CFO breed address</h5>
            <p>
              Address: <span>{cfo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetBreedFee(ev);
              }}
              textInfo="Set breed fee before breeding your cards with cards belonging to other players. Card owners have to pay the breed fee when breeding their cards with others."
              formTitle="Set breeding fee"
              label="Input fee"
            />
          </div>
          <div className="sub">
            <h5>Current breeding fee</h5>
            <p>
              Fee: <span>{breedFee}</span> Matic
            </p>
          </div>
        </div>
        {/* <FormTowInput
          onSubmit={(ev) => {
            Breed(ev)
          }}
          textInfo="Function is used to breed new cards among a player’s own collection. Parent cards must belong in the same faction."
          formTitle="Breed"
          firstLabel="Father’s Token ID"
          secondLabel="Mother’s Token ID"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            RemoveBreed(ev)
          }}
          formTitle="Remove from breed list"
          textInfo="Remove any cards that have been put up on the breed list for breeding with others."
          label="Token ID"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllBreedingOneInput(ev, "setBreedingAddress");
          }}
          textInfo="Change or input the address for Breeding smart contract on the blockchain"
          formTitle="Set breeding address"
          label="New address"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllBreedingOneInput(ev, "setChangeNameFee");
          }}
          textInfo="Set the amount of fee that needs to be paid to run the Change name or Rename cards function"
          formTitle="Set change name fee"
          label="Input fee"
        /> */}
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetRankFee(ev);
              }}
              textInfo="Set the amount of fee that needs to be paid to run the Rank up or Promote cards function"
              formTitle="Set rank up fee"
              label="Input fee"
            />
          </div>
          <div className="sub">
            <h5>Current rank up fee</h5>
            <p>
              Fee: <span>{promoteFee}</span> Matic
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetMutateFee(ev);
              }}
              textInfo="Set the amount of fee that needs to be paid to run mutate cards function"
              formTitle="Set mutate fee"
              label="Input fee"
            />
          </div>
          <div className="sub">
            <h5>Current Mutate Fee</h5>
            <p>
              Fee: <span>{mutateFee}</span> Matic
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetChangeFee(ev);
              }}
              textInfo="Set the amount of fee that needs to be paid to run rename cards function"
              formTitle="Set rename fee"
              label="Input fee"
            />
          </div>
          <div className="sub">
            <h5>Current Rename Fee</h5>
            <p>
              Fee: <span>{renameFee}</span> Matic
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetTPercentage(ev);
              }}
              textInfo="Insert a value that will be deposited in the smart contract every time a paid function is run"
              formTitle="Set Tesseract Percentage "
              label="New percentage"
            />
          </div>
          <div className="sub">
            <h5>Current Tesseract Percentage</h5>
            <p>
              Percentage: <span>{tpercentage}</span> %
            </p>
          </div>
        </div>
        {/* <FormTowInput
          onSubmit={(ev) => {
            PutOnBreed(ev)
          }}
          textInfo="Insert token ID and a user specified breeding fee to add cards on the Breed list. and breed with others"
          formTitle="Add to breed list"
          firstLabel="Token ID"
          secondLabel="Amount"
        /> */}

        {/* <FormSubmit
          onSubmit={() => {
            // getAllBreedingFn("BreedingAddress");
          }}
          textInfo="Check the address that has been set as Breeding smart contract on the blockchain"
          formTitle="Get breeding address"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllBreedingOneInput(ev, "CalcRank");
          }}
          textInfo="Insert total battle points to calculate rank of the relevant card"
          formTitle="Calculate rank"
          label="Insert battle points"
        /> */}

        {/* <FormSubmit
          onSubmit={() => {
            // getAllBreedingFn("ChangeNameFee");
          }}
          textInfo="Check the fee that has been set to change name of cards  or rename cards"
          formTitle="Get change name fee"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllBreedingOneInput(ev, "FinalBreedFeeList");
          }}
          textInfo="Get list of breed fee"
          formTitle="Get final breed fee list"
          label="Input"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
           IsOnBreed(ev)
          }}
          textInfo=""
          formTitle="Is on Breed list"
          label="Ioken ID"
        /> */}
        {/* 
        <FormSubmit
          onSubmit={() => {
            // getAllBreedingFn("MutateFee");
          }}
          textInfo="Check the fee that has been set for the mutation functionality"
          formTitle="Get mutate fee"
        /> */}

        {/* <FormSubmit
          onSubmit={() => {
            // getAllBreedingFn("RankUpFee");
          }}
          textInfo="Check the fee that has been set for the rank up or promote cards functionality"
          formTitle="Get rank up fee"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllBreedingOneInput(ev, "RankList_Num");
          }}
          textInfo="Check levels of each rank in the game"
          formTitle="Get Rank list number"
          label="Input"
        /> */}
      </div>
    </div>
  );
}

export default BreedingAd;
