import React from "react";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import LOGO from "../Footer/LOGO.png";
import "../Footer/Footer.css";
import PopUp from "../Shared/PopUp";
import LoaderFloating from "../Shared/LoaderFloating";

function Footer() {
  const Subscribe=async(e)=>{
    e.preventDefault()
    setLoading(true)
    const email=e.target.email.value
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
    });

    const params = {
      TableName:process.env.REACT_APP_SUBSCRIBE_TABLE_NAME,
      Item: {
        Email: email,       
        Time: Date.now(),
      },
    };
    // console.log(params);
    try {
      const response = await documentClient.put(params).promise();
      setLoading(false);
      setPopUp(true);
      setPopUpSubject("Successful");
      // console.log(response, "contact");
    } catch (err) {
      // console.log(err);
      setLoading(false);
      setPopUp(true);
      setPopUpSubject("Something went wrong");
    }
  }
  const [loading, setLoading] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const location = useLocation();
  const pathString = location.pathname;
  const exludePath = [
    "/cardcollection",
    "/alice-card",
    "/blacknet-card",
    "/humanity-card",
    "/genesis-card",
    "/breeding",
    "/breed-self",
    "/breed-others",
    "/battle-list",
    "/individual-card",
    "/promote",
    "/mutate",
    "/rename",
    "/marketplace",
  ];

  let footer;

  if (exludePath.includes(pathString)) {
    footer = "";
  } else {
    footer = (
      <div>
        <footer>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4 col-xl-3">
                <div className="footer_logo">
                  <img className="align-top" src={LOGO} />
                  <div className="copy_right align-bottom">
                    <p>
                      © {new Date().getFullYear()} EXTREMIS All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xl-2">
                <div className="footer_menu">
                  <ul>
                    <li>
                      <Link to="/profile">My Profile</Link>
                    </li>

                    <li>
                      <Link to="/guide">Gameplay Guide</Link>
                    </li>
                    <li>
                      <Link to="/disclaiMer">Disclaimer</Link>
                    </li>
                    <li>
                    <Link  to="/contact-us">
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-xl-2">
                <div className="footer_menu">
                  <ul>
                    <li>
                      <Link to="/metaverse">About EXTREMIS</Link>
                    </li>
                    <li>
                      <Link to="/meet-team">Meet the Team</Link>
                    </li>

                    <li>
                      <Link className="text-danger" to="/terms-of-use">
                        Terms of use
                      </Link>
                    </li>
                    <li>
                     
                      <Link className="text-danger" to="/PrivacyPolicy">Privacy policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-xl-2">
                <div className="footer_menu">
                  <ul>
                    <li>
                      <a
                        href="https://discord.gg/kV4YeYQWyB"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Discord
                      </a>
                    </li>
                    <li>
                    <a
                      href="https://t.me/+ZKLv8kw7lB4zN2Nh"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Telegram
                    </a>{" "}
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/extremisverse/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/extremisverse/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instagram
                      </a>
                    </li>
                   
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-xl-3">
                <div className="footer_search">
                  <form className="navbar-form" role="search" onSubmit={Subscribe}>
                    <div className="input-group add-on">
                      <input
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        id="srch-term"
                        type="email"
                        required
                      />
                    
                    </div>
                  <button className="subscribe">
                    Subscribe to news letters
                  </button>
                  </form>
                  {/* <Link to="/buy-nft">BUY NFTS</Link> */}

                  <ul>
                    <li>
                      <a
                        href="https://twitter.com/ExtremisVerse"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/extremisverse/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/extremisverse/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://t.me/+ZKLv8kw7lB4zN2Nh"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fab fa-telegram"
                          target="_blank"
                          rel="noreferrer"
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://discord.gg/kV4YeYQWyB"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fab fa-discord"
                          target="_blank"
                          rel="noreferrer"
                        ></i>
                      </a>
                    </li>
                   
                  </ul>
                </div>
              </div>   {loading && <LoaderFloating></LoaderFloating>}
            {popup && <PopUp popUpSubject={popUpSubject}></PopUp>}
            </div>
          </div>
        </footer>
      </div>
    );
  }
  return <div>{footer}</div>;
}
export default Footer;
//faq