import React, { useState } from "react";
import { useForm } from "react-hook-form";

const WarExit = ({ ExitFromWar, setConfirmWarExit }) => {
  const [confirmButton, setConfirmButton] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const Exit = async (data) => {
    // console.log("hello");
    setConfirmButton(true);
    ExitFromWar();
  };

  return (
    <div className="popup">
      <div className="confirm-container">
        <div className="popup-msg ">
          <h3>Exit War</h3>
          <p>
          Are you sure you want to exit the war? <br /> NOTE: In doing so your card's rank will change to "Deserter"
          </p>
          <form action="" onSubmit={handleSubmit(Exit)}>
           
           <div className="button">
           <button
              type="submit"
              id="confirmation"
              className="form-control confirm-button rounded submit px-3"
            >
              {!confirmButton ? "CONFIRM" : "PLEASE WAIT..."}
            </button>
            <button
              onClick={() => {
                setConfirmWarExit(false);
              }}
              className="form-control confirm-button rounded submit px-3"
            >
              CANCEL
            </button>
           </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WarExit;
