import React from 'react';
import ProfileAllcardsList from './Sections/ProfileAllcardsList';
// import AllcardsList from './Sections/ProfileAllcardsList';
import ProfileAllCardsText from './Sections/ProfileAllcardsText';
// import AllCardsText from './Sections/ProfileAllcardsText';

const ProfileAllCard = () => {
    return (
        <div>
            <ProfileAllcardsList></ProfileAllcardsList>
            <ProfileAllCardsText></ProfileAllCardsText>
        </div>
    );
};

export default ProfileAllCard;