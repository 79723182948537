import { Auth } from "aws-amplify";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { addressContext } from "../../../App";
import useCustomerInfo from "../../Hooks/useCustomerInfo";
import useUsername from "../../Hooks/useUsername";
import profile_img from "./Images/profile_img.png";
import * as AWS from "aws-sdk";
import awsmobile from "../../../aws-exports";

const ProfileSliderChild = ({ position, index }) => {
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const Username = useUsername(position.CustomerID);
  const [imageSrc, setImageSrc] = useState("");

  const customerInfo = useCustomerInfo(currentAddress);

  const replaceImage = (error) => {
    //replacement of broken Image
    error.target.src = "/ErrorImage/profile_img.png";
  };
  const [accessKeyId, setAccessKey] = useState("");
  useEffect(() => {
    const cred = async () => {
      const auth = await Auth.currentCredentials();
      setAccessKey(auth);
    };
    cred();
  }, []);
  function encode(data) {
    var str = data?.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  }
  
  useEffect(() => {
    if (
      awsmobile.aws_cognito_region &&
      AWS.config.credentials &&
      position.CustomerID && accessKeyId
    ) {
      const s3 = new AWS.S3({
        credentials: accessKeyId,
      });
      var getParams = {
        Bucket: "extremis-user-profilepics",
        Key: `ProfilePicture/${position.CustomerID}`,
      };
console.log(`ProfilePicture/${position.CustomerID}`);
      s3.getObject(getParams, function (err, data) {
        if (data?.Body) {
          const src = encode(data.Body);
          setImageSrc(src);
        } else if (err) {
          console.log(err);
        }
      });
    }
  }, [awsmobile.aws_cognito_region, AWS.config.credentials, position.CustomerID,accessKeyId]);

  return (
    <div>
      <div className="col-md-12">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img
              // src={`https://tokencharacterimages.s3.amazonaws.com/ProfilePicture/${position.CustomerID}`}
              src={`data:image/jpeg;base64,${imageSrc}`}

              alt=""
              onError={replaceImage}
            />
          </div>
          <div className="col-md-6">
            <h2>LEADERBOARD</h2>
            <h6># {index + 1}</h6>
            <h3>{Username}</h3>
            <p>
              Battles won: <span>{position?.WinCount}</span>
            </p>
            <Link className="play_byn btn" to="/leaderboard">
              SEE FULL LIST
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSliderChild;
