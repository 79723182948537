import React, { useState } from "react";
import BasicButton from "./BasicButton";
import Form from "./Form";
import InputForm from "./InputForm";
import TitleForm from "./TitleForm";

function FormOneInput(props) {
  const [inputText, setInputText] = useState("");
  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        props.onSubmit(inputText);
        setInputText("");
      }}
    >
      <TitleForm text={props.textInfo}>{props.formTitle}</TitleForm>
      <div className="input-content-start">
        <InputForm
          onChange={(event) => {
            setInputText(event);
          }}
          label={props.label}
          value={inputText}
        />
        <BasicButton>Transact</BasicButton>
      </div>
    </Form>
  );
}

export default FormOneInput;
