import React, { useContext } from "react";
import { useEffect, useState } from "react";

// components
import ArrowIcon from "./AdminComponent/ArrowIcon";
import BasicButton from "./AdminComponent/BasicButton";
import Form from "./AdminComponent/Form";
import InputForm from "./AdminComponent/InputForm";
import TitleForm from "./AdminComponent/TitleForm";
import FormOneInput from "./AdminComponent/FormOneInput";
import FormSubmit from "./AdminComponent/FormSubmit";
import FormTowInput from "./AdminComponent/FormTowInput";
import LoadingSpinner from "./AdminComponent/LoadingSpinner";
import {
  createPromoCodeFM,
  deletePromoCodeFM,
  GetBalanceFM,
  GetCFOAddrFixedMarketPlace,
  GetCOOAddrFixedMarketPlace,
  GetFixedMarketPlacePrice,
  GetPromoCodeDetailsFM,
  GetTesseractPercentageFixedMarket,
  IsOnFixedMarketList,
  IsPausedFixedMarketPlace,
  pauseFixedMarketContract,
  putOnFixedMarketPlace,
  removeFromFixedMarketPlace,
  resetPromoCodeFM,
  setCFOAddrFixedMarketPlace,
  setCOOAddrFixedMarketPlace,
  setFixedMarketFeeTessPercentage,
  unPauseFixedMarketContract,
  withdrawBalanceFixedMarket,
} from "../../UserPage/BlockChainFunctions";
import { providerContext } from "../../../App";
import * as AWS from "aws-sdk";
import useFixedMarketContract from "../../Hooks/FixedMarketHook";

//const API Calls from ethers
/////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////

function FixedMarketplace(props) {
  const [loading, setLoading] = useState(false);
  const [rotate, setRotate] = useState("");
  const [dropDownClass, setDropDownClass] = useState("drop-down-close");
  const [pauseOn, setPauseOn] = useState(false);
  const [input4, setInput4] = useState({
    firstInput: "",
    secondInput: "",
    thirdInput: "",
    forthInput: "",
  });

  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [fixedMarketContract, setFixedMarketContract] =
    useFixedMarketContract();
  const ssm = new AWS.SSM();

  const [cfo, setCfo] = useState("");
  const [coo, setCoo] = useState("");
  const [balance, setBalance] = useState("");
  const [tpercentage, setTPercentage] = useState("");
  const [promoDetails, setPromoDetails] = useState();
  const [promoDetails1, setPromoDetails1] = useState();
  const [promoDeleted, setPromoDeleted] = useState(false);

  useEffect(() => {
    if (fixedMarketContract) {
      // console.log("hello");
      props.FixedIsPause();
    }
  }, [fixedMarketContract]);

  const Pause = async () => {
    props.setLoading(true);
    try {
      const result = await pauseFixedMarketContract(walletProvider);
      // setPauseOn(true)
      props.FixedIsPause();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
    // console.log(result);
  };
  const Unpause = async () => {
    props.setLoading(true);
    try {
      const result = await unPauseFixedMarketContract(walletProvider);
      // console.log(result);
      props.FixedIsPause();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const getCOOAddr = async () => {
    const result = await GetCOOAddrFixedMarketPlace(fixedMarketContract);
    // console.log(result, "coo addr");
    setCoo(result);
  };
  const getCFOAddr = async () => {
    const result = await GetCFOAddrFixedMarketPlace(fixedMarketContract);
    // console.log(result, "cfo");
    setCfo(result);
  };

  const getTPercentage = async () => {
    const result = await GetTesseractPercentageFixedMarket(fixedMarketContract);
    setTPercentage(result);
    // console.log(result, "gettpercentage");
  };
  const getBalance = async () => {
    const result = await GetBalanceFM(fixedMarketContract);
    setBalance(result);
  };
  const getPromoDetails = async (Code) => {
    const result = await GetPromoCodeDetailsFM(Code, walletProvider);
    // console.log(result,"promo details");
    setPromoDetails(result);
  };
  const getPromoDetails1 = async (Code) => {
    const result = await GetPromoCodeDetailsFM(Code, walletProvider);
    // console.log(result,"promo details");
    setPromoDetails1(result);
  };
  useEffect(() => {
    if (fixedMarketContract) {
      getBalance();
    }
  }, [fixedMarketContract]);
  useEffect(() => {
    if (fixedMarketContract) {
      getTPercentage();
    }
  }, [fixedMarketContract]);
  useEffect(() => {
    if (fixedMarketContract) {
      getCOOAddr();
    }
  }, [fixedMarketContract]);

  useEffect(() => {
    if (fixedMarketContract) {
      getCFOAddr();
    }
  }, [fixedMarketContract]);

  const SetCOO = async (Address) => {
    props.setLoading(true);
    try {
      const result = await setCOOAddrFixedMarketPlace(Address, walletProvider);
      // console.log(result, "coo set address");
      getCOOAddr();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const SetCFO = async (Address) => {
    props.setLoading(true);

    try {
      const result = await setCFOAddrFixedMarketPlace(Address, walletProvider);
      // console.log(result, "cFo set address");
      getCFOAddr();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const CreatePromo = async ({
    firstInput,
    secondInput,
    thirdInput,
    forthInput,
  }) => {
    props.setLoading(true);

    try {
      const result = await createPromoCodeFM(
        firstInput,
        secondInput,
        thirdInput,
        forthInput,
        walletProvider,
        ssm
      );
      // console.log(result, "cresate promo");
      getPromoDetails(secondInput);

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const DeletePromo = async (Code) => {
    props.setLoading(true);

    try {
      const result = await deletePromoCodeFM(Code, walletProvider, ssm);
      // console.log(result, "delete promo ");
      if (result.txreceipt?.blockHash) {
        setPromoDeleted(true);
      }

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const ResetPromo = async (Code) => {
    props.setLoading(true);

    try {
      const result = await resetPromoCodeFM(Code, walletProvider);
      // console.log(result, "|Reset Promo");
      getPromoDetails1(Code);

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const SetTpercentage = async (Percentage) => {
    props.setLoading(true);

    try {
      const result = await setFixedMarketFeeTessPercentage(
        Percentage,
        walletProvider
      );
      // console.log(result, "t percentage");
      getTPercentage();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const Withdraw = async (Amount) => {
    props.setLoading(true);

    try {
      const result = await withdrawBalanceFixedMarket(Amount, walletProvider);
      // console.log(result, "Withdraw");
      getBalance();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  //End API call

  const onArrowHandler = () => {
    props.handlerFixed();
  };
  useEffect(() => {
    if (props.dropDown) {
      setDropDownClass("drop-down-open");
      setRotate("rotate");
    } else {
      setDropDownClass("drop-down-close");
      setRotate("");
    }
  }, [props.dropDown]);

  return (
    <div>
      {loading && <LoadingSpinner />}

      {/* Start Navigation Bar  */}
      <div className="a-main-container">
        <div className="a-first-section">
          <ArrowIcon rotate={rotate} onClick={onArrowHandler} />
          <div className="a-title">Fixed marketplace</div>
        </div>
        <div className="a-second-section">
          <div className="text-holder">
            {props.fixedPause ? "Pause" : "Unpause"}
          </div>
          {!props.fixedPause ? (
            <BasicButton onClick={Pause}>Pause</BasicButton>
          ) : (
            <BasicButton onClick={Unpause}>unpause</BasicButton>
          )}
        </div>
      </div>
      {/* End Navigation Bar  */}

      <div className={dropDownClass}>
        {/* <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getCOOAddr();
              }}
              textInfo="Get COO Fixed marketplace address          "
              formTitle="Get COO Fixed marketplace address          "
            />
          </div>
          <div className="sub">
            <h5>Current COO fixed marketplace address</h5>
            <p>
              Address: <span>{}</span>
            </p>
          </div>
        </div>

        <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getCFOAddr();
              }}
              textInfo="Get CFO Fixed marketplace address          "
              formTitle="Get CFO Fixed marketplace address          "
            />
          </div>
          <div className="sub">
            <h5>Current CFO fixed marketplace address</h5>
            <p>
              Address: <span>{}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getTPercentage();
              }}
              textInfo="Check value that will be deposited in the smart contract every time a paid function is run"
              formTitle="Get Tesseract Percentage"
            />
          </div>
          <div className="sub">
            <h5>Current Tesseract Percentage input fee</h5>
            <p>
              Fee: <span></span> Matic
            </p>
          </div>
        </div> */}
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCFO(ev);
              }}
              textInfo="Set CFO Fixed marketplace address          "
              formTitle="Set CFO Fixed marketplace address          "
              label="Address"
            />
          </div>
          <div className="sub">
            <h5>Current CFO fixed marketplace address</h5>
            <p>
              Address: <span>{cfo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCOO(ev);
              }}
              textInfo="Set COO Fixed marketplace address          "
              formTitle="Set COO Fixed marketplace address          "
              label="Address"
            />
          </div>
          <div className="sub">
            <h5>Current COO fixed marketplace address</h5>
            <p>
              Address: <span>{coo}</span>
            </p>
          </div>
        </div>

        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetTpercentage(ev);
              }}
              textInfo="Insert a value that will be deposited in the smart contract every time a paid function is run"
              formTitle="Set Tesseract Percentage"
              label="Percentage"
            />
          </div>
          <div className="sub">
            <h5>Current Tesseract Percentage </h5>
            <p>
              Percentage: <span>{tpercentage}</span> %
            </p>
          </div>
        </div>

        {/* 4 input */}
        <div className="section">
          <div className="main">
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                CreatePromo(input4);
                setInput4({
                  firstInput: "",
                  secondInput: "",
                  thirdInput: "",
                  forthInput: "",
                });
              }}
            >
              <TitleForm
                text={
                  "Create promo code to avail discounts for purchase of cards in the Fixed marketplace.  Input % value for discount and the number of times the promo can be used."
                }
              >
                Create Promo
              </TitleForm>
              <div className="input-content-start">
                <InputForm
                  value={input4.firstInput}
                  onChange={(ev) => {
                    setInput4({ ...input4, firstInput: ev });
                  }}
                  label="Promo name"
                />
                <InputForm
                  value={input4.secondInput}
                  onChange={(ev) => {
                    setInput4({ ...input4, secondInput: ev });
                  }}
                  label="Code"
                />
                <InputForm
                  value={input4.thirdInput}
                  onChange={(ev) => {
                    setInput4({ ...input4, thirdInput: ev });
                  }}
                  label="Discount"
                />
                <InputForm
                  value={input4.forthInput}
                  onChange={(ev) => {
                    setInput4({ ...input4, forthInput: ev });
                  }}
                  label="Usage count"
                />
              </div>
              <BasicButton>Transact</BasicButton>
            </Form>
          </div>
          <div className="sub">
            <h5>New promo details</h5>
            <p>
              Promo name: <span>{promoDetails?.Name}</span>
            </p>
            <p>
              Promo code: <span>{promoDetails?.Code}</span>
            </p>
            <p>
              Value: <span>{promoDetails?.TotalValue?.toString()}</span>
            </p>
            <p>
              Usage: <span>{promoDetails?.UseCount?.toString()}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                DeletePromo(ev);
              }}
              textInfo="Delete existing promo code by typing in the code. Action will nullify further use of the promo code."
              formTitle="Delete Promo"
              label="Code"
            />
          </div>
          <div className="sub">
            {promoDeleted && <h5>Promo code deleted</h5>}
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                ResetPromo(ev);
              }}
              textInfo="Type in the promo code to reset its discount and usage value to default settings."
              formTitle="Reset promo value and count"
              label="Code"
            />
          </div>
          <div className="sub">
            <h5>Current promo details</h5>
            <p>
              Promo name: <span>{promoDetails1?.Name}</span>
            </p>
            <p>
              Promo code: <span>{promoDetails1?.Code}</span>
            </p>
            <p>
              Value: <span>{promoDetails1?.TotalValue?.toString()}</span>
            </p>
            <p>
              Usage: <span>{promoDetails1?.UseCount?.toString()}</span>
            </p>
          </div>
        </div>

        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                Withdraw(ev);
              }}
              textInfo="Withdraw money that has been deposited in the contract address related to the specific game function"
              formTitle="Withdraw balance"
              label="Amount"
            />
          </div>
          <div className="sub">
            <h5>Current balance in contract</h5>
            <p>
              Balance: <span>{balance}</span> Matic
            </p>
          </div>
        </div>

        {/* <FormTowInput
          onSubmit={(ev) => {
           PutOnFixedMarket(ev)
          }}
          textInfo="Enter the Token ID of card and a selling price to put card up for sale in the Fixed marketplace"
          formTitle="Sell card"
          firstLabel="Token ID"
          secondLabel="Price"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllFixedOneInput(ev, "setFixedMarketAddress");
          }}
          textInfo="Enter the Token ID of card and a selling price to put card up for sale in the Fixed marketplace"
          formTitle="Set Fixed Marketplace address"
          label="Code"
        /> */}

        {/* <FormSubmit
          onSubmit={() => {
            // getAllFixedFn("Fixed_MarketPlace_ContractAdress");
          }}
          textInfo="Action will show the selected address of the Fixed marketplace smart contract"
          formTitle="Get Fixed Marketplace contract address"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllFixedOneInput(ev, "FPdatabase");
          }}
          textInfo="Get database of all prices in the Fixed marketplace"
          formTitle="Get FP database"
          label="Code"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
           GetFixedPrice(ev)
          }}
          textInfo="Get the price of any token that has been put up on sale in the Fixed marketplace"
          formTitle="Get Fixed Marketplace price"
          label="Token ID"
        /> */}
        {/* 
        <FormSubmit
          onSubmit={() => {
            // getAllFixedFn("GetFixedPriceDatabase");
          }}
          textInfo="Get prices of all cards that are on sale in the Fixed marketplace"
          formTitle="Get Fixed price database"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllFixedOneInput(ev, "GetUseCount");
          }}
          textInfo="Used to check the total number of times a promo code can be used"
          formTitle="Get usage count"
          label="Code"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
          IsOnFixedMarketPlace(ev)
          }}
          textInfo="Used to check whether a card is on sale in the Fixed marketplace. Insert Token ID of card to get results"
          formTitle="Is on Fixed Marketplace"
          label="Token ID"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllFixedOneInput(ev, "PromoList");
          }}
          textInfo="Check all promo codes that are available for use in the Fixed marketplace"
          formTitle="Get Promo list"
          label="User ID"
        /> */}

        {/* <FormOneInput
          onSubmit={(ev) => {
            // getAllFixedOneInput(ev, "Record");
          }}
          textInfo="Get complete record of sale related information of any card by entering its token ID"
          formTitle="Get sale record"
          label="Token ID"
        /> */}
      </div>
    </div>
  );
}

export default FixedMarketplace;
