import React from 'react';
import '../ProfileCollection/CardCollection.css';
import ProfileGenesisList from './SubPages/Genesis/Sections/ProfileGenesisList';
import ProfileGenesisText from './SubPages/Genesis/Sections/ProfileGenesisText';

function ProfileGenesisCard () {
	return <div>
		{/* <Genesisbanner/> */}
		<ProfileGenesisList></ProfileGenesisList>
		<ProfileGenesisText></ProfileGenesisText>

        
	</div>
}
export default ProfileGenesisCard;