import React, { useContext } from "react";
import { useState, useEffect } from "react";

// components
import ArrowIcon from "./AdminComponent/ArrowIcon";
import BasicButton from "./AdminComponent/BasicButton";
import FormOneInput from "./AdminComponent/FormOneInput";
import FormSubmit from "./AdminComponent/FormSubmit";
import LoadingSpinner from "./AdminComponent/LoadingSpinner";
import { providerContext } from "../../../App";
import * as AWS from "aws-sdk";
import {
  GetCFOAddrGameCentre,
  GetContractBalanceGameCentreLink,
  GetCOOAddrGameCentre,
  IsPausedGameCentre,
  pauseGameCentreContract,
  setCFOAddrGameCentre,
  setCOOAddrGameCentre,
  unPauseGameCentreContract,
  withdrawBalanceGameCentreLink,
} from "../../UserPage/BlockChainFunctions";
import useGameCentreContract from "../../Hooks/GameCentreHook";

//const API Calls from ethers
/////////////////////////////////////////////////////////////////////

function GameCenter(props) {
  const [loading, setLoading] = useState(false);
  const [pauseOn, setPauseOn] = useState(false);
  const [rotate, setRotate] = useState("");
  const [dropDownClass, setDropDownClass] = useState("drop-down-close");

  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const ssm = new AWS.SSM();
  const [link, setLink] = useState("");

  const [cfo, setCfo] = useState("");
  const [coo, setCoo] = useState("");

  useEffect(() => {
    if (gameCentreContract) {
      // console.log("hello");
      props.GameCentreIsPause();
    }
  }, [gameCentreContract]);

  const Pause = async () => {
    props.setLoading(true);

    try {
      const result = await pauseGameCentreContract(walletProvider);
      // setPauseOn(true)
      props.GameCentreIsPause();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
    // console.log(result);
  };
  const Unpause = async () => {
    props.setLoading(true);

    try {
      const result = await unPauseGameCentreContract(walletProvider);
      // console.log(result);
      props.GameCentreIsPause();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const getCOOAddr = async () => {
    const result = await GetCOOAddrGameCentre(gameCentreContract);
    // console.log(result, "get coo ");
    setCoo(result);
  };
  const getCFOAddr = async () => {
    const result = await GetCFOAddrGameCentre(gameCentreContract);
    // console.log(result, "get cfo ");
    setCfo(result);
  };
  const getLink = async () => {
    const result = await GetContractBalanceGameCentreLink(gameCentreContract);
    setLink(result.toString());
  };
  useEffect(() => {
    if (gameCentreContract) {
      getCOOAddr();
    }
  }, [gameCentreContract]);

  useEffect(() => {
    if (gameCentreContract) {
      getCFOAddr();
    }
  }, [gameCentreContract]);
  useEffect(() => {
    if (gameCentreContract) {
      getLink();
    }
  }, [gameCentreContract]);
  const SetCOO = async (Address) => {
    props.setLoading(true);

    try {
      const result = await setCOOAddrGameCentre(Address, walletProvider);
      // console.log(result, "set coo");
      getCOOAddr();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const SetCFO = async (Address) => {
    props.setLoading(true);

    try {
      const result = await setCFOAddrGameCentre(Address, walletProvider);
      // console.log(result, "set cFo");
      getCFOAddr();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };
  const WithdrawLink = async (Amount) => {
    props.setLoading(true);

    try {
      const result = await withdrawBalanceGameCentreLink(
        Amount,
        walletProvider
      );
      // console.log(result, "Withdraw link");
      getLink();

      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const onArrowHandler = () => {
    props.handlerOwner();
  };
  useEffect(() => {
    if (props.dropDown) {
      setDropDownClass("drop-down-open");
      setRotate("rotate");
    } else {
      setDropDownClass("drop-down-close");
      setRotate("");
    }
  }, [props.dropDown]);

  return (
    <div>
      {loading && <LoadingSpinner />}

      {/* Start Navigation Bar  */}
      <div className="a-main-container">
        <div className="a-first-section">
          <ArrowIcon rotate={rotate} onClick={onArrowHandler} />
          <div className="a-title">Game center</div>
        </div>
        <div className="a-second-section">
          <div className="text-holder">
            {props.gameCenterPause ? "Pause" : "Unpause"}
          </div>
          {!props.gameCenterPause ? (
            <BasicButton onClick={Pause}>Pause</BasicButton>
          ) : (
            <BasicButton onClick={Unpause}>unpause</BasicButton>
          )}
        </div>
      </div>
      {/* End Navigation Bar  */}

      <div className={dropDownClass}>
        {/* <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getCOOAddr();
              }}
              textInfo="Get COO game center address          "
              formTitle="Get COO game center address          "
            />
          </div>
          <div className="sub">
            <h5>Current COO game center address</h5>
            <p>
              Address: <span>{}</span>
            </p>
          </div>
        </div> */}

        {/* <div className="section">
          <div className="main">
            <FormSubmit
              onSubmit={() => {
                getCFOAddr();
              }}
              textInfo="Get CFO game center address          "
              formTitle="Get CFO game center address          "
            />
          </div>
          <div className="sub">
            <h5>Current CFO game center address</h5>
            <p>
              Address: <span>{}</span>
            </p>
          </div>
        </div> */}

        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCFO(ev);
              }}
              textInfo="Set CFO game center address          "
              formTitle="Set CFO game center address          "
              label="Address"
            />
          </div>
          <div className="sub">
            <h5>Current CFO game center address</h5>
            <p>
              Address: <span>{cfo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                SetCOO(ev);
              }}
              textInfo="Set COO game center address          "
              formTitle="Set COO game center address          "
              label="Address"
            />
          </div>
          <div className="sub">
            <h5>Current COO game center address</h5>
            <p>
              Address: <span>{coo}</span>
            </p>
          </div>
        </div>
        <div className="section">
          <div className="main">
            <FormOneInput
              onSubmit={(ev) => {
                WithdrawLink(ev);
              }}
              textInfo="Withdraw link that has been deposited in the contract address related to the specific game function"
              formTitle="Withdraw link balance"
              label="Amount"
            />
          </div>
          <div className="sub">
            <h5>Current link balance in contract</h5>
            <p>
              Balance: <span>{link}</span> link
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameCenter;
