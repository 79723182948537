import React from 'react';
import AuctionConfirmationBanner from '../PurchaseCards/AuctionHouse/AuctionConfirmation/Sections/AuctionConfirmationBanner';
import AuctionConfirmationTaxt from '../PurchaseCards/AuctionHouse/AuctionConfirmation/Sections/AuctionConfirmationTaxt';
import '../PurchaseCards/PurchaseCards.css';
function AuctionConfirmation () {
	return <div>
    <AuctionConfirmationBanner/>
	<AuctionConfirmationTaxt/>
	
	</div>
}
export default AuctionConfirmation;