import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useAuctionContract from "../Hooks/AuctionContractHook";
import CardDetails from "../Profile/Pages/IndividualCard/Sections/CardDetails";
import FeaturesCard from "../Profile/Pages/IndividualCard/Sections/FeaturesCard";
import AuctionHero from "../PurchaseCards/AuctionHouse/AuctionIndividual/Sections/AuctionHero";
// import CardDetails from "../PurchaseCards/AuctionHouse/AuctionIndividual/Sections/CardDetails";
import CurrentStatus from "../PurchaseCards/AuctionHouse/AuctionIndividual/Sections/CurrentStatus";
import "../PurchaseCards/PurchaseCards.css";
import useCard from "../Shared/Card/useCard";
import { IsOnAuctionList } from "../UserPage/BlockChainFunctions";
function AuctionIndividual() {
  const [isOnBreed, setIsOnBreed] = useState(false);
  const [isOnBattle, setIsOnbattle] = useState(false);
  const [isOnWar, setIsOnWar] = useState(false);
  const [isOnAuction, setIsOnAuction] = useState(false);
  const [isOnFixed, setIsOnFixed] = useState(false);
  const [auctionContract, setAuctionContract] = useAuctionContract();

  const TokenId = useParams();
  // console.log("tok", TokenId);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  },[]);

  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardOwner,
    CardOwnerUsername,
  ] = useCard(TokenId.TokenId);
  const IsOnAuction = async () => {
    const result = await IsOnAuctionList(TokenId.TokenId, auctionContract);
    setIsOnAuction(result);
  };

  useEffect(() => {
    if (auctionContract) {
      IsOnAuction();
    }
  }, [auctionContract]);


  return (
    <div>
      <AuctionHero />
      <CardDetails
        TokenId={TokenId.TokenId}
        isOnBreed={isOnBreed}
        isOnBattle={isOnBattle}
        isOnWar={isOnWar}
        isOnAuction={isOnAuction}
        isOnFixed={isOnFixed}
      />
      <CurrentStatus TokenId={TokenId.TokenId} />
      <FeaturesCard TokenId={TokenId.TokenId} />
    </div>
  );
}
export default AuctionIndividual;
