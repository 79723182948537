import React from 'react';

function Faq3 () {
	return <div>

<div className="faq_box">
   <div className="row">
   <div class="accordion" id="accordionPanelsStayOpenExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingTwentySix">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwentySix" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwentySix">
      Q1: What are ‘factions’?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwentySix" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwentySix">
      <div class="accordion-body">
        <p>A: The EXTREMIS Metaverse is mainly composed of three different factions. These are Blacknet, Alice, and Humanity. Cards and plot characters are centred on these factions having diverse characteristics and backstories.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingTwentySeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwentySeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwentySeven">
      Q2: Do I have to stick to a single faction?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwentySeven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwentySeven">
      <div class="accordion-body">
        <p>A: Players can purchase and collect cards of different factions. Users do not have to stick to a single faction to play EXTREMIS. But sticking to a specific faction throughout the game helps to make solid alliances.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingTwentyEight">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwentyEight" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwentyEight">
      Q3: Does each faction have unique sets of upgrades and features?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwentyEight" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwentyEight">
      <div class="accordion-body">
       <p>A: Each faction in the EXTREMIS metaverse is built upon different skeletons. Due to differences in characteristics and exterior dimensions, factions have different sets of features and upgrades.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingTwentyNine">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwentyNine" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwentyNine">
      Q4: Are alliances possible among factions?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwentyNine" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwentyNine">
      <div class="accordion-body">
        <p>A: Alliances can be made between any two factions of the three factions in EXTREMIS. Players can form alliances through our social communities as mentioned on the website. Our Alliance meter shows the current alliance between two factions</p>
      </div>
    </div>
  </div>
</div>
   </div>
</div>
     </div>
     }
     export default Faq3;