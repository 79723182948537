import React from 'react';
// import Humanitybanner from '../Cardcollection/SubPages/Humanity/Sections/Humanitybanner';
import HumanityText from './SubPages/Humanity/Sections/RenameHumanityText';
import HumanityList from './SubPages/Humanity/Sections/RenameHumanityList';
import '../RenameCollection/CardCollection.css';
import RenameHumanityList from './SubPages/Humanity/Sections/RenameHumanityList';
import RenameHumanityText from './SubPages/Humanity/Sections/RenameHumanityText';

function RenameHumanityCard () {
	return <div>
		{/* <Humanitybanner/> */}
		<RenameHumanityList/>
		<RenameHumanityText/>

        
	</div>
}
export default RenameHumanityCard;