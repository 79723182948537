import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useEffect } from "react";
import AliceImageSVG from "../../Profile/Pages/IndividualCard/Sections/ImageFunction/AliceImageSVG";
import BlacknetImageSVG from "../../Profile/Pages/IndividualCard/Sections/ImageFunction/BlacknetImageSVG";
import FemaleImageSVG from "../../Profile/Pages/IndividualCard/Sections/ImageFunction/FemaleImageSVG";
import MaleImageSVG from "../../Profile/Pages/IndividualCard/Sections/ImageFunction/MaleImageSVG";
import * as AWS from "aws-sdk";

async function IsBucketImg(TokenId) {
  const result = await fetch(
    `https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${TokenId}`
  );
  return result.status;
}
async function ImageCompare(CardFaction, CardGender, TokenId) {
  let SVG;
  if (CardFaction === "Humanity") {
    if (CardGender === "Female") {
      SVG = await FemaleImageSVG(TokenId);
      return SVG;
    } else if (CardGender === "Male") {
      SVG = await MaleImageSVG(TokenId);
      return SVG;
    }
  } else if (CardFaction === "Alice") {
    SVG = await AliceImageSVG(TokenId);
    return SVG;
  } else if (CardFaction === "BlackNet") {
    SVG = await BlacknetImageSVG(TokenId);
    return SVG;
  }
}
const UpdateFeature = async(TokenId) => {
  fetch(
    `https://www.api.extremisverse.com/UpdateFeatureCount?TokenId=${TokenId}`
  )
    .then((res) => res.json())
    .then((data) => {
      // console.log(data);
      return data;
    });
};
const ImageOnBucket = async (CardFaction, CardGender, TokenId) => {
  const result = await IsBucketImg(TokenId);
  if (result === 403) {
    const result = await ImageCompare(CardFaction, CardGender, TokenId);
    // console.log("Compare");
    return result;
  } else if (result === 200) {
    // console.log("ELSE");
    return true;
  }
};
async function Upload(ImageSVG,TokenId) {
  // console.log(ImageSVG,TokenId);
  const accessKeyId = await Auth.currentCredentials();

  const s3 = new AWS.S3({
    credentials: accessKeyId,
  });
  var blob = new Blob([ImageSVG], { type: "image/svg+xml" });
  // console.log(blob);
  try {
    const uploadedImage = await s3
      .upload({
        Bucket: "tokencharacterimages/TokenImageCachePNG",
        Key: TokenId,
        Body: blob,
        ContentType: "image/svg+xml",
      })
      .promise();
      // console.log(uploadedImage,"uploaded image");
      return uploadedImage
  } catch (err) {
    // console.log(err);
    return err
  }

}

const useImage = (CardFaction, CardGender, TokenId) => {
 
  const [fromImage, setFromImage] = useState(false);

  useEffect(() => {
    const ImageChecking = async () => {
      const result = await ImageOnBucket(CardFaction, CardGender, TokenId);
      if (result===true) {
        setFromImage(true)
      }else{
        const uploaded=await Upload(result,TokenId)
        if (uploaded?.Location) {
           UpdateFeature(TokenId)
           setFromImage(true)
        }
      }
    };
    if (CardFaction && CardGender && TokenId) {
      ImageChecking();
    }
  }, [CardFaction, CardGender, TokenId]);


  return fromImage;
};

export default useImage;
