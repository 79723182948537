import "./Styled.css";
import React from "react";

function InputForm(props) {
  return (
    <div className="input-container">
      <label className="input-label">{props.label}</label>
      <input
        className="input-basic"
        value={props.value}
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
      />
    </div>
  );
}

export default InputForm;
