import React, { useContext, useRef } from "react";
// import alice_logo from "../Sections/Images/alice_logo.png";
import { useNavigate } from "react-router-dom";
import useImage from "../Card/useImage";
import useColor from "../useColor";

import Loader from "../Loader/Loader";
import useAuctionCard from "./useAuctionCard";
import "../Card.css";
import { useEffect } from "react";
import { useState } from "react";
import useRankImage from "../useRankImage";
const { ethers } = require("ethers");

function AuctionCard(props) {
  const navigation = useNavigate();
  const [image, setImage] = useState(false);
  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardPrice,
    remainingTimes,
  ] = useAuctionCard(props.TokenId);
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  useEffect(() => {
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);

  const nav = (props) => {
    navigation(`/auction-individual/${props}`);
  };

  return (
    <div
      className="col-md-6 word-wrapping"
      onClick={() => {
        nav(props.TokenId);
      }}
    >
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_box card_img">
          <img
            // style={
            //   CardFaction && CardGender && CardType && CardRank
            //     ? {}
            //     : { display: "none" }
            // }
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />
          <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />{" "}
          <img
            className="extremis-logo"
            src="/LOGO.png"
            alt=""
          />
          {/* {CardFaction && CardGender && CardType && CardRank ? ( */}
          <>
            <>
              <h3>{CardName}</h3>
              <div className="card_info"></div>
              <div className="card_info">
                <div className="card_type">
                  <p className="fs-6 text-white">ID: {props.TokenId}</p>
                  <p>
                    FACTION: <strong>{CardFaction}</strong>
                  </p>
                  <p>
                    PRICE: <span>{CardPrice} MATIC</span>
                  </p>
                </div>
                <div className="card_rank ps-3">
                  <p>
                    RANK: <strong>{CardRank}</strong>
                  </p>
                  <p>
                    TYPE:<strong> {CardType}</strong>
                  </p>
                  <p>
                    TIME LEFT:{" "}
                    <span className="text-danger">{remainingTimes}</span>
                  </p>
                </div>
            
              </div>
            </>
          </>
          {/* ) : ( */}
          {/* <Loader></Loader> */}
          {/* )} */}
        </div>
      )}
    </div>
  );
}
export default AuctionCard;
