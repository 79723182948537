import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../aws-exports";
import BvA from "./BvA";
import AvH from "./AvH";
import BvH from "./BvH";
import Loader from "../../../../../Shared/Loader/Loader";

export async function UpdateWarTxnHash(Hash, Id) {
  const documentClient = new AWS.DynamoDB.DocumentClient({
    region: awsmobile.aws_cognito_region,
    credentials: AWS.config.credentials,
  });
  const param = {
    TableName: process.env.REACT_APP_WAR_HISTORY_TABLE_NAME,
    Key: {
      BattleFieldID: Id,
    },
    UpdateExpression: "set TxnHash = :hash",
    ExpressionAttributeValues: {
      ":hash": Hash,
    },
  };

  try {
    const Response = await documentClient.update(param).promise();
    return Response;
  } catch (err) {
    console.log(err);
  }
}
export async function DeleteWar( Id) {
  const documentClient = new AWS.DynamoDB.DocumentClient({
    region: awsmobile.aws_cognito_region,
    credentials: AWS.config.credentials,
  });
  const param = {
    TableName: process.env.REACT_APP_BATTLE_COUNTER_TABLE_NAME,
    Key: {
      BattleFieldID: Id,
    }
    
  };

  try {
    // console.log(param, "param");
    const Response = await documentClient.delete(param).promise();
    return Response;
  } catch (err) {
    console.log(err);
  }
}

function Battlefield() {
  const [AH, setAH] = useState([]);
  const [BH, setBH] = useState([]);
  const [BA, setBA] = useState([]);
  const [loading, setLoading] = useState(true);
  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_BATTLE_COUNTER_TABLE_NAME,
    //   Limit: 10,
    //   ProjectionExpression: "TokenId",
  });

  async function GetTokenId() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    // console.log(param);
    const AH = [];
    const BH = [];
    const BA = [];
    try {
      const Response = await documentClient.scan(param).promise();
      //   console.log(Response.Items);
      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        if (element.Value === "AH") {
          AH.push(element.BattleFieldID);
        } else if (element.Value === "BH") {
          BH.push(element.BattleFieldID);
        } else if (element.Value === "BA") {
          BA.push(element.BattleFieldID);
        }
      }
    } catch (err) {
      // console.log(err);
    }
    return [AH, BH, BA];
  }

  useEffect(() => {
    const Token = async () => {
      const Id = await GetTokenId();
      setAH((id) => [...id, ...Id[0]]);
      setBH((id) => [...id, ...Id[1]]);
      setBA((id) => [...id, ...Id[2]]);
      setLoading(false);
    };
    Token();
  }, []);
  // console.log(AH, "ah", BH, "bh", BA, "ba");

  return (
    <div>
      <div className="row">
        {loading ? (
          <Loader></Loader>
        ) : (
          <div className="col-md-8 offset-md-2">
            {BH ? (
              BH?.sort().map((id) => <BvH id={id}></BvH>)
            ) : (
              <Loader></Loader>
            )}
            {AH ? (
              AH?.sort().map((id) => <AvH id={id}></AvH>)
            ) : (
              <Loader></Loader>
            )}
            {BA ? (
              BA?.sort().map((id) => <BvA id={id}></BvA>)
            ) : (
              <Loader></Loader>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
export default Battlefield;
