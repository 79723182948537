import React from 'react';
import Allcardsbanner from '../AuctionCollection/Sections/Allcardsbanner';
import AllcardsText from './Sections/AuctionAllcardsText';
import AllcardsList from './Sections/AuctionAllcardsList';
import '../AuctionCollection/CardCollection.css';
import Footer from '../../Footer/Footer';
import { Outlet } from 'react-router-dom';

function AuctionCardCollection () {
	//(CardCollection)?Footer(false):Footer(true);
	return <div>
		<Allcardsbanner/>
		<Outlet></Outlet>
        
	</div>
}
export default AuctionCardCollection;