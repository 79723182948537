import React from "react";
import "../../../CardCollection.css";
import { Link, Outlet } from "react-router-dom";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../aws-exports";
import Card from "../../../../../Shared/Card/Card";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "../../../../../Shared/Pagination";
import Loader from "../../../../../Shared/Loader/Loader";


function HumanityText () { 
	const [loader,setLoader]=useState(true)

	const [TokenId, setTokenId] = useState([]);
	const [TokenArray, setTokenArray] = useState([]);
	const [page, setPage] = useState(1);
	const [next, setNext] = useState(true);
	const [param, setParam] = useState({
	  TableName:  process.env.REACT_APP_METAVERSE_TABLE_NAME,
	  Limit: 30,
	  FilterExpression: "contains(Faction,:fName)",
  
	  ExpressionAttributeValues: {
		":fName": "Humanity",
	  },
  
	  ProjectionExpression: "TokenId",
	});
  
	async function GetTokenId() {
	  const documentClient = new AWS.DynamoDB.DocumentClient({
		region: awsmobile.aws_cognito_region,
		credentials: AWS.config.credentials,
	  });
  
	  const Id = [];
	  try {
		const Response = await documentClient.scan(param).promise();
  
		if (Response.LastEvaluatedKey) {
		  setParam({
			TableName:  process.env.REACT_APP_METAVERSE_TABLE_NAME,
			Limit: 30,
			FilterExpression: "contains(Faction,:fName)",
  
			ExpressionAttributeValues: {
			  ":fName": "Humanity",
			},
  
			ExclusiveStartKey: Response.LastEvaluatedKey,
			ProjectionExpression: "TokenId",
		  });
		}
		//   console.log(Response.Items.tokenId, "resHumanity");
		for (let i = 0; i < Response.Items.length; i++) {
		  const element = Response.Items[i];
		  if (TokenId.indexOf(element.TokenId) === -1) {
			Id.push(element.TokenId);
			setNext(true);
		  } else {
			setNext(false);
		  }
		}
	  } catch (err) {
		// console.log(err);
	  }
	  return Id;
	}
  
	const Next = async () => {
		setLoader(true)
	  if (page === TokenArray.length) {
		const Id = await GetTokenId();
		if (Id.length === 0) {
			setNext(false);
			setLoader(false)
		} else {
		  setNext(true);
		  setTokenId((TokenId) => [...TokenId, ...Id]);
		  setTokenArray((TokenArray) => [...TokenArray, Id]);
		  setPage((page) => page + 1);
		  setLoader(false)
		}
	  } else {
		  setPage((page) => page + 1);
		  setLoader(false)
	  }
	};
	const Previous = () => {
	  setNext(true);
	  setPage((page) => page - 1);
	};
	useEffect(() => {
	  const TokenArr = async () => {
		const Id = await GetTokenId();
		setTokenId((TokenId) => [...TokenId, ...Id]);
		setLoader(false)
		setTokenArray((TokenArray) => [...TokenArray, Id]);
	  };
	  TokenArr();
	}, []);
	//   console.log(TokenId);
	// console.log(page, "page");
	// console.log(TokenArray.length, "array");
	// console.log(next, "next");
	
	return (
		<div>
		  <div className="container">
			<div className="row">
			  <div className="col-md-10 offset-md-1">
			  {
              loader?
              <Loader></Loader>: 	<div className="content_box">
				  <h4>HUMANITY</h4>
	
				  <div className="row">
					{TokenArray[page - 1]?.map((TokenId) => (
					  <Card TokenId={TokenId} key={TokenId}></Card>
					))}
					{
                  TokenArray[page - 1]?.length===0 && <h1>NO CARD</h1>
                }
				  </div>
				  <Pagination
					page={page}
					setPage={setPage}
					length={TokenArray.length}
					Previous={Previous}
					Next={Next}
					next={next}
				  ></Pagination>
				</div>}
			  </div>
			</div>
		  </div>
		</div>
	  );
}
export default HumanityText;