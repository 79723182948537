import React from 'react';
import '../SellCollection/CardCollection.css';
import SellBlacknetList from './SubPages/Blacknet/Sections/SellBlacknetList';
import SellBlacknetText from './SubPages/Blacknet/Sections/SellBlacknetText';

function SellBlacknetCard () {
	return <div>
		{/* <Blacknetbanner/> */}
		<SellBlacknetList></SellBlacknetList>
		<SellBlacknetText></SellBlacknetText>

        
	</div>
}
export default SellBlacknetCard;