import React from "react";
import "./DisconnectWallet.css";
const DisconnectWallet = () => {
  return (
    <div className="disconnect-wallet">
      <h1>Wallet status</h1>
      <div className="wallet-box">
        <div className="wallet-box-username">
          <h2>Username</h2>
          <p>N/A</p>
        </div>
        <div className="wallet-box-address">
          <div>
            <h2>Registered address</h2>
            <p>N/A</p>
          </div>
          <div>
            <h2>Current address</h2>
            <p>N/A</p>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default DisconnectWallet;
