import React from 'react';
import BreedConfirmationBanner from '../BreedConfirmation/Sections/BreedConfirmationBanner';
import BreedConfirmationTaxt from '../BreedConfirmation/Sections/BreedConfirmationTaxt';
function BreedConfirmation(){
 return <div>
<BreedConfirmationBanner/>
<BreedConfirmationTaxt/>
</div>


}
export default BreedConfirmation;