import React from 'react';
import AllcardsList from './Sections/AllcardsList';
import AllCardsText from './Sections/AllcardsText';

const AllCard = () => {
    return (
        <div>
            <AllcardsList></AllcardsList>
            <AllCardsText></AllCardsText>
        </div>
    );
};

export default AllCard;