import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { putDataCustomerInfoTbl } from "./dynamoData";
import { useContext } from "react";
import { addressContext, authContext, forceSignOutContext } from "../../App";

const SignUpConfirm = (props) => {
  const [signInUser, setSignInUser] = useContext(authContext);
  const [currentAddress, setcurrentAddress] = useContext(addressContext);
  // const [forceSignOut, setForceSignOut] = useContext(forceSignOutContext);
  const [confirmButton, setConfirmButton] = useState(false);
  const [err, setErr] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();

  const confirmSignUp = async (data) => {
    setConfirmButton(true);
    // console.log("s con dyn", props.dynamoData);
    const username = props.dynamoData.email;
    const code = data.code;

    try {
      const res = await Auth.confirmSignUp(username, code);
      const user = await Auth.signIn(
        props.dynamoData.email,
        props.dynamoData.password
      );
      if (user) {
        // setForceSignOut(false);
        const session = await Auth.currentSession();
        const user = await Auth.currentAuthenticatedUser();
        const AuthUserCreds = await Auth.currentCredentials();
        AWS.config.update({
          credentials: AuthUserCreds,
          region: awsmobile.aws_cognito_region,
        });

        const auth = { user, session, isAuthenticated: true };
        const result = JSON.stringify(auth);
        setSignInUser(result);
      }
      // setSignInUser(user);
      // const session = await Auth.currentSession();
      // console.log(session,"sessionnnnnn");
      const AuthUserCreds = await Auth.currentUserCredentials();
      const response = await putDataCustomerInfoTbl(
        "CustomerInfo",
        props.dynamoData,
        AuthUserCreds
      );
      // console.log(response, "daynamo result");
      if (response) {
        props.setConfirmPopUp(false);
        navigate("/");
      }
      // console.log(res);
    } catch (err) {
      setConfirmButton(false);
      // console.log(err);
      setErr(err);
    }
  };

  return (
    <div className="confirmation">
      <div className="confirmation-msg">
        <h1>Confirmation!</h1>
        <p>Please check the email for the verification code</p>
        <form action="" onSubmit={handleSubmit(confirmSignUp)}>
          <input
            type="text"
            placeholder="code"
            {...register("code", {
              required: true,
            })}
          />
          {err && (
            <p> Invalid verification code is provided. Please try again.</p>
          )}
          <button
            type="submit"
            // id="confirmation"
            className="recovery"
          >
            {!confirmButton ? "CONFIRM" : "PLEASE WAIT..."}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUpConfirm;
