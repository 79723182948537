import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../../Shared/Pagination";
import Battlefield from "../Sections/WarContentBox/Battlefield";
import CreateBattlefield from "../Sections/WarContentBox/CreateBattlefield";
import CreatePopup from "../Sections/WarContentBox/CreatePopup";
import awsmobile from "../../../../../aws-exports";
import * as AWS from "aws-sdk";

function WarzoneContent() {
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [warHistory, setWarHistory] = useState([]);

  async function GetHistory() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    try {
      const Response = await documentClient
        .scan({
          TableName: process.env.REACT_APP_WAR_HISTORY_TABLE_NAME,
        })
        .promise();
      return Response.Items;
    } catch (err) {
      // console.log(err);
    }
  }
  useEffect(() => {
    if (AWS.config.credentials && awsmobile.aws_cognito_region) {
      const History = async () => {
        const result = await GetHistory();
        setWarHistory(result);
      };
      History();
    }
  }, [awsmobile.aws_cognito_region, AWS.config.credentials]);

  useEffect(() => {
    if (page === Math.ceil(warHistory.length / 6) || warHistory.length === 0) {
      setNext(false);
    } else {
      setNext(true);
    }
  }, [warHistory, page]);

  const Next = async () => {
    setNext(false);
    setPage((page) => page + 1);
    setNext(true);
  };
  const Previous = async () => {
    setPage((page) => page - 1);
  };
  // console.log(warHistory, "history war");
  return (
    <div>
      <section className="warzone_content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="warzone_field">
                <Battlefield />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="create_field">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="create_battlefield text-center">
                <h2>CREATE A NEW BATTLEFIELD</h2>
                <p>
                  <i class="fal fa-info-circle"></i>
                  <em>
                    Select any one option. Only one battlefield can be created
                    at a time
                  </em>
                </p>
                <CreateBattlefield />
                {/* <CreatePopup /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="create_field mt-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="create_battlefield text-center">
                <h2>WAR HISTORY</h2>
                {/* <p>
                  <i class="fal fa-info-circle"></i>
                  <em>......................</em>
                </p> */}
                <ul className="war-history-tab">
                  {warHistory?.slice((page - 1) * 6, page * 6).map((Id) => (
                    <li>
                      <Link
                        to={`/war-history/${Id.BattleFieldID}`}
                        state={{
                          BattleFieldID:Id.BattleFieldID,
                          LosserTokenID: Id.LosserTokenID,
                          WinnerTokenID: Id.WinnerTokenID,
                        }}
                      >
                        BATTLEFIELD {Id.BattleFieldID}
                      </Link>
                    </li>
                  ))}
                  {warHistory?.length === 0 && <h1 className="text-center fs-2">NO WAR HISTORY</h1>}
                </ul>
                <Pagination
                  page={page}
                  setPage={setPage}
                  length={warHistory?.length / 6}
                  Previous={Previous}
                  Next={Next}
                  next={next}
                ></Pagination>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default WarzoneContent;
