import React from "react";
import RoadmapBanner from "./SubPages/Roadmap/Sections/RoadmapBanner";
import RoadmapList from "./SubPages/Roadmap/Sections/RoadmapList";
import RoadmapText from "./SubPages/Roadmap/Sections/RoadmapText";

const Roadmap = () => {
  return (
    <div>
      <RoadmapBanner></RoadmapBanner>
      <RoadmapList></RoadmapList>
      <RoadmapText></RoadmapText>
    </div>
  );
};

export default Roadmap;
