import React, { useContext, useState } from "react";
import {
  addressContext,
  navigationContext,
  providerContext,
} from "../../../../App";
import { transferMultipleCards } from "../../../UserPage/BlockChainFunctions";
import TransferCardsBanner from "./Sections/TransferCardsBanner/TransferCardsBanner";
import * as AWS from "aws-sdk";
import useCustomerInfo from "../../../Hooks/useCustomerInfo";
import PopUp from "../../../Shared/PopUp";
import LoaderFloating from "../../../Shared/LoaderFloating";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const TransferMultiple = () => {
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const [popUpText1, setPopUpText1] = useState("");
 

  const navigate = useNavigate();
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [loading, setLoading] = useState();
  const ssm = new AWS.SSM();
  const Transfer = async (e) => {
    e.preventDefault();
    setLoading(true);
    setPopUpSubject("")
    setPopUpText("")
    const tokens = e.target.tokens.value;
    const arr = tokens.split(",");
    const address = e.target.address.value;

    const arrOfNum = arr.map((str) => {
      return Number(str);
    });

    // console.log(arrOfNum, address);
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      const result = await transferMultipleCards(
        address,
        arrOfNum,
        walletProvider,
        ssm
      );
      // console.log(result, "transfer multiple");
      setLoading(false);
      if (result) {
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.txreceipt?.blockHash) {
          setPopUpSubject(
            `You have successfully transferred the cards to ${address}`
          );
          
          setPopUpText(`          
      Transaction Hash: ${result.txreceipt.transactionHash}                              
          `);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
        }

        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      }
    } else {
      setLoading(false);
      setPopUp(true);
      // setPopUpSubject("Oops");
      setPopUpSubject("Connect your registered wallet ");
    }
  };
  
  return (
    <div>
      <TransferCardsBanner></TransferCardsBanner>
      <form onSubmit={Transfer} className="container">
        <div className="transfer-card-body">
          <h4>Transfer card(s)</h4>
          <hr />

          <h5>Transfer multiple cards</h5>
          <p id="transfer-card-note">*Separate token IDs with comma " , "</p>
          <div className="transfer-multiple-card-input">
            <div>
              <p>Token ID's</p>
              <input
                id="multiple-card-input"
                type="text"
                name="tokens"
                placeholder="Ex: 10,20,30"
                required
              />
            </div>

            <div>
              <p>To address</p>
              <input
                type="text"
                name="address"
                id=""
                required
                placeholder="Ex: 0x64E5274cfe9e4..."
              />
            </div>
          </div>
          <div className="transfer-card-button">
            <button className="btn" type="submit">
              {!loading ? " CONFIRM" : "PLEASE WAIT..."}
            </button>
          </div>
          {popup && (
            <PopUp
              popUpSubject={popUpSubject}
              popUpText={popUpText}
              popUpText1={popUpText1}
              setPopUp={setPopUp}
            ></PopUp>
          )}
          {loading && <LoaderFloating></LoaderFloating>}
        </div>
      </form>
    </div>
  );
};

export default TransferMultiple;
