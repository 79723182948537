import React from 'react';
import PromoteBanner from '../Promote/Sections/PromoteBanner';
import PromoteTaxt from '../Promote/Sections/PromoteTaxt';
import '../Promote/Promote.css';
import { Outlet } from 'react-router-dom';
function Promote(){
 return <div>
<PromoteBanner/>
{/* <PromoteTaxt/> */}
<Outlet></Outlet>
</div>


}
export default Promote;