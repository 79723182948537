import React from 'react';
import { Link } from 'react-router-dom';
function HumanityText () {
	return <div>
<section className="humanitytext">
         <div className="container">
            <div className="row">
              
               <div className="col-md-10 offset-md-1">
                  <div className="humanitytext_box text-center">
                      <h2>HUMANITY</h2>
                     <p>Humans have unveiled true faces of knowledge and have traversed scientific fields experimentally for hundreds of years. In achieving so, humans have expanded on developments that upgraded their lifestyles. They created AI beings that could be programmed to perform work. They perfected those AI beings. Now they aspired to create a conscious being, an artificial life that can rival the naturally evolved human consciousness.</p>

                     <p>Unfortunately, their ventures to create a conscious being backfired due to apparent malfunctions. They shut down the project and hid Morbius away for good. But someone within the human race got their hands on the incomplete project.</p>

                     <p>Labyrinth pushed his luck too far when he programmed the artificial being, Blacknet, to carry out his selfish deed. Meanwhile, Blacknet evolved to gain self awareness and deemed the human race disastrous. A war breaks out between Blacknet and the human race.</p>

                     <p>When Blacknet declares war, humans barely get the time to realize the sheer gravity of the situation. Shrinking and getting scattered due to attacks by the Blacknet Army, Humanity loses all essentials to live. Their chances of survival become scarce as cities get destroyed, money loses its value, firearm, equipment, and machines become rare. Human civilization faces the risk of extinction.</p>

                     <p>Not able to gain the upper hand in the war, seven individuals jointly track down the doctor who had created Blacknet initially. The retired doctor realizes the threat and decides to create Alice to fight alongside humans. Alice brings new hope to human civilization as they march together with humans to fight against Blacknet.</p>
                     <Link to="/cardcollection/humanity-card" className='btn'>SEE HUMANITY CARDS</Link>
                  </div>
               </div>
                
            </div>
         </div>
      </section>
    </div>
    }
    export default HumanityText;