import React from 'react';
import Alicebanner from '../RenameCollection/SubPages/AliceCard/Sections/Alicebanner';
import AliceText from './SubPages/AliceCard/Sections/RenameAliceText';
import AliceList from './SubPages/AliceCard/Sections/RenameAliceList';
import '../RenameCollection/CardCollection.css';
import RenameAliceList from './SubPages/AliceCard/Sections/RenameAliceList';
import RenameAliceText from './SubPages/AliceCard/Sections/RenameAliceText';

function RenameAliceCard () {
	return <div>
		{/* <Alicebanner/> */}
		<RenameAliceList/>
		<RenameAliceText/>

        
	</div>
}
export default RenameAliceCard;