import React from 'react';
import MarketPlaceAllcardsList from './Sections/MarketPlaceAllcardsList';
import AllcardsList from './Sections/MarketPlaceAllcardsList';
import MarketPlaceAllCardsText from './Sections/MarketPlaceAllcardsText';
import AllCardsText from './Sections/MarketPlaceAllcardsText';

const MarketAllCard = () => {
    return (
        <div>
            <MarketPlaceAllcardsList></MarketPlaceAllcardsList>
            <MarketPlaceAllCardsText></MarketPlaceAllCardsText>
        </div>
    );
};

export default MarketAllCard;