import React, { useState } from "react";
import { useDrop } from "react-dnd";
import BreedCard from "../BreedCard/BreedCard";
import DropCard from "./DropCard";
import "../Card.css";

const DragRight = ({ setId2,setFaction2 }) => {
  const [id, setId] = useState();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "div",
    drop: (item) => addToBoard(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const addToBoard = (id) => {
    setId(id);
    setId2(id);
  };
  return (
    //   <li >
    <div className="breeding_lab" ref={drop}>
      <div className="upload_img">
        {id ? (
          <DropCard TokenId={id} setFaction={setFaction2}></DropCard>
        ) : (
          <div class="image-upload">
            <label for="file-input">
              <i class="far fa-plus"></i>
              <br></br>
            
                Drag and drop card to enter{" "}
           
            </label>
          </div>
        )}
      </div>
    </div>
    //   </li>
  );
};

export default DragRight;
