import React from 'react';
import Alicebanner from '../PromoteCollection/SubPages/AliceCard/Sections/Alicebanner';
import AliceText from './SubPages/AliceCard/Sections/PromoteAliceText';
import AliceList from './SubPages/AliceCard/Sections/PromoteAliceList';
import '../PromoteCollection/CardCollection.css';
import PromoteAliceList from './SubPages/AliceCard/Sections/PromoteAliceList';
import PromoteAliceText from './SubPages/AliceCard/Sections/PromoteAliceText';

function PromoteAliceCard () {
	return <div>
		{/* <Alicebanner/> */}
		<PromoteAliceList/>
		<PromoteAliceText/>

        
	</div>
}
export default PromoteAliceCard;