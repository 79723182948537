import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { addressContext } from "../../../../../App";
import useFixedMarketContract from "../../../../Hooks/FixedMarketHook";
import { GetFixedMarketPlacePrice } from "../../../../UserPage/BlockChainFunctions";
function CurrentStatus(props) {
  const [price, setPrice] = useState();
  const { ethers } = require("ethers");
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [selfCard, setSelfCard] = useState(false);
  const [fixedMarketContract, setFixedMarketContract] =
    useFixedMarketContract();
  useEffect(() => {
    if (props.TokenId && fixedMarketContract) {
      const Price = async () => {
        // console.log(props.TokenId);
        const price = await GetFixedMarketPlacePrice(
          props.TokenId,
          fixedMarketContract
        );
        // console.log(price, "price");
        setPrice(ethers.utils.formatEther(price?.price.toString()));
      };
      Price();
    }
  }, [props.TokenId, fixedMarketContract]);

  useEffect(() => {
    if (currentAddress === props.CardOwner) {
      setSelfCard(true);
    }
  }, [currentAddress, props.CardOwner]);

  return (
    <div>
      <section className="card_details_purchase mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="purchase_current_status">
                <ul>
                  {/* <li><span>Time on sale</span><br></br>
                                <strong>00:11:48</strong></li> */}
                  <li>
                    <span>Market price</span>
                    <br></br>
                    <strong>{price} MATIC</strong>
                  </li>
                  <li>
                    {!selfCard ? (
                      <Link to={`/purchase-confirmation/${props.TokenId}`}>
                        PURCHASE
                      </Link>
                    ) : (
                      <Link to={`/fixed-sell-confirmation/${props.TokenId}`}>
                        WITHDRAW FROM FIXED MARKET
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default CurrentStatus;
