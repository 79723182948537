import React, { useContext } from "react";
import UserProfileBanner from "./Sections/UserProfile/UserProfileBanner";
import UserProfile from "./Sections/UserProfile/UserProfile";
import UserProfileTab from "./Sections/UserProfile/UserProfileTab";
import "../Profile/Sections/UserProfile/UserProfile.css";
import { Outlet } from "react-router-dom";
import { addressContext } from "../../App";
import useCustomerInfo from "../Hooks/useCustomerInfo";
import { useEffect } from "react";
import { useState } from "react";
function Profile() {
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const [profileCardAccess, setProfileCardAccess] = useState(false);
  useEffect(() => {
    if (customerInfo?.Item?.CustomerID === currentAddress) {
      setProfileCardAccess(true);
    }
  }, [currentAddress, customerInfo]);
  return (
    <div>
      <UserProfileBanner />
      <UserProfile />
      {profileCardAccess && <Outlet></Outlet>}
    </div>
  );
}
export default Profile;
