import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { addressContext, providerContext } from "../../../../App";
import useSellCard from "../../../Shared/SellCard/useSellCard";
import useStats from "../../../Shared/useStates";
import {
  cancelAuction,
  createAuction,
  GetAuction,
  IsOnAuctionList,
} from "../../../UserPage/BlockChainFunctions";
import blacknet1 from "../Images/blacknet1.png";
import * as AWS from "aws-sdk";
import useCustomerInfo from "../../../Hooks/useCustomerInfo";
import PopUp from "../../../Shared/PopUp";
import useColor from "../../../Shared/useColor";
import useRankImage from "../../../Shared/useRankImage";
import useAuctionContract from "../../../Hooks/AuctionContractHook";
import LoaderFloating from "../../../Shared/LoaderFloating";
function AuctionConfirmationTaxt() {
  const TokenId = useParams();
  // console.log("sell", TokenId);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [popUpText1, setPopUpText1] = useState("");
  const [loading, setLoading] = useState();
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const [CardFaction, CardName, CardType, CardRank, CardGender] = useSellCard(
    TokenId.TokenId
  );
  const [auctionContract, setAuctionContract] = useAuctionContract();

  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  const [price, setPrice] = useState();
  const [time, setTime] = useState();
  const { ethers } = require("ethers");

  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(TokenId.TokenId);
  const [congrats, setCongrats] = useState(false);
  const [congratulation, setCongratulation] = useState(false);

  const ssm = new AWS.SSM();
  const SellAuction = async (e) => {
    e.preventDefault();
    setLoading(true);
    const Price = e.target.price.value;
    setPrice(Price);
    const Duration = e.target.time.value * 3600;
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      const result = await createAuction(
        TokenId.TokenId,
        Price,
        Duration,
        walletProvider,
        ssm
      );
      // console.log(result.txreceipt?.blockHash, "auction");
      setLoading(false);
      if (result) {
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.txreceipt?.blockHash) {
          const today = new Date();
          const times = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
          setTime(times);
          setCongratulation(true);
          setCongrats(true);
          setPopUpSubject("Successful");
          setPopUpText(`          
          Transaction Hash: ${result.txreceipt.transactionHash}                              
               `);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
        }
        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      }
    } else {
      setLoading(false);
      setPopUp(true);
      // setPopUpSubject("Oops");
      setPopUpSubject("Connect your registered wallet ");
    }
  };

  const WithdrawAuction = async () => {
    setLoading(true);
    // console.log(TokenId.TokenId, "test token");
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      const result = await cancelAuction(TokenId.TokenId, walletProvider, ssm);
      // console.log(result, "withdraw auction");
      setLoading(false);
      if (result) {
        // console.log(result.txreceipt.blockHash, "auction");
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result?.txreceipt.blockHash) {
          setCongrats(false);
          setPopUpSubject("Successful");
          setPopUpText(`          
          Transaction Hash: ${result.txreceipt.transactionHash}                              
               `);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
        }
        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      }
    } else {
      setLoading(false);
      setPopUp(true);
      // setPopUpSubject("Oops");
      setPopUpSubject("Connect your registered wallet ");
    }
  };

  const IsOnAuction = async () => {
    const result = await IsOnAuctionList(TokenId.TokenId, auctionContract);
    setCongrats(result);
  };

  useEffect(() => {
    if (auctionContract) {
      IsOnAuction();
    }
  }, [auctionContract]);

  useEffect(() => {
    const Auction = async () => {
      const auction = await GetAuction(TokenId.TokenId, auctionContract);
      setTime(
        new Date(auction?.startedAt.toString() * 1000).toLocaleString("en-US")
      );
      setPrice(ethers.utils.formatEther(auction?.startingPrice));
    };
    Auction();
  }, [TokenId.TokenId, auctionContract]);

  return (
    <div>
      <section className="sell_card_text word-wrapping">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sell_card_heading">
                <h2>SELL CARD</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="sell_card_succ_img card_img">
                <img
                  src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${TokenId.TokenId}`}
                  className={color}
                  alt=""
                />
                <img
                  className="rankImage"
                  src={`/Rank/${rankImage}.png`}
                  alt=""
                />
                <img
                  className="extremis-logo"
                  src="/LOGO.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="sell_card_succ_info">
                <h4>{CardName}</h4>
                <h5>{CardFaction}</h5>
                <div class="sell_card_list_box">
                  <ul>
                  <li>
                      <span> ID:</span> <strong>{TokenId.TokenId}</strong>
                    </li>
                    <li>
                      <span>Rank: </span> {CardRank}
                    </li>
                    <li>
                      <span>Type: </span>
                      {CardType}
                    </li>
                    <li>
                      <span>IQ: </span> {IQ}
                    </li>
                    <li>
                      <span>Potential: </span> {Potential}
                    </li>
                    <li>
                      <span>EQ: </span> {EQ}
                    </li>
                    <li>
                      <span>Wisdom: </span> {Wisdom}
                    </li>
                    <li>
                      <span>Melee: </span> {Melee}
                    </li>
                    <li>
                      <span>Leadership: </span> {Leadership}
                    </li>
                    <li>
                      <span>Weapon: </span> {Weapon}
                    </li>
                    {CardFaction === "Humanity" ? (
                      <li>
                        <span>N. Adaptability: </span>
                        {NaturalAdpt}
                      </li>
                    ) : (
                      <li>
                        <span>AI Influence: </span>
                        {NaturalAdpt}
                      </li>
                    )}
                  </ul>
                </div>
                {!congrats ? (
                  <form onSubmit={SellAuction} className="sell_card_succ_text">
                    <h3>Auction for sale?</h3>
                    <p>
                      <em>*Marked fields are mandatory</em>
                    </p>
                    <div className="sell_price price">
                      <div className="float-left">
                        <span>
                          Set starting price*<br></br>
                          <div class="form-floating mb-3">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="MATIC"
                              name="price"
                              required
                            />
                          </div>
                        </span>
                      </div>
                      <div className="float-right">
                        <span>
                          Set auction duration time*<br></br>
                          <div class="form-floating mb-3">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Hour"
                              name="time"
                              required
                            />
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="sell_btn">
                      <button type="submit" className="confirm-button">
                        {!loading ? "CONFIRM" : "PLEASE WAIT..."}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="sell_card_succ_text">
                    {congratulation && <h3>Congratulations!</h3>}

                    <p>
                      Your card is now auctioned <br></br>
                      <strong>For sale</strong>
                    </p>

                    <div className="price_table">
                      <ul>
                        <li className="border-end">
                          Fixed price<br></br>
                          <strong>{price} MATIC</strong>
                        </li>
                        <li>
                          Sale time<br></br>
                          <strong className="text-danger">{time}</strong>
                        </li>
                      </ul>
                    </div>
                    <div className="purchase_btn">
                      {/* <Link className="btn" to="/sell-auction">WITHDRAW AUCTION</Link> */}
                      <button
                        onClick={WithdrawAuction}
                        className="confirm-button"
                      >
                        {!loading ? "  WITHDRAW AUCTION" : "PLEASE WAIT..."}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {popup && (
            <PopUp
              popUpSubject={popUpSubject}
              popUpText={popUpText}
              popUpText1={popUpText1}
              setPopUp={setPopUp}
            ></PopUp>
          )}
        </div>
        {loading && <LoaderFloating></LoaderFloating>}
      </section>
    </div>
  );
}
export default AuctionConfirmationTaxt;
