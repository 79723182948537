import React from 'react';
import '../SellCollection -auction/CardCollection.css';
import AuctionSellAliceList from './SubPages/AliceCard/Sections/AuctionSellAliceList';
import AuctionSellAliceText from './SubPages/AliceCard/Sections/AuctionSellAliceText';

function AuctionSellAliceCard () {
	return <div>
		{/* <Alicebanner/> */}
		<AuctionSellAliceList></AuctionSellAliceList>
		<AuctionSellAliceText></AuctionSellAliceText>

        
	</div>
}
export default AuctionSellAliceCard;