import React from "react";
function BreedingBanner() {
  return (
    <div>
      <div className="breeding_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="breeding_text mt-3">
                <h1>Breeding Lab</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BreedingBanner;
