import React from "react";
import blacknet from "../Images/blacknet.png";
import alice from "../Images/alice.png";
import metaverse from "../Images/metaverse.png";
import purchase from "../Images/purchase.png";
import breed from "../Images/breeding.png";
import battle from "../Images/battle.png";
import war from "../Images/war.png";
import multiplier from "../Images/multiplier.png";
import mutate from "../Images/mutate (1).png";
import promote from "../Images/promote.png";
import rename from "../Images/rename.png";
import { useNavigate } from "react-router-dom";
function GamefeaturesText() {
  const navigate = useNavigate();
  return (
    <div>
      <section className="guidetab_section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="guide_tab">
                <div className="scroll_link">
                  <ul>
                    <li>
                      <a href="#purchase">Purchase cards</a>
                    </li>
                    <li>
                      <a href="#breed">Breed cards</a>
                    </li>
                    <li>
                      <a href="#battle">Battle</a>
                    </li>
                    <li>
                      <a href="#war">War</a>
                    </li>
                    <li>
                      <a href="#war-multiplier">War Multiplier</a>
                    </li>
                    <li>
                      <a href="#mutation">Mutation</a>
                    </li>
                    <li>
                      <a href="#rank">Rank upgrade</a>
                    </li>
                    <li>
                      <a href="#rename">Rename cards</a>
                    </li>
                  </ul>
                </div>
                <div className="content_box">
                  <h4>GAME FEATURES</h4>

                  <div className="row align-items-center ">
                    <div className="col-md-6">
                      <div className="custom_tab_text" id="purchase">
                        <h3>Purchase cards</h3>
                        <p>
                          After signing up and setting up your profile, purchase
                          your first card through the EXTREMIS 'Marketplace' or
                          'Auction house' to begin playing. Browse through all
                          the cards on sale. You can buy any cards at a fixed
                          rate in the 'Marketplace'. Whereas the 'Auction house'
                          enables you to bid on cards. The highest bidder gets
                          the card. You can start with only one card in your
                          collection. But you may also buy multiple cards to
                          advance in the game more strategically.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img
                        src={purchase}
                        onClick={() => navigate("/purchase-cards")}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-md-6">
                      <div className="custom_tab_text" id="breed">
                        <h3>Breed cards</h3>
                        <p>
                          Players can breed their cards to make new cards with a
                          variety of attribute values and feature rarity. New
                          cards also help grow your collection thus enabling you
                          to sell cards in the future or enlist them for combat.
                          The users are allowed to breed cards within their
                          collection or other player's cards as well for a
                          certain fee. However, breeding is only allowed between
                          cards of the same faction. In case of Human, players
                          can only breed between opposite genders. Sentinels do
                          not have any gender. You can't breed a genesis card.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src={breed} onClick={() => navigate("/breeding")} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="custom_tab_text">
                        <p>
                          Our Breeding functionality is divided into two types.
                          One is “Breeding with self”; another is “Breed with
                          others”. Before calling this breed functionality,
                          please make sure your card is on the breeding list.
                          You can put your selected cars on the breeding list by
                          the button “Add to breeding list”. The “Breed with
                          self” functionality lets users breed with their cards
                          without any breeding fee. However, the user still
                          needs to pay gas fees. Following each breeding, the
                          offspring card will require 8 hours to mature, before
                          it has the ability to breed and the parent cards will
                          require 8 hours of a resting period after that period
                          the new, mature card can be used to breed again. We
                          call it the “Dysfunction Impotence Period”. The “Breed
                          with others” functionality lets users breed with
                          another user's card. You can put your card on the
                          breeding list with a price (user input) which other
                          users will pay to breed a new card with your card. You
                          will receive the breeding fee and the user who
                          initiates the breed will receive the new card. Note
                          the newly bred card will need to wait 8 hours to reach
                          breeding maturity, and Tesseract will keep a
                          percentage of the breeding fee. In the case of the
                          Humanity faction, players must choose a male and a
                          female card for breeding. However, for the cards that
                          belong to Blacknet or Alice faction, there is no
                          gender restriction for breeding. Each of our
                          card/characters has their own unique gene-code. All of
                          the characters information like mental attributes,
                          weapon, dress, etc., is encoded onto this gene-code.
                          We use a custom super secret genetic algorithm to
                          create the gene-code of the child card. The better the
                          “future Potential” of a card the better likelihood for
                          the child to get higher genetic diversity. Our
                          algorithm is designed in such a way that, it supposed
                          to mimic the basic essence of human DNA mixing
                          dictated by evolution via natural selection. We have a
                          very specific reason to do this, this algorithm is
                          crucial in our Population modeling. We might even
                          change the breeding algorithm in the future if
                          required by our games storyline progress or population
                          model. Learn more about the “Population model” in our
                          whitepaper.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center ">
                    <div className="col-md-6">
                      <div className="custom_tab_text" id="battle">
                        <h3>Battle</h3>
                        <p>
                          Players of EXTREMIS can battle their cards against
                          other players. The cards have to be added to the
                          global battle list to be able to participate in a
                          battle. The players can add their cards to the battle
                          list by selecting the card and choosing the “Add to
                          battle list” button from individual card page. After
                          adding cards to the battle list, their cards will be
                          visible to other players in the “Battle-ready players”
                          list. Players with a minimum of one card can
                          participate in a battle. After each battle is
                          concluded, the winning card will receive a certain
                          battle point(Note: Battle points gained from each
                          battle is subjected to change anytime, please check
                          our cost and fee section to learn more) and even rank
                          up. Where else the losing card will die and will be
                          loss forever from our metaverse!!
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img
                        src={battle}
                        onClick={() => navigate("/battle-list")}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="custom_tab_text">
                        <p>
                          Note that, every time you add your card to the battle
                          list, your card will be sent to the contract address,
                          you will no longer be the owner of that card until the
                          battle is concluded. To battle, the participating
                          cards must belong to different factions. The players
                          will be notified about the result after the battle.
                          The winning player's participating card may rank up
                          depending on total battle points accumulated by that
                          card, and note, higher rank cards will always have a
                          higher probability to win a battle or a war. On the
                          contrary, the losing player's card will become
                          non-existent. Players can choose to hold their cards
                          off any battle by choosing the “Rest card” option.
                          This will allow the card to temporarily be inactive
                          from the battlefield. Players will not be able to
                          breed, mutate or sell their cards once they are
                          enlisted for battle. However, if the card is held on
                          “rest card” mode, these functions will be available
                          for the respective card. “Rest card” means the card
                          will be removed from the “Battle-ready players” list.
                          Both winning and losing cards will automatically be
                          removed from the battle list once the battle is
                          concluded. This means the user must add the card again
                          to the global battle list if he/she wishes to do so,
                          Our Battle algorithm takes into account all the mental
                          attributes i.e. IQ, EQ, wisdom, melee attack skill,
                          weapon skill, defense skill, leadership skill, AI
                          influence/ Natural adaptation, Future Potential. The
                          physical attributes represent the entire image
                          attribute in the NFT image. The physical attribute
                          doesn't play any part in the battle, except for the
                          weapon present in the image. Each weapon has a power
                          rating, and how well a character/card can use that
                          weapon depends on the characters/card's weapon skill.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-md-6">
                      <div className="custom_tab_text" id="war">
                        <h3>War</h3>
                        <p>
                          Each EXTREMIS player(s) can join or raise war against
                          another group of player(s). Unlike battles, a war will
                          consist of two groups of players, 11 players on each
                          side, to fight for the ultimate power. The winning
                          team is determined on basis of overall power
                          distribution and the each card's attribute values. Any
                          player can join the battleground in a war. Each player
                          participating in the war can use any number of cards
                          to represent them, but the same card cannot be used in
                          multiple
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src={war} onClick={() => navigate("/war-zone")} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="custom_tab_text">
                        <p>
                          battlegrounds within the war, because once you add a
                          card to warzone it will be escrowed to our contract
                          address until the war is concluded. To add a card to
                          the “War Zone” user must pay the war fee along with
                          the gas fee as well. So, the battle in a war will be
                          among 11 cards from one group against 11 cards from
                          the other one. Players are allowed to form alliances
                          during (before) the war. However, the two groups of
                          participating cards must be from different factions to
                          fight against each other. The winner of the war will
                          be promoted and everyone will receive the war fee
                          divided amongst the winner. Both winning and losing
                          team will incur casualties. Note it is possible for a
                          player to win a warzone battle and still loss their
                          card. We select the dead card based on a probability
                          distribution, where the losing team is more likely to
                          incur higher causalities. Users can remove their card
                          from a battlefield in a war, but the card's rank will
                          change to “Deserter" and all their battle points will
                          turn to zero. EXTREMIS uses the same function to
                          calculate the final result for both war and battle.
                          However each team will recieve a power multiplier if
                          certain combination of ranked officers are present in
                          the team.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-md-6">
                      <div className="custom_tab_text" id="war-multiplier">
                        <h3>Power multiplier</h3>
                        <p>
                          The power multiplier feature in EXTREMIS was inspired
                          by real-life battles where proficient leaders play an
                          essential role in the overall performance of the
                          soldiers. This feature is solely effective on the
                          battlefields in war. With the presence of one or more
                          high-ranked cards, the overall power of the remaining
                          soldiers is enhanced or multiplied. This is an
                          important strategic point to consider while enlisting
                          cards in the war.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img
                        src={multiplier}
                        onClick={() => navigate("/multiplier")}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-md-6">
                      <div className="custom_tab_text" id="mutation">
                        <h3>Mutation</h3>
                        <p>
                          The mutation functionality changes both physical and
                          mental gene-code. Users will have to pay a fee, every
                          time they mutate their cards. The result of the
                          mutation will be random, the card can either improve
                          or depreciate. Card mutations can cause unpredictable
                          alterations (in terms of physical features and power)
                          to the card. Mutations may result in the transition of
                          a card becoming more or less powerful. The same card
                          can be used multiple times for mutation with a
                          “Mutation fee” each time. Note you can't mutate a genesis card.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src={mutate} onClick={() => navigate("/mutate")} />
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-md-6">
                      <div className="custom_tab_text" id="rank">
                        <h3>Rank upgrade</h3>
                        <p>
                          Ranks or levels of cards play a crucial role in
                          EXTREMIS as they are one of the many key determinants
                          of win or loss in battles and war. Each member of the
                          three factions is classified into the ranks as
                          mentioned in the ‘Rank’ section of the ‘More’ page.
                        </p>
                        <p>
                          Based on the total battle point, cards can be promoted
                          in rank thus giving them enhanced power. Loss in
                          battles and war do not cause any change in card ranks.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src={promote} onClick={() => navigate("/promote")} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="custom_tab_text">
                        <p>
                          Card ranks are essential because they play a strategic
                          role to determine win or loss in battles and war.
                          Ranks can be upgraded using a certain rank up fee But
                          there is a limitation to this feature, Rank up is only
                          possible until ‘Major general’. Afterwards,
                          players are bound to engage their cards in battle and
                          earn their promotions by winning.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-md-6">
                      <div className="custom_tab_text" id="rename">
                        <h3>Rename cards</h3>
                        <p>
                          Cards in EXTREMIS are available in their default or
                          generic names. Players can change this to a unique
                          name of their liking by renaming their cards at the
                          cost of a small fee. Cards of any rank can be renamed. You can't rename a genesis card.
                          To rename a card, the players have to click on the
                          card they want to rename, click the “Rename card”
                          option, and type in the desired name for their
                          soldiers.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src={rename} onClick={() => navigate("/rename")} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default GamefeaturesText;
