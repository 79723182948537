import React from "react";
import DragBattleCard from "../../../../Shared/DnD/DragBattleCard";
import DragBreedCard from "../../../../Shared/DnD/DragBreedCard";
import "../BattleCollection/CardCollection.css";
import BattleCardText from "./SubPages/AliceCard/Sections/BattleCardText";

function BattleCard() {
  return (
    <div>
      {/* <Alicebanner/> */}
      {/* <AliceList/> */}
      {/* <AliceText/> */}
      {/* <BreedOthersAliceList ></BreedOthersAliceList> */}
      <div className="drag-and-card">
        <div className="card-area">
          <BattleCardText></BattleCardText>
        </div>
        <div className="drag-area">
          <DragBattleCard />
        </div>
      </div>
    </div>
  );
}
export default BattleCard;
