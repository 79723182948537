import React, { useContext } from "react";
import useGetAllCard from "../../../../Hooks/useGetAllCard";
import Loader from "../../../../Shared/Loader/Loader";
import Pagination from "../../../../Shared/Pagination";
import ProfileCard from "../../../../Shared/ProfileCard/ProfileCard";

function ProfileAllCardsText() {
  const [TokenId,page,setPage,next,Next,Previous,loader]=useGetAllCard()
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
          {
              loader?
              <Loader></Loader>:   <div className="content_box">
              <h4>ALL CARDS - {TokenId.length}</h4>
              <div className="row">
                {TokenId.slice((page - 1) * 10, page * 10).map((TokenId) => (
                  <ProfileCard TokenId={TokenId} key={TokenId}></ProfileCard>
                ))}
                 {
                  TokenId?.length===0 && <h1>NO CARD</h1>
                }
              </div>

              <Pagination
                page={page}
                setPage={setPage}
                length={TokenId.length / 10}
                Previous={Previous}
                Next={Next}
                next={next}
              ></Pagination>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProfileAllCardsText;
