import React from "react";
import InnerImageZoom from "react-inner-image-zoom";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import about_tesseract_roadmap from "../Sections/images/about_tesseract_roadmap.png";

function RoadMap() {
  return (
    <div>
      <section className="roadmap_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="roadmap_heading">
                <h2>ROADMAP</h2>
              </div>
              <div className=" text-center">
                <InnerImageZoom
                  src={about_tesseract_roadmap}
                  zoomSrc={about_tesseract_roadmap}
                  zoomScale=".3"
                  zoomType="hover"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default RoadMap;
