import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import WarHistoryCard from "../../../../../../../Shared/WarCard/WarHistoryCard";

const Winner = ({ TokenId }) => {
 
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {TokenId?.map((TokenId) => (
            <WarHistoryCard TokenId={TokenId}></WarHistoryCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Winner;
