import React from "react";
import BreedSelfBanner from "../BreedSelf/Sections/BreedSelfBanner";
// import BreedSelfTaxt from "../BreedSelf/Sections/BreedSelfTaxt";
import BreedCard from "../../../../Shared/DnD/DragBreedCard";
import "../BreedSelf/BreedSelf.css";
import { Outlet } from "react-router-dom";
function BreedSelf() {
  return (
    <div>
      <BreedSelfBanner />
      {/* <BreedSelfTaxt/> */}
      <Outlet></Outlet>
      {/* <BreedCard /> */}
      
    </div>
  );
}
export default BreedSelf;
