import React from 'react';
import '../TransferCollection/CardCollection.css';
import TransferBlacknetList from './SubPages/Blacknet/Sections/TransferBlacknetList';
import TransferBlacknetText from './SubPages/Blacknet/Sections/TransferBlacknetText';

function TransferBlacknetCard () {
	return <div>
		{/* <Blacknetbanner/> */}
		<TransferBlacknetList></TransferBlacknetList>
		<TransferBlacknetText></TransferBlacknetText>

        
	</div>
}
export default TransferBlacknetCard;