import React from 'react';
import WarHistoryBanner from './Section/WarHistoryBanner';
import WarHistoryText from './Section/WarHistoryText';

const WarHistory = () => {
    return (
        <div>
            <WarHistoryBanner/>
            <WarHistoryText></WarHistoryText>
        </div>
    );
};

export default WarHistory;