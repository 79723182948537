import React from "react";
import { useContext } from "react";
import { navigationContext } from "../../../App";

import "./ResultCard.css";
import ResultCardChild from "./ResultCardChild";
const BreedResultCard = ({ childId, finalResult,setFinalResult }) => {
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);

  return (
    <div
      className="battle-information"
      onClick={() => {
        setNavigationCntxt(true);
        setFinalResult(null);
      }}
    >
      <div className="battle-info-child">
        <h2 className="text-center my-5">
          You've successfully created a new card!
        </h2>
        <div className="battle-info-card">
          <div className="winner-card">
            <ResultCardChild
              TokenId={childId}
            ></ResultCardChild>
          </div>
          <div>

        <div className="history">
        <h5 className="text-center text-light">Breeding History</h5>

<p className="text-light">
  Transaction Hash: {finalResult?.txreceipt?.transactionHash} <br /> <br />
  Aws Response :{finalResult?.Awsresponse}
</p>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreedResultCard;
