import React, { useContext, useEffect, useState } from "react";
import { addressContext } from "../../App";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
const useUsername = (props) => {
  // console.log(props, "use username");
  const [Username, setUsername] = useState("");

  const GetCustomerInfo = async (owner) => {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    const param = {
      TableName: process.env.REACT_APP_CUSTOMERINFO_TABLE_NAME,
      Key: {
        CustomerID: props,
        //customer id will replace (owner props)
      },
      ProjectionExpression: "Username",
    };
    const response = await documentClient.get(param).promise();
    return response;
  };

  useEffect(() => {
    const Username = async () => {
      const customerInfo = await GetCustomerInfo();
      setUsername(customerInfo.Item?.Username);
    };
    if (props !== null) {
      Username();
    }
  }, [props]);

  return Username;
};

export default useUsername;
