import React from 'react';
function ProfileBanner () {
	return <div>
<div className="profile_set_section">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="profile_text">
                     <h1>Profile Settings</h1>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
}
export default ProfileBanner;