import * as AWS from 'aws-sdk';
import awsmobile from '../../../../../../aws-exports';
const { ethers } = require("ethers");

async function GetGeneCode(TokenId){

  let provider;
  if (window.ethereum) {
    // console.log("window wallet connected....");
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    //prompt user to install metamask
  }

  const ABI =[
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "newContract",
				"type": "address"
			}
		],
		"name": "ContractUpgrade",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "Auc_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_cardIdArray",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256",
				"name": "_numNewCards",
				"type": "uint256"
			}
		],
		"name": "AutoCardMaker",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_fatherarray",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_motherarray",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256",
				"name": "_newCardNum",
				"type": "uint256"
			}
		],
		"name": "AutoCardMakerHuman",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "BreedingAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Card_Maker_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Fixed_MarketPlace_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "GameCentreAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Gene_Mix_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardFraction",
		"outputs": [
			{
				"internalType": "string",
				"name": "Frac",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardInfo",
		"outputs": [
			{
				"internalType": "string",
				"name": "Name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "Rank",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "Gender",
				"type": "string"
			},
			{
				"internalType": "uint64",
				"name": "Last_Mating_Time",
				"type": "uint64"
			},
			{
				"internalType": "uint32",
				"name": "Gen",
				"type": "uint32"
			},
			{
				"internalType": "uint16",
				"name": "Battle_Points",
				"type": "uint16"
			},
			{
				"internalType": "bool",
				"name": "isOnAuction",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isOnWarZone",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isBattleReady",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isOnFixedPriceMarket",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "IsOnBreedList",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isBreedReady",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardRank",
		"outputs": [
			{
				"internalType": "uint16",
				"name": "bp",
				"type": "uint16"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardType",
		"outputs": [
			{
				"internalType": "string",
				"name": "Type",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetGender",
		"outputs": [
			{
				"internalType": "string",
				"name": "result",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetRarityCodeVal",
		"outputs": [
			{
				"internalType": "uint64[20]",
				"name": "",
				"type": "uint64[20]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetStats",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "IQ",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "EQ",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "Wisdom",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "MeleeAttck",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "WeaponSkill",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "DeffSkill",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "LeadershipSkill",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "NaturalAdpt",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "FuturePotential",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetWeaponCode",
		"outputs": [
			{
				"internalType": "uint64",
				"name": "WeaponGene",
				"type": "uint64"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "array",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256",
				"name": "_startPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint64",
				"name": "_duration",
				"type": "uint64"
			}
		],
		"name": "PutMultiCardAuc",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "RandomNumbers",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "RandomNumbers2",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newAdrr",
				"type": "address"
			}
		],
		"name": "SetAucContractAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_NewAdd",
				"type": "address"
			}
		],
		"name": "SetCardMakerContractAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newAdrr",
				"type": "address"
			}
		],
		"name": "SetGene_Mix_ContractAdress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newAdrr",
				"type": "address"
			}
		],
		"name": "SetWarContractAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "genecode",
				"type": "uint256"
			}
		],
		"name": "UpdateWeapon",
		"outputs": [
			{
				"internalType": "uint64",
				"name": "result",
				"type": "uint64"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "WarZonechecker",
		"outputs": [
			{
				"internalType": "bool",
				"name": "flag",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "War_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "WeaponUpdateFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "battlelistchecker",
		"outputs": [
			{
				"internalType": "bool",
				"name": "flag",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ceoAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cfoAddress",
		"outputs": [
			{
				"internalType": "address payable",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cooAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deleteRandomArray",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "fee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getLinkBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "Balance",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "num",
				"type": "uint256"
			}
		],
		"name": "getRandomNumber",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "requestId",
				"type": "bytes32"
			},
			{
				"internalType": "uint256",
				"name": "randomness",
				"type": "uint256"
			}
		],
		"name": "rawFulfillRandomness",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setBreedingAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newCEO",
				"type": "address"
			}
		],
		"name": "setCEO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "_newCFO",
				"type": "address"
			}
		],
		"name": "setCFO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newCOO",
				"type": "address"
			}
		],
		"name": "setCOO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setFixedMarketAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setGameCntrAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addr",
				"type": "address"
			}
		],
		"name": "setLinkTokenAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			}
		],
		"name": "setRandomNumGenfee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addr",
				"type": "address"
			}
		],
		"name": "setVRFCordinatorAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			}
		],
		"name": "setWeaponUpdateFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_keyHash",
				"type": "bytes32"
			}
		],
		"name": "setkeyHash",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawLink",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]

  const address = process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR;
  const GameCentreContract = new ethers.Contract(address, ABI, provider);


  try{

    const GeneCode = await GameCentreContract.GetRarityCodeVal(TokenId);
    return GeneCode;
    
  } catch(err){
    // console.log(err);
  }
}


async function GetType(TokenId){

  let provider;
  if (window.ethereum) {
    // console.log("window wallet connected....");
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    //prompt user to install metamask
  }

  const ABI =[
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "newContract",
				"type": "address"
			}
		],
		"name": "ContractUpgrade",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "Auc_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_cardIdArray",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256",
				"name": "_numNewCards",
				"type": "uint256"
			}
		],
		"name": "AutoCardMaker",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_fatherarray",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256[]",
				"name": "_motherarray",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256",
				"name": "_newCardNum",
				"type": "uint256"
			}
		],
		"name": "AutoCardMakerHuman",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "BreedingAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Card_Maker_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Fixed_MarketPlace_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "GameCentreAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Gene_Mix_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardFraction",
		"outputs": [
			{
				"internalType": "string",
				"name": "Frac",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardInfo",
		"outputs": [
			{
				"internalType": "string",
				"name": "Name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "Rank",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "Gender",
				"type": "string"
			},
			{
				"internalType": "uint64",
				"name": "Last_Mating_Time",
				"type": "uint64"
			},
			{
				"internalType": "uint32",
				"name": "Gen",
				"type": "uint32"
			},
			{
				"internalType": "uint16",
				"name": "Battle_Points",
				"type": "uint16"
			},
			{
				"internalType": "bool",
				"name": "isOnAuction",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isOnWarZone",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isBattleReady",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isOnFixedPriceMarket",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "IsOnBreedList",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isBreedReady",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardRank",
		"outputs": [
			{
				"internalType": "uint16",
				"name": "bp",
				"type": "uint16"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetCardType",
		"outputs": [
			{
				"internalType": "string",
				"name": "Type",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetGender",
		"outputs": [
			{
				"internalType": "string",
				"name": "result",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetRarityCodeVal",
		"outputs": [
			{
				"internalType": "uint64[20]",
				"name": "",
				"type": "uint64[20]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetStats",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "IQ",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "EQ",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "Wisdom",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "MeleeAttck",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "WeaponSkill",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "DeffSkill",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "LeadershipSkill",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "NaturalAdpt",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "FuturePotential",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "GetWeaponCode",
		"outputs": [
			{
				"internalType": "uint64",
				"name": "WeaponGene",
				"type": "uint64"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "array",
				"type": "uint256[]"
			},
			{
				"internalType": "uint256",
				"name": "_startPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint64",
				"name": "_duration",
				"type": "uint64"
			}
		],
		"name": "PutMultiCardAuc",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "RandomNumbers",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "RandomNumbers2",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newAdrr",
				"type": "address"
			}
		],
		"name": "SetAucContractAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_NewAdd",
				"type": "address"
			}
		],
		"name": "SetCardMakerContractAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newAdrr",
				"type": "address"
			}
		],
		"name": "SetGene_Mix_ContractAdress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newAdrr",
				"type": "address"
			}
		],
		"name": "SetWarContractAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "genecode",
				"type": "uint256"
			}
		],
		"name": "UpdateWeapon",
		"outputs": [
			{
				"internalType": "uint64",
				"name": "result",
				"type": "uint64"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "WarZonechecker",
		"outputs": [
			{
				"internalType": "bool",
				"name": "flag",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "War_ContractAdress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "WeaponUpdateFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "battlelistchecker",
		"outputs": [
			{
				"internalType": "bool",
				"name": "flag",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ceoAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cfoAddress",
		"outputs": [
			{
				"internalType": "address payable",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cooAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deleteRandomArray",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "fee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getLinkBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "Balance",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "num",
				"type": "uint256"
			}
		],
		"name": "getRandomNumber",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "requestId",
				"type": "bytes32"
			},
			{
				"internalType": "uint256",
				"name": "randomness",
				"type": "uint256"
			}
		],
		"name": "rawFulfillRandomness",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setBreedingAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newCEO",
				"type": "address"
			}
		],
		"name": "setCEO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "_newCFO",
				"type": "address"
			}
		],
		"name": "setCFO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newCOO",
				"type": "address"
			}
		],
		"name": "setCOO",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setFixedMarketAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newAddr",
				"type": "address"
			}
		],
		"name": "setGameCntrAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addr",
				"type": "address"
			}
		],
		"name": "setLinkTokenAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			}
		],
		"name": "setRandomNumGenfee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_addr",
				"type": "address"
			}
		],
		"name": "setVRFCordinatorAddr",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_fee",
				"type": "uint256"
			}
		],
		"name": "setWeaponUpdateFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "_keyHash",
				"type": "bytes32"
			}
		],
		"name": "setkeyHash",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawLink",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]
  const address = process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR;
  const GameCentreContract = new ethers.Contract(address, ABI, provider);


  try{

    const Type = await GameCentreContract.GetCardType(TokenId);
    return Type;
    
  } catch(err){
    // console.log(err);
  }
}


export async function GetImageKeys(TokenId){

  const documentClient = new AWS.DynamoDB.DocumentClient({
    region: awsmobile.aws_cognito_region,
    credentials: AWS.config.credentials
  });

  const FeaturesNameArray = [
    "Background",
    "FCMask",
    "ArmSupport",
    "ShoulderPad",
    "Boot",
    "Legwear",
    "RLHos",
    "LLHos",
    "ChestCore",
    "Gauntlet",
    "Wing",
    "Armor",
    "Belt",
    "Jetpack",
    "Googles",
    "Cape",
    "Drone",
    "Helmet",
    "Partner",
    "Weapon"
  ];

  const GeneCode = await GetGeneCode(TokenId);
//   console.log(GeneCode ,"code gene");
  let ImageKeyArr = [];
  let ImageKeyResponse = [];

  
  for(let i=0; i< GeneCode.length; i++){
    const param = {
      TableName: process.env.REACT_APP_BLACKNET_IMAGE_TABLE,
      Key:{
        GeneCode: GeneCode[i].toString(),
        FeatureName: FeaturesNameArray[i]
      }
    }
  
    try{
      ImageKeyResponse[i] = await documentClient.get(param).promise();
    } catch(err){
    //   console.log(err);
    }
  }

  const data = await Promise.all(ImageKeyResponse);

  for(let i=0;i< data.length;i++){
    ImageKeyArr.push(data[i].Item.ImageFileKey);
  }

  return ImageKeyArr;
}


export async function GetSkeletonImageKey(TokenId){
  
  const documentClient = new AWS.DynamoDB.DocumentClient({
    region: awsmobile.aws_cognito_region,
    credentials: AWS.config.credentials
  });

  const type = await GetType(TokenId);
//   console.log(type);
  const param = {
    TableName: process.env.REACT_APP_SKELETON_TABLE_NAME,
    Key:{
      Type: type,
      Faction: 'BlackNet'
    },
    ProjectionExpression: 'ImageFileKey'
  }

  try{
    const Response = await documentClient.get(param).promise()
    return Response.Item.ImageFileKey;

  }catch(err){
    // console.log(err);
  }

}


const BlacknetImageSVG = async (TokenId) => {
    let ImageSVGblacknet;
    let ImageKeyArr = await GetImageKeys(TokenId);
    // console.log("key",ImageKeyArr);
  let SkeletonImageKey = await GetSkeletonImageKey(TokenId);
    async function BlacknetImage(props) {
      //feature
    const SVGs = [];
    for (let i = 0; i < props.ImageKeyArr.length; i++) {
      const element = props.ImageKeyArr[i];
      const Response = await fetch(
        `https://www.api.extremisverse.com/GetPreSignedUrl?ImageKey=${element}`
      );
      const url=await Response.text()
      const Image = await fetch(url, {
        method: "GET",
      });
      const SVG = await Image.text();
      SVGs.push(SVG);
    }

    //skeleton
    const Response = await fetch(
      `https://www.api.extremisverse.com/GetPreSignedUrl?ImageKey=${props.SkeletonImageKey}`
    );
    const url=await Response.text()
    const Image = await fetch(url, {
      method: "GET",
    });
    const SkeletonImage = await Image.text();
   
    return [SVGs,SkeletonImage];
  }

    
    const [SVGs,SkeletonImage] = await BlacknetImage({ ImageKeyArr, SkeletonImageKey });
    ImageSVGblacknet = `<svg
      className="svg"
      id="Horizon_Image"
      data-name="Horizon Image"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 324 432"
    >
    
      
      <image
        
        width="450"
        height="600"
        transform="scale(0.72)"
        xlink:href="${SVGs[0].slice(SVGs[0].search("data:image/png"),SVGs[0].search(`/>`) + 2 )}
       
        
       
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[13].slice(SVGs[13].search("data:image/png"),SVGs[13].search(`/>`) + 2 )}
        
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[18].slice(SVGs[18].search("data:image/png"),SVGs[18].search(`/>`) + 2 )}
          
          
          <image
            
            width="450"
            height="600"
            transform="scale(0.72)"
            xlink:href="${SkeletonImage.slice(SkeletonImage.search("data:image/png"),SkeletonImage.search(`/>`) + 2 )}
          
         
        
        <image
          id=""
          data-name="1 Image"
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[8].slice(SVGs[8].search("data:image/png"),SVGs[8].search(`/>`) + 2 )}
        
       
        
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[4].slice(SVGs[4].search("data:image/png"),SVGs[4].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[1].slice(SVGs[1].search("data:image/png"),SVGs[1].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[14].slice(SVGs[14].search("data:image/png"),SVGs[14].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[11].slice(SVGs[11].search("data:image/png"),SVGs[11].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[5].slice(SVGs[5].search("data:image/png"),SVGs[5].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[12].slice(SVGs[12].search("data:image/png"),SVGs[12].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[10].slice(SVGs[10].search("data:image/png"),SVGs[10].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[2].slice(SVGs[2].search("data:image/png"),SVGs[2].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[9].slice(SVGs[9].search("data:image/png"),SVGs[9].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[6].slice(SVGs[6].search("data:image/png"),SVGs[6].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[7].slice(SVGs[7].search("data:image/png"),SVGs[7].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[3].slice(SVGs[3].search("data:image/png"),SVGs[3].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[19].slice(SVGs[19].search("data:image/png"),SVGs[19].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[16].slice(SVGs[16].search("data:image/png"),SVGs[16].search(`/>`) + 2 )}
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[15].slice(SVGs[15].search("data:image/png"),SVGs[15].search(`/>`) + 2 )}
        
       
        
       
        
        <image
          
          width="450"
          height="600"
          transform="scale(0.72)"
          xlink:href="${SVGs[17].slice(SVGs[17].search("data:image/png"),SVGs[17].search(`/>`) + 2 )}
        
       
          
          </svg>`;
        //   console.log(ImageSVGblacknet);
  return ImageSVGblacknet;
};

export default BlacknetImageSVG;
