import React, { useState } from "react";
import blacknet_logo from "../Images/blacknet_logo.png";
import alice_logo from "../Images/alice_logo.png";
import humanity_logo from "../Images/humanity_logo.png";
import { useEffect } from "react";
function Allcardsbanner() {
  const [BvA_percentage, setBvA_percentage] = useState("");
  const [BvA_color, setBvA_color] = useState();
  const [BvH_percentage, setBvH_percentage] = useState("");
  const [BvH_color, setBvH_color] = useState();
  const [HvA_percentage, setHvA_percentage] = useState("");
  const [HvA_color, setHvA_color] = useState();
  fetch("https://www.api.extremisverse.com/CalcAlliance")
    .then((res) => res.json())
    .then((data) => {
      setBvA_percentage(Math.trunc(data?.BvA_percentage));
      setBvH_percentage(Math.trunc(data?.BvH_percentage));
      setHvA_percentage(Math.trunc(data?.HvA_percentage));
    });

  useEffect(() => {
    if (BvA_percentage <= 20) {
      setBvA_color("color-one");
    } else if (BvA_percentage > 20 && BvA_percentage <= 40) {
      setBvA_color("color-two");
    } else if (BvA_percentage > 40 && BvA_percentage <= 60) {
      setBvA_color("color-three");
    } else if (BvA_percentage > 60 && BvA_percentage <= 80) {
      setBvA_color("color-four");
    } else if (BvA_percentage > 80 && BvA_percentage <= 100) {
      setBvA_color("color-five");
    }
  }, [BvA_percentage]);
  useEffect(() => {
    if (BvH_percentage <= 20) {
      setBvH_color("color-one");
    } else if (BvH_percentage > 20 && BvH_percentage <= 40) {
      setBvH_color("color-two");
    } else if (BvH_percentage > 40 && BvH_percentage <= 60) {
      setBvH_color("color-three");
    } else if (BvH_percentage > 60 && BvH_percentage <= 80) {
      setBvH_color("color-four");
    } else if (BvH_percentage > 80 && BvH_percentage <= 100) {
      setBvH_color("color-five");
    }
  }, [BvH_percentage]);

  useEffect(() => {
    if (HvA_percentage <= 20) {
      setHvA_color("color-one");
    } else if (HvA_percentage > 20 && HvA_percentage <= 40) {
      setHvA_color("color-two");
    } else if (HvA_percentage > 40 && HvA_percentage <= 60) {
      setHvA_color("color-three");
    } else if (HvA_percentage > 60 && HvA_percentage <= 80) {
      setHvA_color("color-four");
    } else if (HvA_percentage > 80 && HvA_percentage <= 100) {
      setHvA_color("color-five");
    }
  }, [HvA_percentage]);


 
  return (
    <div>
      <div className="CardCollectionSection">
        <div className="container py-3">
          <h1>ALLIANCE METER</h1>
          <div className="row">
            <div className="col-md-4">
              <div className="battle_box">
                <div className="battle_proess">
                  <h3>Blacknet & Humanity</h3>
                  <h4 className={BvH_color}>{BvH_percentage}%</h4>
                  <div className="progress">
                    <div
                      className={BvH_color}
                      role="progressbar"
                      style={{ width: `${BvH_percentage}%` }}
                      aria-valuenow={BvH_percentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="battle_box">
                <div className="battle_proess">
                  <h3>Humanity & Alice</h3>
                  <h4 className={HvA_color}>{HvA_percentage}%</h4>
                  <div className="progress">
                    <div
                      className={HvA_color}
                      role="progressbar"
                      style={{ width: `${HvA_percentage}%` }}
                      aria-valuenow={HvA_percentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="battle_box">
                <div className="battle_proess">
                  <h3>Alice & Blacknet</h3>
                  <h4 className={BvA_color}>{BvA_percentage}%</h4>
                  <div className="progress">
                    <div
                      className={BvA_color}
                      role="progressbar"
                      style={{ width: `${BvA_percentage}%` }}
                      aria-valuenow={BvA_percentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Allcardsbanner;
