import React from "react";
import { useDrag } from "react-dnd";
import { Link, useNavigate } from "react-router-dom";
import useCard from "../Card/useCard";
import Loader from "../Loader/Loader";
import useStats from "../useStates";
import "../Card.css";
import useImage from "../Card/useImage";
import useColor from "../useColor";

import { useEffect } from "react";
import { useState } from "react";
import useRankImage from "../useRankImage";
import { GetCardFinalBreedFee } from "../../UserPage/BlockChainFunctions";
import useBreedingContract from "../../Hooks/BreedingContractHook";

const BreedCardOthers = (props) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "div",
    item: { id: props.TokenId },

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
const [contract,setContract]=useState("")
  const [image, setImage] = useState(false);
  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardOwner,
    CardOwnerUsername,
  ] = useCard(props.TokenId);
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  const [fee,setFee]=useState("")
  const [breedingContract, setBreedingContract] = useBreedingContract();

  useEffect(() => {
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);
  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(props.TokenId);
  const navigation = useNavigate();

  const nav = () => {
    // console.log("prop", props);
    navigation(`/individual-card/${props.TokenId}`);
  };
  const BreedFee = async () => {
    const result = await GetCardFinalBreedFee(props.TokenId, breedingContract);
    setFee(result)
  };
useEffect(()=>{
  if (breedingContract) {
    BreedFee();
  }
},[breedingContract])
useEffect(() => {
  
  if (CardOwner) {
    if (CardOwner === process.env.REACT_APP_WAR_CONTRACT_ADDR) {
      setContract("War Contract");
    } else if (CardOwner === process.env.REACT_APP_BREEDING_CONTRACT_ADDR) {
      setContract("Breed Contract");
    } else if (
      CardOwner === process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR
    ) {
      setContract("Fixed Market Contract");
    } else if (CardOwner === process.env.REACT_APP_AUCTION_CONTRACT_ADDR) {
      setContract("Auction House Contract");
    } else if (
      CardOwner === process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR
    ) {
      setContract("Card ownership Contract");
    } else if (CardOwner === process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR) {
      setContract("Game Centre Contract");
    } else {
      setContract(CardOwnerUsername);
    }
  }
}, [CardOwner, CardOwnerUsername]);
  return (
    <div    onClick={nav}
      className="word-wrapping my-3"
      ref={drag}
      style={{ border: isDragging && "5px solid red" }}
    >
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="breeding_lab card_img">
          <img
            style={
              CardFaction &&
              CardType &&
              CardRank &&
              IQ &&
              EQ &&
              Wisdom &&
              Defense &&
              Weapon &&
              Leadership &&
              Melee &&
              Potential &&
              NaturalAdpt
                ? {}
                : { display: "none" }
            }
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />
           <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />   <img className="extremis-logo" src="/LOGO.png" alt="" />
          {CardFaction &&
          CardType &&
          CardRank &&
          IQ &&
          EQ &&
          Wisdom &&
          Defense &&
          Weapon &&
          Leadership &&
          Melee &&
          Potential &&
          NaturalAdpt ? (
            <>
              <div className="card_info text-light">
                <h3>{CardName}</h3>{" "}
                <p className="fs-6 text-white m-0">ID: {props.TokenId}</p>
              <h5>{CardFaction}</h5>
              <p>OWNER: {contract}</p>
              </div>
              <div class="list_box">
                <ul>
                  <li>
                    <span>Rank: </span> {CardRank}
                  </li>
                  <li>
                    <span>Type: </span>
                    {CardType}
                  </li>
                  <li>
                    <span>IQ: </span> {IQ}
                  </li>
                  <li>
                    <span>Potential: </span> {Potential}
                  </li>
                  <li>
                    <span>EQ: </span> {EQ}
                  </li>
                  <li>
                    <span>Wisdom: </span>
                    {Wisdom}
                  </li>
                  <li>
                    <span>Melee: </span> {Melee}
                  </li>
                  <li>
                    <span>Leadership: </span> {Leadership}
                  </li>
                  <li>
                    <span>Weapon: </span> {Weapon}
                  </li>
                  <li>
                    <span>Defense: </span> {Defense}
                  </li>
                  {CardFaction === "Humanity" ? (
                    <li>
                      <span>N. Adaptability: </span>
                      {NaturalAdpt}
                    </li>
                  ) : (
                    <li>
                      <span>AI Influence: </span>
                      {NaturalAdpt}
                    </li>
                  )}
                   <li>
                    <span>Fee: </span> {fee} Matic
                  </li>
                </ul>
              </div>
              {/* <div className="bettle_btn">
              <a className="btn" href="#">
                SELECT
              </a>
            </div> */}
            </>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
    </div>
  );
};

export default BreedCardOthers;
