import React, { useEffect, useState } from "react";
import blacknet from "../Images/blacknet.png";
import alice from "../Images/alice.png";
import { ethers } from "ethers";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../../../aws-exports";
import BreedCard from "../../../../../../../Shared/BreedCard/BreedCard";
import Pagination from "../../../../../../../Shared/Pagination";
import useGameCentreContract from "../../../../../../../Hooks/GameCentreHook";
import { GetCardFaction } from "../../../../../../../UserPage/BlockChainFunctions";
import BreedCardOthers from "../../../../../../../Shared/BreedCardOthers/BreedCardOthers";
import useBreedingListSelfCardByFaction from "../../../../../../../Hooks/useBreedingListCardByFaction";
import useBreedAllCardByFaction from "../../../../../../../Hooks/useBreedAllCardByFaction";
import Loader from "../../../../../../../Shared/Loader/Loader";

function Self() {
  const [TokenId, page, setPage, next, Next, Previous, loader] =
    useBreedAllCardByFaction("BlackNet");

  return (
    <div>
    <div className="">
      <div className="">
        <div className="col-md-10 offset-md-1">
          <div className="">
            <h4 className="text-danger">BREED READY</h4>

            {loader ? (
              <Loader></Loader>
            ) : (
              <>
                <div className="row">
                  {TokenId.slice((page - 1) * 10, page * 10).map(
                    (TokenId) => (
                      <BreedCardOthers
                        TokenId={TokenId}
                        key={TokenId}
                      ></BreedCardOthers>
                    )
                  )}

                  {TokenId.length === 0 && <h1>NO CARD</h1>}
                </div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  length={TokenId.length / 10}                  
                  Previous={Previous}
                  Next={Next}
                  next={next}
                ></Pagination>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
export default Self;
