import React from 'react';
import CongratulationsBanner from '../Congratulations/Sections/CongratulationsBanner';
import CongratulationsText from '../Congratulations/Sections/CongratulationsText';
import '../Congratulations/Congratulations.css';
function Congratulations(){
 return <div>
<CongratulationsBanner/>
<CongratulationsText/>


 </div>


}
export default Congratulations;