import React from "react";
import TransferCardsBanner from "./Sections/TransferCardsBanner/TransferCardsBanner";
import "./TransferCards.css";
import TransferText from "./TransferText";
const TransferCards = () => {
  return (
    <div>
      <TransferCardsBanner></TransferCardsBanner>
      <TransferText></TransferText>
      {/* <div className="container">
        <div className="transfer-card-body">
          <h4>Transfer card(s)</h4>
          <hr />
          <h5>Transfer singular card</h5>
          <div className="transfer-card-input">
            <div>
              <p>Token ID</p>
              <input type="text" name="" id="" />
            </div>
            <div>
              <p>From address</p>
              <input type="text" name="" id="" />
            </div>
            <div>
              <p>To address</p>
              <input type="text" name="" id="" />
            </div>
          </div>
          <div className="transfer-card-button">
            <button> TRANSACT</button>
          </div>
          <hr id="transfer-card-second-hr" />
          <h5>Transfer multiple cards</h5>
          <p id="transfer-card-note">*Separate token IDs with comma [ , ]</p>
          <div className="transfer-multiple-card-input">
            <div>
              <p>Token ID's</p>
              <input id="multiple-card-input" type="text" name="" />
            </div>

            <div>
              <p>To address</p>
              <input type="text" name="" id="" />
            </div>
          </div>
          <div className="transfer-card-button">
            <button> TRANSACT</button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TransferCards;
