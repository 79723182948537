import React from 'react';
import Genesisbanner from '../Genesis/Sections/Genesisbanner';
import '../Genesis/Genesis.css';
function Genesis () {
	return <div>
		<Genesisbanner/>
       
        
	</div>
}
export default Genesis;