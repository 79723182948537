import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useAuctionAdminCard from "../../Hooks/useAuctionAdminCard";
import AuctionCard from "../../Shared/AuctionCard/AuctionCard";
import AuctionCardAdminPage from "../../Shared/AuctionCard/AuctionCardAdminPage";
import Loader from "../../Shared/Loader/Loader";
import Pagination from "../../Shared/Pagination";
import ArrowIcon from "./AdminComponent/ArrowIcon";
import BasicButton from "./AdminComponent/BasicButton";
const AuctionCardAdmin = (props) => {
  const [TokenId, page, setPage, next, Next, Previous, loader] =
    useAuctionAdminCard();

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="">
            {loader ? (
              <Loader></Loader>
            ) : (
              <div className="content_box">
                <div className="a-title">AUCTION CARDS-{TokenId.length}</div>
                <div className="row fs-6">
                  {TokenId.slice((page - 1) * 10, page * 10).map((TokenId) => (
                    <AuctionCardAdminPage TokenId={TokenId} key={TokenId}></AuctionCardAdminPage>
                  ))}
                  {TokenId?.length === 0 && <h1>NO CARD</h1>}
                </div>

                <Pagination
                  page={page}
                  setPage={setPage}
                  length={TokenId.length / 10}
                  Previous={Previous}
                  Next={Next}
                  next={next}
                ></Pagination>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionCardAdmin;
