import React from "react";
import { Link } from "react-router-dom";
function PurchaseText() {
  return (
    <div>
      <section className="purchase_text">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="purchase_box text-center">
                <Link className="btn market" to="/marketplace">
                  FIXED MARKETPLACE
                </Link>
                <br></br>
                <Link className="btn auction" to="/auction-house">
                  AUCTION HOUSE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default PurchaseText;
