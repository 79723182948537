import React from 'react';
import '../SellCollection/CardCollection.css';
import SellHumanityList from './SubPages/Humanity/Sections/SellHumanityList';
import SellHumanityText from './SubPages/Humanity/Sections/SellHumanityText';

function SellHumanityCard () {
	
	return <div>
		{/* <Humanitybanner/> */}
		<SellHumanityList></SellHumanityList>
		<SellHumanityText></SellHumanityText>

        
	</div>
}
export default SellHumanityCard;