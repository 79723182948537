import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useAuctionContract from "../../../../Hooks/AuctionContractHook";
import { GetSaleRecordSizeAuc } from "../../../../UserPage/BlockChainFunctions";
import DataTableAuction from "../DataTable/DataTableAuction";
import "./Auction.css";
import AuctionList from "./AuctionList";
const Auction = () => {
  const [auctionContract, setAuctionContract] = useAuctionContract();

  const [saleRecord, setSaleRecord] = useState();
  const GetRecordSize = async () => {
    const Id = [];
    const result = await GetSaleRecordSizeAuc(auctionContract);
    for (let i = 0; i < parseInt(result.toString()); i++) {
      const element = i;
      Id.push(element);
    }

    return Id;
  };
  useEffect(() => {
    if (auctionContract) {
      const func = async () => {
        const result = await GetRecordSize();
        setSaleRecord(result.reverse());
      };
      func();
    }
  }, [auctionContract]);
  return (
    <div>
      <AuctionList></AuctionList>
      <DataTableAuction saleRecord={saleRecord}></DataTableAuction>
    </div>
  );
};

export default Auction;
