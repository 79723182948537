import React from 'react';
import BreedListAllcardsList from './Sections/BreedListAllcardsList';
import AllcardsList from './Sections/BreedListAllcardsList';
import BreedListAllCardsText from './Sections/BreedListAllcardsText';
import AllCardsText from './Sections/BreedListAllcardsText';

const BreedingListAllCard = () => {
    return (
        <div>
            <BreedListAllcardsList></BreedListAllcardsList>
            <BreedListAllCardsText></BreedListAllCardsText>
        </div>
    );
};

export default BreedingListAllCard;