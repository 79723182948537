import React, { useEffect, useState } from "react";
import "./Pagination.css";
const Pagination = ({ page, setPage, length, Previous, Next, next }) => {
  const [pageCount, setPageCount] = useState();

  const PresentPage = (num) => [setPage(num)];
  useEffect(() => {
    setPageCount(Math.ceil(length));
  }, [pageCount, length]);
  return (
    <div className="pagination-button">
      <button
        className="pagination disable"
        onClick={Previous}
        disabled={page === 1}
      >
   
      <p>  &#8249;</p>
      </button>
      <h3>{page}</h3>
      {/* {[...Array(pageCount).keys()].map(num=><button key={num+1} className={num+1===page ?`selected`:"pagination"} onClick={()=>PresentPage(num+1)}>{num+1}</button>)} */}
      <button
        className="pagination disable"
        onClick={Next}
        disabled={next === false}
      >
       <p> &#8250;</p>
      </button>
    </div>
  );
};

export default Pagination;
