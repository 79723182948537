import React from "react";
import Alicebanner from "../BreedSelfCollection/SubPages/AliceCard/Sections/Alicebanner";
import AliceText from "./SubPages/AliceCard/Sections/BreedSelfAliceText";
import AliceList from "./SubPages/AliceCard/Sections/BreedSelfAliceList";
import "../BreedSelfCollection/CardCollection.css";
import BreedSelfAliceText from "./SubPages/AliceCard/Sections/BreedSelfAliceText";
import BreedSelfAliceList from "./SubPages/AliceCard/Sections/BreedSelfAliceList";
import DragBreedCard from "../../../../Shared/DnD/DragBreedCard";
import "./breed.css";
function BreedSelfAliceCard() {
  return (
    <div>
      {/* <Alicebanner/> */}
      {/* <AliceList/> */}
      {/* <AliceText/> */}
      <div className="drag-and-card">
        <div className="card-area">
          <BreedSelfAliceList></BreedSelfAliceList>
          <BreedSelfAliceText></BreedSelfAliceText>
        </div>
        <div className="drag-area">
          <DragBreedCard />
        </div>
      </div>
    </div>
  );
}
export default BreedSelfAliceCard;
