import React from "react";
function ContactInfo() {
  return (
    <div>
      <section className="contact_info">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact_box">
                <div className="icon">
                  <i class="fal fa-envelope"></i>
                </div>
                <div className="contact_text">
                  <a href="mailto:extremis.verse@gmail.com">extremis.verse@gmail.com</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact_box">
                <div className="icon">
                  <i class="fal fa-phone-alt"></i>
                </div>
                <div className="contact_text">
                  <a href="tel:+1 506-672-3191">+1 506-672-3191</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact_box">
                <div className="icon">
                  <i class="fal fa-map-marker-alt"></i>
                </div>
                <div className="contact_text">
                  <p>190 Adelaide Street, Saint John, NB, Canada E2K1X1</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactInfo;
