import React, { useContext, useRef } from "react";
// import alice_logo from "../Sections/Images/alice_logo.png";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
// import useMarketPlaceCard from "./useMarketPlaceCard";
import useImage from "../Card/useImage";
import useColor from "../useColor";

import Loader from "../Loader/Loader";
import "../Card.css";

import useMarketPlaceCard from "../MarketPlaceCard/useMarketPlaceCard";
import { useEffect } from "react";
import { useState } from "react";
import useRankImage from "../useRankImage";
import deceased from "../../../assets/Deceased tag for character death-01.png";
import useCard from "../Card/useCard";
const { ethers } = require("ethers");

function ProfileCard(props) {
  const navigation = useNavigate();
  const [image, setImage] = useState(false);
  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardOwner,
    CardOwnerUsername,
  ] = useCard(props.TokenId);
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  const [deceasedTag, setDeceasedTag] = useState(false);

  useEffect(() => {
    if (CardOwner) {
      if (CardOwner === "0x0000000000000000000000000000000000000000") {
        setDeceasedTag(true);
      }
    }
  }, [CardOwner]);

  useEffect(() => {
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);

  const nav = (props) => {
    navigation(`/individual-card/${props}`);
  };

  return (
    <div
      className="col-md-6 word-wrapping"
      onClick={() => {
        nav(props.TokenId);
      }}
    >
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_box card_img">
          <img
            style={
              CardFaction && CardGender && CardType && CardRank
                ? {}
                : { display: "none" }
            }
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />{" "}
          <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />{" "}
          <img
            className="extremis-logo"
            src="/LOGO.png"
            alt=""
          />
          <div className="deceased-tag">
            {deceasedTag && <img src={deceased} alt="" />}
          </div>
          {CardFaction && CardGender && CardType && CardRank ? (
            <>
              <h3>{CardName}</h3> <div className="card_info"></div>
              <div className="card_info">
                <div className="card_type">
                  <p className="fs-6 text-white m-0">ID: {props.TokenId}</p>
                  <p>
                    RANK: <strong>{CardRank}</strong>
                  </p>
                </div>
                <div className="card_rank">
                  <p>
                    FACTION: <strong>{CardFaction}</strong>
                  </p>
                  <p>
                    TYPE: <strong> {CardType}</strong>
                  </p>
                </div>
              </div>
            </>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
    </div>
  );
}
export default ProfileCard;
