import React from 'react';
import 'react-bootstrap-accordion/dist/index.css'
function Faq1 () {
	return <div>

<div className="faq_box">
   <div className="row">
   <div class="accordion" id="accordionPanelsStayOpenExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
      Q1. What device can I play Extremis on?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
      <div class="accordion-body">
      <p>A:Users can play Extremis on Mobile phones and Computers, Given the device is equipped with a browser.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
      Q2. Which browser does Extremis support?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
      <div class="accordion-body">
        <p>A: To ensure the best gaming experience, we offer our game on most browsers.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      Q3. Choosing a crypto wallet! Which Crypto wallets are supported by Extremis?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
      <div class="accordion-body">
        <p>A: A crypto wallet is a digital wallet that allows users to store their cryptocurrencies, tokens and other digital assets. To play EXTREMIS, we recommend users to install a metamask or coinbase wallet. However, Extremis support most injected wallets. </p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
      Q4. How to install a crypto wallet?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
      <div class="accordion-body">
        <p>A: Users can enjoy the EXTREMIS experience by connecting their wallet. We recommend MetaMask, MetaMask is a crypto wallet service that equips you with a key vault, secure login, token wallet, and token exchange. It is a free service that enables you to manage all your digital assets. MetaMask is available as a browser extension and mobile app. PC users can download the free extension from the official website of MetaMask. Mobile users can download the app via Playstore. Once MetaMask is added in your browser or device, simply enter your email address and a desired password to create your own crypto wallet with a unique address. NOTE: We DO NOT support MetaMask mobile app. 
</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
      Q5. What is a wallet address?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
      <div class="accordion-body">
        <p>A: Wallet Addresses, also plainly referred as “Addresses”, is the string of alphanumeric where your cryptocurrencies are stored. A wallet address is generated only when you open an account with a crypto wallet service, such as MetaMask. Your wallet is like an email account for your cryptocurrency; you share your email address but not your password. Your public keys/wallet addresses are like your email address, and private keys are similar to the password you use to login to your email. To play EXTREMIS, users need to have MetaMask wallet which is built on the Polygon network.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
      Q6. Choosing a digital currency
      </button>
    </h2>
    <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
      <div class="accordion-body">
        <p>A: EXTREMIS can be played by MATIC coins stored in the users’ digital wallets. MATIC is the native cryptocurrency of the Polygon network, which is a layer 2 scaling ‘sidechain’ providing faster transactions and lower costs for users. It acts as a speedy parallel blockchain running alongside the main Ethereum blockchain.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
      Q7. Why do I need a digital currency in my wallet?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
      <div class="accordion-body">
        <p>A: EXTREMIS contains some playable features which charges users with MATIC and other gas fees. This is why users need to have MATIC coins in their wallets to immerse in the complete experience of the game.</p>
      </div>
    </div>
  </div>
</div>
   </div>
</div>
     </div>
     }
     export default Faq1;