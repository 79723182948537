import React from "react";
import "./BreedingListBanner.css";
const BreedingListBanner = () => {
  return (
    <div className="breeding-list-banner">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page_title">
                <h1>BREEDING LIST</h1>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreedingListBanner;
