import React from "react";
import { useDrag } from "react-dnd";
import { Link } from "react-router-dom";
import useCard from "../Card/useCard";
import useStats from "../useStates";
// import useBreedCard from "./useBreedCard";
import "../../Profile/Pages/Breeding/BreedSelfCollection/breed.css";
import useWarCard from "./useWarCard";
import Loader from "../Loader/Loader";
import "../Card.css";
import useImage from "../Card/useImage";
import useColor from "../useColor";
import deceased from "../../../assets/Deceased tag for character death-01.png";
import useSellCard from "../SellCard/useSellCard";
import { useEffect } from "react";
import { useState } from "react";
import useRankImage from "../useRankImage";

const WarHistoryCard = (props) => {
  // const [CardFaction, CardName, CardType, CardRank, CardGender] =useSellCard(props.TokenId);
// console.log(props,"history card");
  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardOwner,
    CardOwnerUsername,
  ] = useCard(props.TokenId);
  // console.log(CardOwner,props.TokenId);
  const [image, setImage] = useState(false);
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  const [deceasedTag, setDeceasedTag] = useState(false);

  useEffect(() => {
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);
  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(props.TokenId);
  useEffect(() => {
    if (CardOwnerUsername === "Deceased") {
      setDeceasedTag(true);
    }
  }, [CardOwnerUsername]);
  return (
    <div className="card_section word-wrapping">
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_img">
          <img
            style={
              CardFaction &&
              CardType &&
              CardRank &&
              IQ &&
              EQ &&
              Wisdom &&
              Defense &&
              Weapon &&
              Leadership &&
              Melee &&
              Potential &&
              NaturalAdpt
                ? {}
                : { display: "none" }
            }
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />
          <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />{" "}
          <img
            className="extremis-logo"
            src="/LOGO.png"
            alt=""
          />
          <div className="deceased-tag">
            {deceasedTag && <img src={deceased} alt="" />}
          </div>
          {CardFaction &&
          CardType &&
          CardRank &&
          IQ &&
          EQ &&
          Wisdom &&
          Defense &&
          Weapon &&
          Leadership &&
          Melee &&
          Potential &&
          NaturalAdpt ? (
            <>
              <div className="card_info text-white">
                <h3>{CardName}</h3>
                {/* <p className="fs-6 text-white m-0">gggg</p> */}
              </div>
             <div className="card_info">
             <p className=" text-white text-break">Faction: {CardFaction}</p>
              <p className=" text-white text-break">Owner: {CardOwnerUsername}</p>
             </div>
              <div class="list_box">
                <ul>
                  <li>
                    <span>ID: </span>
                    {props.TokenId}
                  </li>
                  <li>
                    <span>Rank: </span> {CardRank}
                  </li>

                  <li>
                    <span>Type: </span>
                    {CardType}
                  </li>
                  <li>
                    <span>IQ: </span> {IQ}
                  </li>
                  <li>
                    <span>Potential: </span> {Potential}
                  </li>
                  <li>
                    <span>EQ: </span> {EQ}
                  </li>
                  <li>
                    <span>Wisdom: </span>
                    {Wisdom}
                  </li>
                  <li>
                    <span>Melee: </span> {Melee}
                  </li>
                  <li>
                    <span>Leadership: </span> {Leadership}
                  </li>
                  <li>
                    <span>Weapon: </span> {Weapon}
                  </li>
                  <li>
                    <span>Defense: </span> {Defense}
                  </li>
                  {CardFaction === "Humanity" ? (
                    <li>
                      <span>N. Adaptability: </span>
                      {NaturalAdpt}
                    </li>
                  ) : (
                    <li>
                      <span>AI Influence: </span>
                      {NaturalAdpt}
                    </li>
                  )}
                </ul>
              </div>
              {/* <div className="bettle_btn">
              <a className="btn" href="#">
                SELECT
              </a>
            </div> */}
            </>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
    </div>
  );
};

export default WarHistoryCard;
