import React, { useContext, useEffect, useState } from "react";
import { GetCardFaction } from "../UserPage/BlockChainFunctions";
import useGameCentreContract from "./GameCentreHook";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { addressContext, providerContext } from "../../App";

async function getGeneralDetails(TokenId, gameCentreContract) {
  const Value = await GetCardFaction(TokenId, gameCentreContract);
  return Value;
}
const useBreedingListCardByFaction = (Faction) => {
  const [TokenId, setTokenId] = useState([]);
  const [loader, setLoader] = useState(true);
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_BREED_CARD_TABLE_NAME,   
    ProjectionExpression: "TokenId",
  });

  async function GetTokenId() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    const Id = [];
    try {
      const Response = await documentClient.scan(param).promise();
      // console.log(Response, param, "use breed card");
      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        const faction = await getGeneralDetails(
          element.TokenId,
          gameCentreContract
        );
        if (faction === Faction) {
          Id.push(element.TokenId);
        }
      }
    } catch (err) {
      // console.log(err);
    }
    return Id;
  }
  useEffect(() => {
    if (page === Math.ceil(TokenId.length / 10) || TokenId.length === 0) {
      setNext(false);
    }else{
      setNext(true)
    }
  }, [TokenId, page]);
  const Next = async () => {
    setNext(false);
    setPage((page) => page + 1);
    setNext(true);
  };
  const Previous = async () => {
    setPage((page) => page - 1);
  };
  useEffect(() => {
    if (
      gameCentreContract &&
      awsmobile.aws_cognito_region &&
      AWS.config.credentials
    ) {
      const TokenArr = async () => {
        const Id = await GetTokenId();
        setLoader(false);
        setTokenId((TokenId) => [...TokenId, ...Id]);
      };
      TokenArr();
    }
  }, [
    walletProvider,
    currentAddress,
    gameCentreContract,
    awsmobile.aws_cognito_region,
    AWS.config.credentials,
  ]);

  return [TokenId, page, setPage, next, Next, Previous, loader];
};

export default useBreedingListCardByFaction;
