import { Auth } from "aws-amplify";
import React, { useContext } from "react";
import { useState } from "react";
import { addressContext } from "../../../../../../App";
import useCustomerInfo from "../../../../../Hooks/useCustomerInfo";
import LoaderFloating from "../../../../../Shared/LoaderFloating";
import PopUp from "../../../../../Shared/PopUp";
import awsmobile from "../../../../../../aws-exports";
import * as AWS from "aws-sdk";

async function UpdateUsername(username, Id) {
  const documentClient = new AWS.DynamoDB.DocumentClient({
    region: awsmobile.aws_cognito_region,
    credentials: AWS.config.credentials,
  });
  const param = {
    TableName: process.env.REACT_APP_CUSTOMERINFO_TABLE_NAME,
    Key: {
      CustomerID: Id,
    },
    UpdateExpression: "set Username = :name",
    ExpressionAttributeValues: {
      ":name": username.toUpperCase(),
    },
  };
  try {
    // console.log(param, "param");
    const Response = await documentClient.update(param).promise();
    return Response;
  } catch (err) {
    // console.log(err);
  }
}
function ProfileForm() {
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [loading, setLoading] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const ChangeUsername = async (e) => {
    e.preventDefault();
    setLoading(true);
    const username = e.target.username.value;
    const confirmUsername = e.target.confirmUsername.value;

    if (username === confirmUsername) {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
        preferred_username: confirmUsername.toUpperCase(),
      });
      setLoading(false);
      if (result === "SUCCESS") {
        try {
          const result = await UpdateUsername(username, currentAddress);
          setPopUp(true);
          setPopUpSubject("Successful");
          setPopUpText("You've successfully updated your username");
          setNewUserName(username.toUpperCase());
        } catch (error) {
          setPopUp(true);
          setPopUpSubject("Something went wrong");
          // setPopUpText("You've successfully updated your username");
        }
      } else {
        setPopUp(true);
        setPopUpSubject("Something went wrong");
      }
    } else {
      setLoading(false);
      setPopUpSubject("Attention");
      setPopUpText("Username doesn't match");
      setPopUp(true);
    }
  };

  const ChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    const currentPassword = e.target.currentPassword.value;
    const newPassword = e.target.newPassword.value;
    const confirmNewPassword = e.target.confirmNewPassword.value;
    if (newPassword === confirmNewPassword) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const result = await Auth.changePassword(
          user,
          currentPassword,
          newPassword
        );
        setLoading(false);
        // console.log(result, "password");
        if (result === "SUCCESS") {
          setPopUp(true);
          setPopUpSubject("Successful");
          setPopUpText("You've successfully updated your password");
        } else {
          setPopUp(true);
          setPopUpSubject("Something went wrong");
        }
      } catch (err) {
        setLoading(false);
        setPopUp(true);
        setPopUpSubject("Oops");
        setPopUpText("Your current password is incorrect");
      }
    } else {
      setLoading(false);
      setPopUpSubject("Attention");
      setPopUpText("Password doesn't match");
      setPopUp(true);
    }
    // setLoading(false)
  };
  const DeleteUser = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    setLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      if (user) {
        try {
          const result = await Auth.deleteUser();
          // console.log(result);
          setLoading(false);
          setPopUp(true);
          setPopUpSubject("Deleted");
          setPopUpText("Your account have successfully deleted");
          try {
            AWS.config.update({
              credentials: "",
            });
            await Auth.signOut({ global: true });
            window.location.reload();
          } catch (error) {
            // console.log("error signing out:" + error);
          }
        } catch (error) {
          setLoading(false);
          // console.log("Error deleting user", error);
          setPopUpSubject("Something went wrong");
          setPopUp(true);
        }
      } else {
        setLoading(false);
        // console.log("Error deleting user", error);
        setPopUpSubject("Email or password is incorrect");
        setPopUp(true);
      }
    } catch (error) {
      setLoading(false);
      // console.log("Error deleting user", error);
      setPopUpSubject("Something went wrong");
      setPopUp(true);
    }
  };
  return (
    <div>
      <section className="profile_form">
        <div className="container text-light">
          <div className="row">
            <div className="col-md-8 offset-md-1">
              <div className="edit_from">
                <h2>CHANGE USERNAME</h2>
                <form onSubmit={ChangeUsername}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <p>
                          Current username : <br />
                          {newUserName
                            ? newUserName
                            : customerInfo?.Item?.Username}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <p>
                          User ID: <br />
                          {customerInfo?.Item?.CustomerID}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-md-6">
                      <div className="form-group mb-3">
                        <label>New username*</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-label="New username*"
                          name="username"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div className="form-group mb-3">
                        <label>Confirm username*</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Confirm username*"
                          name="confirmUsername"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="form-control btn btn-primary rounded submit px-3"
                    >
                      {loading ? "PLEASE WAIT" : "SAVE USERNAME"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-5 offset-md-1">
              <div className="edit_from mt-5">
                <h2>CHANGE PASSWORD</h2>
                <form onSubmit={ChangePassword}>
                  <div className="form-group mb-3">
                    <label>Current password*</label>
                    <input
                      type="password"
                      className="form-control"
                      aria-label="Current password*"
                      name="currentPassword"
                      required
                    />
                  </div>
                  {/* <div className="form-group d-md-flex forget_password">
                    <div className="text-md-left">
                      <a href="#">Forgot Password</a>
                    </div>
                  </div> */}

                  <div className="form-group mb-3">
                    <label>New password*</label>
                    <input
                      type="password"
                      className="form-control"
                      aria-label="New password"
                      name="newPassword"
                      required
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label>Confirm new password*</label>
                    <input
                      type="password"
                      className="form-control"
                      aria-label="password"
                      name="confirmNewPassword"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="form-control btn btn-primary rounded submit px-3"
                    >
                      {loading ? "PLEASE WAIT" : "SAVE PASSWORD"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 offset-md-1">
              <div className="edit_from mt-5">
                <h2>ACCOUNT DEACTIVATION</h2>
                <form onSubmit={DeleteUser} className="text-light">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          aria-label="user id*"
                          name="email"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div className="form-group mb-3">
                        <label>Password*</label>
                        <input
                          type="password*"
                          className="form-control"
                          aria-label="Password*"
                          name="password"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="form-control btn btn-primary rounded submit px-3"
                    >
                      DELETE ACCOUNT
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {popup && (
              <PopUp
                popUpSubject={popUpSubject}
                popUpText={popUpText}
                setPopUp={setPopUp}
              ></PopUp>
            )}
          </div>
        </div>
      </section>
      {loading && <LoaderFloating></LoaderFloating>}
    </div>
  );
}
export default ProfileForm;
