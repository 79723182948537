import React, { useEffect, useState } from "react";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../aws-exports";
import WeaponsChild from "./WeaponsChild";
import Loader from "../../../../../Shared/Loader/Loader";
import Pagination from "../../../../../Shared/Pagination";
function WeaponsText() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const [Weapons, setWeapons] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [loader, setLoader] = useState(true);

  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_WEAPONS_TABLE_NAME,
  });

  async function GetWeaponId() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    try {
      const Response = await documentClient.scan(param).promise();
      const result = Response.Items.sort((a, b) => {
        return a.Power - b.Power;
      });
      // console.log(result);
      return result;
    } catch (err) {
      // console.log(err);
    }
    // return result;
  }

  useEffect(() => {
    if (AWS) {
      const Token = async () => {
        const Id = await GetWeaponId();
        setLoader(false);
        setWeapons((TokenId) => [...Weapons, ...Id]);
      };
      Token();
    }
  }, [AWS]);

  useEffect(() => {
    if (Math.ceil(Weapons.length / 20) === page) {
      setNext(false);
    }
  }, [page, Weapons]);
  const Next = async () => {
    setNext(false);
    setPage((page) => page + 1);
    setNext(true);
  };
  const Previous = async () => {
    setPage((page) => page - 1);
  };
  return (
    <div>
      <div className="board_section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1">
              {loader ? (
                <Loader></Loader>
              ) : (
                <div className="weapons_tab">
                  <div className="tab-content" id="myTabContent">
                    <div className="weapons_text_section">
                      <h2 className="text-center">WEAPONS’ LIST</h2>
                    </div>
                    <div
                      className="tab-pane fade show active"
                      //   id="blacknet"
                      //   role="tabpanel"
                      //   aria-labelledby="blacknet-tab"
                    >
                      {/* <Blacknet /> */}

                      {Weapons.slice((page - 1) * 20, page * 20).map(
                        (weapon, i) => (
                          <WeaponsChild
                            weapon={weapon}
                            key={(page - 1) * 20 + i + 1}
                            i={(page - 1) * 20 + i}
                          ></WeaponsChild>
                        )
                      )}
                    </div>

                    {/* <div
                    className="tab-pane fade"
                    id="alice"
                    role="tabpanel"
                    aria-labelledby="alice-tab"
                    >
                    <Alice />
                    </div>
                    <div
                    className="tab-pane fade"
                    id="humanity"
                    role="tabpanel"
                    aria-labelledby="humanity-tab"
                    >
                    <Humanity />
                  </div> */}
                  </div>
                  <Pagination
                    page={page}
                    setPage={setPage}
                    length={Weapons.length / 20}
                    Previous={Previous}
                    Next={Next}
                    next={next}
                  ></Pagination>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WeaponsText;
