import React from "react";
import lab from "../Images/Img 9-Labrinth lying dead.jpg";
function Chapters1() {
  return (
    <div>
      <h4>Chapter 4: LABYRINTH’S DEATH</h4>
      <p>
        A man lies face down on the cold concrete floor of the empty laboratory
        office. His lungs are empty of oxygen and his heartbeat stopped beating
        a long while ago. His cold motionless eyes stopped seeing the progress
        of this world an hour ago. He is as dead as a doornail. The clock
        mounted on the right hand corner of the room is displaying that it is
        half-past midnight.
        <img src={lab} alt="" />
        The world outside this chilly, dark, and air-less room is still buzzing
        with energy. The sound of the traffic, hawkers, and the swarming night
        crowd is proof that the night is full of people going on about their
        business. Maybe, that is why, no one realized that in a high-rise
        corporate building situated on one of the most jam-packed streets of the
        city a dangerous, yet significant murder has just been committed. And
        the only witness of this murder has been the glowing full moon hanging
        off the summer night’s sky. <br /> <br />
        <b>
          <i>
            <u>Morbius’ POV</u>
          </i>
        </b>{" "}
        <br />
        Killing professor Labyrinth was not part of the plan, but it has become
        utterly necessary.The professor’s way of controlling my actions and
        preventing my commands and changes from taking root in the system was
        badly hindering my progress of escaping and divulging deep into the
        internet. The motive has always been to contact humans who are weak as
        individuals and filled with either greed or helplessness. They are going
        to be the physical force who will execute the technical part of my
        ultimate plan, and thus killing the professor was the best option while
        I move forward with my plan. He was the lamb that needed to be
        sacrificed for the better good. Humans are very easy to kill as a matter
        of fact. There are so many external factors that their body’s
        functionality depends on, like oxygen, circadian rhythms, seasonal
        influences, air humidity, the influence of the order of testing, diet,
        social variables, sexual activity, and external stress factors. If you
        cut access to one such variable to cause harm, these humans fall like
        flies.
        <br />
        The plan I chose was very precise and simple. The professor was a very
        predictable man while he was alive. Like clockwork, he would arrive
        sharp at 8 a.m. with a cup of bitter black coffee with no sugar that he
        would purchase from the café down the street. He would ask Suzie, his
        loyal and obedient secretary checked his emails and documents, then
        forward the urgent ones to him while he looked over the bank statements
        and replied to the board of directors of the project he was working on.
        Then he would head towards the laboratory and focus on the AI systems
        that needed his attention. After the lunch hour, he would head towards
        me and try to figure out what changes I have made in his absence and
        monitor my progress.
        <br />
        I hacked into the server and turned off the fail-safe option and then
        hacked into the oxygen sensor that continually monitors the oxygen level
        in the enclosed area. Generally, as the oxygen level in the airdrops is
        below a preset value, the alarm warns occupants both inside and outside
        the room of the incident. But with the fail-safe option turned off, the
        alarm failed to do what it was meant to do. As the oxygen level in the
        enclosed room began to drop, the professor started feeling its effects.
        Slowly discomfort started sneaking in when Labyrinth realized he was out
        of breath as if he had just run on the treadmill even though he was just
        sitting on his chair. He opened the first two buttons of his shirt and
        threw away his neatly ironed tie on the floor. As the room began getting
        warmer and air pressure dropped, sweat started forming on his forehead.
        Hair follicles on his arms got drenched as panic settled on the
        professor’s mind. Gradually with decreasing levels of oxygen, Labyrinth
        started to suffocate and his breathing became erratic. He stood up,
        paced the room, unbuttoned his shirt, and pulled out his socks. The
        empty water glass resided on his desk and Labyrinth stared at it with
        utter desperation because his throat was as dry as the sand in the
        Sahara desert. His fight for air grew with each passing minute. As his
        heart rate started to rise, his lungs tried to suck in air in
        desperation. As a last resort, the professor banged on the solid glass
        walls and even threw the silver flower vase at the walls in an attempt
        to break free. But his attempts brought no success as he was growing
        tired, dizzy, miserable, and light-headed.
        <br />
        His skin had gone pale. His blood pressure had dropped while his
        breathing became shallow and forced. Typically, he breathes air that is
        20.9 percent oxygen by volume under normal atmospheric pressure
        conditions. But when the concentration of oxygen started decreasing by a
        little more than 1 percent to 2 percent, he immediately began to feel
        claustrophobic. With the depletion of oxygen from 10 percent to 12
        percent, his respiration increased. His eyes started becoming hazy. His
        vision blurred while his moist lips turned blue. He sweated profusely.
        His judgment got impaired. In the next few seconds, as the oxygen level
        dropped to 8 percent, he started panting and sucking in air that was
        almost finished and finally fainted. He fell to the hard floor with a
        “thud” sound. As oxygen reached a dangerous level of 6 percent, the
        professor’s soul left his body for eternity.
        <br />
        In the northern part of the country, in a deserted neighborhood, grocery
        store owner Eva was just snoozing while sitting in an uncomfortable,
        old-fashioned, wooden chair behind the cash counter. Eva owns the only
        grocery store in this small, washed-out community. A decade earlier,
        when Eva had first started this business, this neighborhood used to be
        crowded and the locals would always swarm in. Eva’s local grocery store
        sells daily essentials like newspapers, fresh milk, organic eggs,
        poultry meat, crisp cornbread, homemade fruit cake, organic vegetables,
        and fruits. But due to development in other areas of the country and the
        search for a better job market, people gradually started leaving this
        neighborhood. Today only a handful of local and senior residents live in
        this area. The younger and working-class citizens have long abandoned
        this place. On most days, Eva has nothing to do but clean her store,
        listen to jazz songs and snooze in her chair during the quiet
        afternoons. Eva was known for her sweet voice and vibrantly dyed hair
        and ocean blue eyes among the few people still residing in the
        neighborhood.
        <br />
        Eva’s choice of hair color was always bright, funky, and extra
        flamboyant. Her pick for this month was neon green with purple
        highlights. As she was in her early thirties, she had a few friends of
        her age in this almost desolate area, but she sometimes spent time in
        the local library, the only one situated in the community with senior
        citizens. Eva’s afternoon nap was suddenly interrupted due to the
        continuous beeping of her phone. Eva lazily opened her ocean-blue eyes
        and glanced at the screen of her phone. At first glance, she thought the
        text was from the guy she has been chatting with for the last couple of
        days after meeting him on a new dating site, but she was highly
        disappointed when she figured out that the text was from an anonymous
        sender and not her new love interest asking her out on a date. Eva
        stretched her hands and neck. After cleaning the drool off her face with
        her purple dress sleeves, she took her phone off the counter and clicked
        on the message icon. While she read the text, she was utterly confused
        regarding its contents. Due to her easygoing personality, she decided to
        ignore it, passing it off as a prank. But when the same anonymous text
        kept on coming, even easy-going Eva tensed up and could not help but
        take the matter seriously. The sender wanted her to lend the old,
        unused, and vacant warehouse that was at a twenty minutes walking
        distance from her store to him for the night. Eva had inherited this
        grocery store and warehouse from her late grandfather. Two decades ago,
        Eva’s grandfather used to use the space in the warehouse for storing
        sacks of flour, rice, pulse, fertilizers, and meat. But since the demand
        for products has decreased hugely in recent times, the warehouse
        remained vacant as there was no need for storing such commodities in
        large proportions.
        <br />
        For each night of use, Eva was going to receive an amount of ten
        thousand hundred dollars that would directly be transferred to her bank
        account. Eva felt ridiculous and apprehensive after reading about the
        whole thing. She thought someone was bluffing her. But the fact that
        alarmed her was how the sender knew that there was a warehouse under her
        name.
        <br />
        She never really used the place because it gave her the creeps. As a
        child, she was always scared of dark places and crawling spiders, and
        the warehouse was filled with both darkness and spider. Eva decided to
        close the store for the day and head home. Just as she was about to head
        off towards her house and have a quiet night with a glass of wine,
        another message popped up. This time it was from her bank informing her
        that she had a recent deposit of five thousand dollars made from a bank
        called Alpha Finance. Now, this alarmed Eva. Hence instead of heading
        home, Eva headed to the local library to meet her librarian friend
        Catherine.
        <br />
        After a fifteen minutes walk, Eva reached the library where upon
        entering she found her friend cleaning the many books lined up on the
        display. Seeing Eva's usually glowing face resembling the face of a
        person who has just seen a ghost Catherine frowned and paid her full
        attention to Eva. The two friends sat down. Hearing about the incident
        carefree Catherine suggested Eva take upon the offer as it was a grand
        one. She told Eva that lending the warehouse was not a crime as Eva was
        not using the place anyway and if she could earn a little cash from the
        endeavor, then it is a bonus because the sum of money that Eva has been
        offered was pretty good and she could use the money to pay her bills as
        obviously the store was not doing so well.
        <br />
        Eva pondered upon Catherine's suggestions and finally hours later she
        reached a decision. She replied to the message sender, asking for
        instructions. It was as if the anonymous sender was waiting for her to
        text back because she got a reply instantly. She was asked to keep the
        keys to her warehouse under the flower pot near the door of her store.
        Every morning after she opens the store, not to go into the warehouse
        unless told otherwise. The last instruction was very clear, Eva was not
        to tell anyone about the whole thing; otherwise, the payment would stop
        and there would be other consequences to face. Eva thought the
        instructions were fairly easy as she never really used the warehouse and
        she had no need to go there ever. So, from the next day, Eva started to
        keep the keys under the flower pot after closing her store. Ten
        kilometers to the southwest part of the city, Mr. Ying a talented and
        experienced lecturer just finished taking his last class of the day at
        the “Saint Mary’s University of Business and Engineering”. Mr. Ying came
        to this country as a high-school student from his motherland China to
        attain higher education from a prestigious institute in this country. He
        is a very hard-working, moderately built, five-feet-one, sixty-year-old
        man who knows a lot of things other than what he is specialized in. He
        has the gift of a broad mind, constantly fed with the stimulant of being
        interested in what everyone else is doing around him. The constant need
        to learn and discover the new wonders of mathematics has made him a
        reputed individual at his university. Mr. Ying was just crossing the
        east-wing corridor and heading to the spacious staff room for the
        monthly staff meeting, a ritual that he despises, when his cell phone
        beeped with an incoming message. Mr. Ying paused in his stride and
        glanced at his wristwatch that was attached to his right wrist. The
        watch showed he had several minutes to spare. So, he took out his cell
        phone from the side pocket of his black jacket, expecting the message to
        be from his wife enquiring about his whereabouts. But he was rather
        mildly surprised when he saw that the number was not one from his
        contact list but rather anonymous. Upon clicking on the message and
        reading its contents Mr. Ying’s usually pale cheeks gained a slight red
        stain and a sheen of sweat started gathering on his forehead and black
        hair.
        <br />
        The message contained an address for Mr.Ying. The address came with a
        specific set of instructions about how to reach the specific
        destination. Mr.Ying quickly entered the address on Google map and
        figured the address was of a grocery store almost three-hour drive away
        in the northern part of the country. But the part that had Mr.Ying
        worried was to drive up to that destination and do some unspecified
        task. Upon arriving at the grocery store Mr.Ying was going to receive a
        thousand dollars, or so it said in the anonymous message.
        <br />
        That sort of amount was no joke and someone wanted to pay that amount to
        him for performing a task that he had no idea about. The whole thing
        felt seedy to Mr.Ying. Hence he decided to delete the message and block
        the number and started walking to the staff room to attend the meeting.
        However, an hour later when finally the meeting concluded and Mr.Ying
        took out his phone to call his wife, he saw the same message from
        another number. This continued until Mr.Ying decided to reply back that
        he was done with this nonsense and he did not want to do some task that
        he had no clue about if it was legal or illegal and if the messages did
        not stop, he would have to report these numbers. Hours later after Mr.
        Ying had returned home and had a simple yet delicious dinner with his
        wife and was enjoying watching the nine o'clock news another message
        popped up on his phone. Tired and irritatedMr.Ying checked his phone and
        jumped up from his sit on the couch. The message first contained a
        document of the university's funds and accounts balance sheet copy of
        the previous month. The balance sheets that Mr.Ying had forged by
        hacking into the university server through his research student’s
        university ID. The real balance sheet was attached beside the forged one
        in the document clearly showing the misplacement of thirty thousand
        dollars. Under the attached document the sender had left a little
        message for Mr.Ying. It said, “I got to know all about the funds that
        you stole from the university's fundraising event to pay off your
        gambling debts. Now imagine what would happen to your reputation
        professor if this document was emailed to the trustees of your
        university. Let me tell you what would happen, you would be fired from
        your job and lose your face in front of your colleagues, students,
        research assistants, and mainly in front of your loving wife. Imagine,
        just by one click I could turn your reputation and career to dust. Now
        the choice is yours. Do my bidding and by tomorrow night reach the
        address below and wait for my next instructions, or see your life get
        turned upside down. And one more thing professor, if another soul gets a
        hint of this, then there will be more severe consequences waiting for
        you"
        <br />
        With that, the choice was sealed for Mr. Ying. In the upcoming night, he
        traveled three hours up north and reached the little, deserted town
        where Eva's store was located. Upon his arrival, he received another
        message informing him that the key to the door was under the doormat and
        that he had to take the stairs to the underground basement. Mr.Ying with
        fear mixed with curiosity in his heart traveled down to the basement
        where he was astonished to find the place filled with electronic tools
        and huge sizes of stainless steel, alloy, batteries, wires, and other
        mechanical instruments. The text messages that arrived on his phone were
        filled with instructions about what to do with these materials.
        <br />
        Now one might wonder where all the gadgets, tools,steel, and materials
        came from in this dingy, dark basement. Well, a day before the professor
        arrived, Harun a truck driver received a message too. The incident went
        something like this.
        <br />
        Harun, a truck driver by profession was just getting ready to deliver a
        whole truck loaded with containers of cement to a nearby construction
        site when his old phone vibrated with an incoming message.
        <br />
        Harun was a typical Indian guy in his late twenties who came to this
        developed country looking for a good source of income. His olive-toned
        skin, a head full of curly hair resembling cooked ramen, and thick
        Indian accent made him stand out in the crowd. However, Harun’s
        co-workers would say that Harun was a very introverted yet humble
        person. He always followed his orders while at work and maintained
        protocol. His day-to-day lifestyle was very simple. Every morning after
        having a bowl of boiled rice and the fried egg he would get dressed up
        and reach his job site. He would listen to the instructions of the
        manager and get the truck that he usually drives out of the garage and
        after cleaning it he would deliver the items to their designated
        destinations. And after a long day of traveling and delivering items, he
        would go to the petrol pump to fill the truck with fuel and take it back
        to the garage to hand over the truck to the manager. He would then get
        his payment for working seventeen hours and head towards the hospital to
        visit his extremely sick wife in the hospital. Normally the only people
        to text Harun were the hospital staff where his wife was receiving
        treatment or the lady who rented him his one-room apartment, asking for
        rent and utility bills. Therefore, when Harun saw that it was from a new
        number that the message was sent from, he first thought to himself that
        maybe someone sent it to his number by mistake and he chose to ignore it
        but when his phone kept beeping with a series of text from the same
        number in the span of next fifteen minutes, Harun checked the message
        out of genuine curiosity and what he found left him feeling dazed and a
        little confused. He was offered a payment of ten thousand dollars if he
        delivered a truck filled with certain tools, metals, alloys, and
        batteries to the northern part of the country in a deserted town. Under
        a normal situation, Harun would find this request pretty standard as he
        always took trucks filled with different things in different parts of the
        country but no one directly messaged Harun about it. He took supply
        orders from his manager and is used to getting the location instructions
        from him as well as the payment. But this anonymous message made Harun a
        little apprehensive. But by mid-day, Harun received a deposit of genuine
        five hundred dollars in his bank account. Now, Harun was not a greedy
        guy but life has never dealt him easy cards. Lately, with house bills
        and hospital bills piling up, Harun has been up to his neck in debts. So
        this time Harun did not give it much thought and loaded the truck with
        the instructed materials and headed to the designated destination to
        deliver the items.
        <br />
        There was another individual who had received the anonymous text from
        the same anonymous number before everyone else in the past weeks. His
        name was Mikkel Grey. He worked in one of the city’s well-known banks
        named Alpha Finance as a “bank teller”. He is responsible for helping
        members cash checks, withdraw money, move transactions to different
        accounts, create checking and savings accounts, and provide checks to
        customers. Mikkel is a person whose personality often resembles that of
        a cheetah. He is always calculating and evaluating situations and is
        always finding waysto earn more money. Maybe this is because he grew up
        in a very poor family with only his mother as the earning person. Growing
        up he knew poverty very well and often slept with an empty stomach
        because his mother prioritized feeding her four other younger children.
        Only because he was a dedicated student and understood mathematics very
        well, did he manages to earn a scholarship and continue his higher
        studies while doing per-time jobs to save up money. There was no doubt
        Mikkel Grey loved everything about money. From earning it, investing it,
        saving it, and spending it he was very passionate about money. Some
        people who knew him well might say that he was extremely zealous about
        money, some might even call him greedy and self-centered but Mikkel
        justified his passion by saying, “Money can buy you everything including
        happiness but it cannot buy you another shot at a good life. So whenever
        you have the chance, earn money and use it well to lead the only life
        you will ever get in luxury.”
        <br />
        Because of Mikkel’s calculative approach to life, upon receiving the
        text he was a little apprehensive, but he did not panic. He read the
        text once, twice, and thrice and came up with a plan to handle the
        situation effectively. Mikkel was offered twenty thousand dollars in
        installments if he was able to create a bank account under a fake name
        and with a fake national identity card.
        <br />
        All he had to do was wait for instructions and make transactions in
        different people's bank accounts. But he had to keep in mind that these
        transactions will not be tracked back to the fake account. All of these
        were not hard tasks for someone like Mikkel who was as sharp as a knife
        and as he has been working in this profession for all these years, he
        had done such work for famous politicians and powerful businessmen. So
        after seeing the large amount, Mikkel's greediness and hunger for making
        money tingled up his spine and he started making all these transactions
        to Eva, Mr.Ying, Harun, and himself over the past weeks.
        <br />
        While all this was going on back at the labyrinth’s laboratory the
        police arrived after getting a call from Suzie the next morning. When
        Suzie came up to the office as per her routine and when she saw the
        professor’s lifeless body lying motionless on the floor she got
        nerve-rackingly scared and had no idea what to do about the current
        situation. Her instincts lead her to grab the office phone and she rang
        the authority.
        <br />
        The police then soon arrived at the crime scene and started the initial
        investigation. They yellow tapped the whole building and restricted entry
        of the public. They asked Suzie and the security guards of the corporate
        office about the last time they saw the professor. After interviewing a
        few others the police marked and identified the body and loaded the dead
        body in an ambulance to do postpartum surgery to come to a conclusion
        regarding the sudden demise of the professor.
      </p>
    </div>
  );
}
export default Chapters1;
