import React from "react";
function MoreText() {
  return (
    <div>
      <section className="more_section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="more_text_section">
                <h2>MORE ABOUT EXTREMIS</h2>
                <p>
                  Tesseract is an incorporated company that strives to be the
                  leading innovator of sustainable fashion. We bring you
                  products made from fabric mixtures that provide maximum
                  comfort and are environment friendly. Innovative design is
                  what we thrive for.
                </p>
                <p>
                  We are one of the first-ever clothing companies completely
                  dedicated to creating a sustainable fashion economy. We here
                  at Tesseract believe that the most sustainable way is not to
                  just make things, the most sustainable way is to make
                  something that is very useful, to solve a problem that hasn’t
                  been solved. For which through our R&D department we plan to
                  contribute towards the fight against climate change, we plan
                  to use materials that would be 100% carbon neutral as soon as
                  it's economically viable.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default MoreText;
