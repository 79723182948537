import React from 'react';
import { BrowserRouter as Router,Routes, Route, a,  } from 'react-router-dom';
function WorkWith () {
	return <div>
<section className="work_section">
         <div className="container">
            <div className="row">
               
               <div className="col-md-10 offset-md-1">
                  <div className="work_text_box text-center">
                     <p>EXTREMIS enthuses players to strategize and form tactical alliances with others. Discover allies and make strategic partnerships through the EXTREMIS community platforms on Discord, Twitter, Facebook, Instagram and Youtube. Follow our pages to get story updates and game tips. Keep updated on your fellow EXTREMIS players by connecting with them through our communities.
                     </p>
                     <ul>
                   <li><a href="https://twitter.com/ExtremisVerse" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                   {/* <li><a href=""><i className="fab fa-youtube"></i></a></li> */}
                   <li><a href="https://www.facebook.com/extremisverse/"  target="_blank" rel="noreferrer"><i className="fab fa-facebook"></i></a></li>
                   
                   <li><a  href="https://t.me/+ZKLv8kw7lB4zN2Nh" target="_blank" rel="noreferrer"><i className="fab fa-telegram"></i></a></li>
                   <li><a href="https://discord.gg/kV4YeYQWyB"  target="_blank" rel="noreferrer"><i className="fab fa-discord"></i></a></li>
                   <li><a href="https://www.instagram.com/extremisverse/"  target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                     {/* <a className="work_byn btn"to="/">WORK WITH US</a> */}
                  </div>
               </div>
              
            </div>
         </div>
      </section>
    </div>
    }
    export default WorkWith;