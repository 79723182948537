import React from "react";
import "./Styled.css";
function BasicButton(props) {
  return (
    <button onClick={props.onClick} className="main-button">
      {props.children}
    </button>
  );
}
export default BasicButton;
