import React, { useState } from "react";
import {useNavigate,Outlet} from 'react-router-dom'
import "./PromoCode.css";
import PromoCodeBanner from "./Sections/PromoCodeBanner/PromoCodeBanner";
const PromoCode = () => {
  const navigate = useNavigate();
  
  return (
    <div className="promoCode">
     <PromoCodeBanner></PromoCodeBanner>
   
     

      <Outlet></Outlet>
    </div>
  );
};

export default PromoCode;
