import React from "react";
import { Link } from "react-router-dom";
function BannerText() {
  return (
    <div>
      <section className="about_section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="play_text_box text-center">
                <p>
                  A doctor, blinded by his ambition to create a conscious being,
                  gives rise to a superpowerful sentinel threatening all of humanity. As
                  war rages on between man and the conscious machine, humans
                  face uncertainty when their beloved earth is free from these machines.
                  Users can choose to collect only one specific faction card or all faction cards. The podium stands
                  tall to embrace one top winner on our leaderboard. Who will that be?
                </p>

                <p>Welcome, to the war-torn world of EXTREMIS.</p>
                <Link className="play_byn btn" to="/play">
                  PLAY NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default BannerText;
