import React from 'react';
import '../SellCollection -auction/CardCollection.css';
import AuctionSellBlacknetList from './SubPages/Blacknet/Sections/AuctionSellBlacknetList';
import AuctionSellBlacknetText from './SubPages/Blacknet/Sections/AuctionSellBlacknetText';
function AuctionSellBlacknetCard () {
	return <div>
		{/* <Blacknetbanner/> */}
		<AuctionSellBlacknetList></AuctionSellBlacknetList>
		<AuctionSellBlacknetText></AuctionSellBlacknetText>

        
	</div>
}
export default AuctionSellBlacknetCard;