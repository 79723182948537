import React from 'react';
import OthersConfirmationBanner from '../OthersConfirmation/Section/OthersConfirmationBanner';
import OthersConfirmationTaxt from '../OthersConfirmation/Section/OthersConfirmationTaxt';
import '../BreedOthers/BreedOthers.css';
function OthersConfirmation(){
 return <div>
<OthersConfirmationBanner/>
<OthersConfirmationTaxt/>

</div>


}
export default OthersConfirmation;