import React, { useEffect, useState } from "react";
import useGetAllCardByType from "../../../../../../../Hooks/useGetAllCardByType";
import Loader from "../../../../../../../Shared/Loader/Loader";
import Pagination from "../../../../../../../Shared/Pagination";
import PRMCard from "../../../../../../../Shared/PRMCard/PRMCard";
function TransferGenesisText() {
  const [TokenId,page,setPage,next,Next,Previous,loader]=useGetAllCardByType("Genesis")
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
          {
              loader?
              
              <Loader></Loader>:       <div className="content_box">
              <h4>GENESIS</h4>

              <div className="row">
                {TokenId?.map((TokenId) => (
                  <PRMCard TokenId={TokenId} key={TokenId}>transfer</PRMCard>
                ))}
                 {
                  TokenId?.length===0 && <h1>NO CARD</h1>
                }
              </div>
              <Pagination
                page={page}
                setPage={setPage}
                length={TokenId.length / 10}
                Previous={Previous}
                Next={Next}
                next={next}
              ></Pagination>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
export default TransferGenesisText;
