import React from "react";
import "../../../CardCollection.css";
import { Link, Outlet } from "react-router-dom";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../aws-exports";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "../../../../../Shared/Pagination";
import MarketPlaceCard from "../../../../../Shared/MarketPlaceCard/MarketPlaceCard";
import { GetCardFaction, GetCardType } from "../../../../../UserPage/BlockChainFunctions";
import useGameCentreContract from "../../../../../Hooks/GameCentreHook";
import Loader from "../../../../../Shared/Loader/Loader";
async function getGeneralDetails(TokenId,gameCentreContract) {
	const Value = await GetCardFaction(TokenId,gameCentreContract);
  // console.log(Value,"value");
	return Value;
  }


function MarketPlaceHumanityText () { 
  const [loader,setLoader]=useState(true)

	const [TokenId, setTokenId] = useState([]);  
	const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [TokenArray, setTokenArray] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_PURCHASE_TABLE_NAME,
    Limit: 30,
   
    ProjectionExpression: "TokenId",
  });

  async function GetTokenId() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    // console.log(param);
    const Id = [];
    try {
      const Response = await documentClient.scan(param).promise();
      if (Response.LastEvaluatedKey) {
        setParam({
          TableName: process.env.REACT_APP_PURCHASE_TABLE_NAME,
          Limit: 30,
         
          ExclusiveStartKey: Response.LastEvaluatedKey,
          ProjectionExpression: "TokenId",
        });
      }
	  // console.log( Response.Items,"humanity");
      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
		// console.log(element.TokenId,"humanity token");
        const faction = await getGeneralDetails(element.TokenId,gameCentreContract);
        // console.log(faction);
        // console.log(TokenId,"tokenTesting");
        if (TokenId.indexOf(element.TokenId) === -1)  {
          if (faction === "Humanity")  {
            Id.push(element.TokenId);
            setNext(true);
          } 
          }else {
            setNext(false);
        }
      }
    } catch (err) {
      // console.log(err);
    }
    return Id;
  }

  const Next = async () => {
    setLoader(true)
    if (page === TokenArray.length) {
      const Id = await GetTokenId();
      if (Id.length === 0) {
        setNext(false);
        setLoader(false)
      } else {
        setNext(true);
        setTokenId((TokenId) => [...TokenId, ...Id]);
        setTokenArray((TokenArray) => [...TokenArray, Id]);
        setPage((page) => page + 1);
        setLoader(false)
      }
    } else {
      setPage((page) => page + 1);
      setLoader(false)
    }
  };
  const Previous = () => {
    setNext(true);
    setPage((page) => page - 1);
  };
  useEffect(() => {
    if (gameCentreContract) {
    const TokenArr = async () => {
        const Id = await GetTokenId();
      setTokenId((TokenId) => [...TokenId, ...Id]);
      setLoader(false)
      setTokenArray((TokenArray) => [...TokenArray, Id]);
    };
    TokenArr();
  }
  }, [gameCentreContract]);
	return (
		<div>
		  <div className="container">
			<div className="row">
			  <div className="col-md-10 offset-md-1">
			  {
              loader?
              <Loader></Loader>: 	<div className="content_box">
				  <h4>HUMANITY</h4>
	
				  <div className="row">
					{TokenArray[page - 1]?.map((TokenId) => (
					  <MarketPlaceCard TokenId={TokenId} key={TokenId}></MarketPlaceCard>
					))}
          {
                  TokenArray[page - 1]?.length===0 && <h1>NO CARD</h1>
                }
				  </div>
				  <Pagination
					page={page}
					setPage={setPage}
					length={TokenArray.length}
					Previous={Previous}
					Next={Next}
					next={next}
				  ></Pagination>
				</div>}
			  </div>
			</div>
		  </div>
		</div>
	  );
}
export default MarketPlaceHumanityText;