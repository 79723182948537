import React from "react";
function TesseractText() {
  return (
    <div>
      <section className="tesserac_text_section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="tesserac_text_box text-center">
                <h2>ABOUT TESSERACT INC.</h2>
                <p>
                  Tesseract Clothings is an incorporated company that strives to be the
                  leading innovator of sustainable fashion. We bring you
                  products made from fabric mixtures that provide maximum
                  comfort and are environment friendly. Innovative design is
                  what we thrive for.
                </p>
                <p>
                  We are one of the first-ever clothing companies completely
                  dedicated to creating a sustainable fashion economy. We here
                  at Tesseract believe that the most sustainable way is not to
                  just make things, the most sustainable way is to make
                  something that is very useful, to solve a problem that hasn’t
                  been solved. For which through our R&D department we plan to
                  contribute towards the fight against climate change, we plan
                  to use materials that would be 100% carbon neutral as soon as
                  it's economically viable.
                </p>
                <p>
                  Tesseract Gamings is a part of Tesseract group. 
                  Tesseract Gamings is responsible for launching a peer-to-peer play-to-earn NFT game called Extremis.
                  Tesseract Gamings aims to be one of the leading entities in the future of  web3 gaming. We plan on utilizing cutting-edge tech to develop our ultimate Metaverse, A 3D game built on top of various blockchains.
                  We plan to make Extremis a cross-chain game starting from our Beta version. Please check our Extremis roadmap for more detail.

                </p>
                <p>
                  Tesseract Electrics is an upcoming venture of the tesseract group. Tesseract Electrics plans to focus on R&D of a wireless power transfer system and thorium reactors.
                </p>
                <a
                  className="tesserac btn"
                  href="https://tesseractclothings.com/"
                >
                  Visit Tesseract Clothings Ltd.
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default TesseractText;
