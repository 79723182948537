import React from "react";
import "./Styled.css";

const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <span className="loader"></span>;
    </div>
  );
};

export default LoadingSpinner;
