import React from "react";
import "./Styled.css";

const Modal = (props) => {
  return (
    <div>
      <div className="modalRes">
        <div className="modalRes-content">
          <span onClick={props.closeModal} className="close-modalRes">
            &times;
          </span>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
