import { useState } from "react";
import React from "react";
import BasicButton from "./BasicButton";
import Form from "./Form";
import InputForm from "./InputForm";
import TitleForm from "./TitleForm";

function FormTowInput(props) {
  const [firstInput, setFirstInput] = useState("");
  const [secondInput, setSecondInput] = useState("");

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        setSecondInput("");
        setFirstInput("");
        props.onSubmit({ firstInput, secondInput });
      }}
    >
      <TitleForm text={props.textInfo}>{props.formTitle}</TitleForm>
      <div className="input-content-start">
        <InputForm
          value={firstInput}
          onChange={(event) => {
            setFirstInput(event);
          }}
          label={props.firstLabel}
        />
        <InputForm
          value={secondInput}
          onChange={(event) => {
            setSecondInput(event);
          }}
          label={props.secondLabel}
        />
      </div>
      <BasicButton>Transact</BasicButton>
    </Form>
  );
}

export default FormTowInput;
