import React from "react";
function ContactBanner() {
  return (
    <div>
      <div className="Page_contact_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page_title">
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactBanner;
