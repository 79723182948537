import React from 'react';
// import Blacknetbanner from '../Cardcollection/SubPages/Blacknet/Sections/Blacknetbanner';
import BlacknetText from './SubPages/Blacknet/Sections/PromoteBlacknetText';
import BlacknetList from './SubPages/Blacknet/Sections/PromoteBlacknetList';
import '../PromoteCollection/CardCollection.css';
import PromoteBlacknetList from './SubPages/Blacknet/Sections/PromoteBlacknetList';
import PromoteBlacknetText from './SubPages/Blacknet/Sections/PromoteBlacknetText';

function PromoteBlacknetCard () {
	return <div>
		{/* <Blacknetbanner/> */}
		<PromoteBlacknetList/>
		<PromoteBlacknetText/>

        
	</div>
}
export default PromoteBlacknetCard;