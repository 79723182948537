import React from 'react';
import Humanitybanner from '../Humanity/Sections/Humanitybanner';
import HumanityText from '../Humanity/Sections/HumanityText';
import '../Humanity/Humanity.css';
function Humanity () {
	return <div>
		<Humanitybanner/>
		<HumanityText/>
       
        
	</div>
}
export default Humanity;