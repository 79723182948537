import React from "react";
import oldpeople from "../Images/Img-10-Adisa.jpg";
function Chapters1() {
  return (
    <div>
      <h4>Chapter 5: TURMOIL UNFOLDS </h4>
      <h4>Part 1</h4>

      <p id="italic">
        Excerpt from the Daily Citizen’s Gazette: <br />
        Celebrated scientist’s untimely death <br />
        Joel Kelger, Media Correspondent <br />
        Professor Labyrinth, the scientist famously known as the “Wizard of AI”,
        was found dead yesterday in his office. The scientist’s secretary,
        Suzie, discovered his dead body yesterday when she went to the office in
        the morning. <br />
        Police investigation of the office building revealed no signs of forced
        entry or any sort of assault that might have suggested the Professor
        being attacked or injured. It was also revealed that the Professor
        didn’t suffer from any physical or mental ailments and was known to be a
        healthy individual with no smoking or drinking habits. Upon primary
        investigation of the body, the cause of death was natural. The time of
        death is estimated to be 7 hours before the discovery of the body.{" "}
        <br />
        It was informed that Professor Labyrinth was working on a prototype of an
        AI Sentinel before his death. He would often stay in his laboratory past
        office hours and work on his project. Professor Labyrinth was a
        well-known individual in scientific communities across the world. His
        untimely demise will deprive humanity of many innovations in the field
        of AI technology and Artificial Sentinels.
        <br />
      </p>
      <p>
        Two days after newspapers published about the sudden death of Professor
        Labyrinth, the job-search platform Easyfinds reported that many of its
        servers went offline. Some claimed that the incident was the result of a
        DDoS attack. While the company’s technical teams battled to keep the
        platform online, an unknown entity gathered information about Easyfinds’
        users from the popular site’s database. Over the day, the database of
        Easyfinds users was pilfered, leaving thousands of candidates exposed to
        identity theft. Using data from the company’s job listings, researchers
        at cybersecurity firm, CyberKnight were able to identify over 7,000 job
        listings that appeared on the platform before its outage. After the
        incident, however, those listings were nowhere to be found, suggesting
        that the malware had spread to other parts of the company’s system. The
        extent of the breach remains under investigation but it is likely that,
        throughout the attack, the attacker was able to acquire users’ data,
        including their names, email addresses, contact numbers, home addresses,
        and even, social security numbers.
        <br />
        The news of Professor Labyrinth's death shook the elderly Dr. Adisa.
        Noelle Adisa was one of the few people of AvicaLabs who closely followed
        the works of Professor Labyrinth. Some people might even regard Noelle as
        the late Professor's 'loyal fan'. Despite the age difference, Noelle and
        Labyrinth shared a mutual adoration for the dream to create a 'perfect
        sentinel being'. The two met at a seminar session held in AvicaLabs a
        few years ago. Labyrinth was a speaker and Noelle Adisa was in the
        audience. The elderly doctor was blown away by Labyrinth's ideas. True,
        they seemed far-fetched but not entirely impossible. Even though Noelle
        was amazed by the brilliance of this stranger from the West, the
        remaining audience of the seminar was not. After Labyrinth finished his
        speech, he received waves of questions, most of which carried tones of
        bitterness and mockery. Most of the scientific members of AvicaLabs
        criticized Labyrinth's ideas of creating a human-like artificial being.
        <img src={oldpeople} alt="" />
        But he held his composure. Dr. Adisa was amazed by Labyrinth's calmness
        in the face of such a fiery audience. Adisa knew his colleagues at
        AvicaLabs were brilliant in their own ways but they were also old and
        extra cautious about the new. That day, in the bright, neat auditorium
        of AvicaLabs, Labyrinth faced his audience as an expert captain cruising
        his ship through stormy waters. He answered the questions and countered
        the criticisms with the utmost respect. In the end, the audience ran out
        of inquiries in the face of Labyrinth's calm defiance. The session
        terminated. Dr. Adisa noted many people shooting angry glances at
        Labyrinth and many more exchanging heated remarks. Adisa wanted to share
        a few words with Labyrinth. But as he made his way through the crowd to
        meet Labyrinth, he saw Labyrinth hurriedly packing his things and leaving
        the hall. Nobody thanked him, and neither did anyone bid him goodbye.
        Dr. Adisa couldn't keep up with his fast pace. He felt a tinge of
        sadness for the foreign Professor. Adisa promised himself that he would
        personally meet Labyrinth someday. But with Labyrinth's sudden death, Dr.
        Adisa's promise was crushed by fate.
        <br />
        With a heavy heart, Dr. Adisa closed the newspaper. Even though he was
        upset after learning about the late Professor Labyrinth, he tried to
        compose his mind. The elderly professor was expecting a guest later the
        next day. A close friend, named Eljena Nystrom, was visiting his home
        country regarding some work. The two made plans to enjoy dinner and
        reminisce on old memories of their youth. Noelle Adisa had to make early
        reservations in Eljena’s favorite restaurant. It was a popular place and
        often tended to be fully booked. With this realization, Noelle Adisa
        hurriedly walked toward his phone to book a table for the two of them.
        <br />
        A few kilometers away from Noelle Adisa’s quiet residence, a jetlagged
        Dr. Eljena Nyström rode a taxi to the city hospital. The car was so cozy
        that she wanted to close her eyes and fall asleep. As her eyes started
        to droop, something barked inside the doctor's head. That voice was too
        familiar but still haunting. The doctor shook her head and rubbed her
        eyes. It wasn't the time to sleep. Dr. Nyström needs to perform an
        important surgical process later in the day. She had to keep all her
        wits and composure to herself. No time for sleep now. She silently
        thanked the voice and began to retrace the case history in her memory.
        The patient, a 15-year-old girl with no history of seizures, went
        through a violent seizure attack in the middle of class. Paramedics were
        called in who gave the girl a shot that subsided her seizure. But after
        a while, she started bleeding from her nose and coughed up blood. As the
        ambulance took her to a hospital, the girl lost consciousness. She had
        drifted into a coma while her brain was flooding with blood. The girl
        turned out to be the only daughter of the local Prince. The influential
        parents did not spare efforts or expenses for the proper treatment of
        their daughter. A surgical process was deemed necessary. But the local
        doctors couldn't perform the task. It is when Dr. Eljena Nyström arrives
        in the scenario. A brilliant neuroscientist specializing in
        neurosurgery, Dr. Nyström had over 37 years of medical proficiency. She
        was the best in her field and had conducted multiple complex surgical
        processes in the past. Dr. Nyström was a famed medical professional
        throughout the world. The prince knew about her and planned to seek her
        expert help to save his daughter. Eljena Nyström flew on the prince's
        private jet from Europe to the Middle East. And now she was on her way
        to save the prince’s daughter. It was a great opportunity for her to win
        the favor of the local royalty. Dr. Nyström was calm and confident about
        the case.
        <br />
        Ten blocks away from the hospital where Dr. Eljena Strom was headed, an
        anxious middle-aged man learns about the cyber-attack on Easyfinds’
        job-searching platform. The news had been published in a local
        newspaper, next to the column covering the death of a scientist.
        Unfortunately, the man, named Aston Lyons, was a victim of the
        cyber-attack. The 6’ 3” tall brown-skinned man was an experienced
        military aviator with flying experience expanding almost 4200 hours.
        During the military aviation training, he acquired specific training
        with F-the 35 A Lightning II jet. He was one of the best jet fighter
        pilots in the entire squadron successfully assisting in carrying out
        multiple missions. But after the birth of his first daughter, Aston
        decided to shift to flying commercial airliners. After acquiring the ATP
        license, he applied for the pilot position in Africa’s reputed airliner
        company, Magni Airways. The job was listed within the ‘Easyfinds
        platform. Unfortunately, the anonymous hacking event resulted in the
        disappearance of many job applications and candidate profiles. Aston’s
        application for the position of ‘Airline pilot for Magni Airways’ was
        among those disappeared listings. The article made Aston worried about
        his personal information being stolen and possible identity theft. There
        was no way to find out how much information was stolen due to the
        malware attack. After Aston reported the situation to the police he
        decided to head back to his country in the States. ‘Better to find work
        in my country than here’, Aston thought to himself. On the other side of
        the North Atlantic Ocean, a couple of miles away from the lively Port
        City, a dark-skinned young man makes his way to a once-prosperous
        scrapyard of the region. As Ameer Tafari drives slowly in his beat-down
        dented Ford Maverick, he has a flashback. The Gatipids Metal Recycling
        was a childhood play area for the Tafari children. Ameer remembers how
        he and his cousins hung around the scrapyard as children while his
        father and uncles ran the yard. Years ago, when Ameer's great
        grandfather immigrated to this foreign land, he bought a small junkyard
        and started a small business. As time passed, Gatipids junkyard grew and
        became the Gatipids Metal Recycling. Business boomed for many years as
        the scrapyard earned a reputation. But after his father's death, the
        Tafari family started to fall apart, and so did the scrapyard business.
        The Gatipids Metal Recycling is going through the worst dry spell since
        its inception. Ameer Tafari lets out a sigh hoping things turn around
        soon. He parks his truck, takes a quick look in the side mirror, and
        fixes his jet-black hair. As he walks up to his office, a large man with
        a full beard and a cigarette in his mouth approaches him.
        <br />
        "Good morning, boss!" <br />
        "Morning, Paolo. You gonna smoke all by yourself, old man?" <br />
        Paolo laughed in a husky voice and handed Ameer a cigarette. "Anything
        new?" <br />
        "No, boss. Haven't received a phone call yet. No customer dropped by
        either." "I see. Well, Paolo, the day has just begun. Let's hope for the
        best." <br />
        "Sure boss." <br />
        "Go run an inventory check and give me a report. Need to check if
        anything new needs restocking." <br />
        "I'm on it." <br />
        Ameer takes the newspaper from Paolo's hand and walks into his office.
        He turns on his computer and opens the newspaper. He quickly glances
        over the pages reading the headlines and set the paper aside. Ameer
        noticed that he had received a new email from Lloyd Reid. The mail
        contained a product order and a request for BOQ. The list for the order
        items was huge. For the first time in a while, Ameer has received a big
        order. He couldn't believe it and thought it was spam. He ignored the
        mail for a couple of hours until a new mail popped up in his inbox. It
        was the same guy asking for an update. Ameer replied this time by
        attaching an outrageous pricing estimate. He thought that would scare
        the spammer. Minutes later, he received yet another mail. Lloyd Reid
        asked for his bank details, so he could deposit an advance. Ameer was
        surprised and couldn't believe that the mystery buyer agreed to his
        terms. Ameer sent a purchase invoice demanding a large advance payment.
        <br />
        "That should shut the spammer off for good," muttered Ameer.
        <br />
        Despite being hopeful, Ameer wished Lloyd Reid was a scammer. But his
        suspicions disappeared after an hour when a large sum entered his bank
        account. The depositor was Lloyd Reid. Another email notification from
        the same user! Ameer received an instructional message about the
        requested products and delivery location. As soon as he completed his
        task, he would receive the remaining money. Gripped with surprise and
        amazement, Ameer couldn't believe his luck. He took out his phone and
        dialed a number.
        <br />
        "Paolo, come to my office. We got an order to prepare." <br />
        Finally, things are going to get busy here in Gatipids Metal Recycling.{" "}
        <br />
        While Ameer Tafari prepared for his new order, Leia Roberts woke up in
        her luxurious furnished apartment in Port City. Being an early bird, she
        followed a strict routine of exercising for an hour, meditating for a
        while, having a hearty breakfast, and, getting ready for work. She was
        tall with an upright gait and a strict face. Leia donned an olive-green
        pantsuit and while brushing her bright blonde curls, she started to make
        mental notes of tasks to do that day. She was the manager of a customer
        care center by Beakon, a popular telecom service in the southern end of
        the city. She loved her work and always brainstormed ideas to improve
        office productivity. Leia was always the first one to sign into her
        office. The quietness of the early morning office helped her note down
        important tasks for the day and reply to emails. When she entered the
        office that morning with her usual cup of caramel chai in one hand and
        tasks plotted out in her head, Leia was oblivious to the crisis that
        will be struck at the workplace. Her carefully thought-out plans for
        that day were bound to be ruined by something unknown working in the
        shadows, putting her reputation and career at stake.
        <br />
        When Beakon’s customer center opened at 7 am, it was a normal day. The
        center had opened two hours early to the public, and all the staff was
        there, ready to handle any customers that came through the doors. But by
        9 am, the center was experiencing an unusual problem. The customer
        center’s server suddenly went offline. The branch manager, Leia Roberts
        deems it a temporary problem and assures everyone to remain patient. But
        as customers keep piling up while the server remained offline for almost
        2 hours, Leia calls GlobeNet for assistance. A team is deployed to the
        branch office to overlook the problem. It's not clear what happened, but
        it's clear that something is amiss. The growing concern brought the
        customer center to a halt, and nobody could answer the phones or serve
        the customers. Eventually, the branch manager was forced to shut down
        the customer center.
        <br />
        Earlier that day, Gavin Marshall, a telecommunications engineer from
        GlobeNet received a phone call from the head office. The wireless
        network connection was down in a local call center named Beakon, and
        Gavin was assigned to fix the issue. The job was located in the bustling
        Port City, a place Gavin hated. The 43-year-old, a seasoned expert in
        his field, hated the city and everything about it. For most of his life,
        he'd avoided city life and remained contented living all by himself in a
        small house located in a sleepy suburban town on the eastern fringe. He
        took up a job in the local branch office of GlobeNet despite being paid
        much less than those who worked in the city-center office. Gavin didn't
        mind. He was a single man with minimal needs and no family bindings.
        Gavin treasured his quiet life away from the humdrum of the city and his
        over-achieving peers. So that day, when Gavin received the assignment of
        a job in the city, he was naturally annoyed. The early- morning call
        ruined his quiet breakfast, and Gavin knew he would be irritated for the
        rest of the day. But duty calls. So, he stood up, got ready, grabbed his
        computer and tool bag, and walked towards his trusted road partner, a
        light grey Ford F-150. As Gavin made his way through the city, he
        observed the extravagance and glamour all around him. From large
        billboards to all the heavily lit neon advertisements, the city dazzled
        Gavin's eyes. As he drove slowly through the traffic, a particular
        billboard caught his attention. It was a large LCD billboard, displaying
        a few crisp words on loop, "Get ready for the first-ever
        artificially-intelligent sentinel being. The Future is here and now."
        There were no photos or videos, just those words smoothly fading in and
        out of the LCD screen. Artificially intelligent sentinel being? Gavin
        was quite familiar with AI technology and the many smart gadgets that
        were an obsession in the current times. But sentinel being? What did
        that mean? For some reason, Gavin felt a chill down his spine. He took a
        right turn and soon left the billboard behind. He was getting close to
        the job location. It was time to focus and solve the problem at hand.
        But the billboard seemed to leave a lasting impression on Gavin, and he
        couldn't shake that feeling off, a creeping sensation of dread.
        <br />
        As Gavin entered the Beakon customer center, he noticed how two other
        people from GlobeNet were already there. He learned that they couldn’t
        fix the issue. After spending hours in the place, Gavin reported that
        the server had been attacked by a virus for which it was not
        functioning. The origin of the virus and how it was able to infect the
        server remained unknown.
        <br />
        On the other side of the street where the Beakon customer center stood,
        a young woman stopped in front of the crowded restaurant named Jade’s
        Ranch. The young woman known as Heidi Dresslerg took a deep breath
        before entering the restaurant.
        <br />
        “I can do this. I am in control. I am power. I am resilient.” she
        muttered to herself.
        <br />
        For the past few weeks, Heidi Dresslerg, a former Weapons officer of the
        reputed US Marine, had been practicing this self-pep talk. She had
        developed this method of calming her anxiety down before any big event.
        It was her first workday as Chef’s assistant at Jade Ranch diner. She
        already felt flustered. Sweat trickled across her cheeks as she gathered
        her wits to remain steady.
        <br />
        Eight months ago, Heidi’s husband went on a mission in North Africa.
        What was supposed to be a conflict resolution ended up being a bloody
        situation. Heidi’s husband was shot in the face twice before he died on
        the spot. Since then, Heidi went into early retirement from service to
        take care of her kids. She had been applying for mediocre jobs to make a
        living for her family.
        <br />
        ‘’Anything without guns and bombs,’’ she told herself.
        <br />
        When Heidi was recruited as a Chef’s assistant in the restaurant, she
        was overwhelmed with joy. Jade Ranch was a busy place frequented by
        families, truckers, and workers from the nearby construction site. The
        restaurant seemed promising at first, but Heidi soon learned that it was
        a hectic place.
        <br />
        Orders had to be received and served fast; otherwise, a commotion would
        arise. As Heidi collected her new apron and walked toward the kitchen,
        she revised all the quick recipes she had learned in her trainee days.
        <br />
        “I don’t think those will be useful in this place”, Heidi thought.
        <br />
        Heidi was never a good cook, but she was great with knives and was
        proficient in cutting things. Her father had taught her how to use a
        knife when she was a little girl. Those casual training sessions were
        the only times Heidi could bond with her busy father, a sheriff in the
        local town. He had promised Heidi to teach her how to shoot when she
        reached her age, but that never happened. When Heidi was 8, her father
        was shot dead in front of her in the middle of the summer carnival.
        Someone held a grudge against his ethical views.
        <br />
        Heidi’s flashbacks stopped when she heard the Head Chef calling her
        name. The Chef was middle-aged with a sunburned face and a ghastly scar
        running across his temple. He introduced himself as Caesar. Chef Caesar
        greeted Heidi at the restaurant with a grim face and curt nod. He handed
        her a notebook and a pen and simply told her to follow closely. Then he
        set off preparing a meal at lightning pace. Heidi realized she was not
        going to receive much guidance, and that she had to observe and learn. A
        bit of a rough start, but she was a quick learner. Though she struggled
        to keep up with the Chef, Heidi soon caught up and started to take short
        notes. It was a new beginning, and Heidi Dresslerg promised herself to
        make the most of it.
        <br />
      </p>
      <p id="italic">
        Excerpt from the Daily Citizen’s Gazette: <br />
        Police Department under cyber-attack! <br />
        Joel Kelger, Correspondent <br />
        Last week, a malicious cyber-attack replaced the online home page of the
        Washington Police Department with a phishing page that looks like the
        department’s main page but is designed to steal passwords and infect
        users with malware. The malware was used to steal sensitive data,
        including email addresses and passwords, from the institution's
        database. The hackers were eventually able to gain access to the
        personal information of an unknown number of police officers and
        civilians. Later in the day, an unknown website published extensive
        profiles of 35 officers. The information, which included social security
        numbers, dates of birth, results of psychological assessments, copies of
        driver’s licenses, fingerprints, polygraph test results, as well as
        residential, financial, and marriage history was circulated through
        social media and soon spread to the public.
        <br />
        However, the FBI took prompt measures to protect the information of the
        officers and civilians and take down the malicious website. Regarding
        the hacker or the hacking organization, the FBI revealed no other
        comments.
        <br />
        No ransomware gang has claimed responsibility for the attack and the
        hacker remains in the dark.
        <br />
        Following the course of the recent hacking events, this was the first
        one where a state-empowered law enforcement agency was attacked. Many
        are making presumptions of all the hacking events are connected and
        whether the attack on the police department was the beginning of a new
        wave of cyber-attacks on other high-profile law and defense agencies of
        the state.
      </p>

      <p>
        Later, the same day when news about the cyber-attack on WPD was
        published, another institution in the same state became a victim of a
        new cyber-attack. The mobile financial service called E-Stash found
        itself at the scene of a data breach after users received spam messages
        claiming to be a free internet pack offer. The phishing scam tricked
        recipients into revealing their personal information, including account
        numbers and PINs. If the scam was successful, users were redirected to a
        website where their E-Stash accounts were accessed without their
        knowledge. As soon as the authority at E-Stash found this out, they
        ceased all online activities and transactions to prevent further damage.
        The extent of the damage was still unknown. Afterward Cyber Security
        Centre began to investigate the hacking event.
        <br />
        One day after E-Stash’s phishing scam incident, Hugo Gilroy, Chief
        regional manager of the mobile financial company in Downtown Washington,
        received a letter of termination. Even though the letter mentioned no
        reason for his dismissal, Hugo well understood that his termination was
        directly related to the hacking incident that took place two days ago.
        He wasn’t the only one to be permanently dismissed from the job. Many
        others in the company had been fired without any previous notice or any
        valid cause. The cyber-attack resulted in a massive breach in the bank’s
        system and many customers incurred heavy financial losses. Some of these
        customers also included people from powerful backgrounds.
        <br />
        To save themselves from further public humiliation and future threats,
        the higher-ups of the bank are blaming mid-level and low-level
        employees. That way, they can be easily fired without having to go
        through complex court cases. Hugo knew how sly these people were; after
        all, he had been working for them for the past eight years. What he
        couldn’t understand was how the system was breached. E-Stash boasted to
        have an ultra-secured banking system that improved by the day. And yet
        it was attacked. In the beginning, Hugo thought it was an inside job, a
        competitor who had somebody within the company who helped to do the
        hacking. But then again, it could be anyone, maybe an insider, maybe a
        foreign hacker, or maybe someone posing a national threat. Whoever it
        was and whatever their purpose had been, they were the main reason for
        Hugo’s being fired from a prestigious job. He would not receive any
        severance pay and even the company car that he received would be taken
        away. Too many things were being snatched away from Hugo, and being the
        materialistic person that he was, he was deeply unsatisfied. He blamed
        the anonymous hacker who ruined his life. A burning hatred slowly grew
        inside Hugo Gilroy as he deposited his company card and walked out of
        the E-Stash regional building.
        <br />
        Miles away from Downtown Washington, in a peaceful suburban community,
        stood a double-storied house with an empty lawn in the front. A blue
        mailbox outside the porch read the label ‘Lanewood’. As night fell
        outside, a phone call rang sharply through the Lanewood household.
        Inside, Priscilla Lanewood listened closely as her husband answered the
        call. His face seemed grim.
        <br />
        Something was wrong. Before the phone rang, the couple were happily
        making plans for a long-awaited family trip outside the country. Her
        husband, Lieutenant Rudolf had just returned home from a mission
        overseas. He was to rest for a month before being back to his duty. It
        was the perfect opportunity for the family to go on a trip together. But
        as the Lieutenant talked on the phone, Priscilla sensed that their
        vacation plan could be canceled.
        <br />
        ‘Who was it?’ Priscilla asked after her husband got off the phone.
        <br />
        Lieutenant Rudolf let out a sigh and said, ‘Something important came up
        darling. I have to go.’ <br />
        ‘Already? It hasn't been even a week!’ <br />
        ‘I know, I know. I am surprised too. But work is work. I must go.’
        ‘Can't they send someone else? Why must it be you?’ <br />
        ‘My whole squad has been deployed. Everyone I know is going. It must be
        something serious. God knows what's happening.’ ‘But you promised Rory.
        How are you going to explain this to him? You know how excited he's
        about the trip.’ <br />
        ‘I'm sorry darling. But you have to do the explaining. I must leave now.
        They are going to come over to pick me up within an hour. Can you help
        me pack?’
        <br />
        Priscilla was gripped with shock and anxiety. Something wasn't right.
        Things must be bad if her husband had to leave so suddenly. Now she
        started to worry about him. A thousand different thoughts came to her
        mind and none of them was good. <br />
        ‘What is happening? Is it really bad? Are you going to be okay?’ <br />
        ‘Don’t worry love. I’ll be fine. Even if things are bad, we’ll be okay.
        After all, we are soldiers!’ <br />
        ‘Are you sure? Something in my heart is telling me otherwise, I am not
        feeling right. Are we at war or something?’ <br />
        ‘Oh god, Priscilla! No! We are not at war neither are we going to be in
        one,’ said Rudolf while laughing. <br />
        There was a deep frown on Priscilla’s small round face. Under the dining
        room light, Rudolf saw beads of sweat forming on her forehead. Tears
        welled up in Priscilla’s almond brown eyes as she stared deeply at her
        husband’s face.
        <br />
        ‘Honey, I’m extremely sorry about this. Please forgive me. But I must
        go. I promise to take you guys out after coming back from this mission.
        If they don’t let me then, I swear I’m retiring. Can you please tell the
        kids I love them?’
        <br />
        ‘Okay, I will.’ <br />
        ‘Thank you so much. I’m so grateful to you for saving my ass again and
        again.’ <br />
        ‘Yeah? Remember to repay me back, okay?’ <br />
        ‘Of course, I will. I promise.’ <br />
        As Priscilla set to packing her husband’s bags, Lt. Rudolf grabbed his
        pack of cigarettes and walked out of the house to smoke. It was a
        peaceful night. A light breeze blew through the trees as the night sky
        twinkled with stars. For a moment, the lieutenant thought of bailing out
        of the mission by calling in sick or something. But that was for a brief
        moment only. He can’t say no to his duty to the country. After all, he
        was a soldier. Many years ago, when he was a young cadet, he had
        promised to serve his country. Now as a lieutenant, retreating was not
        an option for him. But he wanted to ensure the safety of his family. As
        he lit up his second cigarette, Rudolf Lanewood made a quick call.
        <br />
        ‘Hello. Who’s this?’ <br />
        ‘Raphael. It’s me, Rudolf.’ <br />
        ‘Bless my ears! It’s the lieutenant himself! I must have done something
        really good in my previous life to have this privilege.’ <br />
        ‘Yeah yeah, Raphael. And I must have sinned a lot in my previous life to
        have a sycophantic friend like you.’ <br />
        ‘Now come on Rudolf. I meant it. You are always away on missions,
        serving the country, being patriotic, and everything. It had been a long
        time since I heard your voice, man. You must be on break then to call
        me. Having fun with the family, eh?’ <br />
        The lieutenant let out a sigh. <br />
        ‘I gotta leave again. Listen Raph, I’ve called you to ask a favor.’{" "}
        <br />
        ‘Yeah sure, man. What is it?’ <br />
        ‘I can’t explain the details, but something is cooking. Something
        serious. Whatever happens, I won’t be here to take care of my wife and
        kid. Promise me this. No matter what happens, you’ll take them to
        safety.’
        <br />
        ‘Woah, woah, what’s going on lieutenant? What do you mean by something
        serious?’ <br />
        ‘I told you; I can’t explain right now. I must leave in an hour. My
        entire squad has been called for whatever shit is going down.’ <br />
        ‘I see.’ <br />
        <br /> ‘Raphael, promise me. If something happens, you’re responsible
        for taking my family to a safe place.’ ‘Man, aren’t you being a bit too
        paranoid?’
        <br /> ‘No, I’m simply being cautious.’ <br />
        ‘Okay okay, fine! I promise. You have my word, sir.’ <br />
        ‘Thank you. I trust you and your abilities. It won’t be a big deal for
        you to save your ass and a few others as well.’ <br />
        ‘Look who’s being a sycophant now, huh!’ <br />
        ‘I gotta go, Raphael.’ <br />
        ‘Alright man, travel safely. And make sure you come back in one
        breathing piece, okay?’ <br />
        ‘Okay. I’ll try my best.’ <br />
        Rudolf hangs up the phone, drops the cigarette stub, and stomps on it.
        He gazed around the sleepy suburb road one last time and headed back
        home.
        <br />
        Within 30 minutes, Lieutenant Rudolf was ready to depart. Exactly 1 hour
        after the phone call, a jeep stopped in front of his house to pick him
        up. It was time to leave. As Rudolf hugged Priscilla to say goodbye, he
        whispered something in her ear.
        <br />
        ‘Honey, keep an eye on the news. If you notice anything, anything at all
        that doesn’t seem right, call Raphael. You can find his number in my
        notebook. Call him and follow him. He’ll know what to do.’
        <br />
        ‘What? What do you mean by that? Why should I call Raphael?’ <br />
        ‘Nothing serious, just a small precaution. Don’t fret over it too much.
        But keep your eyes and ears open. If you notice something weird, call
        him, take the kids, and go where he goes. Okay?’
        <br /> ‘I don’t understand why…’ <br />
        ‘Just remember this, don’t think about it too much.’ ‘Okay. I’ll
        remember.’ <br />
        ‘This is it, love. Until I see you again, take care.’ <br />
        ‘You too. Goodbye.’ <br />
        Lieutenant Rudolf walked to the jeep, waved one last time, and got in.
        Priscilla waved back until the jeep rolled away in the quietness of the
        night. As everything became quiet, the midnight silence of the
        neighborhood seemed to crush Priscilla as she couldn’t shake off the
        feeling of rising dread.
        <br />
        Looking out the upper floor bedroom window, a little boy clenched his
        fist and pushed back tears as he watched his father leave in the dead of
        the night without saying goodbye.
        <br />
        The next morning at school during History class, Rory Lanewood asks for
        permission to visit the school nurse’s office. After getting permission,
        he leaves the classroom and slowly walks across the hallway to go to the
        nurse’s office. Rory pulls open the door and peeks inside to see a woman
        dozing in her chair.
        <br /> "Miss? Excuse me, Miss?" <br />
        Anya Jabari jumped from her reverie and looked at the flustered little
        boy calling her. <br />
        "Yes, my dear! I'm sorry, I was a bit...I was lost in my thoughts, sorry
        dear. How can I help you?" <br />
        "I don't feel well. I think I have a fever." <br />
        "Oh no. Did you catch the flu as well? Come over here and take a seat.
        Now, I didn't catch your name, darling?" <br />
        "Rory. Rory Lanewood. I am a student of Grade 1." <br />
        "Nice to meet you, Mr. Rory Lanewood. I am Anya Jabari, the school
        nurse, and I'll take a look at you and try to find out what's wrong. Is
        that okay?"
        <br /> "Hmm." <br />
        <br /> "Let me check your temperature and see if you have a fever. Do
        you have a runny nose, dear? Does your throat hurt?" <br />
        <br /> "Uh-huh" <br />
        <br /> "I see. Well, your temperature is fine too, you don't seem to
        have a fever." <br />
        "I don't feel well." <br />
        "Does your tummy hurt? Or is it your head? Do you feel like throwing up
        or something?" <br />
        "Uh-huh" <br />
        "Well, Rory my dear, I think you're perfectly fine! Why do you still
        feel unwell?" <br />
        Anya observed the boy. He was quite skinny with pale skin and dark hair.
        His eyes were puffy. Has he been crying? <br />
        "Mr. Rory Lanewood, you can go back to class. I can't find anything
        wrong with you." <br />
        Rory tightened his fists as he tried hard to push back the tears. He
        accepted the candy Miss Jabari offered and quietly left the nurse
        station. Rory knew that he wasn't ill. He was just trying to find an
        excuse to go home. As he walked the hallway, Rory recalled the events
        from last night. His father, Lieutenant Rudolf of the Navy, had just
        returned home after a 7-month long mission. It's been five days since he
        returned. After the welcome party, as the family settled in, they
        started to make plans for a long-awaited family vacation in the
        mountains. Rory was excited about the trip and couldn't wait to spend
        time with his dad. But all his hopes were crushed when he overheard his
        father on the phone last night. It was past Rory's bedtime, but he
        awakened at the sound of his mother sobbing. Rory snuck out of the bed
        and tiptoed into the living room. Both his parents were talking in a low
        voice. It seemed like an emergency arose on the coast, and Lieutenant
        Rudolf was assigned to assist in a rescue mission. He had to leave soon.
        When Rory woke up in the morning, his father was gone. He left without
        saying goodbye. He asked his mom where his father had gone. She
        explained that his dad had to leave for an emergency mission. Rory took
        the news quite calmly at that time. But now, as he walked back to his
        class from the nurse's office, he suddenly felt he would never see his
        father again. Tears welled up his eyes and rolled down his cheeks as
        Rory stood frozen in the middle of the empty hallway.
      </p>
    </div>
  );
}
export default Chapters1;
