import React from "react";
import { Link } from "react-router-dom";
function SellStartText() {
  return (
    <div>
      <section className="purchase_text">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="purchase_box text-center">
                <Link className="btn market" to="/sell-fixed-market">
                  SELL ON FIXED MARKETPLACE
                </Link>
                <br></br>
                <Link className="btn auction" to="/sell-auction">
                  SELL ON AUCTION
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default SellStartText;
