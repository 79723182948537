import React from 'react';
import '../SellCollection -auction/CardCollection.css';
import AuctionSellGenesisList from './SubPages/Genesis/Sections/AuctionSellGenesisList';
import AuctionSellGenesisText from './SubPages/Genesis/Sections/AuctionSellGenesisText';

function AuctionSellGenesisCard () {
	return <div>
		{/* <Genesisbanner/> */}
		<AuctionSellGenesisList></AuctionSellGenesisList>
		<AuctionSellGenesisText></AuctionSellGenesisText>

        
	</div>
}
export default AuctionSellGenesisCard;