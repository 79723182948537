import React from "react";
import "./Styled.css";

function TitleForm(props) {
  return (
    <div className="title-form-container">
      <div className="title-form">{props.children}</div>
      <div className="svg-icon">
        <svg
          width="22"
          height="22"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13" cy="13" r="12.5" stroke="#FF0000" />
          <path
            d="M11.2 7.9V6.74L12.02 5.94H13.24L14.06 6.74V7.9L13.24 8.7H12.02L11.2 7.9ZM11.5 10.04H13.76V20H11.5V10.04Z"
            fill="#FF0000"
          />
        </svg>
      </div>
      <div className="text-message">{props.text}</div>
    </div>
  );
}

export default TitleForm;
