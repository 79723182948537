import React from 'react';
import { Outlet } from 'react-router-dom';
import './BreedingList.css'
import BreedingListBanner from './Sections/BreedingListBanner/BreedingListBanner';
import BreedingListText from './Sections/BreedingListText/BreedingListText';
const BreedingList = () => {
    return (
        <div className='breeding-list'> 
            <BreedingListBanner></BreedingListBanner>
            {/* <BreedingListText></BreedingListText> */}
            <Outlet></Outlet>
        </div>
    );
};

export default BreedingList;