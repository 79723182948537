import React from 'react';
import BreedingBanner from '../Breeding/Sections/BreedingBanner';
import BreedingTaxt from '../Breeding/Sections/BreedingTaxt';
import '../Breeding/Breeding.css';
function Breeding(){
 return <div>
<BreedingBanner/>
<BreedingTaxt/>
</div>


}
export default Breeding;