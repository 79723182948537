import React from 'react';
import '../ProfileCollection/CardCollection.css';
import ProfileHumanityList from './SubPages/Humanity/Sections/ProfileHumanityList';
import ProfileHumanityText from './SubPages/Humanity/Sections/ProfileHumanityText';

function ProfileHumanityCard () {
	
	return <div>
		{/* <Humanitybanner/> */}
		<ProfileHumanityList></ProfileHumanityList>
		<ProfileHumanityText></ProfileHumanityText>

        
	</div>
}
export default ProfileHumanityCard;