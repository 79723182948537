import React from "react";
import "../../../CardCollection.css";
import { Link, Outlet } from "react-router-dom";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../../../aws-exports";
import { useState } from "react";
import { useEffect } from "react";
import PRMCard from "../../../../../../../Shared/PRMCard/PRMCard";
import Pagination from "../../../../../../../Shared/Pagination";
import usePRMCards from "../../../../../../../Hooks/usePRMCards";

function RenameHumanityText() {
  const [TokenId, page, setPage, next, Next, Previous, loader] =
    usePRMCards("Humanity");

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            {loader ? (
              <loader></loader>
            ) : (
              <div className="content_box">
                <h4>HUMANITY</h4>

                <div className="row">
                  {TokenId.slice((page - 1) * 10, page * 10).map((TokenId) => (
                    <PRMCard TokenId={TokenId} key={TokenId}>
                      rename
                    </PRMCard>
                  ))}
                  {TokenId.length === 0 && <h1>NO CARD</h1>}
                </div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  length={TokenId.length}
                  Previous={Previous}
                  Next={Next}
                  next={next}
                ></Pagination>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default RenameHumanityText;
