import React from "react";

function Faq12() {
  return (
    <div>
      <div className="faq_box">
        <div className="row">
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2
                class="accordion-header"
                id="panelsStayOpen-headingNinetyThree"
              >
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseNinetyThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseNinetyThree"
                >
                  Q1. Where can I find the communities?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseNinetyThree"
                class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingNinetyThree"
              >
                <div class="accordion-body ">
                  <p>
                    A: Connect with our communities to stay updated regarding
                    new blogs, story plots and cards’ sale. You can find us on{" "}
                    <a
                      href="https://discord.gg/kV4YeYQWyB"
                      target="_blank"
                      rel="noreferrer" className="text-decoration-none text-danger"
                    >
                      Discord
                    </a>{" "}
                    , {" "}
                    <a
                      href="https://twitter.com/ExtremisVerse"
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none text-danger"
                    >
                      Twitter
                    </a> {" "}
                    ,{" "}
                    <a
                      href="https://www.facebook.com/extremisverse/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none text-danger"
                    >
                      Facebook
                    </a>{" "}
                    ,{" "}
                    <a
                      href="https://www.instagram.com/extremisverse/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none text-danger "
                    >
                      Instagram
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://t.me/+ZKLv8kw7lB4zN2Nh"
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none text-danger"
                    >
                      Telegram
                    </a>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2
                class="accordion-header"
                id="panelsStayOpen-headingNinetyFour"
              >
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseNinetyFour"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseNinetyFour"
                >
                  Q2. Where can I find out new updates, auction news and story
                  reveals?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseNinetyFour"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingNinetyFour"
              >
                <div class="accordion-body">
                  <p>
                    A: Subscribe to our mailing list to get personalized email
                    that will notify you every time there’s news! You can also
                    join our social media communities to participate in the
                    public discussions regarding the game.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Faq12;
