import React from "react";
import { useContext } from "react";
import { emptySlotContext, slotContext } from "../../../App";
import Loader from "../Loader/Loader";
import "../Card.css";

import useStats from "../useStates";
import "./War.css";
import useSellCard from "../SellCard/useSellCard";
import useImage from "../Card/useImage";
import useColor from "../useColor";

import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useRankImage from "../useRankImage";
const WarSelfCard = (props) => {
  const [warSlot, setWarSlot] = useContext(slotContext);
  const [image, setImage] = useState(false);
  const [CardFaction, CardName, CardType, CardRank, CardGender] = useSellCard(
    props.TokenId
  );
  const [disable, setDisable] = useState(false);
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);

  // console.log(props.availableSlot, "self card");

  useEffect(() => {
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);

  const navigation = useNavigate();

  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(props.TokenId);
  const SelectCard = (e) => {
    e.preventDefault();
    var slot = e.target.slot.value;
    setWarSlot({
      faction: CardFaction,
      slot: slot,
      TokenId: props.TokenId,
    });
    props.setAvailableSlot({ ...props.availableSlot, [slot]: true });
    setDisable(true);
  };

  const nav = (props) => {
    // console.log("prop", props);
    navigation(`/individual-card/${props}`);
  };
  return (
    <div className="col-md-12 mx-1 word-wrapping">
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_box card_img">
          <img
            onClick={() => {
              nav(props.TokenId);
            }}
            style={
              CardFaction &&
              CardType &&
              CardRank &&
              IQ &&
              EQ &&
              Wisdom &&
              Defense &&
              Weapon &&
              Leadership &&
              Melee &&
              Potential &&
              NaturalAdpt
                ? {}
                : { display: "none" }
            }
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />
          <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />{" "}
          <img
            className="extremis-logo"
            src="/LOGO.png"
            alt=""
          />
          {CardFaction &&
          CardType &&
          CardRank &&
          IQ &&
          EQ &&
          Wisdom &&
          Defense &&
          Weapon &&
          Leadership &&
          Melee &&
          Potential &&
          NaturalAdpt ? (
            <>
              <div className="card_info">
                <h3>{CardName}</h3>{" "}
                {/* <p className="fs-6 text-white m-0">Token Id: {props.TokenId}</p> */}
              </div>
              <h5 className="text-white">{CardFaction}</h5>
              <div class="list_box">
                <ul>
                  <li>
                    <span>ID: </span>
                    {props.TokenId}
                  </li>
                  <li>
                    <span>Rank: </span> {CardRank}
                  </li>
                  <li>
                    <span>Type: </span>
                    {CardType}
                  </li>
                  <li>
                    <span>IQ: </span> {IQ}
                  </li>
                  <li>
                    <span>Potential: </span> {Potential}
                  </li>
                  <li>
                    <span>EQ: </span> {EQ}
                  </li>
                  <li>
                    <span>Wisdom: </span>
                    {Wisdom}
                  </li>
                  <li>
                    <span>Melee: </span> {Melee}
                  </li>
                  <li>
                    <span>Leadership: </span> {Leadership}
                  </li>
                  <li>
                    <span>Weapon: </span> {Weapon}
                  </li>
                  <li>
                    <span>Defense: </span> {Defense}
                  </li>
                  {CardFaction === "Humanity" ? (
                    <li>
                      <span>N. Adaptability: </span>
                      {NaturalAdpt}
                    </li>
                  ) : (
                    <li>
                      <span>AI Influence: </span>
                      {NaturalAdpt}
                    </li>
                  )}
                </ul>
              </div>
              <form onSubmit={SelectCard}>
                <div className="d-flex justify-content-between">
                  {/* <input
                    className="war-input"
                    type="number"
                    min={1}
                    max={11}
                    name="slot"
                    placeholder="Empty slot #"
                  /> */}
                  <select name="slot" id="">
                    {Object.keys(props.availableSlot)?.map((key, i) => {
                      if (props.availableSlot[key] === false) {
                        return <option value={key}>Empty slot #{key}</option>;
                      }
                   
                    })}
                  </select>
                  <button
                    className="war-button"
                    type="submit"
                    disabled={disable ? true : false}
                  >
                    {disable ? "SELECTED" : "SELECT CARD"}
                  </button>
                </div>
              </form>
            </>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
    </div>
  );
};

export default WarSelfCard;
