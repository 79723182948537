import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import * as AWS from "aws-sdk";
import awsmobile from "../../../aws-exports";
import ProfileSliderChild from "./ProfileSliderChild";
export default function ProfileSlider() {
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_BOARD_TABLE_NAME,
  });

  async function GetLeaderBoard() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    try {
      const Response = await documentClient.scan(param).promise();
      const result = Response.Items.sort((a, b) => {
        return b.WinCount - a.WinCount;
      });
      return result;
    } catch (err) {
      console.log(err);
    }
  }

  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    if (awsmobile.aws_cognito_region && AWS.config.credentials) {
    const Token = async () => {
      const Id = await GetLeaderBoard();
      setLeaderBoard(Id);
    };
      Token();
    }
  }, [awsmobile.aws_cognito_region, AWS.config.credentials]);

// console.log(leaderBoard);

  return (
    <div>
      <section className="profile_section">
        <div className="container">
          <div className="row">
            <div className="profile_slider">
              <Slider {...settings}>
                {leaderBoard?.slice(0, 3).map((position, index) => (
                  <ProfileSliderChild
                    position={position}
                    index={index}
                    key={index}
                  ></ProfileSliderChild>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
