import React, { useContext, useRef } from "react";
// import alice_logo from "../Sections/Images/alice_logo.png";
import { useNavigate } from "react-router-dom";
import useImage from "../Card/useImage";
import useColor from "../useColor";

import Loader from "../Loader/Loader";
import useAuctionCard from "./useAuctionCard";
import "../Card.css";
import { useEffect } from "react";
import { useState } from "react";
import useRankImage from "../useRankImage";
import { GetAuction } from "../../UserPage/BlockChainFunctions";
import useAuctionContract from "../../Hooks/AuctionContractHook";
import useUsername from "../../Hooks/useUsername";
const { ethers } = require("ethers");

function AuctionCardAdminPage(props) {
  const navigation = useNavigate();
  const [image, setImage] = useState(false);
  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardPrice,
    remainingTimes,
  ] = useAuctionCard(props.TokenId);
  const [seller, setSeller] = useState(null);
  const [auctionContract, setAuctionContract] = useAuctionContract();
  const Username = useUsername(seller);
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  useEffect(() => {
    if (uploaded === true) {
      setImage(true);
    }
  }, [uploaded]);

  const nav = (props) => {
    navigation(`/auction-individual/${props}`);
  };
  const AuctionGet = async () => {
    const auction = await GetAuction(props.TokenId, auctionContract);

    setSeller(auction?.seller);
  };

  useEffect(() => {
    if (auctionContract && props.TokenId) {
      AuctionGet();
    }
  }, [auctionContract, props.TokenId]);
  return (
    <div
      className="col-md-6  fs-6"
      onClick={() => {
        nav(props.TokenId);
      }}
    >
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_box card_img text-break">
          <img
            // style={
            //   CardFaction && CardGender && CardType && CardRank
            //     ? {}
            //     : { display: "none" }
            // }
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />
          <img className="rankImage" src={`/Rank/${rankImage}.png`} alt="" />{" "}
          <img className="extremis-logo" src="/LOGO.png" alt="" />
          {/* {CardFaction && CardGender && CardType && CardRank ? ( */}
          <>
            <div className="text-break word-wrapping">
              <div className="card_info">
                <h3 className="">{CardName}</h3>
                <p className="fs-6 text-white ps-3">ID: {props.TokenId}</p>
              </div>
              <div className="card_info">
                <div className="card_type ">
                  <p>
                    FACTION: <strong>{CardFaction}</strong>
                  </p>
                  <p>
                    PRICE: <span>{CardPrice} MATIC</span>
                  </p>
                </div>
                <div className="card_rank ">
                  <p>
                    RANK: <strong>{CardRank}</strong>
                  </p>
                  <p>
                    TYPE:<strong> {CardType}</strong>
                  </p>
                </div>
              </div>
                <div className="card_rank col-12 ">
                  <p>
                    OWNER: <span>{Username} </span>
                  </p>

                  <p>
                    TIME LEFT: <span className="text-danger">{remainingTimes}</span>
                  </p>
                </div>
            </div>
          </>
          {/* ) : ( */}
          {/* <Loader></Loader> */}
          {/* )} */}
        </div>
      )}
    </div>
  );
}
export default AuctionCardAdminPage;
