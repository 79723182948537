import React from "react";
import { useDrag } from "react-dnd";
import { Link, useNavigate } from "react-router-dom";
import useCard from "../Card/useCard";
import useStats from "../useStates";
import "../../Profile/Pages/Breeding/BreedSelfCollection/breed.css";
import Loader from "../Loader/Loader";
import useImage from "../Card/useImage";
import useColor from "../useColor";

import { useEffect } from "react";
import { useState } from "react";
import useRankImage from "../useRankImage";
const BreedCard = (props) => {
  const navigation = useNavigate();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "div",
    item: { id: props.TokenId },

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [image, setImage] = useState(false);
  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardOwner,
    CardOwnerUsername,
  ] = useCard(props.TokenId);
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);

  useEffect(() => {
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);
  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(props.TokenId);

  const nav = () => {
    // console.log("prop", props);
    navigation(`/individual-card/${props.TokenId}`);
  };
  return (
    <div    onClick={nav}
      className="col-md-6 word-wrapping"
      ref={drag}
      style={{ border: isDragging && "5px solid red" }}
    >
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_box card_img">
          <img
            style={
              CardFaction &&
              CardType &&
              CardRank &&
              IQ &&
              EQ &&
              Wisdom &&
              Defense &&
              Weapon &&
              Leadership &&
              Melee &&
              Potential &&
              NaturalAdpt
                ? {}
                : { display: "none" }
            }
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />
           <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />   <img className="extremis-logo" src="/LOGO.png" alt="" />
          {CardFaction &&
          CardType &&
          CardRank &&
          IQ &&
          EQ &&
          Wisdom &&
          Defense &&
          Weapon &&
          Leadership &&
          Melee &&
          Potential &&
          NaturalAdpt ? (
            <>
              <div className="card_info text-light">
                <h3>{CardName}</h3>
                <p className="fs-6 text-white m-0"> ID: {props.TokenId}</p>
              <h5 className="text-white">{CardFaction}</h5>
              <p>OWNER: {CardOwnerUsername}</p>
              </div>
              <div class="list_box">
                <ul>
                  <li>
                    <span>Rank: </span> {CardRank}
                  </li>
                  <li>
                    <span>Type: </span>
                    {CardType}
                  </li>
                  <li>
                    <span>IQ: </span> {IQ}
                  </li>
                  <li>
                    <span>Potential: </span> {Potential}
                  </li>
                  <li>
                    <span>EQ: </span> {EQ}
                  </li>
                  <li>
                    <span>Wisdom: </span>
                    {Wisdom}
                  </li>
                  <li>
                    <span>Melee: </span> {Melee}
                  </li>
                  <li>
                    <span>Leadership: </span> {Leadership}
                  </li>
                  <li>
                    <span>Weapon: </span> {Weapon}
                  </li>
                  <li>
                    <span>Defense: </span> {Defense}
                  </li>
                  {CardFaction === "Humanity" ? (
                    <li>
                      <span>N. Adaptability: </span>
                      {NaturalAdpt}
                    </li>
                  ) : (
                    <li>
                      <span>AI Influence: </span>
                      {NaturalAdpt}
                    </li>
                  )}
                </ul>
              </div>
              {/* <div className="bettle_btn">
              <a className="btn" href="#">
                SELECT
              </a>
            </div> */}
            </>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
    </div>
  );
};

export default BreedCard;
