import React from "react";
import WarBattleBanner from "./Sections/WarBattleBanner";
import WarBattleText from "./Sections/BHWarBattleText";
import CardTab from "./Sections/CardTab";
import BreedSelfAliceCard from "../../../Breeding/BreedSelfCollection/BreedSelfAliceCard";
import BHWarBattleText from "./Sections/BHWarBattleText";
import { Outlet, useLocation } from "react-router-dom";
import BHCard from "./BHCard/BHCard";
function BHWarBattlefield() {
  const location = useLocation();
  // console.log(location.state);
  return (
    <div>
      <WarBattleBanner />
      <BHWarBattleText state={location.state}/>
      {/* <CardTab/> */}
      {/* <BreedSelfAliceCard></BreedSelfAliceCard> */}
      {/* <Outlet></Outlet> */}
      <BHCard></BHCard>
    </div>
  );
}
export default BHWarBattlefield;
