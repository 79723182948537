import React from 'react';
import OthersConfirmationCard from '../OthersConfirmationCard/OthersConfirmationCard';

function OthersConfirmationTaxt(){
 return <div>
<section className="breeding_cards">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                   <div className="breed_cards_heading">
                   <h2>BREED WITH OTHERS</h2>
               <OthersConfirmationCard/>
               </div>
               </div>
               
            </div>
         </div>
      </section>
  
 </div>
 

}
export default OthersConfirmationTaxt;