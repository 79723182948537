import React, { useContext, useEffect, useState } from "react";
import { addressContext, providerContext } from "../../App";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { GetCardType } from "../UserPage/BlockChainFunctions";
import useGameCentreContract from "./GameCentreHook";

async function getGeneralDetails(TokenId, gameCentreContract) {
  const Type = await GetCardType(TokenId, gameCentreContract);
  return Type;
}

const usePRMAllCard = () => {
  const [TokenId, setTokenId] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [loader, setLoader] = useState(true);
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();

  async function GetTokenId() {
    // console.log(param, "inside function");
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    const Id = [];
    const tableName1 = process.env.REACT_APP_CUSTOMER_CARD_TABLE_NAME;
    const GSI_index = process.env.REACT_APP_CUSTOMER_CARD_TABLE_INDEX_NAME;
    const address = currentAddress;
    try {
      const params = {
        TableName: tableName1,
        IndexName: GSI_index,
        KeyConditionExpression: "CustomerID = :cxId",
        ExpressionAttributeValues: {
          ":cxId": address,
        },
        ProjectionExpression: "TokenId",
      };

      const Response = await documentClient.query(params).promise();
      if (Response.Items.length <= 10) {
        setNext(false);
      }

      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        const Result = await getGeneralDetails(
          element.TokenId,
          gameCentreContract
        );
        // console.log(Result, "use prm");
        if (Result !== "Genesis") {
          Id.push(element.TokenId);

          // setNext(true);
        }
      }
    } catch (err) {
      // console.log(err);
    }
    return Id;
  }
  useEffect(() => {
    if (page === Math.ceil(TokenId.length / 10) || TokenId.length === 0) {
      setNext(false);
    }else{
      setNext(true)
    }
  }, [TokenId, page]);
  const Next = async () => {
    setNext(false);
    setPage((page) => page + 1);
    setNext(true);
  };
  const Previous = async () => {
    setPage((page) => page - 1);
  };
  useEffect(() => {
    if (gameCentreContract) {
      const Token = async () => {
        const Id = await GetTokenId();
        setLoader(false);
        setTokenId((TokenId) => [...TokenId, ...Id]);
      };
      Token();
    }
  }, [walletProvider, currentAddress, gameCentreContract]);

  // console.log(TokenId);
  return [TokenId, page, setPage, next, Next, Previous, loader];
};

export default usePRMAllCard;
