import React from "react";
function Chapters1() {
  return (
    <div>
      <h4>Chapter 6: THE EXTREMIS</h4>
      <h5 className="text-center text-white">COMING SOON ...</h5>
    </div>
  );
}
export default Chapters1;
