import React from "react";

import Faq1 from "./AllCard/Faq1";
import Faq2 from "./AllCard/Faq2";
import Faq3 from "./AllCard/Faq3";
import Faq4 from "./AllCard/Faq4";
import Faq5 from "./AllCard/Faq5";
import Faq6 from "./AllCard/Faq6";
import Faq7 from "./AllCard/Faq7";
import Faq8 from "./AllCard/Faq8";
import Faq9 from "./AllCard/Faq9";
import Faq10 from "./AllCard/Faq10";
import Faq11 from "./AllCard/Faq11";
import Faq12 from "./AllCard/Faq12";

function FaqTab() {
  return (
    <div>
      <section className="faq_tab_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="faq_tab">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="Faq1-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq1"
                      type="button"
                      role="tab"
                      aria-controls="Faq1"
                      aria-selected="true"
                    >
                      What do I need to play EXTREMIS
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq2-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq2"
                      type="button"
                      role="tab"
                      aria-controls="Faq2"
                      aria-selected="false"
                    >
                      Buying, Selling and Charges
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq3-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq3"
                      type="button"
                      role="tab"
                      aria-controls="Faq3"
                      aria-selected="false"
                    >
                      About factions
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq4-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq4"
                      type="button"
                      role="tab"
                      aria-controls="Faq4"
                      aria-selected="true"
                    >
                      Functional and physical attributes
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq5-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq5"
                      type="button"
                      role="tab"
                      aria-controls="Faq5"
                      aria-selected="false"
                    >
                      Card breeding
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq6-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq6"
                      type="button"
                      role="tab"
                      aria-controls="Faq6"
                      aria-selected="false"
                    >
                      Battle
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq7-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq7"
                      type="button"
                      role="tab"
                      aria-controls="Faq7"
                      aria-selected="false"
                    >
                      War
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq8-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq8"
                      type="button"
                      role="tab"
                      aria-controls="Faq8"
                      aria-selected="false"
                    >
                      Mutations
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq9-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq9"
                      type="button"
                      role="tab"
                      aria-controls="Faq9"
                      aria-selected="false"
                    >
                      Card ranks
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq10-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq10"
                      type="button"
                      role="tab"
                      aria-controls="Faq10"
                      aria-selected="false"
                    >
                      Upgrades
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq11-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq11"
                      type="button"
                      role="tab"
                      aria-controls="Faq11"
                      aria-selected="false"
                    >
                      About transactions
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Faq12-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Faq12"
                      type="button"
                      role="tab"
                      aria-controls="Faq12"
                      aria-selected="false"
                    >
                      Communities
                    </button>
                  </li>
                </ul>
                <h2 className="faq_heading">FAQ</h2>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="Faq1"
                    role="tabpanel"
                    aria-labelledby="Faq1-tab"
                  >
                    <h3>What do I need to play EXTREMIS</h3>
                    <Faq1 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq2"
                    role="tabpanel"
                    aria-labelledby="Faq2-tab"
                  >
                    <h3>Buying, Selling and Charges</h3>
                    <Faq2 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq3"
                    role="tabpanel"
                    aria-labelledby="Faq3-tab"
                  >
                    <h3>About Factions</h3>
                    <Faq3 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq4"
                    role="tabpanel"
                    aria-labelledby="Faq4-tab"
                  >
                    <h3>Functional and physical attributes</h3>
                    <Faq4 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq5"
                    role="tabpanel"
                    aria-labelledby="Faq5-tab"
                  >
                    <h3>Card breeding</h3>
                    <Faq5 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq6"
                    role="tabpanel"
                    aria-labelledby="Faq6-tab"
                  >
                    <h3>Battle</h3>
                    <Faq6 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq7"
                    role="tabpanel"
                    aria-labelledby="Faq7-tab"
                  >
                    <h3>War</h3>
                    <Faq7 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq8"
                    role="tabpanel"
                    aria-labelledby="Faq8-tab"
                  >
                    <h3>Mutations</h3>
                    <Faq8 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq9"
                    role="tabpanel"
                    aria-labelledby="Faq9-tab"
                  >
                    <h3>Card ranks</h3>
                    <Faq9 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq10"
                    role="tabpanel"
                    aria-labelledby="Faq10-tab"
                  >
                    <h3>Upgrades</h3>
                    <Faq10 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq11"
                    role="tabpanel"
                    aria-labelledby="Faq11-tab"
                  >
                    <h3>About transactions</h3>
                    <Faq11 />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Faq12"
                    role="tabpanel"
                    aria-labelledby="Faq2-tab"
                  >
                    <h3>Communities</h3>
                    <Faq12 />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default FaqTab;
