import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useSellCard from "../../../Shared/SellCard/useSellCard";
import useColor from "../../../Shared/useColor";
import useStats from "../../../Shared/useStates";
import blacknet1 from "../Images/blacknet1.png";
function SellCardDetailsTaxt() {
  const TokenId = useParams();
  // console.log("tok", TokenId);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  const [CardFaction, CardName, CardType, CardRank, CardGender] = useSellCard(
    TokenId.TokenId
  );
  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(TokenId.TokenId);
  const color = useColor(CardRank, CardType);

  return (
    <div>
      <section className="sell_card_text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sell_card_heading">
                <h2>SELL CARD</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="sell_card_succ_img">
                <img
                  src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${TokenId}`}
                  className={color}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="sell_card_succ_info">
                <h4>{CardName}</h4>
                <h5>{CardFaction}</h5>
                <div class="sell_card_list_box">
                  <ul>
                    <li>
                      <span>Rank: </span> {CardRank}
                    </li>
                    <li>
                      <span>Type: </span>
                      {CardType}
                    </li>
                    <li>
                      <span>IQ: </span>
                      {IQ}
                    </li>
                    <li>
                      <span>Potential: </span> {Potential}
                    </li>
                    <li>
                      <span>EQ: </span> {EQ}
                    </li>
                    <li>
                      <span>Wisdom: </span>
                      {Wisdom}
                    </li>
                    <li>
                      <span>Melee: </span> {Melee}
                    </li>
                    <li>
                      <span>Leadership: </span>
                      {Leadership}
                    </li>
                    <li>
                      <span>Weapon: </span>
                      {Weapon}
                    </li>
                    <li>
                      <span>Defense: </span> {Defense}
                    </li>
                    {CardFaction === "Humanity" ? (
                      <li>
                        <span>N. Adaptability: </span>
                        {NaturalAdpt}
                      </li>
                    ) : (
                      <li>
                        <span>AI Influence: </span>
                        {NaturalAdpt}
                      </li>
                    )}
                  </ul>
                </div>
                <div className="sell_card_succ_text">
                  <h3>Sell card?</h3>
                  <div className="sell_price">
                    <div className="sell_btn fixed">
                      <Link
                        className="btn"
                        to={`/sell-confirmation/${TokenId.TokenId}`}
                      >
                        FIXED SALE
                      </Link>
                    </div>
                    <div className="sell_btn">
                      <Link
                        className="btn"
                        to={`/sell-auction/${TokenId.TokenId}`}
                      >
                        AUCTION SALE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default SellCardDetailsTaxt;
