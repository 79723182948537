import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { slotContext, warCardContext } from "../../../App";
import WarCard from "./WarCard";

const WarEmptyCard = ({ i, faction }) => {
  const [warSlot, setWarSlot] = useContext(slotContext);
  const [warCard,setWarCard]=useContext(warCardContext)
  const [pass, setPass] = useState(false);
  const [TokenId,setTokenId]=useState("")
  useEffect(() => {
    // console.log(warSlot?.slot,i+1,"slot",warSlot?.faction,faction,"war card");
    if (warSlot?.slot == i + 1 && warSlot?.faction == faction) {
      // console.log(i + 1, faction, "slot test passed ");
      setPass(true);
      setTokenId(warSlot.TokenId)
      setWarCard(warCard=>[...warCard,warSlot.TokenId])
    } else {
      // console.log("fail");
      // alert("no available slot")
    }
  },[warSlot]);
  // console.log(warCard, "pass ");
  return (
    <div>
      {
        pass ? <WarCard TokenId={TokenId}></WarCard>:
        <div className="war_card">
        <div className="upload_img">
          <div class="image-upload">
            <label for="file-input">
              <i class="far fa-plus"></i>
              <br></br>
              Select card from your card collection <br></br>to enter 
            </label>
            {/* <input id="file-input" type="file" /> */}
          </div>
        </div>
        <h4>EMPTY SLOT #{i + 1}</h4>

        <div className="war_list">
          <ul>
            <li>
              <span>Rank: </span> ---
            </li>
            <li>
              <span>Type: </span> ---
            </li>
            <li>
              <span>IQ: </span> ---
            </li>
            <li>
              <span>Potential: </span> ---
            </li>
            <li>
              <span>EQ: </span> ---
            </li>
            <li>
              <span>Wisdom: </span> ---
            </li>
            <li>
              <span>Melee: </span> ---
            </li>
            <li>
              <span>Leadership: </span> ---
            </li>
            <li>
              <span>Weapon: </span> ---
            </li>
            <li>
              <span>AI influence /<br />N. Adaptability:</span> ---
            </li>
          </ul>
        </div>
       
      </div>
      }
    </div>
  );
};

export default WarEmptyCard;
