import React from 'react';
// import Genesisbanner from '../Cardcollection/SubPages/Genesis/Sections/Genesisbanner';
import GenesisText from './SubPages/Genesis/Sections/AuctionGenesisText';
import GenesisList from './SubPages/Genesis/Sections/AuctionGenesisList';
import '../AuctionCollection/CardCollection.css';
import AuctionGenesisList from './SubPages/Genesis/Sections/AuctionGenesisList';
import AuctionGenesisText from './SubPages/Genesis/Sections/AuctionGenesisText';

function AuctionGenesisCard () {
	return <div>
		{/* <Genesisbanner/> */}
		<AuctionGenesisList/>
		<AuctionGenesisText/>

        
	</div>
}
export default AuctionGenesisCard;