import React from 'react';
import '../SellCollection/CardCollection.css';
import SellAliceList from './SubPages/AliceCard/Sections/SellAliceList';
import SEllAliceText from './SubPages/AliceCard/Sections/SellAliceText';

function SellAliceCard () {
	return <div>
		{/* <Alicebanner/> */}
		<SellAliceList></SellAliceList>
		<SEllAliceText></SEllAliceText>

        
	</div>
}
export default SellAliceCard;