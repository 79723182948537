import React from 'react';
import MutateBanner from '../Mutate/Sections/MutateBanner';
import MutateTaxt from '../Mutate/Sections/MutateTaxt';
import '../Mutate/Mutate.css';
import { Outlet } from 'react-router-dom';
function Mutate(){
 return <div>
<MutateBanner/>
{/* <MutateTaxt/>
 */}
 <Outlet></Outlet>
</div>


}
export default Mutate;