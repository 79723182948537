import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Blacknet from "./WarCard/WARBlacknet";
import Humanity from "./WarCard/WARHumanity";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../../aws-exports";
// import '../../../breed.css'
import { GetCardFaction } from "../../../../../../UserPage/BlockChainFunctions";
import useGameCentreContract from "../../../../../../Hooks/GameCentreHook";
import WARHumanity from "./WarCard/WARHumanity";
import WARAlice from "./WarCard/WARAlice";
import { emptySlotContext, warCardContext } from "../../../../../../../App";
async function getGeneralDetails(TokenId, gameCentreContract) {
  const Value = await GetCardFaction(TokenId, gameCentreContract);
  return Value;
}
function AHWarBattleText({ state }) {
  const [leftWarSlot, setLeftWarSlot, rightWarSlot, setRightWarSlot]=useContext(emptySlotContext)

  const Id = state?.id;
  const navigate = useNavigate();
  const [warCard, setWarCard] = useContext(warCardContext);
  // console.log(state, "confirmation for war");
  const RoutingToConfirmation = ({ battleFaction }) => {
    // console.log(battleFaction, "factin");
    navigate("/confirmation", { state: { warCard, Id, battleFaction } });
  };
  return (
    <div>
      <section className="war_battle_card">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="war_card_scroll">
                <h2>ALICE</h2>
                <WARAlice state={state?.AliceId} BattleId={state?.id} availableSlot={leftWarSlot} setAvailableSlot={setLeftWarSlot}></WARAlice>
              </div>
            </div>
            <div className="col-md-6">
              <div className="war_card_scroll">
                <h2>HUMANITY</h2>
                <WARHumanity state={state?.HumanityId} BattleId={state?.id} availableSlot={rightWarSlot} setAvailableSlot={setRightWarSlot} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="confirm_button">
                {/* <Link className="btn" to="/confirmation">
                  CONFIRM SELECTION
                </Link> */}
                <button
                  className="war-button"
                  onClick={() => RoutingToConfirmation({ battleFaction: "AH" })}
                >
                  CONFIRM SELECTION
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default AHWarBattleText;
