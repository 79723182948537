import React from "react";
function WarHistoryBanner() {
  return (
    <div>
      <div className="warzone_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="warzone_text">
                <h1>War History </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WarHistoryBanner;
