import React from 'react';
import { useLocation } from 'react-router-dom';
import ConfirmationBanner from '../WarConfirmation/Sections/ConfirmationBanner';
import ConfirmationText from '../WarConfirmation/Sections/ConfirmationText';
import '../WarConfirmation/WarConfirmation.css';
function WarConfirmation(){
    const location = useLocation();
    // console.log(location.state,"confirmation");
    // console.log("hello confirmation");
 return <div>
<ConfirmationBanner/>
<ConfirmationText/>


 </div>


}
export default WarConfirmation;