import React from 'react';
import { Outlet } from 'react-router-dom';
import TransferCardsBanner from './Sections/TransferCardsBanner/TransferCardsBanner';
import TransText from './TransText';

const TransferSingle = () => {
    return (
        <div>
            <TransferCardsBanner></TransferCardsBanner>
            <Outlet></Outlet>
        </div>
    );
};

export default TransferSingle;