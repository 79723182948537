import React from 'react';
import AuctionAllcardsList from './Sections/AuctionAllcardsList';
import AllcardsList from './Sections/AuctionAllcardsList';
import AuctionAllCardsText from './Sections/AuctionAllcardsText';
import AllCardsText from './Sections/AuctionAllcardsText';

const AuctionAllCard = () => {
    return (
        <div>
            <AuctionAllcardsList></AuctionAllcardsList>
            <AuctionAllCardsText></AuctionAllCardsText>
        </div>
    );
};

export default AuctionAllCard;