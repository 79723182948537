import React from 'react';
import SellCongratulationsHero from '../SellCards/SellCongratulations/Sections/SellCongratulationsHero';
import SellCongratulationsText from '../SellCards/SellCongratulations/Sections/SellCongratulationsText';
import '../SellCards/SellCards.css';
function SellCongratulations () {
	return <div>
        <SellCongratulationsHero/>
		<SellCongratulationsText/>
	</div>
}
export default SellCongratulations;