import React from "react";
import { useLocation } from "react-router-dom";
import Loser from "./Section/Loser";
import Winner from "./Section/Winner";

const WarHistoryText = () => {
  const location = useLocation();
  // console.log(location?.state?.WinnerTokenID,"state of location");
  return (
    <div>
      <section className="war_battle_card">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="war_card_scroll">
                <h2>WINNER</h2>
                <Winner TokenId={location?.state?.WinnerTokenID}></Winner>
              </div>
            </div>
            <div className="col-md-6">
              <div className="war_card_scroll">
                <h2>LOSER</h2>
                <Loser TokenId={location?.state?.LosserTokenID}></Loser>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WarHistoryText;
