import React from 'react';
import Others from './Others';
import Self from './Self';

const BreedOthersBlacknetText = () => {
   return (
      <div className="d-flex w-100">
      <div className="col-6">
      <Self></Self>
      </div>
       <div className="col-6">
       <Others></Others>
       </div>
     </div>
   );
};

export default BreedOthersBlacknetText;