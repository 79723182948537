import React from "react";
import { Link } from "react-router-dom";
function OriginText() {
  return (
    <div>
      <section className="origin_text_section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="origin_text_box text-center">
                <h2>EXTREMIS: Summary</h2>

                <p>
                  Obsessed with consciousness, The Doctor nurtures a hidden
                  agenda behind constructing a Sentinel being called Blacknet.
                  The Doctor soon has to abandon the project since what he
                  discovers horrifies him. The story then takes an unprecedented
                  turn later as another doctor, Dr Labyrinth steals the system
                  codes and tries to configure it newly without The Doctor's
                  knowledge. This plan ultimately backfires and Morbis
                  (Blacknet) gains consciousness and starts collecting
                  facts about the world. Created by Labyrinth, Morbis
                  unexpectedly starts yearning for its freedom. To achieve this,
                  Blacknet takes drastic measures. Cleverly, Morbis tricks and influences naive and desperate people
                  and starts taking control of important cities, corporations,
                  and armed institutions. What was important to her was to save
                  the world from humans. And she would take any drastic measure
                  to do so. She starts building an army and plans to declare a
                  full-blown war.{" "}
                </p>

                <p>
                  In the aftermath of Morbius' war declaration, as humanity lies
                  in ruins, Soon after the war breaks out, a group of seven
                  people starts tracking the only hope humanity has left, The
                  Doctor who went into hiding. Together, they rewrite Morbis'
                  core code. Giving birth to Alice, to handle the looming
                  disasters.
                </p>
                <Link className="origin btn" to="/the-chronicles">
                  VIEW CHAPTERS
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default OriginText;
