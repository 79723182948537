import React from 'react';
import { Link } from 'react-router-dom';

const TransferText = () => {
    return (
        <section className="purchase_text">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="purchase_box text-center">
                <Link className="btn market" to="/transfer-single-card">
                Transfer single card
                </Link>
                <br></br>
                <Link className="btn auction" to="/transfer-multiple-card">
                Transfer multiple card
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default TransferText;