import React from 'react';
import ConfirmBanner from './Sections/ConfirmBanner';
import '../Confirm/Confirm.css';
import PromoteConfirmText from './Sections/PromoteConfirmText';
function PromoteConfirm(){
 return <div>
<ConfirmBanner/>

<PromoteConfirmText></PromoteConfirmText>

 </div>


}
export default PromoteConfirm;