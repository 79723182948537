import React, { useState, useEffect, useRef } from "react";
import "../UserPage/User.css";
import "./WalletContainer.css";
import { useContext } from "react";
import { addressContext, providerContext } from "../../App";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { Auth } from "aws-amplify";
import { connectWallet } from "../../App";
import { disconnectWallet } from "../../App";
import useBreedingContract from "../Hooks/BreedingContractHook";
import useFixedMarketContract from "../Hooks/FixedMarketHook";
import useGameCentreContract from "../Hooks/GameCentreHook";
import useAuctionContract from "../Hooks/AuctionContractHook";
import useCardOwnerShipContract from "../Hooks/CardOwnershipHook";
import useWarContract from "../Hooks/WarContractHook";

import useCustomerInfo from "../Hooks/useCustomerInfo";

export let Authentication;
let documentClient;
let getUser = async () => {
  const AuthUserCreds = await Auth.currentCredentials();

  try {
    AWS.config.update({
      credentials: AuthUserCreds,
      region: awsmobile.aws_cognito_region,
    });
  } catch (err) {
    // console.log(err);
  }

  documentClient = new AWS.DynamoDB.DocumentClient({
    credentials: AWS.config.credentials,
    region: awsmobile.aws_cognito_region,
  });
};

// async function getRegisteredAddressAndUserName(currentAddress) {
//   const documentClient = new AWS.DynamoDB.DocumentClient({
//     region: awsmobile.aws_cognito_region,
//     credentials: AWS.config.credentials,
//   });
//   console.log(currentAddress);
//   if (currentAddress) {
//     console.log(currentAddress);
//     const param = {
//       TableName: process.env.REACT_APP_CUSTOMERINFO_TABLE_NAME,
//       Key: {
//         CustomerID: currentAddress,
//       },
//     };
// console.log(param);
//     try {
//       const response = await documentClient.get(param).promise();
//       console.log(response);
//       return response
//     } catch (err) {
//       console.log(err);

//     }
//   } else {
//     console.log("erorr");
//   }
// }

function WalletContainer() {
  // const [username, setUserName] = useState("");
  // const [registeredWalletAddr, setRegisteredWalletAddr] = useState("");
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [address, setAddress] = useState(null);
  const [paired, setPaired] = useState(false);
  const [breedingContract, setBreedingContract] = useBreedingContract();
  const [warContract, setWarContract] = useWarContract();
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [fixedMarketContract, setFixedMarketContract] =
    useFixedMarketContract();
  const [auctionContract, setAuctionContract] = useAuctionContract();
  const [cardOwnerShipContract, setCardOwnerShipContract] =
    useCardOwnerShipContract();
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const [username, setUserName] = useState("");
  const [rAddress, setRAddress] = useState("");
  // console.log(customerInfo, "info");

  const ssm = new AWS.SSM();

  useEffect(() => {
    // console.log("get user");
    const GetUser = async () => {
      getUser();
    };
    GetUser();
  });

  useEffect(() => {
    const address = async () => {
      const userAddress = await walletProvider.getSigner().getAddress();
      setAddress(userAddress);
    };
    address();
  }, [walletProvider]);

  useEffect(() => {
    Authentication = async () => {
      if (customerInfo?.Item?.CustomerID) {
        setUserName(customerInfo?.Item?.Username);
        setRAddress(customerInfo?.Item?.CustomerID);
        setPaired(true);
      } else {
        setUserName("");
        setRAddress("");
      }
    };
    Authentication();
  }, [customerInfo]);

  useEffect(() => {
    // console.log(currentAddress, typeof currentAddress, "helelo");
    if (currentAddress === null || currentAddress === undefined) {
      const Pairing = async () => {
        setUserName("");
        setRAddress("");
        setPaired(false);
      };
      Pairing();
    }
  }, [currentAddress, walletProvider]);

  return (
    <div className="wallet-container ">
      <div className="wallet_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="wallet_text text-center ">
                <h1>Connect your wallet</h1>
                <p>
                  By connecting your wallet, you agree to our Terms of Use and{" "}
                  <br></br>our Privacy Policy.
                </p>
                <div className="wallet-section-button ">
                  {!walletProvider ? (
                    <button onClick={connectWallet}>connect wallet</button>
                  ) : (
                    <button onClick={disconnectWallet}>
                      disconnect wallet
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="connect-wallet">
        <h1>Wallet status</h1>
        <div className="wallet-box">
          <div className="wallet-box-username">
            <h2>Username</h2>
            <h4>{username}</h4>
          </div>
          <div className="wallet-box-address">
            <div>
              <h2>Registered address</h2>
              {rAddress && <p>{rAddress}</p>}
            </div>
            <div>
              <h2>Current address</h2>
              {walletProvider && <p>{currentAddress}</p>}
            </div>
          </div>
          {walletProvider ? (
            <div className="wallet-connected ">
              <div id="checked">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="46"
                  fill="currentColor"
                  class="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
                <h2>Connected</h2>
              </div>
              {paired ? (
                <div id="checked">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    fill="currentColor"
                    class="bi bi-check-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg>
                  <h2> Paired</h2>
                </div>
              ) : (
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    fill="currentColor"
                    class="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                  <h2>
                    Not <br /> Paired
                  </h2>
                </div>
              )}
            </div>
          ) : (
            <div className="wallet-not-connected">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                fill="currentColor"
                class="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
              <h2>
                Not <br /> Connected
              </h2>
            </div>
          )}
        </div>
      </div>

      <div className="hint">
        <p>*Tips & Tricks:</p>
        <p>
          - We recommend users use a brand new account or wallet to interact
          with our game for added security.
        </p>
        <p>
          - Never share you private key with anyone! Anyone who have access to
          your private key have access to your wallet.
        </p>
        <p>
          - The "Paired" indicator is turned on when both the registered and
          current wallet addresses match.
        </p>
        <p>
          - Please make sure the "Paired" indicator is check marked before any
          interaction with the Game. Users will be able to view NFTs from
          unregistered wallet addresses, but won't be able to interact with the
          Game, only registered wallet addresses can interact with our Game.
        </p>
        <p>
          - "Current Address" refers to the wallet address associated with the
          selected Metamask account.
        </p>
        <p>
          - "Registered Address" refers to the wallet address used during the
          Sign Up process.
        </p>
        <p>
          - Go and checkout{" "}
          <a
            href="https://academy.binance.com/en/articles/how-to-add-polygon-to-metamask"
            target="_blank"
            rel="noreferrer"
          >
            BINANCE ACADEMY
          </a>{" "}
          to know how to add polygon to metamask and checkout{" "}
          <a
            href="https://medium.com/prepo/setting-up-metamask-and-getting-eth-matic-on-polygon-step-by-step-guide-fd55147a0f05"
            target="_blank"
            rel="noreferrer"
          >
            MEDIUM
          </a>{" "}
          to know how to add matic in your wallet.
        </p>
      </div>
    </div>
  );
}

export default WalletContainer;
