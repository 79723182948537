import React from 'react';
import { Link } from 'react-router-dom';
function RestingFeatures(){
 return <div>
<section className="card_details_section mt-5">
         <div className="container">
            <div className="row">
                <div className="col-md-12">
                <div className="features_status">
                        <h2>FEATURES</h2>
                        <ul>
                                <li><span>Type 1</span><br></br>
                                <strong>Name</strong><hr></hr><strong>90%</strong><br></br> have this feature</li>
                                <li><span>Type 2</span><br></br>
                                <strong>Name</strong><hr></hr><strong>2%</strong><br></br>have this feature</li>
                                <li><span>Type 3</span><br></br>
                                <strong>Name</strong><hr></hr><strong>10%</strong><br></br>have this feature</li>
                                <li><span>Type 4</span><br></br>
                                <strong>Name</strong><hr></hr><strong>32%</strong><br></br>have this feature</li>
                                <li><span>Type 5</span><br></br>
                                <strong>Name</strong><hr></hr><strong>82%</strong><br></br>have this feature</li>
                        </ul>
                        <ul>
                                <li><span>Type 6</span><br></br>
                                <strong>Name</strong><hr></hr><strong>90%</strong><br></br> have this feature</li>
                                <li><span>Type 7</span><br></br>
                                <strong>Name</strong><hr></hr><strong>2%</strong><br></br>have this feature</li>
                                <li><span>Type 8</span><br></br>
                                <strong>Name</strong><hr></hr><strong>10%</strong><br></br>have this feature</li>
                                <li><span>Type 9</span><br></br>
                                <strong>Name</strong><hr></hr><strong>32%</strong><br></br>have this feature</li>
                                <li><span>Type 10</span><br></br>
                                <strong>Name</strong><hr></hr><strong>82%</strong><br></br>have this feature</li>
                        </ul>
                        <ul>
                                <li><span>Type 11</span><br></br>
                                <strong>Name</strong><hr></hr><strong>90%</strong><br></br> have this feature</li>
                                <li><span>Type 12</span><br></br>
                                <strong>Name</strong><hr></hr><strong>2%</strong><br></br>have this feature</li>
                                <li><span>Type 13</span><br></br>
                                <strong>Name</strong><hr></hr><strong>10%</strong><br></br>have this feature</li>
                                <li><span>Type 14</span><br></br>
                                <strong>Name</strong><hr></hr><strong>32%</strong><br></br>have this feature</li>
                                <li><span>Type 15</span><br></br>
                                <strong>Name</strong><hr></hr><strong>82%</strong><br></br>have this feature</li>
                        </ul>
                        <ul>
                                <li><span>Type 16</span><br></br>
                                <strong>Name</strong><hr></hr><strong>90%</strong><br></br> have this feature</li>
                                <li><span>Type 17</span><br></br>
                                <strong>Name</strong><hr></hr><strong>2%</strong><br></br>have this feature</li>
                                <li><span>Type 18</span><br></br>
                                <strong>Name</strong><hr></hr><strong>10%</strong><br></br>have this feature</li>
                                <li><span>Type 19</span><br></br>
                                <strong>Name</strong><hr></hr><strong>32%</strong><br></br>have this feature</li>
                                <li><span>Type 20</span><br></br>
                                <strong>Name</strong><hr></hr><strong>82%</strong><br></br>have this feature</li>
                        </ul>
                        </div>

                    </div>
            </div>
         </div>
      </section>
      <section className="card_details_section mt-5">
         <div className="container">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="features_tab">
                    <ul>
                            <li><Link to="/battle-list">ADD TO BATTLE LIST</Link></li>
                            <li><Link to="/breeding">ADD FOR BREEDING</Link></li>
                            <li><Link to="/rename">RENAME CARD</Link></li>
                            <li><Link to="/resting-card">REST CARD</Link></li>
                            <li><Link to="/resting-card">EXIT BREED LIST</Link></li>
                            <li><Link to="/promote">PROMOTE</Link></li>
                            <li><Link to="/mutate">MUTATE</Link></li>
                            <li><Link to="/war-zone">JOIN WAR</Link></li> 
                            <li><Link to="/war-zone">EXIT WAR</Link></li>
                            <li><Link to="/sell-cards">SELL</Link></li>
                            <li><Link to="/purchase-cards">AUCTION</Link></li>
                            <li><Link to="/sell-cards">WITHDRAW FROM AUCTION</Link></li>
                            <li><Link to="/sell-auction">WITHDRAW FROM FIXED MARKETPLACE</Link></li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    </div>
                    </section>
 </div>


}
export default RestingFeatures;