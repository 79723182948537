import React from 'react';
import { Link } from 'react-router-dom';
function AliceText () {
	return <div>
<section className="alicetext">
         <div className="container">
            <div className="row">
              
               <div className="col-md-10 offset-md-1">
                  <div className="alicetext_box text-center">
                      <h2>ALICE</h2>
                     <p>Chaos ensues after Blacknet declares war against the humans. Humans are caught in a precarious situation as they have to fight a powerful opponent unhindered by exhaustion. Humans had no way to gain the upper level on such an advanced being, perfect in all aspects.</p>

                     <p>As the situation worsens, Humanity has to immediately come up with solutions to end the ongoing rampage by Blacknet. Otherwise, they face the risk of extinction.</p>

                     <p>During the war, the doctor creates another artificial being, Alice, the ally and savior of Humanity. Alice is created to help humans to fight the war against Blacknet. But Alice too was posed with the same dilemma previously visible in Morbius.</p> 

                     <p>To not let Alice turn into a potential threat like Blacknet, the doctor inserts the capability of analyzing and detecting feelings in her. Equipping her with the best sensors available to man. Alice inherently realizes the job and sets out to save human civilization by building her idealistic army of Sentinel beings. Alice and her army join forces with humans and have the common goal of defeating Blacknet.</p>
                     <Link to="/cardcollection/alice-card" className='btn'>SEE ALICE CARDS</Link>
                  </div>
               </div>
                
            </div>
         </div>
      </section>
    </div>
    }
    export default AliceText;