import React from 'react';
import '../TransferCollection/CardCollection.css';
import TransferGenesisList from './SubPages/Genesis/Sections/TransferGenesisList';
import TransferGenesisText from './SubPages/Genesis/Sections/TransferGenesisText';

function TransferGenesisCard () {
	return <div>
		{/* <Genesisbanner/> */}
		<TransferGenesisList></TransferGenesisList>
		<TransferGenesisText></TransferGenesisText>

        
	</div>
}
export default TransferGenesisCard;