import React from 'react';
import Alicebanner from '../BreedingListCollection/SubPages/AliceCard/Sections/Alicebanner';
import AliceText from './SubPages/AliceCard/Sections/BreedListAliceText';
import AliceList from './SubPages/AliceCard/Sections/BreedListAliceList';
import '../BreedingListCollection/CardCollection.css';
import BreedListAliceList from './SubPages/AliceCard/Sections/BreedListAliceList';
import BreedListAliceText from './SubPages/AliceCard/Sections/BreedListAliceText';

function BreedingListAliceCard () {
	return <div>
		{/* <Alicebanner/> */}
		<BreedListAliceList/>
		<BreedListAliceText/>

        
	</div>
}
export default BreedingListAliceCard;