import React, { useContext, useEffect, useState } from "react";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../aws-exports";
import useUsername from "../../../../../Hooks/useUsername";
import { Auth } from "aws-amplify";
const LeaderBoardChild = ({ position, i }) => {
  const [imageSrc, setImageSrc] = useState("");

  const replaceImage = (error) => {
    //replacement of broken Image
    error.target.src = "/ErrorImage/profile_img.png";
  };
  const [accessKeyId, setAccessKey] = useState("");
  useEffect(() => {
    const cred = async () => {
      const auth = await Auth.currentCredentials();
      setAccessKey(auth);
    };
    cred();
  }, []);
  function encode(data) {
    var str = data?.reduce(function (a, b) {
      return a + String.fromCharCode(b);
    }, "");
    return btoa(str).replace(/.{76}(?=.)/g, "$&\n");
  }
 
  useEffect(() => {
    if (
      awsmobile.aws_cognito_region &&
      AWS.config.credentials &&
      position.CustomerID &&
      accessKeyId
    ) {
      const s3 = new AWS.S3({
        credentials: accessKeyId,
      });
      var getParams = {
        Bucket: "extremis-user-profilepics",
        Key: `ProfilePicture/${position.CustomerID}`,
      };

      s3.getObject(getParams, function (err, data) {
        if (data?.Body) {
          const src = encode(data.Body);
          setImageSrc(src);
        } else if (err) {
          console.log(err);
        }
      });
    }
  }, [
    awsmobile.aws_cognito_region,
    AWS.config.credentials,
    position.CustomerID,
    accessKeyId,
  ]);

  // console.log(index);
  const Username = useUsername(position.CustomerID);
console.log(imageSrc);
  return (
    <div className="leaderboard-child">
      <div className="profile text-start">
        <div className="profie_img">
          <img
            // src={`https://tokencharacterimages.s3.amazonaws.com/ProfilePicture/${position.CustomerID}`}
            src={`data:image/jpeg;base64,${imageSrc}`}
            alt=""
            onError={replaceImage}
          />
        </div>
        <div className="user_name">
          <span># {i + 1}</span>
          <h5>{Username}</h5>
        </div>
      </div>
      <div className="text-end">
        <div className="info_user">
          <p>Total won</p>
          <strong>{position.WinCount}</strong>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardChild;
