import React from 'react';
import RenameCongratulationsBanner from '../RenameCongratulations/Sections/RenameCongratulationsBanner';
import RenameCongratulationsText from '../RenameCongratulations/Sections/RenameCongratulationsText';
import '../RenameCongratulations/RenameCongratulations.css';
function RenameCongratulations(){
 return <div>
<RenameCongratulationsBanner/>
<RenameCongratulationsText/>


 </div>


}
export default RenameCongratulations;