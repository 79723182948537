import React from "react";
import Chapter1 from "../Sections/WhitepaperTab/Chapter1";
import Chapter2 from "../Sections/WhitepaperTab/Chapter2";
import Chapter3 from "../Sections/WhitepaperTab/Chapter3";
import Chapter4 from "../Sections/WhitepaperTab/Chapter4";
import Chapter5 from "../Sections/WhitepaperTab/Chapter5";
import Chapter6 from "../Sections/WhitepaperTab/Chapter6";
import Chapter7 from "../Sections/WhitepaperTab/Chapter7";
import Chapter8 from "../Sections/WhitepaperTab/Chapter8";
import Chapter9 from "../Sections/WhitepaperTab/Chapter9";
import Chapter10 from "../Sections/WhitepaperTab/Chapter10";
import { Link } from "react-router-dom";
function WhitepaperText() {
  return (
    <div>
      <div className="board_section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1">
              <div className="whitepaper_tab">
                <div className="tab-content" id="myTabContent">
                  <div className="whitepaper_text_section">
                    <h2 className="text-center">WHITEPAPER</h2>


                    <iframe src="https://docs.google.com/gview?url=https://tokencharacterimages.s3.amazonaws.com/WhitePaper/WHITEPAPER.pdf&embedded=true"  frameborder="0" title="WhitePaper"></iframe>
                    <a href="https://tokencharacterimages.s3.amazonaws.com/WhitePaper/WHITEPAPER.pdf"  target="_blank" rel="noreferrer" download="WhitePaper.pdf" class="text-center btn">
                      DOWNLOAD PDF
                    </a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WhitepaperText;
