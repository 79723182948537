import React from "react";
// import Humanitybanner from '../Cardcollection/SubPages/Humanity/Sections/Humanitybanner';
import HumanityText from "./SubPages/Humanity/Sections/BreedSelfHumanityText";
import HumanityList from "./SubPages/Humanity/Sections/BreedSelfHumanityList";
import "../BreedSelfCollection/CardCollection.css";
import BreedSelfHumanityList from "./SubPages/Humanity/Sections/BreedSelfHumanityList";
import BreedSelfHumanityText from "./SubPages/Humanity/Sections/BreedSelfHumanityText";
import DragBreedCard from "../../../../Shared/DnD/DragBreedCard";

function BreedSelfHumanityCard() {
  return (
    <div>
      {/* <Humanitybanner/> */}
      <div className="drag-and-card">
        <div className="card-area">
          <BreedSelfHumanityList />
          <BreedSelfHumanityText />
        </div>
        <div className="drag-area">
          <DragBreedCard></DragBreedCard>
        </div>
      </div>
    </div>
  );
}
export default BreedSelfHumanityCard;
