import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import gallery_user from "../Images/gallery_user.png";

const WeaponsChild = ({ weapon, i }) => {
  const [price, setPrice] = useState("5");
  const [image, setImage] = useState("");

  const Price = (Power) => {
    setPrice((parseInt(Power) * 5).toString());
  };
  const Image = (Name) => {
    setImage(Name.split(" ").join(""));
  };

  useEffect(() => {
    Price(weapon.Power);
  }, [weapon.Power]);

  useEffect(() => {
    Image(weapon.Name);
  }, [weapon.Name]);

  return (
    <div>
      <div className="weapons_list">
        <ul>
          <li className="weapons_profile text-start">
            <div className="weapons_content">
              <div className="weapons_img">
                {image && (
                  <img
                    src={`/Weapon/${weapon.Type}/${image}.png`}
                    alt=""
                  />
                )}
              </div>
              <div className="name_price">
                <div className="weapons_name ">
                  <div className="">
                    <span># {i + 1}</span>
                    <h5>{weapon.Name}</h5>
                    <h4>{weapon.Type}</h4>
                  </div>
                  <div className="weapons_rank ">
                    <p>
                      Power<br></br>
                      <span>{weapon.Power}</span>
                    </p>
                  </div>
                </div>
                <div className="product_price">
                  <p>Price</p>
                  <strong>{price} MATIC</strong>
                </div>
              </div>
            </div>
          </li>
        
        </ul>
      </div>
    </div>
  );
};

export default WeaponsChild;
