import React from "react";
import '../Roadmap.css'
const RoadmapBanner = () => {
  return (
    <div>
      <div className="Page_roadmap_section">
        {/* <img src={more_banner}/> */}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page_title">
                <h1>ROADMAP</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadmapBanner;
