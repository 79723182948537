import React from 'react';
function Plotbanner () {
	return <div>
<div className="Page_plotsummary_section">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="page_title">
                    
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
}
export default Plotbanner;