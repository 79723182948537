import React, { useEffect } from "react";
import { useState } from "react";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
const useWon = (address) => {
  const [won, setWon] = useState("");
  const GetCustomerInfo = async (owner) => {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });
    const param = {
      TableName: process.env.REACT_APP_BOARD_TABLE_NAME,
      Key: {
        CustomerID: address,
      },
    };
    const response = await documentClient.get(param).promise();
    return response;
  };

  useEffect(() => {
    const Won = async () => {
      if (awsmobile.aws_cognito_region && AWS.config.credentials) {
        const response = await GetCustomerInfo();
        // console.log(response,"inside useeffect");
        setWon(response?.Item?.WinCount);
      }
    };
    Won();
  }, [awsmobile.aws_cognito_region, AWS.config.credentials]);
  return won;
};

export default useWon;
