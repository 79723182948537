import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { addressContext } from "../../../../../App";
import useCard from "../../../../Shared/Card/useCard";
import useImage from "../../../../Shared/Card/useImage";
import Loader from "../../../../Shared/Loader/Loader";
import useColor from "../../../../Shared/useColor";
import useStats from "../../../../Shared/useStates";
import alice_logo from "../Sections/Images/alice_logo.png";
import blacknet_logo from "../Sections/Images/blacknet_logo.png";
import humanity_logo from "../Sections/Images/humanity_logo.png";
import deceased from "../../../../../assets/Deceased tag for character death-01.png";
import useRankImage from "../../../../Shared/useRankImage";

function CardDetails({ TokenId, setGenesis }) {
  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardOwner,
    CardOwnerUsername,
  ] = useCard(TokenId);
  const [contract, setContract] = useState();
  const [
    IQ,
    EQ,
    Wisdom,
    Defense,
    Weapon,
    Leadership,
    Melee,
    Potential,
    NaturalAdpt,
  ] = useStats(TokenId);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [image, setImage] = useState(false);
  const [deceasedTag, setDeceasedTag] = useState(false);
  const uploaded = useImage(CardFaction, CardGender, TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  useEffect(() => {
    if (CardOwnerUsername === "Deceased") {
      setDeceasedTag(true);
    }
  }, [CardOwnerUsername]);
  useEffect(() => {
    // console.log(uploaded,"uploaded from card details");
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);
// console.log(uploaded ,'uploaded from outside');
  useEffect(() => {
    if (CardOwner) {
      if (CardOwner === process.env.REACT_APP_WAR_CONTRACT_ADDR) {
        setContract("War Contract Address");
      } else if (CardOwner === process.env.REACT_APP_BREEDING_CONTRACT_ADDR) {
        setContract("Breed Contract Address");
      } else if (
        CardOwner === process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR
      ) {
        setContract("Fixed Market Contract Address");
      } else if (CardOwner === process.env.REACT_APP_AUCTION_CONTRACT_ADDR) {
        setContract("Auction House Contract Address");
      } else if (
        CardOwner === process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR
      ) {
        setContract("Card ownership Contract Address");
      } else if (CardOwner === process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR) {
        setContract("Game Centre Contract");
      } else {
        setContract(CardOwnerUsername);
      }
    }
  }, [CardOwner, CardOwnerUsername]);
  useEffect(() => {
    if (CardType === "Genesis") {
      setGenesis(true);
    }
  },[CardType,setGenesis]);


  return (
    <div>
      <section className="card_details_section mt-5 word-wrapping">
        <div className="container">
          {/* {!image ? (
            <Loader></Loader>
          ) : ( */}
            <div className="row">
              <div className="col-md-7 deceased">
                <img
                  className={color}
                  src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${TokenId}`}
                  alt=""
                />
                <img
                  className="rankImage"
                  src={`/Rank/${rankImage}.png`}
                  alt=""
                />
                <img
                  className="extremis-logo"
                  src="/LOGO.png"
                  alt=""
                />
                <div className="deceased-tag">
                  {deceasedTag && <img src={deceased} alt="" />}
                </div>
              </div>

              <div className="col-md-5">
                <div className="details_card">
                  <h2>{CardName}</h2>
                  <p>
                    <span>ID:</span> <strong>{TokenId}</strong>
                  </p>
                  <p>
                    <span>FACTION:</span> <strong>{CardFaction}</strong>
                  </p>
                  <p>
                    <span>CARD TYPE: </span> <strong>{CardType}</strong>
                  </p>
                  <p>
                    <span>RANK: </span> <strong>{CardRank}</strong>
                  </p>

                  <p>
                    <span>OWNER:</span> <strong>{contract}</strong>
                  </p>

                  <ul>
                    <li className="border-bottom border-end">
                      {CardFaction === "Alice" && (
                        <img src={alice_logo} alt="" />
                      )}
                      {CardFaction === "BlackNet" && (
                        <img src={blacknet_logo} alt="" />
                      )}
                      {CardFaction === "Humanity" && (
                        <img src={humanity_logo} alt="" />
                      )}
                    </li>
                    {/* <li className="border-bottom">
                     {NaturalAdpt}
                  </li> */}
                    <li className="border-bottom">
                      <p>
                        {CardFaction === "Humanity" ? (
                          <span>N. Adaptability </span>
                        ) : (
                          <span>AI Influence </span>
                        )}
                        <br />
                        {NaturalAdpt}
                      </p>
                    </li>

                    <li className="border-bottom border-end">
                      <p>
                        <span>IQ</span>
                        <br></br> {IQ}
                      </p>
                    </li>
                    <li className="border-bottom">
                      <p>
                        <span>EQ</span>
                        <br></br> {EQ}
                      </p>
                    </li>
                    <li className="border-bottom border-end">
                      <p>
                        <span>Wisdom</span>
                        <br></br> {Wisdom}
                      </p>
                    </li>
                    <li className="border-bottom">
                      <p>
                        <span>Potential</span>
                        <br></br> {Potential}
                      </p>
                    </li>
                    <li className="border-bottom border-end">
                      <p>
                        <span>Weapon Skill</span>
                        <br></br> {Weapon}
                      </p>
                    </li>
                    <li className="border-bottom">
                      <p>
                        <span>Melee Skill</span>
                        <br></br> {Melee}
                      </p>
                    </li>
                    <li className="border-bottom-0 border-end">
                      <p>
                        <span>Leadership Skill</span>
                        <br></br> {Leadership}
                      </p>
                    </li>
                    <li className="border-bottom-0">
                      <p>
                        <span>Defensive Skill</span>
                        <br></br> {Defense}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          {/* )} */}
        </div>
      </section>
    </div>
  );
}
export default CardDetails;
