import React from 'react';
function RestingCardStatus(){
 return <div>
<section className="card_details_section mt-5">
         <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="current_status">
                        <h2>CURRENT STATUS</h2>
                        <ul>
                        <li><span>Breed list</span><br></br>
                                <strong>Yes</strong></li>
                                <li><span>Last Breeding time</span><br></br>
                                <strong>06:42:36</strong></li>
                                <li><span>Battle list</span><br></br>
                                <strong>No</strong></li>
                                <li><span>Auction list</span><br></br>
                                <strong>No</strong></li>
                                <li><span>Marketplace</span><br></br>
                                <strong>Yes</strong></li>
                                <li><span>In War</span><br></br>
                                <strong>No</strong></li>
                        </ul>
                        </div>
          </div>
            </div>
         </div>
      </section>
 </div>


}
export default RestingCardStatus;