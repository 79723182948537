import React from 'react';
import AuctionSellAllcardsList from './Sections/AuctionSellAllcardsList';
import AuctionSellAllCardsText from './Sections/AuctionSellAllcardsText';

const AuctionSellAllCard = () => {
    return (
        <div>
            <AuctionSellAllcardsList></AuctionSellAllcardsList>
            <AuctionSellAllCardsText></AuctionSellAllCardsText>
        </div>
    );
};

export default AuctionSellAllCard;