import React from 'react';
import StoryBanner from '../Story/Sections/StoryBanner';
import StoryText from '../Story/Sections/StoryText';
import '../Story/Story.css';
function Story () {
	return <div>
		<StoryBanner/>
        <StoryText/>
        
	</div>
}
export default Story;