import React, { createContext, useContext, useEffect, useState } from "react";
import "../UserPage/User.css";
import { putDataCustomerInfoTbl } from "./dynamoData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import SignUpConfirm from "./SignUpConfirm";
import { addressContext, providerContext } from "../../App";
import PopUp from "../Shared/PopUp";
import LoaderFloating from "../Shared/LoaderFloating";
function Signup() {
  const [confirmation, setConfirmation] = useState(false);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [dynamoData, setDynamoData] = useState();
  const [loading, setLoading] = useState();
  // const [currentAddress,setcurrentAddress]=useState("")
  const [error, setError] = useState();
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");

  const day = [];
  for (let i = 1; i <= 31; i++) {
    const element = i;
    day.push(element);
  }
  const month = [];
  for (let i = 1; i <= 12; i++) {
    const element = i;
    month.push(element);
  }
  //year
  const year = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear-13; i >= 1905; i--) {
    const element = i;
    year.push(element);
  }
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "all" });
  const signUp = async (data) => {
    // setLoading(true);
    if (currentAddress) {
      // console.log(data, "data");
      const day = ("0" + data.day).slice(-2);
      const month = ("0" + data.month).slice(-2);
      setLoading(true);
      setDynamoData(data);
      // console.log(dynamoData, "dyna1");

      const SignUpData = {
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          given_name: data.firstName,
          family_name: data.lastName,
          preferred_username: data.userName.toUpperCase(),
          birthdate: `${data.year}-${month}-${day}`,
          phone_number: data.phoneNumber,
        },
      };
      if (data.password !== data.cpassword) {
        setLoading(false)
        setConfirmation(true);
      } else {
        try {
          const user = await Auth.signUp(SignUpData);

          setLoading(false);
          // console.log("signup confirmation", user);
          if (user) {
            setConfirmPopUp(true);
          }
        } catch (err) {
          // setError(err);
          // console.log("error in signup");
          setLoading(false);
          setPopUpSubject("Oops");
          setPopUpText("Email already exist");
          setPopUp(true);
        }
      }
    } else {
      setPopUpSubject("PLEASE CONNECT YOUR WALLET");
      setLoading(false);
      setPopUp(true);
    }
  };
  const [passVisibility, setPassVisibility] = useState(false);
  const [cpassVisibility, setCPassVisibility] = useState(false);
  const [walletVisibility, setWalletVisibility] = useState(false);
  const TogglePass = () => {
    setPassVisibility(!passVisibility);
  };
  const ToggleCPass = () => {
    setCPassVisibility(!cpassVisibility);
  };
  const ToggleWallet = () => {
    setWalletVisibility(!walletVisibility);
  };
  // const onSubmit = async (data, e) => {
  //   e.target.reset();
  //   if (data.password !== data.cpassword) {
  //     setConfirmation(true);
  //   } else {
  //     data = {
  //       ...data,
  //       ...{ CustomerID: `` },
  //     };
  //     localStorage.setItem("form", JSON.stringify(data));

  //     //check putData...
  //   }
  // };

  return (
    <div>
      <div className="login_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 offset-md-6">
              <div className="logup_form">
                <h2>SIGN UP</h2>
                <form onSubmit={handleSubmit(signUp)} className="signup-form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          {...register("firstName", {
                            required: true,
                          })}
                          placeholder="First name*"
                        />
                        {errors.firstName?.type === "required" && (
                          <p className="error">First name is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          {...register("lastName", {
                            required: true,
                          })}
                          placeholder="Last name*"
                        />
                        {errors.lastName?.type === "required" && (
                          <p className="error">Last name is required</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          {...register("userName", { required: true })}
                          placeholder="Username*"
                        />
                        {errors.userName?.type === "required" && (
                          <p className="error">User name is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <input
                          type="tel"
                          className="form-control"
                          {...register("phoneNumber", {
                            required: true,
                            minLength: 11,
                            pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                          })}
                          placeholder="Phone number*"
                        />
                        {errors.phoneNumber?.type === "required" && (
                          <p className="error">Phone number is required</p>
                        )}
                        {errors.phoneNumber?.type === "minLength" && (
                          <p className="error">
                            Your phone number must be at least 11 characters
                            long
                          </p>
                        )}
                        {errors.phoneNumber?.type === "pattern" && (
                          <p className="error">
                            Provide a valid phone number with country code. Ex:
                            +xxxxxxxxxx
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      {...register("email", {
                        required: true,
                        pattern: /^[^\s@]+@[^\s@]{2,}$/i,
                      })}
                      placeholder="Email address*"
                    />
                    {errors.email?.type === "required" && (
                      <p className="error">Email is required</p>
                    )}
                    {errors.email?.type === "pattern" && (
                      <p className="error">Email is invalid</p>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <div className="toggle">
                      <input
                        type={passVisibility ? "text" : "password"}
                        className="form-control"
                        {...register("password", {
                          required: true,
                          minLength: 8,
                        })}
                        placeholder="Password*"
                        id="id_password"
                      />
                      <i
                        onClick={TogglePass}
                        className={
                          !passVisibility ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                        id="togglePassword"
                      ></i>
                    </div>
                    {errors.password?.type === "required" && (
                      <p className="error">Password is required</p>
                    )}
                    {errors.password?.type === "minLength" && (
                      <p className="error">
                        Your password must be at least 8 characters long
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <div className="toggle">
                      <input
                        type={cpassVisibility ? "text" : "password"}
                        className="form-control"
                        {...register("cpassword", {
                          required: true,
                          minLength: 8,
                        })}
                        placeholder="Confirm password*"
                        id="id_password"
                      />
                      <i
                        onClick={ToggleCPass}
                        className={
                          !cpassVisibility ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                        id="togglePassword"
                      ></i>
                    </div>
                    {errors.cpassword?.type === "required" && (
                      <p className="error">Confirm Password is required</p>
                    )}
                    {confirmation && (
                      <p className="error">Password doesn't match!</p>
                    )}
                  </div>
                  <p className="dob">Date of birth </p>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group ">
                        <select
                          className="select"
                          name="day"
                          id="day"
                          {...register("day", {
                            required: true,
                          })}
                        >
                          {day.map((day) => (
                            <option value={day}>{day}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group ">
                        <select
                          className="select"
                          name="month"
                          id="month"
                          {...register("month", {
                            required: true,
                          })}
                        >
                          {month.map((month) => (
                            <option value={month}>{month}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group ">
                        <select
                          className="select"
                          name="year"
                          id="year"
                          {...register("year", {
                            required: true,
                          })}
                        >
                          {year.map((year) => (
                            <option value={year}>{year}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {currentAddress && (
                    <div className="form-group mb-3">
                      {currentAddress && <p className="dob">Wallet Address</p>}
                      <div className="toggle">
                        <div className="toggle">
                          <input
                            type={walletVisibility ? "text" : "password"}
                            // type="text"
                            value={currentAddress}
                            className="form-control"
                            {...register("wallet", { required: true })}
                            placeholder="Wallet address ( Connect your Wallet* )"
                            id="id_password"
                          />
                          <i
                            onClick={ToggleWallet}
                            className={
                              walletVisibility
                                ? "fas fa-eye-slash"
                                : "fas fa-eye"
                            }
                            id="togglePassword"
                          ></i>
                        </div>
                        {errors.wallet?.type === "required" && (
                          <p className="error">Wallet address is required</p>
                        )}
                      </div>
                    </div>
                  )}
                  {!walletProvider && (
                    <div className="form-group">
                      <Link
                        to="/connect-wallet"
                        className="form-control btn btn-primary rounded submit px-3"
                      >
                        CONNECT WALLET
                      </Link>
                    </div>
                  )}
                  <br></br>
                  <div className="form-group ">
                    <input
                      type="submit"
                      value={!loading ? "SIGN UP" : "SIGNING UP..."}
                      className="form-control signup-button btn btn-primary rounded submit px-3"
                    />
                  </div>
                </form>
                {confirmPopUp && (
                  <SignUpConfirm
                    setConfirmPopUp={setConfirmPopUp}
                    dynamoData={dynamoData}
                  ></SignUpConfirm>
                )}

                <ToastContainer />

                <div className="signup_text text-center">
                  <p>
                    Already have an account? <Link to="/signin">SIGN IN</Link>
                  </p>
                </div>
                {popup && (
                  <PopUp
                    popUpSubject={popUpSubject}
                    popUpText={popUpText}
                    setPopUp={setPopUp}
                  ></PopUp>
                )}
                {loading && <LoaderFloating></LoaderFloating>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Signup;
