import React from 'react';
import PurchaseBanner from '../PurchaseCards/Sections/PurchaseBanner';
import PurchaseText from '../PurchaseCards/Sections/PurchaseText';
import '../PurchaseCards/PurchaseCards.css';
function PurchaseCards () {
	return <div>
        <PurchaseBanner/>
		<PurchaseText/>
	</div>
}
export default PurchaseCards;
