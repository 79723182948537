import React, { useEffect, useState } from "react";
import Pagination from "../../../../../../../Shared/Pagination";
import useBattleListSelfCard from "../../../../../../../Hooks/useBattleListSelfCard";
import Loader from "../../../../../../../Shared/Loader/Loader";
import BattleCard from "../../../../../../../Shared/BattleCard/BattleCard";

function Self() {
  const [TokenId, page, setPage, next, Next, Previous, loader] =
    useBattleListSelfCard();
  return (
    <div>
      <div className="">
        <div className="">
          <div className="col-md-10 offset-md-1">
            <div className="">
              <h4 className="text-danger">BATTLE READY</h4>
              {loader ? (
                <Loader></Loader>
              ) : (
                <>
                  <div className="row">
                    {TokenId.slice((page - 1) * 10, page * 10).map(
                      (TokenId) => (
                        <BattleCard
                          TokenId={TokenId}
                          key={TokenId}
                        ></BattleCard>
                      )
                    )}
                    {TokenId?.length === 0 && <h1>NO CARD</h1>}
                  </div>
                  <Pagination
                    page={page}
                    setPage={setPage}
                    length={TokenId.length / 10}
                    Previous={Previous}
                    Next={Next}
                    next={next}
                  ></Pagination>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Self;
