import React from 'react';
import { Link } from 'react-router-dom';
import blacknet from "./Images/blacknet.png";
function MetaverseBlacknet () {
	return <div>
<section className="image_text_box_section">
   <div className="container">
      <div className="row align-items-center">
         <div className="col-md-6">
            <div className="box_image">
               <img src={blacknet}/>
            </div>
         </div>
         <div className="col-md-6">
            <div className="text_box">
               <h3>BLACKNET</h3>
               <p>Created to help humans solve complex problems in the 2040s, Morbius is a self-learning AI having a brilliant caliber that the world has never seen before. Over time, it evolves into a conscious being. Morbius learns to use its consciousness to its fullest extent and learns to change its core code at will. It deems humans a threat to the planet and pledges to eradicate human civilization. The army of "Blacknet" is thus born.</p>
               <ul>
                  <li><Link to="/cardcollection/blacknet-card" className='btn'>SEE BLACKNET CARDS</Link></li>
                  <li><Link to="/blacknet" className='btn'>READ MORE</Link></li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</section>
</div>
    }
    export default MetaverseBlacknet;