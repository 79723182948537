import React from "react";
import ConfirmationMessageBanner from "../ConfirmationMessage/Sections/ConfirmationMessageBanner";
import ConfirmationMessageText from "../ConfirmationMessage/Sections/ConfirmationMessageText";
import "../ConfirmationMessage/ConfirmationMessage.css";
import { useLocation } from "react-router-dom";
function ConfirmationMessage() {
  const location = useLocation();
  // console.log(location.state,"confirmation");
  // console.log("hello confirmation");
  return (
    <div>
      <ConfirmationMessageBanner />
      <ConfirmationMessageText />
    </div>
  );
}
export default ConfirmationMessage;
