import React from "react";
import DragBreedOthers from "../../../../Shared/DnD/DragBreedOthers";
import "../BreedOthersCollection/CardCollection.css";
import BreedOthersAliceList from "./SubPages/AliceCard/Sections/BreedOthersAliceList";
import BreedOthersAliceText from "./SubPages/AliceCard/Sections/BreedOthersAliceText";

function BreedOthersAliceCard() {
  return (
    <div>
      {/* <Alicebanner/> */}
      {/* <AliceList/> */}
      {/* <AliceText/> */}
      <div className="drag-and-card">
        <div className="card-area">
          <BreedOthersAliceList></BreedOthersAliceList>
          <BreedOthersAliceText></BreedOthersAliceText>
        </div>
        <div className="drag-area">
          <DragBreedOthers />

		</div>
      </div>
    </div>
  );
}
export default BreedOthersAliceCard;
