import React from 'react';
import MutateCongratulationsBanner from '../MutateCongratulations/Sections/MutateCongratulationsBanner';
import MutateCongratulationsText from '../MutateCongratulations/Sections/MutateCongratulationsText';
import '../MutateCongratulations/MutateCongratulations.css';
function MutateCongratulations(){
 return <div>
<MutateCongratulationsBanner/>
<MutateCongratulationsText/>


 </div>


}
export default MutateCongratulations;