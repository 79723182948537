import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useImage from "../Card/useImage";
import useColor from "../useColor";

import {
  exitBreedList,
  GetCardFinalBreedFee,
} from "../../UserPage/BlockChainFunctions";
import { addressContext, providerContext } from "../../../App";
import * as AWS from "aws-sdk";
import { useState } from "react";
import Loader from "../Loader/Loader";
import "../Card.css";
import { useEffect } from "react";
import useCard from "../Card/useCard";
import useRankImage from "../useRankImage";
import PopUp from "../PopUp";
import useCustomerInfo from "../../Hooks/useCustomerInfo";
import LoaderFloating from "../LoaderFloating";
import useBreedingContract from "../../Hooks/BreedingContractHook";

function BreedListCard(props) {
  const ssm = new AWS.SSM();
  const navigation = useNavigate();
  const [image, setImage] = useState(false);
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [selfCard, setSelfCard] = useState(false);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState();
  const [popUpText1, setPopUpText1] = useState();
  const [loading, setLoading] = useState(false);
  const customerInfo = useCustomerInfo(currentAddress);
  const [navigationCntxt, setNavigationCntxt] = useState(false);
  const [navConfirm, setNavConfirm] = useState(false);

  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardOwner,
    CardOwnerUsername,
  ] = useCard(props.TokenId);
  const [fee, setFee] = useState("");
  const [breedingContract, setBreedingContract] = useBreedingContract();

  const BreedFee = async () => {
    const result = await GetCardFinalBreedFee(props.TokenId, breedingContract);
    setFee(result);
  };
  useEffect(() => {
    if (breedingContract) {
      BreedFee();
    }
  }, [breedingContract]);
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const rankImage = useRankImage(CardRank);
  const color = useColor(CardRank, CardType);
  useEffect(() => {
    if (uploaded === true) {
      setImage(true);
    }
  }, [uploaded]);
  useEffect(() => {
    if (CardOwner === currentAddress) {
      setSelfCard(true);
    }
  }, [CardOwner, currentAddress]);

  const nav = (props) => {
    navigation(`/individual-card/${props}`);
  };

  const withBreed = (props) => {
    if (CardFaction !== "BlackNet") {
      navigation(`/breed-Others/${CardFaction.toLowerCase()}`, {
        state: { TokenId: props },
      });
    } else {
      navigation(`/breed-Others`, {
        state: { TokenId: props },
      });
    }
  };

  const ExitList = async () => {
    setLoading(true);
    setPopUpSubject("")
    setPopUpText("")
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      try {
        const result = await exitBreedList(props.TokenId, walletProvider, ssm);
        // console.log(result, "exitlist");
        setLoading(false);
        if (result.txreceipt?.blockHash) {
          setPopUpSubject("REMOVED FROM BREED LIST");
          setPopUpText(`Transaction Hash: ${result.txreceipt.transactionHash}`);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
          setNavConfirm(true);
        }
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      } catch (err) {
        console.log(err, "error from exit err");
        setLoading(false);
      }
    } else {
      setPopUpSubject("Connect your registered wallet");
      setPopUp(true);
    }
  };
  useEffect(() => {
    if (navigationCntxt === true && navConfirm === true) {
      window.location.reload();
    }
  }, [navigationCntxt, navConfirm]);
  return (
    <div className="col-md-6 word-wrapping">
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_box card_img">
          <img
            onClick={() => {
              nav(props.TokenId);
            }}
            style={
              CardFaction && CardType && CardRank ? {} : { display: "none" }
            }
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />
          <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />{" "}
          <img
            className="extremis-logo"
            src="/LOGO.png"
            alt=""
          />
          {CardFaction && CardType && CardRank ? (
            <>
              <div className="card_info">
                <h3>{CardName}</h3>{" "}
                <p className="fs-6 text-white m-0">ID: {props.TokenId}</p>
              </div>
              <div className="card_info">
                <div className="card_type">
                  <p>
                    FACTION: <strong>{CardFaction}</strong>
                  </p>
                  <p>
                    TYPE: <strong> {CardType}</strong>
                  </p>
                  <p>
                    RANK: <strong>{CardRank}</strong>
                  </p>
                </div>
                <div className="card_rank  features_tab">
                  <p>
                    <span>Fee: </span> {fee} Matic
                  </p>
                  <li>
                    {selfCard ? (
                      <button className=" " onClick={ExitList}>
                        {loading ? "PLEASE WAIT " : "EXIT BREEDING LIST"}
                      </button>
                    ) : (
                      <button
                        className=""
                        onClick={() => withBreed(props.TokenId)}
                      >
                        BREED WITH THIS CARD
                      </button>
                    )}
                  </li>
                </div>
                {popup && (
                  <PopUp
                    popUpSubject={popUpSubject}
                    popUpText={popUpText}
                    popUpText1={popUpText1}
                    setPopUp={setPopUp}
                  ></PopUp>
                )}
                {loading && <LoaderFloating></LoaderFloating>}
              </div>
            </>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
    </div>
  );
}
export default BreedListCard;
