import React from 'react';
function MutateConfirmBanner(){
 return <div>
<div className="MutateSection">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="promote_text">
                     <h1>MUTATE CARDS</h1>
                  </div>
               </div>
            </div>
         </div>
      </div>
 </div>


}
export default MutateConfirmBanner;