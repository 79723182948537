import React from 'react';
import SellCardDetailsBanner from '../SellCards/SellCardDetails/Sections/SellCardDetailsBanner';
import SellCardDetailsTaxt from '../SellCards/SellCardDetails/Sections/SellCardDetailsTaxt';
import '../SellCards/SellCards.css';
function SellCardDetails () {
	return <div>
        <SellCardDetailsBanner/>
		<SellCardDetailsTaxt/>
	</div>
}
export default SellCardDetails;