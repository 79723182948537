import React from 'react';
import '../SellCollection/CardCollection.css';
import Footer from '../../Footer/Footer';
import { Outlet } from 'react-router-dom';
import SellProfileBanner from '../SellProfile/SellProfileBanner';
import SellProfile from '../SellProfile/SellProfile';

function SellCardCollection () {
	//(CardCollection)?Footer(false):Footer(true);
	return <div>
		{/* <Allcardsbanner/> */}
		<SellProfileBanner></SellProfileBanner>
		<SellProfile></SellProfile>
		<Outlet></Outlet>
        
	</div>
}
export default SellCardCollection;