import React from 'react';
import MutateConfirmBanner from '../MutateConfirm/Sections/MutateConfirmBanner';
import MutateConfirmText from '../MutateConfirm/Sections/MutateConfirmText';
import '../MutateConfirm/MutateConfirm.css';
function MutateConfirm(){
 return <div>
<MutateConfirmBanner/>
<MutateConfirmText/>


 </div>


}
export default MutateConfirm;