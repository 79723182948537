import React from 'react';
import ProfileBanner from './Sections/ProfileBanner';
import ProfileEdit from './Sections/ProfileEdit';
import '../ProfileSetting/ProfileSetting.css';
function ProfileSetting (){
 return <div>
<ProfileBanner/>
<ProfileEdit/>
 </div>


}
export default ProfileSetting;