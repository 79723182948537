import React from 'react';
import SellOutCongratulationsHero from '../SellCards/SellOutCongratulations/Sections/SellOutCongratulationsHero';
import SellOutCongratulationsText from '../SellCards/SellOutCongratulations/Sections/SellOutCongratulationsText';
import '../SellCards/SellCards.css';
function SellOutCongratulations () {
	return <div>
        <SellOutCongratulationsHero/>
		<SellOutCongratulationsText/>
	</div>
}
export default SellOutCongratulations;