import React from 'react';
// import Blacknetbanner from '../Cardcollection/SubPages/Blacknet/Sections/Blacknetbanner';
import BlacknetText from './SubPages/Blacknet/Sections/BreedListBlacknetText';
import BlacknetList from './SubPages/Blacknet/Sections/BreedListBlacknetList';
import '../BreedingListCollection/CardCollection.css';
import BreedListBlacknetList from './SubPages/Blacknet/Sections/BreedListBlacknetList';
import BreedListBlacknetText from './SubPages/Blacknet/Sections/BreedListBlacknetText';

function BreedingListBlacknetCard () {
	return <div>
		{/* <Blacknetbanner/> */}
		<BreedListBlacknetList/>
		<BreedListBlacknetText/>

        
	</div>
}
export default BreedingListBlacknetCard;