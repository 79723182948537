import React, { useEffect, useState } from "react";
import useAuctionContract from "../../../../../Hooks/AuctionContractHook";
import useBreedingContract from "../../../../../Hooks/BreedingContractHook";
import useFixedMarketContract from "../../../../../Hooks/FixedMarketHook";
import useWarContract from "../../../../../Hooks/WarContractHook";
import {
  GetBreedFee,
  GetMutateFee,
  GetRankUpFee,
  GetRenameFee,
  GetTesseractPercentageFixedMarket,
  GetTessractPercentageAuction,
  GetWarFee,
} from "../../../../../UserPage/BlockChainFunctions";
function CostfeesText() {
  const [breedFee, setBreedFee] = useState("");
  const [warFee, setWarFee] = useState("");
  const [rankFee, setRankFee] = useState("");
  const [mutateFee, setMutateFee] = useState("");
  const [renameFee, setRenameFee] = useState("");
  const [tperAuction, setTperAuction] = useState("");
  const [tperFixed, setTperFixed] = useState("");

  const [breedingContract, setBreedingContract] = useBreedingContract();
  const [warContract, setWarContract] = useWarContract();
  const [fixedMarketContract, setFixedMarketContract] =
    useFixedMarketContract();
  const [auctionContract, setAuctionContract] = useAuctionContract();

  useEffect(() => {
    if (breedingContract) {
      const getBreedFee = async () => {
        const result = await GetBreedFee(breedingContract);
        setBreedFee(result);
      };
      getBreedFee();
    }
  }, [breedingContract]);

  useEffect(() => {
    if (warContract) {
      const getWarFee = async () => {
        const result = await GetWarFee(warContract);
        setWarFee(result);
      };
      getWarFee();
    }
  }, [warContract]);

  useEffect(() => {
    if (breedingContract) {
      const getMutateFee = async () => {
        const result = await GetMutateFee(breedingContract);
        // console.log(result);
        setMutateFee(result);
      };
      getMutateFee();
    }
  }, [breedingContract]);
  useEffect(() => {
    if (breedingContract) {
      // conditi
      const getRankUpFee = async () => {
        const result = await GetRankUpFee(breedingContract);
        // console.log(result);
        setRankFee(result);
      };
      getRankUpFee();
    }
  }, [breedingContract]);
  useEffect(() => {
    if (breedingContract) {
      //
      const getRenameFee = async () => {
        const result = await GetRenameFee(breedingContract);
        // console.log(result);
        setRenameFee(result);
      };
      getRenameFee();
    }
  }, [breedingContract]);

  useEffect(() => {
    if (auctionContract) {
      //
      const getTPercentage = async () => {
        const result = await GetTessractPercentageAuction(auctionContract);
        setTperAuction(result);
        // console.log(result, "get t percenTAGE");
      };
      getTPercentage();
    }
  }, [auctionContract]);

  useEffect(() => {
    if (fixedMarketContract) {
      //
      const getTPercentage = async () => {
        const result = await GetTesseractPercentageFixedMarket(
          fixedMarketContract
        );
        setTperFixed(result);
        // console.log(result, "gettpercentage");
      };
      getTPercentage();
    }
  }, [fixedMarketContract]);

  return (
    <div>
      <section className="guidetab_section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="guide_tab">
                <div className="content_box cost_fee_tab">
                  <h4>COST AND FEES</h4>

                  <div className="row align-items-center ">
                    <div className="col-md-12">
                      <div className="custom_tab_text cost_fee">
                        <div className="cost-flex-box">
                          <h3>Registering</h3>
                          <h3>Fees</h3>
                          <h3>Gas*</h3>
                        </div>
                        <div className="cost-flex-box">
                          <p>1. Signing up</p>
                          <p>Free</p>
                          <p>Required</p>
                        </div>
                        <hr></hr>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="custom_tab_text cost_fee">
                        <h3>Breeding</h3>
                        <div className="cost-flex-box">
                          <p>1. Breed cards within own collection</p>
                          <p>Free</p>
                          <p>Required</p>
                        </div>
                        <div className="cost-flex-box">
                          <p>2. Breed cards with others</p>
                          <p>User input + {breedFee} MATIC*</p>
                          <p>Required</p>
                        </div>

                        <hr></hr>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="custom_tab_text cost_fee">
                        <h3>Battle with others</h3>
                        <div className="cost-flex-box">
                          <p>1. Enlist card to battle list</p>
                          <p>Free</p>
                          <p>Required</p>
                        </div>
                        <div className="cost-flex-box">
                          <p>2. Initiate battle with challengers</p>
                          <p>Free</p>
                          <p>Required</p>
                        </div>

                        <hr></hr>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="custom_tab_text cost_fee">
                        <h3>War</h3>

                        <div className="cost-flex-box">
                          <p>1. Joining any battlefields in the War</p>
                          <p>{warFee} MATIC</p>
                          <p>Required</p>
                        </div>
                        <hr></hr>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="custom_tab_text cost_fee">
                        <h3>Rank upgrade</h3>

                        <div className="cost-flex-box">
                          <p>1. Upgrade cards’ rank by one level</p>
                          <p>{rankFee} MATIC</p>
                          <p>Required</p>
                        </div>
                        <hr></hr>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="custom_tab_text cost_fee">
                        <h3>Mutation</h3>
                        <div className="cost-flex-box">
                          <p>1. Mutate cards</p>
                          <p>{mutateFee} MATIC</p>
                          <p>Required</p>
                        </div>

                        <hr></hr>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="custom_tab_text cost_fee">
                        <h3>Rename cards</h3>

                        <div className="cost-flex-box">
                          <p>1. Rename cards</p>
                          <p>{renameFee} MATIC</p>
                          <p>Required</p>
                        </div>
                        <hr></hr>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="custom_tab_text cost_fee">
                        <h3>Auction</h3>
                        <div className="cost-flex-box">
                          <p>1. Initiating auction</p>
                          <p>User input* ({tperAuction}% Tesseract’s fee)</p>
                          <p>Required</p>
                        </div>
                        <div className="cost-flex-box">
                          <p>2. Making a bid</p>
                          <p>
                            {" "}
                            &#8250;/= Card price set by User or current highest
                            bid price
                          </p>
                          <p>Required</p>
                        </div>
                        {/* <div className="cost-flex-box">
                          <p>3. Sell out auction card(s)</p>
                          <p>0 MATIC</p>
                          <p>Required</p>
                        </div> */}

                        <hr></hr>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="custom_tab_text cost_fee">
                        <h3>Fixed marketplace</h3>
                        <div className="cost-flex-box">
                          <p>1. Putting cards on sale</p>
                          <p>Free</p>
                          <p>Required</p>
                        </div>
                       
                        <div className="cost-flex-box">
                          <p>3. Buying cards at fixed price</p>
                          <p>User input* ({tperFixed}% Tesseract’s fee)</p>
                          <p>Required</p>
                        </div>

                        <hr></hr>
                      </div>

                      <p className="fs-6">
                        {" "}
                        <i>
                          *Denoted a percentage of the fees goes to Tesseract
                          and the remaining goes to user (breeder/seller). Fees
                          subject to change.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default CostfeesText;
