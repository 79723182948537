import React from 'react';
import ChaptersBanner from '../Chapters/Sections/ChaptersBanner';
import ChaptersTab from '../Chapters/Sections/ChaptersTab';
import '../Chapters/Chapters.css';
function Chapters () {
	return <div>
		<ChaptersBanner/>
        <ChaptersTab/>
        
	</div>
}
export default Chapters;