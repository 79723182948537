import React from 'react';
import Blacknetbanner from '../Blacknet/Sections/Blacknetbanner';
import BlacknetText from '../Blacknet/Sections/BlacknetText';
import '../Blacknet/Blacknet.css';
function Blacknet () {
	return <div>
		<Blacknetbanner/>
		<BlacknetText/>
       
        
	</div>
}
export default Blacknet;