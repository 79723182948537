import React from "react";
import { useContext } from "react";
import { emptySlotContext } from "../../../../../../../App";
import AliceWarCard from "../CardHolder/AliceWarCard";
import BlackNetWarCard from "../CardHolder/BlackNetWarCard";
// import BlackNetWarWarCard from '../CardHolder/BlackNetWarCard';

const BACard = () => {
  const [leftWarSlot, setLeftWarSlot, rightWarSlot, setRightWarSlot] =
    useContext(emptySlotContext);

  return (
    <div className="breed-section-part container">
      <div className="col-xl-6 col-lg-6 col-md-6">
        <BlackNetWarCard
          availableSlot={leftWarSlot}
          setAvailableSlot={setLeftWarSlot}
        ></BlackNetWarCard>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6">
        <AliceWarCard
          availableSlot={rightWarSlot}
          setAvailableSlot={setRightWarSlot}
        ></AliceWarCard>
      </div>
    </div>
  );
};

export default BACard;
