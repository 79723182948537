import React, { useEffect, useState } from "react";
import useGameCentreContract from "../../../../Hooks/GameCentreHook";
import { GetCardInfo } from "../../../../UserPage/BlockChainFunctions";

function CurrentStatus({ status }) {
  const timestamp = new Date(status.LastBreedTime * 1000).toLocaleTimeString(
    "en-US"
  );
  const BreedDate = new Date(status.LastBreedTime * 1000).toLocaleDateString(
    "en-US"
  );

  const DateTimeStamp = timestamp + "\n(" + BreedDate + ")";

  return (
    <div>
      <section className="card_details_section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="current_status">
                <h2>CURRENT STATUS</h2>
                <ul>
                  <li>
                    <span> Is On Breed List?</span>
                    <br></br>
                    <strong>{status.BreedListStatus}</strong>
                  </li>
                  <li>
                    <span>Last Breeding Time</span>
                    <br></br>
                    <strong>{DateTimeStamp}</strong>
                  </li>
                  <li>
                    <span>Is On Battle List?</span>
                    <br></br>
                    <strong>{status.BattleListStatus}</strong>
                  </li>
                  <li>
                    <span>Is On Auction List?</span>
                    <br></br>
                    <strong> {status.AuctionListStatus} </strong>
                  </li>
                  <li>
                    <span>Is On Marketplace?</span>
                    <br></br>
                    <strong>{status.FixedMarketStatus} </strong>
                  </li>
                  <li>
                    <span>Is In War Zone?</span>
                    <br></br>
                    <strong>{status.WarListStatus} </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default CurrentStatus;
