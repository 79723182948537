import React from 'react';
import WarzoneBanner from '../Warzone/Sections/WarzoneBanner';
import WarzoneContent from '../Warzone/Sections/WarzoneContent';
import '../Warzone/Warzone.css';
function Warzone(){
 return <div>
<WarzoneBanner/>
<WarzoneContent/>

 </div>


}
export default Warzone;