import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../../Shared/Loader/Loader";
import LoaderFloating from "../../../../Shared/LoaderFloating";
function FeaturesCard({
  TokenId,
  isOnBreed,
  isOnBattle,
  isOnWar,
  isOnAuction,
  isOnFixed,
  setConfirmBreed,
  ExitList,
  AddToBattle,
  RemoveFromBattle,
  WithdrawFixedSell,
  WithdrawAuction,
  setConfirmWarExit,
  buttonAct,
  breedLoading,
  battleLoading,
  warLoading,
  fixedLoading,
  auctionLoading,
  allButtonAct,
  breedButtonAct,
  battleButtonAct,
  warButtonAct,
  auctionButtonAct,
  fixedButtonAct,
  genesis,
  withBreed,
  withBattle,
}) {
  const [Rarity, setRarity] = useState();
  const [loader, setLoader] = useState(false);
  // (isOnBreed===false && isOnBattle===false && isOnWar===false && isOnAuction===false && isOnFixed===false)
  // console.log(buttonAct, "button act");

  useEffect(() => {
    fetch(`https://www.api.extremisverse.com/CalcRarity?TokenId=${TokenId}`)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (!data.message) {
          setRarity(data);
        }
      });
  }, [TokenId]);

  // console.log(allButtonAct,warButtonAct,"war button act");
  useEffect(() => {
    if (
      breedLoading ||
      battleLoading ||
      fixedLoading ||
      auctionLoading ||
      warLoading
    ) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  });

  return (
    <div>
      {!genesis && (
        <section className="card_details_section mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="features_status">
                  <h2>FEATURES</h2>

                  {!Rarity ? (
                    <Loader></Loader>
                  ) : (
                    <ul>
                      {Object.keys(Rarity)?.map((key, i) => {
                        return (
                          <li>
                            <span>Type {i + 1}</span>
                            <br></br>
                            <strong>{key}</strong>
                            <hr></hr>
                            <strong>{Rarity[key]?.toFixed(2)}%</strong>
                            <br></br> have this feature
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {buttonAct ? (
        <section className="card_details_section mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="features_tab">
                  {allButtonAct ? (
                    <ul>
                      <li>
                        <Link to={`/promote-confirm/${TokenId}`}>PROMOTE</Link>
                      </li>
                      {!genesis && (
                        <li>
                          <Link to={`/mutate-confirm/${TokenId}`}>MUTATE</Link>
                        </li>
                      )}
                      {!genesis && (
                        <li>
                          <Link to={`/rename-confirm/${TokenId}`}>
                            RENAME CARD
                          </Link>
                        </li>
                      )}

                      {!genesis && (
                        <li
                          onClick={() => {
                            setConfirmBreed(true);
                          }}
                        >
                          <button className="">
                            {!breedLoading
                              ? "ADD FOR BREEDING"
                              : "PLEASE WAIT ..."}
                          </button>
                        </li>
                      )}

                      <li onClick={AddToBattle}>
                        <button className="">
                          {!battleLoading
                            ? "  ADD TO BATTLE LIST"
                            : "PLEASE WAIT ..."}
                        </button>
                      </li>

                      <li>
                        <Link to="/war-zone">JOIN WAR</Link>
                      </li>

                      <li>
                        <Link to={`/fixed-sell-confirmation/${TokenId}`}>
                          SELL ON FIXED MARKET
                        </Link>
                      </li>

                      <li>
                        <Link to={`/sell-auction-confirmation/${TokenId}`}>
                          SELL ON AUCTION
                        </Link>
                      </li>

                      <li>
                        <Link to={`/transfer-confirm/${TokenId}`}>
                          TRANSFER CARD
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <>
                      {breedButtonAct && (
                        <ul
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <li>
                            <button
                              style={{ width: "200px" }}
                              onClick={ExitList}
                            >
                              {!breedLoading
                                ? "  EXIT BREED LIST "
                                : "PLEASE WAIT ..."}
                            </button>
                          </li>
                        </ul>
                      )}
                      {battleButtonAct && (
                        <ul
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <li>
                            <button
                              style={{ width: "200px" }}
                              onClick={RemoveFromBattle}
                            >
                              {!battleLoading ? "REST CARD" : "PLEASE WAIT ..."}
                            </button>
                          </li>
                        </ul>
                      )}
                      {fixedButtonAct && (
                        <ul
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <li>
                            <button
                              style={{ width: "200px" }}
                              onClick={WithdrawFixedSell}
                            >
                              {fixedLoading
                                ? "PLEASE WAIT"
                                : "WITHDRAW FROM FIXED MARKET"}
                            </button>
                          </li>
                        </ul>
                      )}
                      {auctionButtonAct && (
                        <ul
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <li>
                            <button
                              style={{ width: "200px" }}
                              onClick={WithdrawAuction}
                            >
                              {!auctionLoading
                                ? "WITHDRAW FROM AUCTION"
                                : "PLEASE WAIT ..."}
                            </button>
                          </li>
                        </ul>
                      )}
                      {warButtonAct && (
                        <ul
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <li>
                            <button
                              style={{ width: "200px" }}
                              onClick={() => setConfirmWarExit(true)}
                            >
                              {!warLoading ? "EXIT WAR" : "PLEASE WAIT ..."}
                            </button>
                          </li>
                        </ul>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {loader && <LoaderFloating></LoaderFloating>}
          </div>
        </section>
      ) : (
        <section className="card_details_section mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="features_tab">
                  {breedButtonAct && (
                    <ul style={{ display: "flex", justifyContent: "center" }}>
                      <li>
                        <button
                          style={{ width: "200px" }}
                          onClick={() => withBreed(TokenId)}
                        >
                          BREED WITH THIS CARD
                        </button>
                      </li>
                    </ul>
                  )}
                  {battleButtonAct && (
                    <ul style={{ display: "flex", justifyContent: "center" }}>
                      <li>
                        <button
                          style={{ width: "200px" }}
                          onClick={() => withBattle(TokenId)}
                        >
                          BATTLE WITH THIS CARD
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
export default FeaturesCard;
