import React, { useContext, useEffect, useState } from "react";
import IndividualBanner from "../IndividualCard/Sections/IndividualBanner";
import CardDetails from "../IndividualCard/Sections/CardDetails";
import CurrentStatus from "../IndividualCard/Sections/CurrentStatus";
import FeaturesCard from "../IndividualCard/Sections/FeaturesCard";
import "../IndividualCard/IndividualCard.css";
import { useNavigate, useParams } from "react-router-dom";
import useBreedingContract from "../../../Hooks/BreedingContractHook";
import {
  addToBattleList,
  cancelAuction,
  exitBreedList,
  exitWar,
  GetAuction,
  GetCardFaction,
  GetCardInfo,
  IsOnAuctionList,
  IsOnBattleList,
  IsOnBreedingList,
  IsOnFixedMarketList,
  IsOnWarZone,
  putOnBreedList,
  removeFromBattleList,
  removeFromFixedMarketPlace,
} from "../../../UserPage/BlockChainFunctions";
import useWarContract from "../../../Hooks/WarContractHook";
import useAuctionContract from "../../../Hooks/AuctionContractHook";
import useFixedMarketContract from "../../../Hooks/FixedMarketHook";
import useGameCentreContract from "../../../Hooks/GameCentreHook";
import {
  addressContext,
  navigationContext,
  providerContext,
} from "../../../../App";
import * as AWS from "aws-sdk";
import useCustomerInfo from "../../../Hooks/useCustomerInfo";
import PopUp from "../../../Shared/PopUp";
import BreedInput from "./Sections/BreedInput";
import useCustomerInfoByTokenId from "../../../Hooks/useCustomerInfoByTokenId";
import useCard from "../../../Shared/Card/useCard";
import Loader from "../../../Shared/Loader/Loader";
import WarExit from "./Sections/WarExit";
import useBattleListSelfCard from "../../../Hooks/useBattleListSelfCard";

function IndividualCard() {
  const TokenId = useParams();
  const [isOnBreed, setIsOnBreed] = useState(false);
  const [isOnBattle, setIsOnbattle] = useState(false);
  const [isOnWar, setIsOnWar] = useState(false);
  const [isOnAuction, setIsOnAuction] = useState(false);
  const [isOnFixed, setIsOnFixed] = useState(false);
  const [status, setStatus] = useState("");
  const [breedLoading, setBreedLoading] = useState(false);
  const [battleLoading, setBattleLoading] = useState(false);
  const [warLoading, setWarLoading] = useState(false);
  const [fixedLoading, setFixedLoading] = useState(false);
  const [auctionLoading, setAuctionLoading] = useState(false);
  const [buttonAct, setButtonAct] = useState(false);
  const [breedingContract, setBreedingContract] = useBreedingContract();
  const [warContract, setWarContract] = useWarContract();
  const [auctionContract, setAuctionContract] = useAuctionContract();
  const [fixedMarketContract, setFixedMarketContract] =
    useFixedMarketContract();
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const customerInfo = useCustomerInfo(currentAddress);
  const customerInfo1 = useCustomerInfoByTokenId(
    TokenId.TokenId,
    currentAddress
  );
  const [BattleTokenId, page, setPage, next, Next, Previous, loader] =
    useBattleListSelfCard();
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);
  const [navConfirm, setNavConfirm] = useState(false);
  const [allButtonAct, setAllButtonAct] = useState(false);
  const [breedButtonAct, setBreedButtonAct] = useState(false);
  const [battleButtonAct, setBattleButtonAct] = useState(false);
  const [warButtonAct, setWarButtonAct] = useState(false);
  const [auctionButtonAct, setAuctionButtonAct] = useState(false);
  const [fixedButtonAct, setFixedButtonAct] = useState(false);

  const navigation = useNavigate();

  const ssm = new AWS.SSM();
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState();
  const [popUpText1, setPopUpText1] = useState();
  const [confirmBreed, setConfirmBreed] = useState(false);
  const [confirmWarExit, setConfirmWarExit] = useState(false);
  const [faction, setFaction] = useState("");
  const [loading, setLoading] = useState(false);
  const [genesis, setGenesis] = useState(false);
  const [selfBattleCard, setSelfBattleCard] = useState(false);
  const [selfAuctionCard, setSelfAuctionCard] = useState(false);
  useEffect(() => {
    setLoading(true);
    setInterval(() => {
      setLoading(false);
    }, 5000);

    const SessionChecking = async () => {
      if (TokenId.TokenId && gameCentreContract) {
        const faction = await GetCardFaction(
          TokenId.TokenId,
          gameCentreContract
        );
        setLoading(false);
        if (faction) {
          setFaction(faction);
        } else {
          setLoading(false);
        }
      }
    };
    SessionChecking();
  }, [TokenId.TokenId, gameCentreContract]);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //   });
  // }, []);

  const IsOnBreedList = async () => {
    const result = await IsOnBreedingList(TokenId.TokenId, breedingContract);
    // console.log(result, "isOnBreed");
    setIsOnBreed(result);
  };

  const IsOnBattle = async () => {
    const result = await IsOnBattleList(TokenId.TokenId, warContract);
    // console.log(result, "is on battle list");
    setIsOnbattle(result);
  };
  const IsOnWar = async () => {
    const result = await IsOnWarZone(TokenId.TokenId, warContract);
    // console.log(result, "is on war zone");
    setIsOnWar(result);
  };

  const IsOnAuction = async () => {
    const result = await IsOnAuctionList(TokenId.TokenId, auctionContract);
    setIsOnAuction(result);
  };

  const IsOnFixed = async () => {
    const result = await IsOnFixedMarketList(
      TokenId.TokenId,
      fixedMarketContract
    );
    setIsOnFixed(result);
  };

  const AllGetFunction = () => {
    IsOnBreedList();
    IsOnBattle();
    IsOnFixed();
    IsOnWar();
    IsOnAuction();
  };

  const Info = async () => {
    const CardInfo = await GetCardInfo(TokenId.TokenId, gameCentreContract);
    const status = {
      BreedListStatus: CardInfo.IsOnBreedList.toString(),
      LastBreedTime: CardInfo.Last_Mating_Time.toNumber(),
      BattleListStatus: CardInfo.isBattleReady.toString(),
      WarListStatus: CardInfo.isOnWarZone.toString(),
      AuctionListStatus: CardInfo.isOnAuction.toString(),
      FixedMarketStatus: CardInfo.isOnFixedPriceMarket.toString(),
    };

    setStatus(status);
  };
  const PutOnBreedList = async (price) => {
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      setConfirmBreed(false);
      setBreedLoading(true);
      // console.log(ssm, "breed add ");
      try {
        const result = await putOnBreedList(
          TokenId.TokenId,
          price,
          walletProvider,
          ssm
        );
        // console.log(result, "breedlist");
        setBreedLoading(false);
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
        if (result.txreceipt?.blockHash) {
          AllGetFunction();
          setPopUpSubject("ADDED TO BREED LIST");
          setPopUpText(`Transaction Hash: ${result.txreceipt.transactionHash}`);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
          setNavConfirm(true);
        }
      } catch (err) {
        setBreedLoading(false);
      }
      setBreedLoading(false);
      Info();
    } else {
      setPopUpSubject("Connect your registered wallet");
      setPopUp(true);
    }
  };
  const ExitList = async () => {
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      setBreedLoading(true);
      try {
        const result = await exitBreedList(
          TokenId.TokenId,
          walletProvider,
          ssm
        );
        // console.log(result, "exitlist");
        setBreedLoading(false);
        if (result.txreceipt?.blockHash) {
          AllGetFunction();
          setAllButtonAct(true);
          setPopUpSubject("REMOVED FROM BREED LIST");
          setPopUpText(`Transaction Hash: ${result.txreceipt.transactionHash}`);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
          setNavConfirm(true);
        }
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      } catch (err) {
        setBreedLoading(false);
      }

      Info();
    } else {
      setPopUpSubject("Connect your registered wallet");
      setPopUp(true);
    }
  };

  const AddToBattle = async () => {
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      setBattleLoading(true);
      try {
        const result = await addToBattleList(
          TokenId.TokenId,
          walletProvider,
          warContract,
          ssm
        );
        setBattleLoading(false);
        if (result.txreceipt?.blockHash) {
          AllGetFunction();
          setPopUpSubject("ADDED TO BATTLE LIST");
          setPopUpText(`Transaction Hash: ${result.txreceipt.transactionHash}`);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
          setNavConfirm(true);
        }
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      } catch (err) {
        // console.log(err, "ind");
        setBattleLoading(false);
      }

      Info();
    } else {
      setPopUpSubject("Connect your registered wallet");
      setPopUp(true);
    }
  };

  const RemoveFromBattle = async () => {
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      setBattleLoading(true);
      try {
        const result = await removeFromBattleList(
          TokenId.TokenId,
          walletProvider,
          ssm
        );
        // console.log(result, "remove from battle");
        setBattleLoading(false);
        if (result.txreceipt?.blockHash) {
          AllGetFunction();
          setAllButtonAct(true);
          setPopUpSubject("REMOVED FROM BATTLE LIST");
          setPopUpText(`Transaction Hash: ${result.txreceipt.transactionHash}`);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
          setNavConfirm(true);
        }
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }
      } catch (err) {
        // console.log(err, "ind");
        setBattleLoading(false);
      }

      Info();
    } else {
      setPopUpSubject("Connect your registered wallet");
      setPopUp(true);
    }
  };

  const WithdrawFixedSell = async () => {
    setFixedLoading(true);
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      const result = await removeFromFixedMarketPlace(
        TokenId.TokenId,
        walletProvider,
        ssm
      );
      // console.log(result, "sell withdraw");
      if (result.txreceipt?.blockHash) {
        AllGetFunction();
        setAllButtonAct(true);
        setFixedLoading(false);
        setPopUpSubject("REMOVED FROM FIXED MARKET");
        setPopUpText(`Transaction Hash: ${result.txreceipt.transactionHash}`);
        setPopUpText1(` Aws Response :${result.Awsresponse}`);
        setPopUp(true);
        setNavConfirm(true);
      }
      if (result?.error?.code) {
        setPopUpSubject("Oops");
        setPopUpText(result.message);
        setPopUp(true);
      }
      if (result.data?.message) {
        setPopUpSubject("Oops");
        setPopUpText(result.data.message);
        setPopUp(true);
      }

      IsOnFixed();
      Info();
    } else {
      setFixedLoading(false);
      setPopUpSubject("Connect your registered wallet");
      setPopUp(true);
    }
  };

  const WithdrawAuction = async () => {
    // console.log(TokenId.TokenId, "test token");
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      setAuctionLoading(true);
      try {
        const result = await cancelAuction(
          TokenId.TokenId,
          walletProvider,
          ssm
        );
        // console.log(result, "withdraw auction");
        if (result.txreceipt?.blockHash) {
          AllGetFunction();
          setAllButtonAct(true);
          setAuctionLoading(false);
          setPopUpSubject("REMOVED FROM AUCTION HOUSE");
          setPopUpText(`Transaction Hash: ${result.txreceipt.transactionHash}`);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
          setNavConfirm(true);
        }
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }

        IsOnAuction();
        Info();
      } catch (err) {
        setAuctionLoading(false);
      }
    } else {
      setPopUpSubject("Connect your registered wallet");
      setPopUp(true);
    }
  };
  const ExitFromWar = async () => {
    if (currentAddress === customerInfo?.Item?.CustomerID) {
      setConfirmWarExit(false);
      setWarLoading(true);
      try {
        const result = await exitWar(TokenId.TokenId, walletProvider, ssm);
        // console.log(result, "exit war");
        if (result.txreceipt?.blockHash) {
          AllGetFunction();
          setAllButtonAct(true);
          setWarLoading(false);
          setPopUpSubject("REMOVED FROM WAR");
          setPopUpText(`Transaction Hash: ${result.txreceipt.transactionHash}`);
          setPopUpText1(` Aws Response :${result.Awsresponse}`);
          setPopUp(true);
          setNavConfirm(true);
        }
        if (result?.error?.code) {
          setPopUpSubject("Oops");
          setPopUpText(result.message);
          setPopUp(true);
        }
        if (result.data?.message) {
          setPopUpSubject("Oops");
          setPopUpText(result.data.message);
          setPopUp(true);
        }

        IsOnWar();
        Info();
      } catch (err) {
        setWarLoading(false);
      }
    } else {
      setPopUpSubject("Connect your registered wallet");
      setPopUp(true);
    }
  };

  useEffect(() => {
    if (breedingContract) {
      IsOnBreedList();
    }
  }, [breedingContract]);

  useEffect(() => {
    if (warContract) {
      IsOnBattle();
    }
  }, [warContract]);

  useEffect(() => {
    if (warContract) {
      IsOnWar();
    }
  }, [warContract]);

  useEffect(() => {
    if (auctionContract) {
      IsOnAuction();
    }
  }, [auctionContract]);

  useEffect(() => {
    if (fixedMarketContract) {
      IsOnFixed();
    }
  }, [fixedMarketContract]);

  useEffect(() => {
    if (gameCentreContract) {
      Info();
    }
  }, [gameCentreContract]);
  useEffect(() => {
    // console.log(customerInfo1);
    if (
      customerInfo1?.Item?.CustomerID === currentAddress ||
      selfBattleCard === true ||
      selfAuctionCard === true
    ) {
      setButtonAct(true);
    } else {
      setButtonAct(false);
    }
  }, [
    currentAddress,
    customerInfo1,
    selfBattleCard,
    selfAuctionCard,
    TokenId.TokenId,
  ]);

  useEffect(() => {
    if (
      isOnBreed === false &&
      isOnBattle === false &&
      isOnWar === false &&
      isOnAuction === false &&
      isOnFixed === false
    ) {
      // console.log(isOnBreed, isOnBattle, isOnWar, isOnAuction, isOnFixed);
      setAllButtonAct(true);
    } else {
      setAllButtonAct(false);
    }
  });

  useEffect(() => {
    if (
      isOnBreed === true &&
      isOnBattle === false &&
      isOnWar === false &&
      isOnAuction === false &&
      isOnFixed === false
    ) {
      setBreedButtonAct(true);
    } else {
      setBreedButtonAct(false);
    }
  }, [isOnBreed, isOnBattle, isOnWar, isOnAuction, isOnFixed, buttonAct]);

  useEffect(() => {
    if (
      isOnBreed === false &&
      isOnBattle === true &&
      isOnWar === false &&
      isOnAuction === false &&
      isOnFixed === false
    ) {
      setBattleButtonAct(true);
    } else {
      setBattleButtonAct(false);
    }
  }, [isOnBreed, isOnBattle, isOnWar, isOnAuction, isOnFixed, buttonAct]);

  useEffect(() => {
    if (
      isOnBreed === false &&
      isOnBattle === false &&
      isOnWar === false &&
      isOnAuction === true &&
      isOnFixed === false
    ) {
      setAuctionButtonAct(true);
    } else {
      setAuctionButtonAct(false);
    }
  }, [isOnBreed, isOnBattle, isOnWar, isOnAuction, isOnFixed, buttonAct]);

  useEffect(() => {
    if (
      isOnBreed === false &&
      isOnBattle === false &&
      isOnWar === false &&
      isOnAuction === false &&
      isOnFixed === true
    ) {
      setFixedButtonAct(true);
    } else {
      setFixedButtonAct(false);
    }
  }, [isOnBreed, isOnBattle, isOnWar, isOnAuction, isOnFixed, buttonAct]);
  useEffect(() => {
    if (
      isOnBreed === false &&
      isOnBattle === false &&
      isOnWar === true &&
      isOnAuction === false &&
      isOnFixed === false
    ) {
      setWarButtonAct(true);
    } else {
      setWarButtonAct(false);
    }
  }, [isOnBreed, isOnBattle, isOnWar, isOnAuction, isOnFixed, buttonAct]);
  // console.log(isOnWar, warButtonAct, "war button act");
  useEffect(() => {
    if (navigationCntxt === true && navConfirm === true) {
      window.location.reload();
    }
  }, [navigationCntxt, navConfirm]);
  useEffect(() => {
    if (BattleTokenId.length > 0) {
      for (let i = 0; i < BattleTokenId.length; i++) {
        const element = BattleTokenId[i];
        if (element === TokenId.TokenId) {
          setSelfBattleCard(true);
        } else {
          setSelfBattleCard(false);
        }
      }
    }
  }, [BattleTokenId, TokenId.TokenId]);

  useEffect(() => {
    const Auction = async () => {
      const auction = await GetAuction(TokenId.TokenId, auctionContract);
      // console.log(seller);
      // setSeller();
      if (auction.seller === currentAddress) {
        setSelfAuctionCard(true);
      } else {
        setSelfAuctionCard(false);
      }
    };
    if (TokenId.TokenId && auctionContract && currentAddress) {
      Auction();
    }
  }, [TokenId.TokenId, auctionContract, currentAddress]);

  const withBreed = (props) => {
    // console.log(props);
    if (faction !== "BlackNet") {
      navigation(`/breed-Others/${faction.toLowerCase()}`, {
        state: { TokenId: props },
      });
    } else {
      navigation(`/breed-Others`, {
        state: { TokenId: props },
      });
    }
  };
  const withBattle = (props) => {
    navigation(`/battle-list`, {
      state: { TokenId: props },
    });
  };
  return (
    <div>
      <IndividualBanner />
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          {faction ? (
            <>
              <CardDetails TokenId={TokenId.TokenId} setGenesis={setGenesis} />
              <CurrentStatus status={status} />
              <FeaturesCard
                TokenId={TokenId.TokenId}
                isOnBreed={isOnBreed}
                isOnBattle={isOnBattle}
                isOnWar={isOnWar}
                isOnAuction={isOnAuction}
                isOnFixed={isOnFixed}
                setConfirmBreed={setConfirmBreed}
                ExitList={ExitList}
                AddToBattle={AddToBattle}
                RemoveFromBattle={RemoveFromBattle}
                WithdrawFixedSell={WithdrawFixedSell}
                WithdrawAuction={WithdrawAuction}
                setConfirmWarExit={setConfirmWarExit}
                buttonAct={buttonAct}
                breedLoading={breedLoading}
                battleLoading={battleLoading}
                warLoading={warLoading}
                fixedLoading={fixedLoading}
                auctionLoading={auctionLoading}
                allButtonAct={allButtonAct}
                breedButtonAct={breedButtonAct}
                battleButtonAct={battleButtonAct}
                warButtonAct={warButtonAct}
                auctionButtonAct={auctionButtonAct}
                fixedButtonAct={fixedButtonAct}
                genesis={genesis}
                withBreed={withBreed}
                withBattle={withBattle}
              />
            </>
          ) : (
            <h1 className="individual-error">
              Sorry a card doesn't exist with token number {TokenId.TokenId}
            </h1>
          )}
        </>
      )}
      {popup && (
        <PopUp
          popUpSubject={popUpSubject}
          popUpText={popUpText}
          popUpText1={popUpText1}
          setPopUp={setPopUp}
        ></PopUp>
      )}

      {confirmBreed && (
        <BreedInput
          PutOnBreedList={PutOnBreedList}
          setConfirmBreed={setConfirmBreed}
        ></BreedInput>
      )}
      {confirmWarExit && (
        <WarExit
          ExitFromWar={ExitFromWar}
          setConfirmWarExit={setConfirmWarExit}
        ></WarExit>
      )}
    </div>
  );
}
export default IndividualCard;
//setConfirmWarExit
