import React from 'react';
import BattleBanner from '../BattleList/Sections/BattleBanner';
import BattleContent from '../BattleList/Sections/BattleContent';
import '../BattleList/BattleList.css';
function BattleList(){
 return <div>
<BattleBanner/>
<BattleContent/>
 </div>


}
export default BattleList;