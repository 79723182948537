import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../UserPage/User.css";
import { Auth } from "aws-amplify";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { useContext } from "react";
import { addressContext, authContext } from "../../App";
import { useForm } from "react-hook-form";
import useCustomerInfo from "../Hooks/useCustomerInfo";
import PopUp from "../Shared/PopUp";
import ConfirmRecovery from "./ConfirmRecovery";
function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [popUpSubject, setPopUpSubject] = useState("");
  const [popUpText, setPopUpText] = useState("");
  const [signInUser, setSignInUser] = useContext(authContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const [recovery, setRecovery] = useState(false);
  const [email, setEmail] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "all" });

  const Forgot = async (data) => {
    setEmail(data.email);
    setLoading(true);
    try {
      const result = await Auth.forgotPassword(data.email);
      // console.log(result, "recover ");
      if (result.CodeDeliveryDetails) {
        setRecovery(true);
      }
      setRecovery(true);
    } catch (error) {
      setLoading(false);
      setPopUp(true);
      setPopUpSubject("ACCOUNT IS NOT FOUND");
      // console.log(error, "recovery error");
    }
  };

  return (
    <div>
      <div className="login_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 offset-md-6">
              <div className="login_form">
                <h3 className="text-light my-3 fs-2">FIND YOUR ACCOUNT</h3>
                <form onSubmit={handleSubmit(Forgot)} className="signin-form">
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      {...register("email", {
                        required: true,
                        pattern: /^[^\s@]+@[^\s@]{2,}$/i,
                      })}
                      placeholder="Email address*"
                    />
                    {errors.email?.type === "required" && (
                      <p className="error">Email is required</p>
                    )}
                    {errors.email?.type === "pattern" && (
                      <p className="error">Email is invalid</p>
                    )}
                  </div>

                  <br></br>
                  <div className="form-group ">
                    <button
                      type="submit"
                      className="form-control btn btn-primary rounded submit px-3 submit"
                    >
                      {!loading ? "FIND YOUR ACCOUNT" : "PLEASE WAIT..."}
                    </button>
                  </div>
                </form>
                <div className="signup_text text-center">
                  <p>
                    Do you want to sign in? <Link to="/signin">SIGN IN</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {popup && (
            <PopUp
              popUpSubject={popUpSubject}
              popUpText={popUpText}
              setPopUp={setPopUp}
            ></PopUp>
          )}
          {recovery && <ConfirmRecovery email={email}></ConfirmRecovery>}
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
