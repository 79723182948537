import React, { useContext, useEffect, useRef, useState } from "react";
// import alice_logo from "../Sections/Images/alice_logo.png";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import useImage from "./useImage";
import useCard from "./useCard";
import deceased from "../../../assets/Deceased tag for character death-01.png";
import Loader from "../Loader/Loader";
import "../Card.css";
import useColor from "../useColor";
import useRankImage from "../useRankImage";

const { ethers } = require("ethers");

function Card(props) {
  const navigation = useNavigate();
  const [image, setImage] = useState(false);
  const [contract, setContract] = useState("");
  // const [color, setColor] = useState("Red");
  const [deceasedTag, setDeceasedTag] = useState(false);
  const [
    CardFaction,
    CardName,
    CardType,
    CardRank,
    CardGender,
    CardOwner,
    CardOwnerUsername,
  ] = useCard(props.TokenId);
  const uploaded = useImage(CardFaction, CardGender, props.TokenId);
  const color = useColor(CardRank, CardType);
  const rankImage = useRankImage(CardRank);
  useEffect(() => {
    // console.log(uploaded,"uploaded");
    if (uploaded===true) {
      setImage(true);
    }
  }, [uploaded]);

  const nav = (props) => {
    // console.log("prop", props);
    navigation(`/individual-card/${props}`);
  };

  useEffect(() => {
    if (CardOwnerUsername === "Deceased") {
      setDeceasedTag(true);
    }
  }, [CardOwnerUsername]);
  // console.log(CardOwnerUsername);
  useEffect(() => {
    if (CardOwner) {
      if (CardOwner === process.env.REACT_APP_WAR_CONTRACT_ADDR) {
        setContract("War Contract");
      } else if (CardOwner === process.env.REACT_APP_BREEDING_CONTRACT_ADDR) {
        setContract("Breed Contract");
      } else if (
        CardOwner === process.env.REACT_APP_FIXEDMARKET_CONTRACT_ADDR
      ) {
        setContract("Fixed Market Contract ");
      } else if (CardOwner === process.env.REACT_APP_AUCTION_CONTRACT_ADDR) {
        setContract("Auction House Contract ");
      } else if (
        CardOwner === process.env.REACT_APP_CARDOWNERSHIP_CONTRACT_ADDR
      ) {
        setContract("Card ownership Contract ");
      } else if (CardOwner === process.env.REACT_APP_GAMECENTRE_CONTRACT_ADDR) {
        setContract("Game Centre Contract");
      } else {
        setContract(CardOwnerUsername);
      }
    }
  }, [CardOwner, CardOwnerUsername]);
  return (
    <div
      className="col-md-6 word-wrapping"
      onClick={() => {
        nav(props.TokenId);
      }}
    >
      {!image ? (
        <Loader></Loader>
      ) : (
        <div className="card_box deceased ">
          <img
            src={`https://tokencharacterimages.s3.amazonaws.com/TokenImageCachePNG/${props.TokenId}`}
            className={color}
            alt=""
          />
          <img
            className="rankImage"
            src={`/Rank/${rankImage}.png`}
            alt=""
          />{" "}
          <img
            className="extremis-logo"
            src="/LOGO.png"
            alt=""
          />
          <div className="deceased-tag">
            {deceasedTag && <img src={deceased} alt="" />}
          </div>
          {CardFaction && CardGender && CardType && CardRank ? (
            <>
              <h3>{CardName}</h3>
              <div className="card_info"></div>
              <div className="card_info">
                <div className="card_type">
                  <p className="fs-6 text-white">ID: {props.TokenId}</p>
                  <p>
                    FACTION: <strong>{CardFaction}</strong>
                  </p>
                </div>
                <div className="card_rank">
                  <p>
                    RANK: <strong>{CardRank}</strong>
                  </p>
                  <p>
                    TYPE:<strong> {CardType}</strong>
                  </p>
                </div>
                <div className="card_rank">
                  <p>
                    OWNER: <strong>{contract}</strong>
                  </p>
                </div>
              </div>
            </>
          ) : (
            <Loader></Loader>
          )}
        </div>
      )}
    </div>
  );
}
export default Card;
