import { specialChars } from "@testing-library/user-event";
import React, { useContext } from "react";
import { addressContext, profilePicContext } from "../../../../App";
import useCustomerInfo from "../../../Hooks/useCustomerInfo";
import useUsername from "../../../Hooks/useUsername";
import useWon from "../../../Hooks/useWon";
import user from "../UserProfile/Images/user.png";
function UserProfile() {
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  const won = useWon(currentAddress) || "0";
  const [imageSrc,setImageSrc]=useContext(profilePicContext)
  const customerInfo = useCustomerInfo(currentAddress);

  const replaceImage = (error) => {
    error.target.src = "/ErrorImage/user.png";
  };
  return (
    <div>
      <div className="user_section">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-12">
              <div className="user_profile_image">
                <img
                  className="rounded-circle header-profile-user"
                  // src={`https://tokencharacterimages.s3.amazonaws.com/ProfilePicture/${currentAddress}`}
                  src={`data:image/jpeg;base64,${imageSrc}`}
                  alt=""
                  onError={replaceImage}
                />
              </div>
            </div>
            <div className="col-md-5 col-sm-5">
              <div className="profile_info">
                <h3>{customerInfo?.Item?.Username}</h3>
                <p>User ID: {customerInfo?.Item?.CustomerID}</p>
              </div>
            </div>
            <div className="col-md-5 col-sm-5">
              <div className="profile_info  ms-xl-5 ms-lg-5 ms-md-5  ps-xl-5 ps-lg-5 ps-md-5 text-xl-center text-lg-center text-md-center">
                <h3>{won}</h3>
                <p>Battles won</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserProfile;
