import React from "react";
import { useNavigate } from "react-router-dom";
import breeding from "../../../../assets/banner/Breeding-lab-banner.png";
import battle from "../../../../assets/banner/Battle-list.png";
import war from "../../../../assets/banner/War-landing-page.png";
import mutate from "../../../../assets/banner/Mutate.png";
import promote from "../../../../assets/banner/Promote.png";
import rename from "../../.././../assets/banner/Rename-cards.png";
import purchase from "../../../../assets/banner/Buys_sell-cards.png";
import transfer from "../../../../assets/banner/Transfer cards.png";
import "./Play.css";
const PlayContainer = () => {
  const navigate = useNavigate();
  return (
    <div className="play-container">
      <div class="card-decks">
        <div
          class="card cards"
          onClick={() => {
            navigate("/breeding");
          }}
        >
          <img class="card-img-top" src={breeding} alt="Card image cap" />
          <h1>Breeding</h1>
        </div>
        <div
          class="card cards"
          onClick={() => {
            navigate("/breeding-list");
          }}
        >
          <img class="card-img-top" src={breeding} alt="Card image cap" />
          <h1>Breeding List</h1>
        </div>

        <div
          class="card cards"
          onClick={() => {
            navigate("/battle-list");
          }}
        >
          <img class="card-img-top" src={battle} alt="Card image cap" />
          <h1>Battle list</h1>
        </div>
        <div
          class="card cards"
          onClick={() => {
            navigate("/war-zone");
          }}
        >
          <img class="card-img-top" src={war} alt="Card image cap" />
          <h1>War Zone</h1>
        </div>
        <div
          class="card cards"
          onClick={() => {
            navigate("/promote");
          }}
        >
          <img class="card-img-top" src={promote} alt="Card image cap" />
          <h1>Promote Card</h1>
        </div>
        <div
          class="card cards"
          onClick={() => {
            navigate("/mutate");
          }}
        >
          <img class="card-img-top" src={mutate} alt="Card image cap" />
          <h1>Mutate Card</h1>
        </div>
        <div
          class="card cards"
          onClick={() => {
            navigate("/rename");
          }}
        >
          <img class="card-img-top" src={rename} alt="Card image cap" />
          <h1>Rename Card</h1>
        </div>
        {/* <div
          class="card cards"
          onClick={() => {
            navigate("/purchase-cards");
          }}
        >
          <img class="card-img-top" src={purchase} alt="Card image cap" />
          <h1>Marketplace/Auction</h1>
        </div> */}
        {/* <div
          class="card cards"
          onClick={() => {
            navigate("/transfer-cards");
          }}
        >
          <img class="card-img-top" src={transfer} alt="Card image cap" />
          <h1>Transfer Card</h1>
        </div> */}
      </div>
    </div>
  );
};

export default PlayContainer;
