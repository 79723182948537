import React, { useContext, useEffect, useState } from "react";
import { GetCardFaction, IsOnWarZone } from "../UserPage/BlockChainFunctions";
import useGameCentreContract from "./GameCentreHook";
import * as AWS from "aws-sdk";
import awsmobile from "../../aws-exports";
import { addressContext, providerContext } from "../../App";
import useWarContract from "./WarContractHook";

async function getGeneralDetails(TokenId, gameCentreContract, warContract) {
  const Value = await GetCardFaction(TokenId, gameCentreContract);
  const IsOnWar = await IsOnWarZone(TokenId, warContract);
  return { Value, IsOnWar };
}
const useGetWarCardByFaction = (Faction) => {
  const [TokenId, setTokenId] = useState([]);
  const [loader, setLoader] = useState(true);
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [warContract, setWarContract] = useWarContract();

  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [walletProvider, setWalletProvider] = useContext(providerContext);
  const [currentAddress, setCurrentAddress] = useContext(addressContext);
  // const [param, setParam] = useState();

  async function GetTokenId(currentAddress) {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    const Id = [];
    const tableName1 = process.env.REACT_APP_CUSTOMER_CARD_TABLE_NAME;
    const GSI_index = process.env.REACT_APP_CUSTOMER_CARD_TABLE_INDEX_NAME;
    const address = currentAddress;
    try {
      const params = {
        TableName: tableName1,
        IndexName: GSI_index,
        KeyConditionExpression: "CustomerID = :cxId",
        ExpressionAttributeValues: {
          ":cxId": address,
        },
        ProjectionExpression: "TokenId",
      };

      const Response = await documentClient.query(params).promise();

      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        const result = await getGeneralDetails(
          element.TokenId,
          gameCentreContract,
          warContract
        );
        if (result.Value === Faction && result.IsOnWar === false) {
          Id.push(element.TokenId);

          // setNext(true);
        }
      }
    } catch (err) {
      // console.log(err);
    }
    return Id;
  }

  useEffect(() => {
    if ((page === Math.ceil(TokenId.length / 10))|| TokenId.length===0) {
      setNext(false);
    }else{
      setNext(true)
    }
  }, [TokenId, page]);

  const Next = async () => {
    setNext(false);
    setPage((page) => page + 1);
    setNext(true);
  };
  const Previous = async () => {
    setPage((page) => page - 1);
  };
  useEffect(() => {
    if (
      warContract &&
      gameCentreContract &&
      awsmobile.aws_cognito_region &&
      AWS.config.credentials &&
      currentAddress
    ) {
      const TokenArr = async () => {
        const Id = await GetTokenId(currentAddress);
        setLoader(false);
        setTokenId((TokenId) => [...TokenId, ...Id]);
      };
      TokenArr();
    }
  }, [
    walletProvider,
    currentAddress,
    gameCentreContract,
    warContract,
    awsmobile.aws_cognito_region,
    AWS.config.credentials,
  ]);

  return [TokenId, page, setPage, next, Next, Previous, loader];
};

export default useGetWarCardByFaction;
