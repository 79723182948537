import React from "react";
import "../../../CardCollection.css";
import { Link, Outlet } from "react-router-dom";
import * as AWS from "aws-sdk";
import awsmobile from "../../../../../../aws-exports";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "../../../../../Shared/Pagination";
import AuctionCard from "../../../../../Shared/AuctionCard/AuctionCard";
import { GetCardFaction } from "../../../../../UserPage/BlockChainFunctions";
import useGameCentreContract from "../../../../../Hooks/GameCentreHook";

async function getGeneralDetails(TokenId, gameCentreContract) {
  const Value = await GetCardFaction(TokenId, gameCentreContract);

  return Value;
}

function AuctionHumanityText() {
  const [TokenId, setTokenId] = useState([]);
  const [gameCentreContract, setGameCentreContract] = useGameCentreContract();
  const [TokenArray, setTokenArray] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);
  const [param, setParam] = useState({
    TableName: process.env.REACT_APP_AUCTION_TABLE_NAME,
    Limit: 30,

    ProjectionExpression: "TokenId",
  });

  async function GetTokenId() {
    const documentClient = new AWS.DynamoDB.DocumentClient({
      region: awsmobile.aws_cognito_region,
      credentials: AWS.config.credentials,
    });

    // console.log(param);
    const Id = [];
    try {
      const Response = await documentClient.scan(param).promise();
      if (Response.LastEvaluatedKey) {
        setParam({
          TableName: process.env.REACT_APP_AUCTION_TABLE_NAME,
          Limit: 30,

          ExclusiveStartKey: Response.LastEvaluatedKey,
          ProjectionExpression: "TokenId",
        });
      }
      for (let i = 0; i < Response.Items.length; i++) {
        const element = Response.Items[i];
        const faction = await getGeneralDetails(
          element.TokenId,
          gameCentreContract
        );
        // console.log(faction);
        // console.log(element.TokenId, "id testing");
        // console.log(TokenId, "tokenTesting");
        if (TokenId.indexOf(element.TokenId) === -1) {
          if (faction === "Humanity") {
            Id.push(element.TokenId);
            setNext(true);
          }
        } else {
          setNext(false);
        }
      }
    } catch (err) {
      // console.log(err);
    }
    return Id;
  }

  const Next = async () => {
    if (page === TokenArray.length) {
      const Id = await GetTokenId();
      if (Id.length === 0) {
        setNext(false);
      } else {
        setNext(true);
        setTokenId((TokenId) => [...TokenId, ...Id]);
        setTokenArray((TokenArray) => [...TokenArray, Id]);
        setPage((page) => page + 1);
      }
    } else {
      setPage((page) => page + 1);
    }
  };
  const Previous = () => {
    setNext(true);
    setPage((page) => page - 1);
  };
  useEffect(() => {
    if (gameCentreContract) {
      const TokenArr = async () => {
        const Id = await GetTokenId();
        setTokenId((TokenId) => [...TokenId, ...Id]);
        setTokenArray((TokenArray) => [...TokenArray, Id]);
      };
      TokenArr();
    }
  }, [gameCentreContract]);
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="content_box">
              <h4>HUMANITY</h4>

              <div className="row">
                {TokenArray[page - 1]?.map((TokenId) => (
                  <AuctionCard TokenId={TokenId} key={TokenId}></AuctionCard>
                ))}
                {TokenArray[page - 1]?.length === 0 && <h1>NO CARD</h1>}
              </div>
              <Pagination
                page={page}
                setPage={setPage}
                length={TokenArray.length}
                Previous={Previous}
                Next={Next}
                next={next}
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AuctionHumanityText;
