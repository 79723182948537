import React from "react";
import WarBattleBanner from "./Sections/WarBattleBanner";

import BreedSelfAliceCard from "../../../Breeding/BreedSelfCollection/BreedSelfAliceCard";
import AHWarBattleText from "./Sections/AHWarBattleText";
import { useLocation } from "react-router-dom";
import AHCard from "./AHCard/AHCard";
function AHWarBattlefield() {
  const location = useLocation();
  // console.log(location.state);
  return (
    <div>
      <WarBattleBanner />
      <AHWarBattleText state={location.state}/>
      {/* <CardTab/> */}
      {/* <BreedSelfAliceCard></BreedSelfAliceCard> */}
      <AHCard></AHCard>
    </div>
  );
}
export default AHWarBattlefield;
