import React, { useContext, useState } from "react";
import { navigationContext } from "../../App";
import "./PopUp.css";
const PopUp = ({ popUpText, popUpSubject, setPopUp, popUpText1 }) => {
  const [navigationCntxt, setNavigationCntxt] = useContext(navigationContext);

  return (
    <div
      className="popup"
      onClick={() => {
        setNavigationCntxt(true);
        setPopUp(false);
      }}
    >
      <div className="confirm-container">
        <div className="popup-msg">
          <h3>{popUpSubject} !</h3>
          <p>{popUpText}</p>
          <p>{popUpText1}</p>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
