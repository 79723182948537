import React from 'react';
// import Blacknetbanner from '../Cardcollection/SubPages/Blacknet/Sections/Blacknetbanner';
import BlacknetText from './SubPages/Blacknet/Sections/MutateBlacknetText';
import BlacknetList from './SubPages/Blacknet/Sections/MutateBlacknetList';
import '../MutateCollection/CardCollection.css';
import MutateBlacknetList from './SubPages/Blacknet/Sections/MutateBlacknetList';
import MutateBlacknetText from './SubPages/Blacknet/Sections/MutateBlacknetText';

function MutateBlacknetCard () {
	return <div>
		{/* <Blacknetbanner/> */}
		<MutateBlacknetList/>
		<MutateBlacknetText/>

        
	</div>
}
export default MutateBlacknetCard;