import React from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
function TransferAllcardsList () {
	return <div>
<section className="cards_menu_list mt-3">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <div className="nav_menu">
            <ul>
                  <li className="nav-item">
                     <Link className="nav-link active btn" to="/transfer-single-card">
                     ALL CARDS</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link btn" to="/transfer-single-card/blacknet">
                     BLACKNET</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link btn" to="/transfer-single-card/alice">
                     ALICE</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link btn" to="/transfer-single-card/humanity">
                     HUMANITY</Link>
                  </li>
                  <li className="nav-item">
                     <Link className="nav-link btn" to="/transfer-single-card/genesis">
                     GENESIS</Link>
                  </li>
                  </ul>
            </div>
         </div>
      </div>
   </div>
</section>
    </div>
}
export default TransferAllcardsList;