import React from "react";
import "./ConnectWallet.css";

const ConnectWallet = () => {
  return (
    <></>
  );
};

export default ConnectWallet;
